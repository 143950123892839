import React, {useRef, useState} from 'react';
import {Grid, GridColumn, GridDataStateChangeEvent} from '@progress/kendo-react-grid';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import {ColumnMeta} from "../../../interfaces/grid-interfaces";
import ExportPanel from '../../common/action-panel/ExportPanel';
import ActionPanel from '../../common/action-panel/ActionPanel';
import {initialDataState, replaceSpaceWithUnderscore} from '../../../services/data-grid-service';
import {process, State} from '@progress/kendo-data-query';
import ClearFilterPanel from '../../common/action-panel/ClearFilterPanel';
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';


interface HistoricalGridProps {
    columnMeta: Array<ColumnMeta>,
    data: Array<any>,
    title: string
}

const WrapHistoricalGrid: React.FC<HistoricalGridProps> = ({columnMeta, data, title}) => {
    const [dataState, setDataState] = useState<State>(initialDataState);
    const _export = useRef<ExcelExport | null>(null);

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(data);
        }
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    return (
        <div>
            <ActionPanel title={title}>
                <ClearFilterPanel clearFilter={clearFilters}/>
                <ExportPanel exportData={excelExport} disabled={false}/>
            </ActionPanel>
            <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`History_${title.split(' ').includes('Winter') ? title.split(' ')
                .filter((word) => word !== 'Winter') + ' Winter' : title.split(' ').includes('Summer') ? title.split(' ')
                .filter((word) => word !== 'Summer').join(' ') + ' Summer' : title + ' All'}.xlsx`)} ref={_export}>
                <Grid
                    data={process(data, dataState)}
                    resizable={true}
                    filterable={true}
                    sortable={true}
                    style={{ maxHeight: '873px' }}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                    onPageChange={pageChange}
                >
                    {columnMeta.length
                        ? columnMeta
                            .filter((col) => col.visInd === '1')
                            .map((col: ColumnMeta, index) => (
                                <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                            ))
                        : <GridColumn/>
                    }
                </Grid>
            </ExcelExport>
        </div>
    );
};

export default WrapHistoricalGrid;

