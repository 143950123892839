import * as React from 'react';
import {Table} from 'semantic-ui-react';
import CommentReplyModal from "./CommentReplyModal";
import CommentDeleteModal from "./CommentDeleteModal";

export interface CommentData {
        id: number,
        postingsId: number,
        parentId: number,
        userId: number,
        userName: string,
        userLongName: string,
        respEntityId: number,
        respEntNameType: string,
        entityLock: string,
        comment: string,
        isPrivate: boolean,
        createdDateTime: Date
}

export interface CommentResponse {
    commentsDeleted: number,
}

interface CommentProps {
    comment: CommentData,
    handleCommentReply(newComment: CommentData): void,
    handleCommentDelete(newComment: CommentData,commentResponse : any) : void,
}

export class Comment extends React.Component<CommentProps,{}> {
    constructor(props: CommentProps) {
        super(props);
        this.handleCommentReply = this.handleCommentReply.bind(this);
        this.handleCommentDelete = this.handleCommentDelete.bind(this);
    }

    handleCommentReply = (newComment: CommentData) => {
        this.props.handleCommentReply(newComment);
    };
    handleCommentDelete = (newComment: CommentData, commentResponse : any) => {
        this.props.handleCommentDelete(newComment, commentResponse);
    };

    render() {
        const privacyMessage = this.props.comment.isPrivate
            ? "Private: Only your entity and SPP can view this comment"
            : "Public: Everyone can view this comment";

        return (
            <Table celled={false} collapsing={false}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell colSpan={4}>{privacyMessage}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            {this.props.comment.userLongName}
                        </Table.Cell>
                        <Table.Cell>
                            {this.props.comment.respEntNameType}
                        </Table.Cell>
                        <Table.Cell>
                            <CommentReplyModal comment={this.props.comment}
                                               handleCommentReply={this.handleCommentReply}/>
                            </Table.Cell>
                        <Table.Cell>
                            <CommentDeleteModal comment={this.props.comment}
                                                handleCommentDelete={this.handleCommentDelete} />
                        </Table.Cell>
                        <Table.Cell>
                            {this.props.comment.createdDateTime}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan={5}>
                            <div style={{whiteSpace: 'normal'}}>
                                {this.props.comment.comment}
                            </div>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        )
    }
}