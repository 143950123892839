import {Container, Header, Menu} from 'semantic-ui-react';
import logo from '../SPPLogo.png';
import {loggedIn} from '../services/user-service';
import LoggedInMenu from './Menu/LoggedInMenu';

export const NavMenu = () => {

    return (
        <Container fluid={true}>
            <Menu className={'navMenu'} inverted={true} fixed={'top'}>
                <img src={logo} alt={'logo'} style={{height: '60px', marginTop: 7}}/>
                <Header as='h3' color={'grey'} style={{marginTop: '1rem', marginRight: 10}}>Engineering Data<br/>Submission Tool</Header>
                {loggedIn(true) && <LoggedInMenu/>}
            </Menu>
        </Container>
    );
}