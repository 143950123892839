import * as React from 'react';
import axios from 'axios';
import {Button, Icon, Table} from 'semantic-ui-react';
import {Comments, CommentLineage} from "./Comments";
import {CommentData} from "./Comment";
import PostingReplyModal from "./PostingReplyModal";
import PostingDeleteModal from "./PostingDeleteModal";
import PostingModal from "./PostingModal";
import {toast} from 'react-toastify';

const padStyle = {
    padding: '0',
    borderStyle: 'none',
    margin: '0',
};

interface CommentsContainerProps {
    postingId: number,
    currentUser: string,
    posting: any,
    isAdmin: boolean,
    onPostEdit: (post: any) => void,
    onDeletePost: () => void
}

interface CommentsContainerState {
    commentsList: CommentLineage[],
    commentCount: number,
    showComments: boolean,
    loading: boolean,
    creatingPosting: boolean,
    isPrivateOnly: boolean
}

export class CommentsContainer extends React.Component<CommentsContainerProps,CommentsContainerState> {
    _isMounted: boolean = false;

    constructor(props: CommentsContainerProps, state: CommentsContainerState) {
        super(props, state);
        this.state = {
            commentsList: [],
            commentCount: 0,
            showComments: false,
            loading: false,
            creatingPosting: false,
            isPrivateOnly: false
        };
    }

    addLineage = (commentList: CommentData[]) => {
        let descendantList: CommentLineage[] = [];
        if(commentList.length > 0) {
            let parent: number | null = commentList[0].parentId;
            //let parent: number | null = this.commentList[0].parentId;
            commentList.forEach((com) => {
                // com = comment (CommentData)
                if (com.parentId !== parent) {
                    descendantList.forEach((des) => {
                        // des = descendent (CommentLineage)
                        if (com.parentId === des.descendant.id) {
                            if (com.id !== des.descendant.id) {
                                descendantList.push({
                                    descendant: com,
                                    lineage: [...des.lineage, com.id]
                                });
                            }
                        }
                    });
                } else {
                    descendantList.push({
                        descendant: com,
                        // first = first parent in this iteration of Comments
                        lineage: [com.id]
                    });
                }
            });
            return descendantList;
        }
        else return [];
    };


    getComments = () => {
        this.setState({loading: true});
        axios.get(`/api/auth/comments/comments/${this.props.postingId}`)
            .then((resp) => {
                if (this._isMounted) {
                    this.setState({
                        commentsList: this.addLineage(resp.data),
                        commentCount: resp.data.length,
                    });
                }
            })
            .catch(() => {
                toast.error('Error loading comments.', {toastId: 'get-comments-error'});
            })
            .finally(() => {
                this.setState({loading: false});
            });
    };

    componentDidMount() {
        this._isMounted = true;
        this.getComments();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    toggleComments = () => {
        this.setState( (prevState) => {
            return {showComments: !prevState.showComments}
        });
    };

    addPostingReply = (newComment: CommentData) => {
        this.setState((prevState) => {
            return {
                commentsList: [...prevState.commentsList, {
                    descendant: newComment,
                    lineage: [newComment.id]
                }],
                commentCount: prevState.commentCount + 1,
                showComments: true,
            }
        });
    };

    addCommentReply = (newComment: CommentData) => {
        let found = false;
        let comment: CommentLineage = {descendant: newComment, lineage: []};
        for(let i = 0; i < this.state.commentsList.length && !found; i++) {
            if(this.state.commentsList[i].descendant.id === newComment.parentId) {
                found = true;
                comment.lineage = [...this.state.commentsList[i].lineage, newComment.id];
            }
        }

        this.setState((prevState) => {
            return {
                commentsList: [...prevState.commentsList, comment],
                commentCount: prevState.commentCount + 1,
                showComments: true,
            }
        });
    };
    deletePosting = () => {
       this.props.onDeletePost();
    };

    isPresent(array : any, element : number) {
        let i;
        for(i = 0; i < array.length ; i++) {

                if (array[i].descendant.id === element)
                    return i;

        }
        return -1;
    };

    togglePostingModal = () => {
        this.setState({creatingPosting: !this.state.creatingPosting})
    };
    editPosting = (posting: any) => {
        this.props.onPostEdit(posting);
        this.togglePostingModal();
    };
    updatePost =  (posting: any, commentResponse : any) => {
        this.props.onPostEdit(posting);
        //this.removeComment(posting);

        let index = this.isPresent(this.state.commentsList, posting.commentId);//this.state.data.findIndex(this.checkElement(data.commentId));
        let stateData = this.state.commentsList;
        if (index > -1) {
            stateData.splice(index, 1);
        }
        this.setState((prevState) => {
            return {
                commentsList: stateData,
                commentCount: prevState.commentCount - commentResponse.commentsDeleted,
                showComments: true,
            }
        });
    };
    render() {
        const action = (this.state.showComments) ? 'Hide' : 'Show';
        const showText = (this.state.commentCount > 1) ?
            action + ' ' + this.state.commentCount + ' Comments'
            : (this.state.commentCount === 1) ?
                action + ' ' + this.state.commentCount + ' Comment'
                : '0 Comments';

        return (
            <Table.Row>
                <Table.Cell colSpan={4} collapsing={true} style={padStyle}>
                    <Button
                        floated={'left'}
                        size={'mini'}
                        style={{ width: '120px'}}
                        onClick={this.toggleComments}
                        content={showText}
                        disabled={this.state.commentCount === 0}
                        loading={this.state.loading}
                    />
                    <PostingReplyModal
                        postingId={this.props.postingId}
                        handlePostReply={this.addPostingReply}
                    />
                    <PostingModal
                        open={this.state.creatingPosting}
                        onClose={this.togglePostingModal}
                        currentUser={this.props.currentUser}
                        toggle={this.editPosting}
                        editPosting={true}
                        post = {this.props.posting}
                    />
                    {this.props.isAdmin &&
                        <Button
                            floated={'left'}
                            size={'mini'}
                            color={'black'}
                            onClick={this.togglePostingModal}
                            disabled={this.state.loading}
                        >
                            <Icon name='edit'/>
                            Edit
                        </Button>
                    }
                    {this.props.isAdmin &&
                        <PostingDeleteModal
                            postingId={this.props.postingId}
                            post={this.props.posting}
                            handleDelete={this.deletePosting}
                        />
                    }
                    {this.state.commentCount > 0 && this.state.showComments &&
                        <Comments
                            commentsList={this.state.commentsList}
                            handleCommentReply={this.addCommentReply}
                            handleCommentDelete={this.updatePost}
                        />
                    }
                </Table.Cell>
            </Table.Row>
        );
    }
}
