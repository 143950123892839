import {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import {
    Grid,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent,
    GridPageChangeEvent
} from '@progress/kendo-react-grid';
import {process, State} from '@progress/kendo-data-query';
import {format} from 'date-fns';
import {toast} from 'react-toastify';
import {ApiValidations, DataUpdate, PageRole, UiValidations} from '../../../../interfaces/grid-interfaces';
import {
    replaceSpaceWithUnderscore,
    toastPreSaveValidationErrors,
    toastSuccessfulSave
} from '../../../../services/data-grid-service';
import {
    distinctNumberArray,
    getInitialDataState,
    updateEditedRows,
    updateEditedVerified
} from '../../../../services/generic-data-grid-service';
import ActionPanel from '../../action-panel/ActionPanel';
import AddPanel from '../../action-panel/AddPanel';
import ClearFilterPanel from '../../action-panel/ClearFilterPanel';
import ExportPanel from '../../action-panel/ExportPanel';
import ImportPanel from '../../action-panel/ImportPanel';
import RefreshPanel from '../../action-panel/RefreshPanel';
import RemoveModal from '../../action-panel/RemoveModal';
import RemovePanel from '../../action-panel/RemovePanel';
import SavePanel from '../../action-panel/SavePanel';
import ValidatePanel from '../../action-panel/ValidatePanel';
import VerifyPanel from '../../action-panel/VerifyPanel';
import {getRemoveCell} from '../RemoveCell';
import TooltipContainer from '../TooltipContainer';
import ValidationMessageGrid from '../ValidationMessageGrid';
import ValidationPreSaveGrid from '../ValidationPreSaveGrid';
import {CustomLocalizationProvider, getVerifiedCell} from '../VerifiedCell';

interface EditData<T extends Record<string, any>> {
    inEdit?: boolean,
    originalData?: T,
    removed?: boolean
}

export type GridEditData<T> = T & EditData<T>;

interface GenericEditGridProps<T> extends PageRole {
    title: string;
    windowOpen: boolean;
    data: GridEditData<T>[];
    setData: (value: SetStateAction<GridEditData<T>[]>) => void;
    keyField: string;
    getData: () => Promise<boolean>;
    addRow: (sequence: number) => number;
    verifyData?: (verifyList: T[]) => Promise<boolean>;
    saveData: (saveList: T[]) => Promise<boolean>;
    deleteData?: (removeList: number[]) => Promise<boolean>;
    validateData?: () => Promise<ApiValidations[]>;
    importApi: string;
    importData: (uploadData: T[], sequence: number,
                 setEditedRows: Dispatch<SetStateAction<DataUpdate[]>>,
                 setDataErrors: Dispatch<SetStateAction<UiValidations[]>>) => number;
    handleChangeSideEffects: (item: T, id: number, field: string, value: any,
                              setDataErrors: Dispatch<SetStateAction<UiValidations[]>>) => void;
    filterEmptyRequiredColumns: (setDataErrors: Dispatch<SetStateAction<UiValidations[]>>) => boolean;
    formatCustomExport?: (data: T[]) => any[];
    children: ReactNode;
}

export const GenericEditGrid = <T extends Record<string, any>>(
    {
        title, windowOpen,
        isStaff, readRole, addRole, modifyRole, removeRole,
        data, setData, keyField, getData, verifyData, saveData, deleteData, importData, validateData,
        addRow, handleChangeSideEffects, importApi, filterEmptyRequiredColumns, formatCustomExport,
        children
    }: GenericEditGridProps<T>) => {

    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRows, setEditedRows] = useState<DataUpdate[]>([]);
    const [editedVerified, setEditedVerified] = useState<number[]>([]);
    const [editedRemoved, setEditedRemoved] = useState<number[]>([]);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [dataState, setDataState] = useState<State>(getInitialDataState(keyField));
    const [dataErrors, setDataErrors] = useState<UiValidations[]>([]);
    const [validationErrors, setValidationErrors] = useState<ApiValidations[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

    const refreshData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        getData()
            .finally(() => {
                setLoadingData(false);
            });
    }, [getData, readRole]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    /* Edit */

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = (id: number, field: string, value: any) => {
        let rowItem;
        const localData = data.map((item) => {
            if (item[keyField] === id) {
                const dataItem = {...item};
                dataItem[field as keyof T] = value;
                handleChangeSideEffects(dataItem, id, field, value, setDataErrors);
                rowItem = dataItem;
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (rowItem) {
            if (inEdit && id > 0) { // updating a record already in the database
                setEditedRows(updateEditedRows(editedRows, rowItem, keyField, field));
            }
            if (inVerify && field === 'verified') {
                setEditedVerified(updateEditedVerified(editedVerified, rowItem, keyField));
            }
        }
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
        resetRemove();
    };

    /* Add */

    const addNewRow = () => {
        let sequence = addRow(tempSequence);
        setTempSequence(sequence);
    };

    /* Save */

    const save = () => {
        if (filterEmptyRequiredColumns(setDataErrors)) {
            return;
        }

        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }
        // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        //save the data
        saveData(newAndUpdatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch(() => {
                toast.error('Error saving data');
            });
    };

    /* Verify */

    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        refreshData();
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (!verifyData) {
            return;
        }
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        verifyData(updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
            })
            .catch(() => {
                toast.error('Error verifying data.');
            });
    };

    const handleSelectAll = () => {
        const newlyValidated = data
            .filter(d => !d.verified)
            .map(d => Number(d[keyField]));

        const localData = data.map(d => ({...d, verified: true}));

        setEditedVerified(distinctNumberArray(newlyValidated.concat(editedVerified)));
        setData(localData);
    };

    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);

    /* Delete */

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            // initialize 'removed' field to false on all data objects
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }
    };

    const cancelRemove = () => {
        const localData = data.map(d => ({...d, removed: undefined}));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openRemoveModal = () => setRemoveModalOpen(true);
    const closeRemoveModal = () => setRemoveModalOpen(false);

    const confirmRemove = () => {
        if (!deleteData) {
            return;
        }
        deleteData(editedRemoved)
            .then(() => {
                setRemoveModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));
                const localDataErrors = dataErrors.filter(item => !editedRemoved.includes(item.id));

                toast.success("Successfully deleted data");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    setDataErrors(localDataErrors);
                    resetRemove();
                }
            })
            .catch(() => {
                toast.error('Error deleting data.');
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item[keyField] === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
    };

    const RemoveRowCell = getRemoveCell(handleRemoveChange);

    /* dataState */

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    /* Import */

    const handleUpload = (uploadData: any[]) => {
        let sequence = importData(uploadData, tempSequence, setEditedRows, setDataErrors);
        setTempSequence(sequence);
    };

    /* Export */

    const formatExport = (data: T[]) => {
        if (!verifyData && !formatCustomExport) {
            return data;
        }
        if (!formatCustomExport) {
            return data.map(item => ({
                ...item,
                verified: item.verified ? 'YES' : 'NO'
            }));
        }
        return formatCustomExport(data);
    }

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    /* Validate */

    const handleValidation = () => {
        if (!validateData) {
            return;
        }
        setValidationErrors([]);
        setLoadingValidation(true);
        validateData()
            .then(response => {
                setValidationErrors(response);
            })
            .catch(() => {
                toast.error('Error validating data.');
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && !!deleteData && <RemovePanel inRemove={inRemove} openModal={openRemoveModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && (windowOpen || isStaff) && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={importApi} setUploadRecords={handleUpload} />}
                {!inEdit && modifyRole && isStaff && !!verifyData &&
                    <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleSelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && !inVerify && modifyRole && (windowOpen || isStaff) && !!validateData &&
                    <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={handleValidation}/>}
            </ActionPanel>
            <RemoveModal open={removeModalOpen} closeModal={closeRemoveModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                    <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(), 'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                        <Grid
                            data={process(data, dataState)}
                            dataItemKey={keyField}
                            editField='inEdit'
                            onItemChange={itemChange}
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                            onPageChange={pageChange}
                            style={{maxHeight: '400px'}}
                        >
                            {inEdit && inRemove && !!deleteData &&
                                <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                            {!!verifyData &&
                                <GridColumn field='verified' title='SPP Verified' width='105px' cell={VerifiedCell} filter={'boolean'}/>}
                            {children}
                        </Grid>
                    </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'Load'}/>}
        </div>
    );
};