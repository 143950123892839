import 'core-js/stable';
import ReactDOM from 'react-dom';
import history from "./history";
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import './kendo-react-theme.scss';
import axios, {InternalAxiosRequestConfig} from 'axios';
import configureStore from "./store/configureStore";
import {toast} from "react-toastify";
import {Provider} from "react-redux";
import AppRoutes from "./routes";

const store = configureStore();

axios.interceptors.response.use(
    (response:any) => {
        return response;
    },
    (error:any) => {
        if (typeof error.response.data === 'object') {
            error.response.data.badToken = false;
        }
        let code = error.response.status;
        switch(code) {
            case 403:
                history.push({
                    pathname: '/accessdenied',
                    state: {from: window.location !== undefined ? (window.location.search !== undefined ? {pathname: window.location.pathname, search: window.location.search} : {pathname: window.location.pathname}) : {pathname: '/'}}
                });
                break;
            case 401:
                //toast.warn('Not Authenticated, please login through portal first.');
                if (typeof error.response.data === 'object') {
                    error.response.data.badToken = true;
                }
                history.push({
                    pathname: '/',
                    state: {from: window.location !== undefined ? (window.location.search !== undefined ? {pathname: window.location.pathname, search: window.location.search} : {pathname: window.location.pathname}) : {pathname: '/'}}
                });
                break;
            case 500:
                if(error.response.data.message !== '') {
                    toast.error(error.response.data.message);
                }
                else {
                    toast.error(error.message);
                }
                break;
        }

        return Promise.reject(error);
    }
);

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    let token = localStorage.getItem('edst_token');
    if(token == null || token === '') {
        config.headers.set('user', localStorage.getItem('userName') || '');
        config.headers.set('uuid', sessionStorage.getItem('uuid') || '');
    }
    else {
        config.headers.set('Authorization', `Bearer ${token}`);
    }

    return config;
});

ReactDOM.render(
    <Provider store={store}><AppRoutes /></Provider>,
    document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();