import {GridCellProps} from '@progress/kendo-react-grid';
import * as React from 'react';
import {GridEditContext} from './context/GridEditContext';
import {FORM_DATA_INDEX, requiredValidator} from './RowEditGrid';
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import {Field, FieldRenderProps} from '@progress/kendo-react-form';
import {FacilityLinkingOptionContext} from './context/FacilityLinkingOptionContext';

export const DropDownListWithValidation = (fieldRenderProps: FieldRenderProps) => {
    const {validationMessage, visited, ...others} = fieldRenderProps;
    return (
        <div>
            <DropDownList {...others} />
            {visited && validationMessage && (
                <div role='alert' className='k-form-error k-text-start'>
                    {validationMessage}
                </div>
            )}
        </div>
    );
};

export const ParticipantOptionFormCell = (props: GridCellProps) => {
    const {editIndex} = React.useContext(GridEditContext);
    const {setFacilityOptions, participantOptions, facilityParticipantList} = React.useContext(FacilityLinkingOptionContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const initialSelected = props.field ? props.dataItem[props.field] : [];
    const [selected, setSelected] = React.useState<string[]>(initialSelected);

    const handleChange = (event: DropDownListChangeEvent) => {
        const participant = event.target.value;
        setFacilityOptions(Array.from(new Set(facilityParticipantList
            .filter(f => f.participantNm === participant)
            .map(f => f.facilityNm))));
        setSelected(participant);
        if (props.onChange && editIndex) {
            props.onChange({
                dataIndex: editIndex,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: participant
            });
        }
    };

    return (
        <td>
            {isInEdit ? (
                <Field
                    name={`${props.field}`}
                    component={DropDownListWithValidation}
                    data={participantOptions}
                    value={selected}
                    onChange={handleChange}
                    validator={requiredValidator}
                />
            ) : (
                selected
            )}
        </td>
    );
};