import * as React from 'react';
import {
    Button,
    Form,
    Header,
    Icon,
    Modal,
    ModalProps,
    Segment,
    Select,
    Dimmer,
    Loader,
    Checkbox,
    CheckboxProps
} from "semantic-ui-react";
import * as actionTypes from "../../../store/actions/actionTypes";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import AdminService from "../../../services/admin-services";
import GeneralService from "../../../services/general-services";

interface EntityModalProps extends ModalProps {
}

interface EntityModalState {
    id: number
    entityTypeName: string
    entityTypeLongName: string
    entityTypeId: number
    existingEntity: boolean;
    nm: string,
    longNm: string,
    loading: boolean
    submitButtonDisabled: boolean
    submitEntNm: string
    respEntNm: string
    respEntId: number
    lreEntId: number
    mpEntNm: string
    lreEntNm: string
    entityTypeDropdownVals: any[]
    availableRespEntDropdown: any[]
    availableLreEntDropdown: any[]
    entityTypes: any[]
    subRespEntRel: any[]
    mpLreEntRel: any[]
    subRespEntJsx: any[]
    mpLreRelJsx: any[]
    currentSubYear: number
    templateModalNeeded: boolean;
    windSolarOnly: boolean;
}

const initialState = {
    id: 0,
    entityTypeName: '',
    entityTypeLongName: '',
    entityTypeId: 0,
    existingEntity: false,
    nm: '',
    longNm: '',
    loading: false,
    submitButtonDisabled: true,
    submitEntNm: '',
    respEntNm: '',
    respEntId: 0,
    lreEntId: 0,
    mpEntNm: '',
    lreEntNm: '',
    entityTypeDropdownVals: [],
    availableRespEntDropdown: [],
    availableLreEntDropdown: [],
    entityTypes: [],
    subRespEntRel: [],
    mpLreEntRel: [],
    subRespEntJsx: [],
    mpLreRelJsx: [],
    currentSubYear: 0,
    templateModalNeeded: false,
    windSolarOnly: false
}
const adminClient = new AdminService();
const generalService = new GeneralService();
let some = require('lodash/some');

/**Entity modal that handles all of the user interaction for adding or modifying entity information, as well and adding
 * or deleting submitting/responsible entity relationships and mp/lre relationships for the selected entity. */
class EntityModal extends React.Component<EntityModalProps,EntityModalState> {
    constructor(props: EntityModalProps) {
        super(props);
        this.state = initialState
    }

    componentDidMount(): void {
        /**When the component mounts we need to get all of the dropdown values for each editable field, as well as getting
         * a list of all of the potential Lre values.*/
        this.setUpModal();
    }

    componentWillReceiveProps(nextProps: any, nextState:any) {
        /**componentDidMount doesn't get called consistently for modals, so we will also run this and check for changes
         * to determine if we have a different row that our previous prop. If so we will take same actions as component
         * did mount, and will also set current row in state to nextProps.current row value.*/
        if (this.props.currentRow !== nextProps.currentRow) {
            this.setUpModal();
            this.setState(nextProps.currentRow);

        }

    }

    componentDidUpdate(prevProps: Readonly<EntityModalProps>, prevState: Readonly<EntityModalState>, snapshot?: any): void {
        /** Checks values of state to determine if anything in the modal has changed. Updates the dropdowns accordingly.
         * Also does validation to ensure the required fields are complete before activating the submit/update buttons.*/

        //This will be called when a new entity is added through the modal
        if(prevState.id !== this.state.id && this.state.id !== 0){
            this.getExistingRels('subRespRel');
            this.getExistingRels('mpLreRel');
            this.setState({submitEntNm: '', respEntNm: ''})
        }
        else {
            //If any of the data has changed and all required fields are completed, activate the submit button
            if (prevState.nm !== this.state.nm || prevState.longNm !== this.state.longNm ||
                prevState.entityTypeName !== this.state.entityTypeName ||
                prevState.entityTypeLongName !== this.state.entityTypeLongName ||
                prevState.windSolarOnly !== this.state.windSolarOnly) {
                if (this.state.nm && this.state.longNm && this.state.entityTypeLongName) {
                    this.setState({submitButtonDisabled: false});
                }
            }
        }
    }

    handleSubmitClick = () =>{
        /**Conditional logic to define what method to use when the submit button is clicked, depending on the addOrModifyEntity
         * prop stored in the redux store. */
        if(this.props.addOrModifyEntity === 'Modify') {
            this.modifyEntityInfo();
        } else {
            this.addEntity();
        }
    }

    handleCancelClick = () => {
        this.closeModalCleanup();
    }

    closeModalCleanup = () => {
        /**Steps taken each time the modal is closed to reset state and redux properties to ensure the modal returns to
         * the proper state the next time it is opened.*/
        //set state back to empty so that a blank form is opened next time they click Add Contact
        this.setState(initialState)
        //reset redux store values to the initial
        this.props.updateEntityModalActive(false);
        this.props.updateAddOrModifyEntity('');
        this.props.updateCurrentRow({});

    }

    modifyEntityInfo = () => {
        /**Sends the new data via an axios call and updates the database with changes made by the user for the entity.
         * This is changes to the entity name, long name, and type. Changes to relationships are managed in the add and
         * delete relationship methods for each relationship type.*/

        //Get then entity type info for the current, selected entity type data stored in state
        let myEntity = this.state.entityTypes.find((entity) => entity.entityType_nm === this.state.entityTypeName);
        axios.post(`/api/auth/entity/responsibleEntity/update`, null,
            {
                params: {id: this.state.id,
                        entTypId: myEntity.id,
                        nm: this.state.nm,
                        longNm: this.state.longNm,
                        windSolarOnly: this.state.windSolarOnly
                }
            }).then(response => {
            if (response.status === 200) {
                toast.success('Entity information updated successfully!')
                this.setState({submitButtonDisabled: true})
            }
        }).catch(response => {
            toast.error(`Error while updating entity info - ${response.message}`)
        });
    }

    addEntity = () => {
        /**Axios call to add the entity to the database. Once this action completes succesfully the user is able to add
         * relationships to the entity. */
        /*let data = {
            entTypId: this.state.entityTypeId,
            nm: this.state.nm,
            longNm: this.state.longNm,
            comments: null,
            windSolarOnly: this.state.windSolarOnly
        };
        */
        //Check to see if the entity name/type combination already exists in the database. If not, we will add the entity.
        //If it already exists, we'll send an error message to the user.
        let existingEntity = some(this.props.allEntities, {entityTypeName: this.state.entityTypeName, nm: this.state.nm})
        if(!existingEntity) {
            axios.post(`/api/auth/entity/responsibleEntity/add`, null,
                { params: {
                        entTypId: this.state.entityTypeId,
                        nm: this.state.nm,
                        longNm: this.state.longNm,
                        comments: null,
                        windSolarOnly: this.state.windSolarOnly
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        let responseArray: any[] = response.data.split(' ');
                        let entityId: number = parseInt(responseArray[responseArray.length - 1]);
                        this.setState({id: entityId})

                        //Once the entity is succesfully added to the database, we change the redux addOrModifyEntity
                        //property to Modify so that we can add relationships or modify the added entity
                        this.props.updateAddOrModifyEntity('Modify');
                        toast.success('Entity added successfully!')
                    }
                }).catch(response => {
                toast.error(`Error occurred adding a new entity - ${response.message}`)
                this.closeModalCleanup();
            });
        } else{
            toast.error('The selected entity name/entity type combination already exists.')
        }
    }

    handleFormChange = (e: any, { value, name}: any) => {
        /** Updates state as values in the form are changed. This displays the new value in the input box, and also prepares
         * state to be sent across when we add or modify the record.*/
        let field;
        field = name
        if(name === 'entityTypeName'){
            let entityData = this.state.entityTypes.find((entity: any) => entity.entityType_nm === value)
            this.setState({entityTypeLongName: entityData.entityType_longNm, entityTypeName: value, entityTypeId: entityData.id})
        } else if(name === 'respEntNm') {
            let entityData = this.state.availableRespEntDropdown.find((entity: any) => entity.value === value)
            this.setState({respEntId: entityData.key, respEntNm: value})
        }else if(name === 'lreEntNm') {
            let entityData = this.state.availableLreEntDropdown.find((entity: any) => entity.value === value)
            this.setState({lreEntId: entityData.key, lreEntNm: value})
        }
        else{
            // @ts-ignore
            this.setState({[field]: value})
        }
    }

    checkBoxChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        this.setState({ [data.name as string] : data.checked } as any);
    };

    setUpModal = () =>{
        this.getEntityTypeDropdownVals();
        generalService.getCurrentSubYear('RA')
            .then((resp: any) => {
                this.props.updateSubYear(resp.data);
                this.getAvailableRespEntDropdownVals();
                this.getAvailableRespLreVals();
            });
    }

    addEntRel = (e: any , {id}: any) =>{
        /** Axios call to add a new entity relationship for the selected entity.*/
        let data;
        let url;
        if(id === 'subRespRel') {
            url = '/api/auth/entity/submitentrespentrel/add';
            data = { 
                params: {
                    subYearId: this.props.subYear,
                    submitEntId: this.state.id,
                    respEntId: this.state.respEntId,
                    comments: null
                }
            };
        } else {
            url = '/api/auth/entity/mptolre/add';
            data = { 
                params: {
                    subYearId: this.props.subYear,
                    mPEntId: this.state.id,
                    lREEntId: this.state.lreEntId,
                    comments: null
                }
            };
        }


        axios.post(url, null, data)
            .then(response => {
                if (response.status === 200) {
                    //If the update was successful, we request a new list of relationships to update the modal with the
                    //latest.
                    this.getExistingRels(id);
                    this.setState({respEntNm: '',lreEntNm: ''})
                } else {
                    if(response.data.toString().includes('already has this role:')){
                        toast.error(`User role already exists.`)
                    }
                }
            }).catch(response => {
            toast.error(`Error occurred adding a new role - ${response.message}`)
            this.closeModalCleanup();
        });
    }

    getExistingRels = (relType:string) =>{
        /**Gets all existing entity relationships from database and calls createRole, which builds
         * the JSX that is used to display the relationships in the modal */
        this.setState({loading: true});
        let url
        if(relType === 'subRespRel'){
            url = `/api/auth/entity/allentrespmapping`
        } else {
            url = `/api/auth/entity/allmp2lremapping`
        }
        axios.get(url,{
                params: {
                    subyearid: this.props.subYear
                    ,id: this.state.id
                }
            })
            .then(response => {
                if (response.status === 200) {
                    if(relType === 'subRespRel'){
                        this.setState({subRespEntRel: response.data})
                        let element: any[] = []
                        response.data.forEach((r:any)=>{
                            element.push(this.createRole(r.submitEntNm + " (" + r.submitEntType + ")", 
                                r.respEntNm + " (" + r.respEntType + ")", 
                                r.submitEntId + '-' + r.respEntId, 'subRespRel'));
                        });
                        this.setState({subRespEntJsx: element, loading: false});

                    } else{
                        this.setState({mpLreEntRel: response.data})
                        let element: any[] = []
                        response.data.forEach((r:any)=>{
                            element.push(this.createRole(r.mpEntIdNm + " (" + r.mpEntType + ")", r.lreEntNm + " (" + r.lreEntType + ")", r.mpentId + '-' + r.lreentId, 'mpLreRel'))

                        });
                        this.setState({mpLreRelJsx: element, loading: false});
                    }
                }
            }).catch(response => {
            toast.error(`Error occurred adding a new posting - ${response.message}`)
            this.closeModalCleanup();
        });
    }

    createRole = (submitEntNm: string, respEntNm: string, roleId: string, relType: string ) =>{
        /**JSX for each relationship. Gets called for each relationship and added to an array to display multiple
         * relationships in the modal*/
        return(
        <Form.Group key={roleId}>

                <Form.Input fluid={true}
                    // label={{children: 'Stakeholder Name', htmlFor: 'form-select-control-type'}}
                    //         placeholder='Stakeholder Name'
                            searchInput={{id: 'form-select-control-type'}}
                            value={submitEntNm}
                            name={'submitEntNm'}
                            width={12}
                            readOnly={true}
                />
                <Form.Input fluid={true}
                            // placeholder='Type'
                            searchInput={{id: 'form-select-control-type'}}
                            value={respEntNm}
                            name={'respEntType'}
                            width={12}
                            readOnly={true}
                />
            <Form.Button icon={true} color={'red'} onClick={this.deleteEntRel} index={roleId} id={relType}>

                <Icon name='delete'/>

            </Form.Button>
        </Form.Group>

        )
    }

    deleteEntRel = (e: any, {index, id}: any) =>{
        /** Axios call to delete a submitting/responsible entity relationship for the selected entity. The ids are stored as a '-' seperated
         * string in the index value and passed in, then split and passed back to the back end.*/

        let url, data;

        if(id ==='subRespRel'){
            url = '/api/auth/entity/submitentrespentrel/delete'
            data = { params: {
                    subyearId: this.props.subYear,
                    respEntId: parseInt(index.split('-')[1])
                }
            }
        } else {
            url = '/api/auth/entity/mptolre/delete'
            data = { params: {
                    mPEntId: parseInt(index.split('-')[0]),
                    lREEntId: parseInt(index.split('-')[1])
                }
            }
        }

        axios.delete(url, data)
            .then(response => {
                if (response.status === 200) {
                    this.getExistingRels(id);
                }
            }).catch(response => {
            toast.error(`Error occurred deleting entity relationship - ${response.message}`)
            this.closeModalCleanup();
        });
    }

    getEntityTypeDropdownVals = () => {
        /**Collects the dropdown values via an axios call through the admin client. Those values are then added to an
         * array of objects formatted properly for semantic react ui to handle in the dropdown. */
        adminClient.getEntityDropdowns().then( (response:any) => {
            this.setState({entityTypes: response.data})
            let dropDownItems: any = []
            response.data.forEach((s: any) => {
                dropDownItems.push({key: s.id, text: s.entityType_longNm, value: s.entityType_nm})
            });

            this.setState({entityTypeDropdownVals: dropDownItems, loading: false});
        });
    }

    getAvailableRespEntDropdownVals = () => {
        /**Collects the dropdown values via an axios call through the admin client. Those values are then added to an
         * array of objects formatted properly for semantic react ui to handle in the dropdown. */
        adminClient.getAvailableRespEntDropdowns(this.props.subYear).then( (response:any) => {
            let dropDownItems: any = []
            response.data.forEach((s: any) => {
                dropDownItems.push({key: s.id, text: s.nm + " (" + s.entityTypeName + ")", value: s.nm + " (" + s.entityTypeName + ")"})
            });

            this.setState({availableRespEntDropdown: dropDownItems, loading: false});
        });
    }

    getAvailableRespLreVals = () => {
        /**Collects the dropdown values via an axios call through the admin client. Those values are then added to an
         * array of objects formatted properly for semantic react ui to handle in the dropdown. */
        adminClient.getAvailableLreEntDropdowns(this.props.subYear).then( (response:any) => {
            let dropDownItems: any = []
            response.data.forEach((s: any) => {
                dropDownItems.push({key: s.id, text: s.nm + " (" + s.entityTypeName + ")", value: s.nm})
            });

            this.setState({availableLreEntDropdown: dropDownItems, loading: false});
        });
    }

    public render(){
        /**JSX for displaying the modal*/
        return (
            <div>
            <Modal size={"large"} open={this.props.entityModalActive}>
                <Modal.Header>
                    {this.props.addOrModifyEntity === 'Add' ? 'Add Entity': 'Modify Entity/Manage Relationships'}
                </Modal.Header>
                <Modal.Content>
                    <div className={'addContactDiv'}>

                        <Form>
                            {this.state.loading?
                                <div>
                                    <Dimmer inverted={true} active={true}>
                                        <Loader inverted={true} content='Loading' />
                                    </Dimmer>
                                </div>: null }

                            <Segment>
                                <Header>Entity Info</Header>
                                <Form.Input fluid={true} label='Name' entryType={'string'} className={'nm'}
                                            placeholder={'Name'}
                                            onChange={this.handleFormChange}
                                            name={'nm'}
                                            value={this.state.nm}
                                />
                                <Form.Input fluid={true} label='Long Name' entryType={'string'} className={'longName'}
                                            placeholder={'Long Name'}
                                            onChange={this.handleFormChange}
                                            name={'longNm'}
                                            value={this.state.longNm}
                                />

                                <Form.Input required={true} fluid={true} selection={true}
                                            label={'Type'}
                                            control={Select}
                                            options={this.state.entityTypeDropdownVals}
                                            placeholder='Entity Type'
                                            searchInput={{id: 'form-select-control-type'}}
                                            onChange={this.handleFormChange}
                                            value={this.state.entityTypeName}
                                            name={'entityTypeName'}
                                            width={12}
                                            search={true}
                                />
                                <Form.Input  label={'Wind/Solar Only'} >
                                    <Checkbox checked={this.state.windSolarOnly} name={'windSolarOnly'} onChange={this.checkBoxChange}/>
                                </Form.Input>

                                <Modal.Actions>
                                    {this.props.addOrModifyEntity === 'Add' ?
                                        <div>
                                            < Button content={'Add Entity'} color={'blue'} onClick={this.handleSubmitClick}
                                                     disabled={this.state.submitButtonDisabled}/>
                                            <Button content={'Cancel'} color={'red'} onClick={this.handleCancelClick}/>
                                        </div>:
                                        <div>
                                            < Button content={'Submit Changes'} color={'blue'} onClick={this.handleSubmitClick}
                                                     disabled={this.state.submitButtonDisabled}/>
                                            <Button content={'Cancel'} color={'red'} onClick={this.handleCancelClick} disabled={this.state.submitButtonDisabled}/>
                                        </div>
                                    }

                                </Modal.Actions>

                            </Segment>

                            {this.props.addOrModifyEntity === 'Modify' ?
                                <Segment>
                                    <Header>Submit/Resp Relationships</Header>
                                    <Segment>
                                        <Header as={'h4'}>Add Relationship</Header>
                            <Form.Group>


                                    <Form.Input required={true} fluid={true}
                                                searchInput={{id: 'form-select-control-type'}}
                                                value={this.state.nm}
                                                name={'submitEntNm'}
                                                width={12}
                                                search={true}
                                                readOnly={true}
                                    />
                                <Form.Input required={true} fluid={true} selection={true}
                                            control={Select}
                                            options={this.state.availableRespEntDropdown}
                                            placeholder='Responsible Entity'
                                            searchInput={{id: 'form-select-control-type'}}
                                            onChange={this.handleFormChange}
                                            value={this.state.respEntNm}
                                            name={'respEntNm'}
                                            width={12}
                                            search={true}
                                />
                                <Form.Button icon={true} color={'green'} onClick={this.addEntRel} disabled={!this.state.respEntNm} id={'subRespRel'}>

                                            <Icon name='add' />

                                </Form.Button>
                            </Form.Group>
                                    </Segment>
                                    {this.state.subRespEntRel.length?
                                        <div>
                                    <Segment>
                                        <Header as={'h4'}>Delete Relationships</Header>
                                    {this.state.subRespEntJsx}
                                    </Segment>
                                        </div>: null}
                                </Segment>

                                : null}


                            {this.props.addOrModifyEntity === 'Modify' && this.props.userType === 'RA'  ?
                            <Segment>
                                <Header>MP/LRE Relationships</Header>
                                    <Segment>
                                        <Header as={'h4'}>Add Relationship</Header>
                                        <Form.Group>


                                            <Form.Input required={true} fluid={true}
                                                        searchInput={{id: 'form-select-control-type'}}
                                                        value={this.state.nm}
                                                        name={'mpEntNm'}
                                                        width={12}
                                                        search={true}
                                                        readOnly={true}
                                            />
                                            <Form.Input required={true} fluid={true} selection={true}
                                                        control={Select}
                                                        options={this.state.availableLreEntDropdown}
                                                        placeholder='LRE'
                                                        searchInput={{id: 'form-select-control-type'}}
                                                        onChange={this.handleFormChange}
                                                        value={this.state.lreEntNm}
                                                        name={'lreEntNm'}
                                                        width={12}
                                                        search={true}
                                            />
                                            <Form.Button icon={true} color={'green'} onClick={this.addEntRel}
                                                         disabled={!this.state.lreEntNm} id={'mpLreRel'}>

                                                <Icon name='add'/>

                                            </Form.Button>
                                        </Form.Group>
                                    </Segment>
                                    {this.state.mpLreRelJsx.length?
                                        <div>
                                    <Segment>
                                        <Header as={'h4'}>Delete Relationships</Header>
                                    {this.state.mpLreRelJsx}
                                    </Segment>
                                        </div>: null}
                            </Segment>

                            : null}

                        </Form>

                    </div>
                </Modal.Content>
                    <Modal.Actions>
                        <Button content={'Done'} color={'blue'} onClick={this.handleCancelClick}/>
                    </Modal.Actions>

            </Modal>
            </div>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        entityModalActive: Boolean(state.entityManagementReducer.entityModalActive),
        addOrModifyEntity: String(state.entityManagementReducer.addOrModifyEntity),
        currentRow: state.defaultReducer.currentRow,
        subYear: state.defaultReducer.subYear,
        userType: state.defaultReducer.userType,
        allEntities: state.entityManagementReducer.allEntities,

    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentRow: (currentRow: boolean) => dispatch(
            {type: actionTypes.SET_CURRENTROW, payload: currentRow}),
        updateAddOrModifyEntity: (addOrModifyEntity: string) => dispatch(
            {type: actionTypes.SET_ADDORMODIFYENTITY, payload: addOrModifyEntity}),
        updateEntityModalActive: (entityModalActive: boolean) => dispatch(
            {type: actionTypes.SET_ENTITYMODALACTIVE, payload: entityModalActive}),
        updateSubYear: (subYear: number) => dispatch(
            {type: actionTypes.SET_SUBYEAR, payload: subYear}),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityModal);