import * as React from 'react';
import {Calendar as BigCalendar, dateFnsLocalizer, View} from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Grid, Segment} from "semantic-ui-react";
import PostingModal from "./Comment/PostingModal";
import axios from "axios";
import {toast} from "react-toastify";
import {connect} from 'react-redux';

const locales = {'en-US': enUS};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});

interface Event {
    id: number
    title: string
    startDate: Date
    endDate: Date
}

interface CalendarProps {
    currentUser: string,
    roles: any[],
    userEntities: string[],
    currentCategory: string,
    sppStaff: boolean
}

interface CalendarState {
    creatingEvent: boolean,
    postingTitle: string,
    reminders: any,
    action: string,
    events: any[],
    loading: boolean,
    postings: any[],
    currentPosting: any,
    currentView: View,
    startDate?: Date,
    endDate?: Date,
}

// const localizer = BigCalendar.momentLocalizer(moment);
const find = require('lodash/find');
const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';

class Calendar extends React.Component<CalendarProps, CalendarState> {
    _isMounted = false;

    constructor(props: CalendarProps, state: CalendarState) {
        super(props, state);
        this.state = {
            creatingEvent: false,
            postingTitle: '',
            reminders: [],
            action: '',
            events: [],
            loading: true,
            postings: [],
            currentPosting: {},
            currentView: 'month',
        };
    }

    componentDidMount() {
        this._isMounted = true;

        setTimeout(this.getPostings, 0);
    }

    componentDidUpdate(prevProps: Readonly<CalendarProps>, prevState: Readonly<CalendarState>, snapshot?: any): void {
        if (prevProps !== this.props && !this.state.loading) {
            this.getPostings();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getPostings = () => {
        axios.get(`/api/auth/postingsByCategory/${this.props.currentCategory}`)
            .then((response) => {
                let events: Event[] = response.data.map((event: any) => {
                    return ({
                        id: event.id,
                        title: event.posting,
                        startDate: parse(event.startDate, DATE_TIME_FORMAT, new Date()),
                        endDate: parse(event.endDate, DATE_TIME_FORMAT, new Date())
                    });
                });
                if (this._isMounted) {
                    this.setState({events: events, postings: response.data, loading: false});
                }
            })
            .catch((error) => {
                if (error.message) {
                    toast.error(`Error retrieving postings from the server - ${error.message}`)
                }
                this.setState({loading: false});
            })
            .finally(() => {
                this.setState({loading: false});
            });
        this.setState({loading: true});
    };

    selectDate = (slotInfo: any) => {
        if (this.props.sppStaff) {
            let startDate = slotInfo.start;
            let endDate = slotInfo.end;
            this.setState({creatingEvent: true, startDate, endDate})
        }
    };

    cancelEventModal = () => {
        this.setState({creatingEvent: false})
    };

    addPosting = (posting: any) => {
        let start = parse(posting.startDate, DATE_TIME_FORMAT, new Date());
        let end = parse(posting.endDate, DATE_TIME_FORMAT, new Date());
        let event: Event = {
            id: posting.id,
            title: posting.posting,
            startDate: start,
            endDate: end
        };

        this.setState((prevState) => {
            return {
                events: [...prevState.events, event],
                postings: [...prevState.postings, posting],
                creatingEvent: false,
            }
        });
    };

    eventDoubleClick = (event: Event, e: React.SyntheticEvent<HTMLElement>) => {
        let currentPosting = find(this.state.postings, {id: event.id, posting: event.title});
        this.setState({currentPosting})
    };

    onViewChange = (view: View) => {
        this.setState({currentView: view})
    };

    public render() {
        return (
            <Segment loading={this.state.loading}>
            <Grid padded={true}>
                <Grid.Row>
                    <Grid.Column>
                        <Segment style={{height: '600px'}}>
                            {
                                <PostingModal
                                    open={this.state.creatingEvent}
                                    onClose={this.cancelEventModal}
                                    currentUser={this.props.currentUser}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    toggle={this.addPosting}
                                />
                            }
                            <BigCalendar
                                localizer={localizer}
                                startAccessor="startDate"
                                endAccessor="endDate"
                                events={this.state.events}
                                selectable={true}
                                onSelectSlot={this.selectDate} onSelectEvent={this.eventDoubleClick}
                                popup={true}
                                onView={this.onViewChange}
                                view={this.state.currentView}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>
        )

    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.authReducer.currentUser,
        roles: state.authReducer.roles,
        userEntities: state.defaultReducer.userEntities,
        currentCategory: state.defaultReducer.currentCategory,
        sppStaff: state.authReducer.userRolesEntities.sppStaff
    }
};

export default connect(mapStateToProps)(Calendar);