import {useEffect, useState} from 'react';
import axios from 'axios';
import {FuelTypeOptionContext} from '../../common/row-edit-grid/context/FuelTypeOptionContext';
import {RowEditGrid} from '../../common/row-edit-grid/RowEditGrid';
import {GridColumn} from '@progress/kendo-react-grid';
import {COMMAND_CELL_WIDTH, CommandCell} from '../../common/row-edit-grid/CommandCell';
import {RequiredStringFormCell} from '../../common/row-edit-grid/RequiredStringFormCell';
import {StringFormCell} from '../../common/row-edit-grid/StringFormCell';
import {FuelTypeOptionFormCell} from '../../common/row-edit-grid/FuelTypeOptionFormCell';

export const EnergySourceFormGrid = () => {
    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        axios.get('/api/auth/wrap/data/fuel-type/dropdown')
            .then(resp => {
                setOptions(resp.data);
            })
    }, []);

    return (
        <FuelTypeOptionContext.Provider value={{options, setOptions}}>
            <div style={{maxWidth: '1512px'}}>
                <RowEditGrid
                    dataApi={'/api/auth/wrap/data/energy-source'}
                    uniqueFields={[{field: 'longNm', title: 'Long Name'}]}
                >
                    <GridColumn title='Command' cell={CommandCell} width={COMMAND_CELL_WIDTH}/>
                    <GridColumn title='ID' field='id' editable={false} width={70}/>
                    <GridColumn title='Name' field='nm' cell={RequiredStringFormCell} width={70}/>
                    <GridColumn title='Long Name' field='longNm' cell={RequiredStringFormCell} width={400}/>
                    <GridColumn title='Description' field='description' cell={StringFormCell} width={400}/>
                    <GridColumn title='Fuel Type' field='fuelTypeNm' cell={FuelTypeOptionFormCell} width={400}/>
                </RowEditGrid>
            </div>
        </FuelTypeOptionContext.Provider>
    );
};