import {ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Form} from '@progress/kendo-react-form';
import {Grid, GridRowProps, GridToolbar} from '@progress/kendo-react-grid';
import {toast} from 'react-toastify';
import {GridEditContext} from './context/GridEditContext';
import {FormSubmitContext} from './context/FormSubmitContext';

// This started out as an example on https://www.telerik.com/kendo-react-ui/components/grid/editing/editing-inline-kr-form/

// Notes about column sizing for components using RowEditGrid.
// 32 character max width = 400px
// 64 character max width = 772px. Comfortable width = 400px (can resize).
// Side scroll bar width = 22px

export const FORM_DATA_INDEX = 'formDataIndex';
export const DATA_ITEM_KEY = 'id';

export const requiredValidator = (value: string) => value ? "" : 'Value is required';

const GridInlineFormRow = (props: { children: any; dataItem: any }) => {
    const {onRowAction, editIndex} = useContext(GridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const onSubmit = useCallback(
        (e) => {
            onRowAction({rowIndex: editIndex!, operation: 'save', dataItem: e});
        },
        [onRowAction, editIndex]
    );
    if (isInEdit) {
        return (
            <Form
                key={JSON.stringify(props.dataItem)}
                initialValues={props.dataItem}
                onSubmit={onSubmit}
                render={(formRenderProps) => {
                    return (
                        <FormSubmitContext.Provider value={formRenderProps.onSubmit}>
                            {props.children}
                        </FormSubmitContext.Provider>
                    );
                }}
            />
        );
    }
    return props.children;
};

const rowRender = (row: any, props: GridRowProps) => {
    return <GridInlineFormRow dataItem={props.dataItem}>{row}</GridInlineFormRow>;
};

interface RowEditGridProps {
    children: ReactNode;
    dataApi: string;
    uniqueFields?: {
        field: string;
        title: string;
    }[];
    addUpdateTogether?: boolean;
}

export const RowEditGrid = (props: RowEditGridProps) => {
    // Form data index is used as an alternative to ID for rows after data operations
    const [dataState, setDataState] = useState<any[]>([]);

    const [editIndex, setEditIndex] = useState<number | undefined>(
        undefined
    );

    const [maxIndex, setMaxIndex] = useState<number>(-1);

    const getData = useCallback(() => {
        axios.get(`${props.dataApi}`)
            .then((resp) => {
                setDataState(
                    resp.data.map((dataItem: any, index: number) => ({
                        ...dataItem,
                        [FORM_DATA_INDEX]: index,
                    }))
                );
                setMaxIndex(resp.data.length > 0 ? -1 * resp.data.length : -1);
            })
            .catch(() => {
                toast.error('Error loading data');
            });
    }, [props.dataApi]);

    useEffect(() => {
        getData();
    }, [getData]);

    const refreshData = () => {
        getData();
        toast.info('Data has been refreshed');
    };

    const addOrUpdate = useCallback(async (index: number, newDataState: any[]) => {
        if (newDataState[index].id < 0) {
            let {formDataIndex, id, ...data} = newDataState[index];
            const formData = new FormData();
            let hasArrayProperty = false;
            for (const property in data) {
                if (Array.isArray(data[property])) {
                    if (data[property].length > 0) {
                        hasArrayProperty = true;
                    }
                } else {
                    formData.append(property, data[property].toString());
                }
            }

            try {
                const response = await axios.post(`${props.dataApi}/add`, formData);
                id = response.data.id;
            } catch (error) {
                toast.error('Error saving item.');
                return;
            }
            newDataState[index].id = id;

            // Lists are complicated to send as form data.
            // Easier to get the new ID and make second API call to update array property.
            if (hasArrayProperty) {
                const {formDataIndex, ...data} = newDataState[index];
                axios.put(`${props.dataApi}`, data)
                    .then(() => {
                        setEditIndex(-1);
                        toast.success('Successfully saved item');
                    })
                    .catch(() => {
                        toast.error('Error saving item.');
                    });
            } else {
                setEditIndex(-1);
                toast.success('Successfully saved item');
            }
        } else {
            const {formDataIndex, ...data} = newDataState[index];
            axios.put(`${props.dataApi}`, data)
                .then(() => {
                    setEditIndex(-1);
                    toast.success('Successfully saved item');
                })
                .catch(() => {
                    toast.error('Error saving item.');
                });
        }
    }, [props.dataApi]);

    const addAndUpdate = useCallback(async (index: number, newDataState: any[]) => {
        const {formDataIndex, ...data} = newDataState[index];
        
        try {
            const response = await axios.post(`${props.dataApi}/save`, data);
            newDataState[index] = {...response.data};
            setEditIndex(-1);
            toast.success('Successfully saved item');
        } catch (error: any) {
            const message = error!.response!.data;
            toast.error(!!message ? message : 'Error saving item.');
            return;
        }
    }, [props.dataApi]);

    const isDuplicate = useCallback((dataItem: any) => {
        if (!props.uniqueFields) {
            return false;
        }
        for (let i = 0; i < props.uniqueFields.length; i++) {
            let {field, title} = props.uniqueFields[i];
            if (-1 < dataState.findIndex((item) => item[field] === dataItem[field] && item[DATA_ITEM_KEY] !== dataItem[DATA_ITEM_KEY])) {
                toast.error(`Duplicate value in ${title} column. New value must be unique.`);
                return true;
            }
        }
        return false;
    }, [dataState, props.uniqueFields]);

    const handleSave = useCallback(async (dataItem: any) => {
        let localData = dataState.map((data: any) => ({...data}));
        let index = localData.findIndex((item) => item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY]);
        localData[index] = {...dataItem};

        if (props.addUpdateTogether) {
            await addAndUpdate(index, localData);
        } else {
            await addOrUpdate(index, localData);
        }

        setDataState(localData);
    }, [addAndUpdate, addOrUpdate, dataState, props.addUpdateTogether]);
    
    const handleAdd = useCallback((rowIndex: number) => {
        let localData = dataState.map((data: any) => ({...data}));
        localData.unshift({
            [FORM_DATA_INDEX]: rowIndex,
            [DATA_ITEM_KEY]: maxIndex,
        });
        setEditIndex(rowIndex);
        setMaxIndex((prevState: number) => prevState - 1);
        setDataState(localData);
    }, [dataState, maxIndex]);

    const handleRemove = useCallback((dataItem: any) => {
        if (dataItem[DATA_ITEM_KEY] < 0) {
            setDataState((prevState) =>
                prevState.filter((item) => item[DATA_ITEM_KEY] !== dataItem[DATA_ITEM_KEY])
            );
            return;
        }
        axios.delete(`${props.dataApi}/${dataItem[DATA_ITEM_KEY]}`)
            .then(() => {
                setDataState((prevState) =>
                    prevState.filter((item) => item[DATA_ITEM_KEY] !== dataItem[DATA_ITEM_KEY])
                );
                toast.success("Successfully deleted item");
            })
            .catch((error) => {
                const message = error!.response!.data;
                toast.error(message);
            });
    }, [props.dataApi]);

    const onRowAction = useCallback(
        (options: {
            rowIndex: number;
            operation: "save" | "remove" | "add";
            dataItem?: any;
        }) => {
            switch (options.operation) {
                case "remove":
                    handleRemove(options.dataItem);
                    break;
                case "save":
                    if (isDuplicate(options.dataItem)) {
                        break;
                    }
                    handleSave(options.dataItem);
                    break;
                case "add":
                    handleAdd(options.rowIndex);
                    break;
                default:
                    break;
            }
        },
        [handleRemove, isDuplicate, handleSave, handleAdd]
    );

    const onAddClick = useCallback(() => {
        onRowAction({rowIndex: dataState.length, operation: "add"});
    }, [onRowAction, dataState]);

    return (
        <GridEditContext.Provider value={{onRowAction, editIndex, setEditIndex}}>
            <Grid
                data={dataState}
                dataItemKey={DATA_ITEM_KEY}
                rowRender={rowRender}
                resizable={true}
                style={{maxHeight: '600px'}}
            >
                <GridToolbar>
                    <button
                        title="Add new"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={onAddClick}
                    >
                        Add new
                    </button>
                    <button
                        title="Refresh Data"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={refreshData}
                    >
                        Refresh Data
                    </button>
                </GridToolbar>
                {props.children}
            </Grid>
        </GridEditContext.Provider>
    );
};