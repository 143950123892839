import {Accordion, Icon} from 'semantic-ui-react';
import {ChangeSeasonControl} from './ChangeSeasonControl';
import {FC, useState} from 'react';

export interface RolloverAdminControlProps {
    getSeasons: () => void;
}

export const RolloverAdminControl: FC<RolloverAdminControlProps> = ({getSeasons}) => {
    const [active, setActive] = useState<boolean>(false);
    
    const handleClick = () => {
        setActive((prevActive) => !prevActive);
    };
    
    return (
        <Accordion styled={true}>
            <Accordion.Title active={active}
                             onClick={handleClick}
                             style={{backgroundColor:'#f3f4f5'}}>
                <Icon name={active ? 'minus' : 'plus'}
                      style={{float: 'right', cursor: 'pointer'}}
                />
                Rollover Admin Controls
            </Accordion.Title>
            <Accordion.Content active={active}>
                <p>
                    This will change which season is active by one season at at time. It will not rollover data. 
                    If you rollback and change something, please use "Rollover WRAP Data" button to roll data forward to replace the rolled data.
                </p>
                <ChangeSeasonControl getSeasons={getSeasons}/>
            </Accordion.Content>
        </Accordion>
    );
};