import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from '../../interfaces/grid-interfaces';
import {
    ForcedOutageData,
    ForcedOutageEditData
} from '../../interfaces/wrap/forced-outage-interface';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState,
    numberBoundValidationOnEdit,
    processColumnMeta,
    processDropDownListOptions, removeDataErrorByValue,
    replaceSpaceWithUnderscore,
    resetOption, toastPreSaveValidationErrors,
    toastSuccessfulSave,
    updateEditedRows,
    updateEditedVerified
} from '../../services/data-grid-service';
import {
    DropDownOption,
    getDropDownCell
} from '../common/grid/DropDownCell';
import {CustomLocalizationProvider, getVerifiedCell} from '../common/grid/VerifiedCell';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import ExportPanel from "../common/action-panel/ExportPanel";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import TooltipContainer from '../common/grid/TooltipContainer';
import AddPanel from "../common/action-panel/AddPanel";
import {toast} from "react-toastify";
import {getDecimalCell} from "../common/grid/DecimalCell";
import ImportPanel from "../common/action-panel/ImportPanel";
import RemovePanel from '../common/action-panel/RemovePanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import {getRemoveCell} from '../common/grid/RemoveCell';
import RefreshPanel from "../common/action-panel/RefreshPanel";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import format from "date-fns/format";
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import {InputCell} from "../common/grid/InputCell";
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import ValidatePanel from "../common/action-panel/ValidatePanel";
import {getChildApiDropDownCell} from '../common/grid/ChildApiDropDownCell';

interface ForcedOutageProps extends PageRole{
    title: string,
    dataApi: string,
    season: 'Summer' | 'Winter',
}

const ForcedOutageGrid: React.FC<ForcedOutageProps> = ({title, isStaff, dataApi, season, readRole, addRole, modifyRole, removeRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<ForcedOutageEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<ForcedOutageData>>([]);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [subYear, setSubYear] = useState<number>(0);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const globalPageSize = localStorage.getItem(`globalPageSize`)
    const [dataState, setDataState] = useState<any>({...initialDataState, take: globalPageSize ? +globalPageSize : 10, pageSize: globalPageSize ? +globalPageSize : 10});
    const [resourceIdentifierOptions, setResourceIdentifierOptions] = useState<Array<DropDownOption>>([]);
    const [participantNmOptions, setParticipantNmOptions] = useState<Array<DropDownOption>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`${dataApi}/columns`)
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    useEffect(() => {
        axios.get('/api/auth/wrap/subyear/current')
            .then((resp) => {
                setSubYear(resp.data);
            })
    }, []);

    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/user-participants')
            .then((resp) => {
                setParticipantNmOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

// Fetch resource identifiers
    useEffect(() => {
        axios.get(`/api/auth/wrap/resource/${season}/resource-identifiers`)
            .then((resp) => {
                const resourceOptions = resp.data.map((identifier: any) => ({
                    text: identifier,
                    value: identifier,
                }));
                setResourceIdentifierOptions(resourceOptions);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [season]);

    const fetchPercentOwned = async (resourceIdentifier: any, season: any) => {
        try {
            const response = await axios.get(`/api/auth/wrap/resource/${season}/percent-owned`, {
                params: { resourceIdentifier }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(dataApi), axios.get(`/api/auth/wrap/season-window/${season}`)])
            .then((resp) => {
                setData(deepCopyGridDataWithOriginal(resp[0].data));
                setOriginalData(deepCopyGridData(resp[0].data));
                setTempSequence(-10000);
                if (!isStaff) {
                    if (resp[1].data === false) {
                        toast.info(`WRAP FS submittal window is closed for the ${season} season`);
                    }
                    setSeasonOpen(resp[1].data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, isStaff, readRole, season]);

    useEffect(() => {
        getData();
    }, [getData]);

    const refreshData = () => {
        getData();
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = async (id: number, field: string, value: any) => {
        const percentOwned = field === 'resourceIdentifier' ? await fetchPercentOwned(value,season) : 0;
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;

                if (value && (['resourceIdentifier', 'participantNm'].includes(field))) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if ((value || value === 0) && (['month1', 'month2', 'month3', 'month4', 'month5'].includes(field))) {
                    numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 100000, 0);
                }

                if (field === 'resourceIdentifier') {
                    dataItem.percentOwned = percentOwned;
                }
                    // Calculate ownedMonth values
                    if (field.startsWith('month')) {
                        const monthFields = ['month1', 'month2', 'month3', 'month4', 'month5'];
                        monthFields.forEach(monthField => {
                            const ownedMonthField = `owned${monthField.charAt(0).toUpperCase() + monthField.slice(1)}`;
                            dataItem[ownedMonthField] = (dataItem[monthField] * dataItem.percentOwned).toFixed(3);
                        });
                    }

                return dataItem;
            } else {
                return item;
            }
        });

        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify && field === 'verified') {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
    };

    const handleUpload = (uploadedData: Array<ForcedOutageEditData>) => {
        console.log(uploadedData);
        let sequence = tempSequence;
        const localData = data.map((item) => {
            let upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                const item = {
                    ...u,
                    verified: false,
                    subYearId: subYear,
                    id: sequence,
                    participantNm: resetOption(u.participantNm, participantNmOptions, ''),
                    resourceIdentifier: resetOption(u.resourceIdentifier, resourceIdentifierOptions, ''),
                    percentOwned: Number(u.percentOwned.toFixed(2)),
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true,
                    originalData: generateDefaultRow(sequence)
                };
                sequence = sequence + 1;
                return item;
            });

        const dataUploaded: Array<ForcedOutageEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u as ForcedOutageEditData);
        });

        setData(dataUploaded);
        setTempSequence(sequence);
    };

    const processUploadEdits = (upload: ForcedOutageEditData, item: ForcedOutageEditData) => {
        let participantNm = resetOption(upload.participantNm, participantNmOptions, item.participantNm);
        if (participantNm?.toLowerCase() !== item.participantNm.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'participantNm', upload.participantNm));
        }
        let resourceIdentifier = resetOption(upload.resourceIdentifier, resourceIdentifierOptions, item.resourceIdentifier);
        if (resourceIdentifier?.toLowerCase() !== item.resourceIdentifier.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceIdentifier', upload.resourceIdentifier));
        }
        if (upload.percentOwned !== item.percentOwned) {
            let field = 'percentOwned';
            numberBoundValidationOnEdit(upload[field], field, setDataErrors, item, columnMeta, 1, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.comments?.toLowerCase() !== item.comments?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'comments', upload.comments));
        }

        const fieldsToUpdate = ['month1', 'month2', 'month3', 'month4', 'month5'];

        fieldsToUpdate.forEach(field => {
            if (upload[field] !== item[field]) {
                numberBoundValidationOnEdit(upload[field], field, setDataErrors, item, columnMeta, 100000, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
        });

        const processedItem = {
            ...item,
            participantNm: upload.participantNm,
            percentOwned: upload.percentOwned,
            resourceIdentifier: upload.resourceIdentifier,
            comments: upload.comments
        };

        if (season === 'Summer') {
            processedItem.month1 = upload.month1 ? upload.month1 : 0;
            processedItem.month2 = upload.month2 ? upload.month2 : 0;
            processedItem.month3 = upload.month3 ? upload.month3 : 0;
            processedItem.month4 = upload.month4 ? upload.month4 : 0;
        }
        if (season === 'Winter') {
            processedItem.month1 = upload.month1 ? upload.month1 : 0;
            processedItem.month2 = upload.month2 ? upload.month2 : 0;
            processedItem.month3 = upload.month3 ? upload.month3 : 0;
            processedItem.month4 = upload.month4 ? upload.month4 : 0;
            processedItem.month5 = upload.month5 ? upload.month5 : 0;
        }

        return processedItem;
    };

    const generateDefaultRow = (sequence: number) => ({
        verified: false,
        subYearId: subYear,
        id: sequence,
        participantNm: '',
        resourceIdentifier: '',
        percentOwned: 0,
        comments: '',
        lastUserModBy: '',
        lastUserModDt: null,
        verifiedBy: '',
        verifiedDt: null,
        inEdit: true,
        ...(season === 'Summer' ? {
                month1: 0,
                month2: 0,
                month3: 0,
                month4: 0
            } : season === 'Winter' ? {
                month1: 0,
                month2: 0,
                month3: 0,
                month4: 0,
                month5: 0
            } : {}
        )
    });

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: ForcedOutageEditData = generateDefaultRow(sequence++);

        defaultNewRow.originalData = {...defaultNewRow};

        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
    };

    const save = () => {
        if (filterEmptyRequiredColumns()) {
            return;
        }

        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }

        // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        //save the data
        axios.post(dataApi, newAndUpdatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const filterEmptyRequiredColumns = (): boolean => {
        const missingRequired = data.filter((item) =>
            !item.participantNm
            || !item.resourceIdentifier
            || (!item.month1 && item.month1 !== 0)
            || (!item.month2 && item.month2 !== 0)
            || (!item.month3 && item.month3 !== 0)
            || (!item.month4 && item.month4 !== 0)
            || (season === 'Winter' && (!item.month5 && item.month5 !== 0)));

        if (missingRequired.length === 0) {
            return false;
        }

        toast.error('There are columns missing required data. Please provide values to save changes.');
        let localErrors = [...dataErrors];

        missingRequired.forEach((item) => {
            if (!item.participantNm) {
                localErrors = addDataError(item.id, 'participantNm', 'Last Modifying Participant', '',
                    'Last Modifying Participant is required to save.', true, localErrors);
            }
            if (!item.resourceIdentifier) {
                localErrors = addDataError(item.id, 'resourceIdentifier', 'Resource Identifier', '',
                    'Resource Identifier is required to save.', true, localErrors);
            }

            if (season === 'Summer') {
                if (!item.month1) {
                    localErrors = addDataError(item.id, 'month1', `June ${subYear}`, '',
                        `June ${subYear} value is required to save.`, true, localErrors);
                }
                if (!item.month2) {
                    localErrors = addDataError(item.id, 'month2', `July ${subYear}`, '',
                        `July ${subYear} value is required to save.`, true, localErrors);
                }
                if (!item.month3) {
                    localErrors = addDataError(item.id, 'month3', `August ${subYear}`, '',
                        `August ${subYear} value is required to save.`, true, localErrors);
                }
                if (!item.month4) {
                    localErrors = addDataError(item.id, 'month4', `September ${subYear}`, '',
                        `September ${subYear} value is required to save.`, true, localErrors);
                }
            } else if (season === 'Winter') {
                if (!item.month1) {
                    localErrors = addDataError(item.id, 'month1', `November ${subYear}`, '',
                        `November ${subYear} value is required to save.`, true, localErrors);
                }
                if (!item.month2) {
                    localErrors = addDataError(item.id, 'month2', `December ${subYear}`, '',
                        `December ${subYear} value is required to save.`, true, localErrors);
                }
                if (!item.month3) {
                    localErrors = addDataError(item.id, 'month3', `January ${subYear + 1}`, '',
                        `January ${subYear + 1} value is required to save.`, true, localErrors);
                }
                if (!item.month4) {
                    localErrors = addDataError(item.id, 'month4', `February ${subYear + 1}`, '',
                        `February ${subYear + 1} value is required to save.`, true, localErrors);
                }
                if (!item.month5) {
                    localErrors = addDataError(item.id, 'month5', `March ${subYear + 1}`, '',
                        `March ${subYear + 1} value is required to save.`, true, localErrors);
                }
            }
        });

        setDataErrors(localErrors);
        return true;
    };

    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post(`${dataApi}/verify`, updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
                toast.success("Successfully verified.");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            // initialize 'removed' field to false on all data objects
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }
    };

    const cancelRemove = () => {
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
        setInEdit(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const confirmRemove = () => {
        axios.delete(`${dataApi}/delete`, {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));
                const localDataErrors = dataErrors.filter(item => !editedRemoved.includes(item.id));

                toast.success("Successfully deleted data");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    setDataErrors(localDataErrors);
                    resetRemove();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`${dataApi}/validate`)
            .then(response => {
                setValidationErrors(response.data);
                getData();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    const formatExport = (data: Array<ForcedOutageData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO',
            percentOwned: item.percentOwned === null ? null : item.percentOwned * 100,
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    const ResourceIdentifierDropDownCell = useMemo(() => getChildApiDropDownCell(`/api/auth/wrap/resource/${season}/resource-identifiers`, 'participantNm'), [season]);
    const MonthMwDecimalCell = useMemo(() => getDecimalCell(1, true, 100000, 0), []);
    const ParticipantsDropDownCell = useMemo(() => getDropDownCell(participantNmOptions, 'Participant'), [participantNmOptions]);
    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);

    const monthWidths = season === 'Summer' ? ['110px','100px','120px','140px','130px'] : ['140px','140px','130px','130px','120px'];
    const ownedMonthWidths = season === 'Summer' ? ['150px','150px','170px','180px','170px'] : ['180px','180px','170px','170px','160px'];

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/upload`} setUploadRecords={handleUpload} />}
                {!inEdit && modifyRole && seasonOpen && isStaff && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleSelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && !inVerify && modifyRole && seasonOpen && <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                    <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                        <Grid
                            data={process(data, dataState)}
                            dataItemKey='id'
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            style={{ height: "510px" }}
                            {...dataState}
                            editField='inEdit'
                            onDataStateChange={dataStateChange}
                            onItemChange={itemChange}
                            pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                            onPageChange={pageChange}
                        >
                            {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                            {
                                columnMeta.length
                                    ? columnMeta.map((col: ColumnMeta, index) => {
                                        if (col.nm === 'verified') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={VerifiedCell} filter={"boolean"}/>
                                        } else if (col.nm === 'id') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='120px' editable={false}/>
                                        } else if (col.nm === 'participantNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={ParticipantsDropDownCell}/>
                                        } else if (col.nm === 'resourceIdentifier') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='325px' cell={ResourceIdentifierDropDownCell}/>
                                        } else if (col.nm === 'percentOwned') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='160px' editable={false} format='{0:p2}'/>
                                        } else if (col.nm === 'month1') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={monthWidths[0]} cell={MonthMwDecimalCell}/>
                                        } else if (col.nm === 'month2') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={monthWidths[1]} cell={MonthMwDecimalCell}/>
                                        } else if (col.nm === 'month3') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={monthWidths[2]} cell={MonthMwDecimalCell}/>
                                        } else if (col.nm === 'month4') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={monthWidths[3]} cell={MonthMwDecimalCell}/>
                                        } else if (col.nm === 'month5') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={monthWidths[4]} cell={MonthMwDecimalCell}/>
                                        } else if (col.nm === 'ownedMonth1') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={ownedMonthWidths[0]} editable={false}/>
                                        } else if (col.nm === 'ownedMonth2') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={ownedMonthWidths[1]} editable={false}/>
                                        } else if (col.nm === 'ownedMonth3') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={ownedMonthWidths[2]} editable={false}/>
                                        } else if (col.nm === 'ownedMonth4') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={ownedMonthWidths[3]} editable={false}/>
                                        } else if (col.nm === 'ownedMonth5') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width={ownedMonthWidths[4]} editable={false}/>
                                        } else if (col.nm === 'comments') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={InputCell}/>
                                        } else if (col.nm === 'lastUserModBy' || col.nm === 'verifiedBy') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        } else if (col.nm === 'lastUserModDt' || col.nm === 'verifiedDt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        } else {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                        }
                                    })
                                    : <GridColumn/>
                            }
                        </Grid>
                    </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'Forced Outages'}/>}
        </div>
    );
};

export default ForcedOutageGrid;