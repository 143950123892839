import JWT from 'jwt-decode';
import {toast} from 'react-toastify';
import {Principal} from '../auth';

// Copied from general-services.ts to make non-class function. Modified to remove "this" and code redundancy.
export const loggedIn = (printTokenMessage?:boolean) => {
    // Checks if there is a saved token and it's still valid
    const token = localStorage.getItem('edst_token') || '';
    return !!token && !isTokenExpired(token, printTokenMessage);
};

// Copied from general-services.ts to make non-class function. Modified to remove "this".
const isTokenExpired = (token: string, printTokenMessage?:boolean) => {
    try {
        const decoded = JWT(token) as any;
        if (decoded['exp'] < Date.now() / 1000) { // Checking if token is expired. N
            if(printTokenMessage !== undefined && printTokenMessage) {
                toast.warn('Token Expired. Please log back in.', {toastId: 'token_expired'});
            }
            return true;
        }
        else {
            return false;
        }
    }
    catch (error) {
        console.log(error);
        return false;
    }
};

// Copied from general-services.ts to make non-class function. Modified to remove "this".
export const logout = () => {
    // Clear user token and profile data from localStorage
    return new Promise((resolve) => {
        localStorage.removeItem('edst_token');
        localStorage.removeItem('userName');
        localStorage.removeItem('roles');
        localStorage.removeItem('userEntities');
        localStorage.removeItem('userRolesEntities');
        sessionStorage.removeItem('uuid');
        localStorage.removeItem('currentCategory');
        resolve(true);
    }) as any;
};

export const getViewPage = (page: string, roles: Array<Principal>) => {
    return roles.some(role => role.tabNm === page && role.readRole);
};