import {Button, Modal} from 'semantic-ui-react';
import axios, {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import React, {useState} from 'react';
import {Loader} from '@progress/kendo-react-indicators';

interface WrapSeasonRollForwardModalProps {
    getSeasons: () => void
}

export const WrapSeasonRollForwardModal: React.FC<WrapSeasonRollForwardModalProps> = ({getSeasons}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const rollover = () => {
        setOpen(false);
        setLoading(true);
        toast.info('Rollover started')
        axios.post('/api/auth/wrap/rollover')
            .then(() => {
                console.log('Rollover Complete.');
                toast.success('Rollover is complete.');
                getSeasons();
            })
            .catch((error: AxiosError) => {
                console.log(error);
                if (error.response?.status === 500) {
                    toast.error('Rollover canceled due to error. Please contact EDST IT support.');
                } else if (error.response?.status === 504) {
                    toast.error('Rollover is taking a while. Check season status in a few minutes.');
                } else {
                    toast.error(`Error during Rollover: ${error.message}`);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const openModal = () => setOpen(true);

    const closeModal = () => setOpen(false);

    return (
        <Modal
            trigger={<Button onClick={openModal}>Rollover WRAP Data {loading && <Loader size="small" type="pulsing" />}</Button>}
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
        >
            <Modal.Header>Roll Forward Confirmation</Modal.Header>
            <Modal.Content>
                <p>This will archive the Current season, roll the Current season into the next year, promote the Next season to the Current season, and set the new season as the Next season.</p>
                <p>The rollover process may take up to ten minutes to complete. Both seasons will be locked during the rollover process and will unlock when complete.</p>
                <p><strong>Are you sure you wish to roll forward WRAP data to the next season?</strong></p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={closeModal}>Cancel</Button>
                <Button color='green' onClick={rollover}>Roll Forward</Button>
            </Modal.Actions>
        </Modal>
    );
};