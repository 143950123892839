import * as React from 'react';
import {Header} from "semantic-ui-react";
import BaseCaseGrid from "./BaseCaseGrid";
import ChangesetOperations from "./ChangesetOperations";
import ChangesetGrid from "./ChangesetGrid";
import {RouteComponentProps} from "react-router";
import {AccordionSegment} from "./AccordionSegment";
import {connect} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import {Principal} from "../../auth";
import {Element, scroller} from 'react-scroll';
import AdminService from "../../services/admin-services";
import {toast} from "react-toastify";
import ValidationMessages from "../Administration/ChangesetHistory/ValidationMessages";
import AttachmentGrid from "../Administration/ChangesetHistory/AttachmentGrid";

interface BaseCaseDataProps extends RouteComponentProps {
    updateCurrentTab: (currentTab: string) => void,
    updateCurrentChangeset: (currentChangeset: Changeset) => void,
    resetChangesetVersion: () => void,
    resetBasecaseVersion: () => void,
    roles: Principal[],
    currentChangeset: any,
    currentTab: string,
    userRolesEntities: any,
}

interface BaseCaseDataState {
    page: string,
    category: string,
    currentChangeset: Changeset,
    uploadFile: {},
    modDevLockedOut: boolean,
    resAdqLockedOut: boolean
}

const lockedOutClient = new AdminService();
class BaseCaseData extends React.Component<BaseCaseDataProps,BaseCaseDataState> {
    constructor(props: BaseCaseDataProps, state: BaseCaseDataState) {
        super(props, state);
        this.state = {
            page: this.props.match.url.split("/")[1],
            category: this.getCategory(),
            currentChangeset: {
                status:'',
                number:0,
                name:'',
                comments:'',
                changeSetAuthorName: ''
            },
            uploadFile: {},
            modDevLockedOut: true,
            resAdqLockedOut: true
        };
    }

    getLockedOutStatusForUser = () => {
        lockedOutClient.getLockOutData().then((data:any) => {
            let modDevLockedOut = true;
            let resAdqLockedOut = true;
            if(!data.modDevLocked) {
                modDevLockedOut = false;
            } else {
                if(this.state.category === 'ModelingDevelopment' && !this.props.userRolesEntities.sppStaff) {
                    toast.info("Modeling Development data is currently locked and cannot be edited. Users can access basecase information.", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: false
                    });
                }
            }
            if(!data.raLocked) {
                resAdqLockedOut = false;
            } else {
                if(this.state.category === 'ResourceAdequacy' && !this.props.userRolesEntities.sppStaff) {
                    toast.info("Resource Adequacy data is currently locked and cannot be edited. Users can access basecase information.", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: false
                    });
                }
            }
            this.setState({modDevLockedOut: modDevLockedOut, resAdqLockedOut: resAdqLockedOut});
        });
    };

    getCategory = () => {
        let resource = this.props.match.url.split("/")[1];
        if(resource === 'PurchasesSales' || resource === 'ResourceOwnership' || resource === 'Plants'
            || resource === 'Resources' || resource === 'GeneratorTestResults') {
            return 'ResourceAdequacy';
        }
        else {
            return 'ModelingDevelopment';
        }
    };

    componentDidMount(): void {
        this.getLockedOutStatusForUser();
        let previousTab: string = this.props.currentTab;
        this.props.updateCurrentTab(this.props.match.url.split("/")[1]);
        if (this.props.currentChangeset.number && previousTab === "Home") {
            scroller.scrollTo('changesets',{
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            })
        } else{
            window.scrollTo(0, 0);
            let currentChangeset = {
                status:'',
                number:0,
                name:'',
                comments:'',
                changeSetAuthorName: ''
            };
            this.setState({currentChangeset});
            this.props.updateCurrentChangeset(currentChangeset);
            this.props.resetChangesetVersion();
            this.props.resetBasecaseVersion();
        }


    }

    getHeader = () => {
      let rolesForPage = this.props.roles.filter((value:Principal) => {
          return value.tabNm === this.state.page;
      });

      return rolesForPage[0].visNm;
    };

    public render() {
        return (
            <div style={{marginTop:'50px',paddingLeft:'20px',paddingRight:'20px',paddingTop:'20px'}}>
                <Header size={'large'}>{this.getHeader()}</Header>
                <AccordionSegment segmentHeader={'BaseCase Data'} size={'mini'}>
                    <BaseCaseGrid resource={this.state.page} resAdqLockedOut={this.state.resAdqLockedOut} modDevLockedOut={this.state.modDevLockedOut} category={this.state.category} sppStaff={this.props.userRolesEntities.sppStaff} />
                </AccordionSegment>
                <AccordionSegment segmentHeader={'Changeset Operations'} size={'mini'}>
                    <ChangesetOperations resource={this.props.match.url} resAdqLockedOut={this.state.resAdqLockedOut} modDevLockedOut={this.state.modDevLockedOut} category={this.state.category} sppStaff={this.props.userRolesEntities.sppStaff} />
                </AccordionSegment>

                <Element name={'changesets'}>
                    <AccordionSegment segmentHeader={'Changeset Details'} size={'mini'}>
                        <ChangesetGrid resource={this.state.page} changesetNumber={this.props.match.url.split('/')[2]} resAdqLockedOut={this.state.resAdqLockedOut} modDevLockedOut={this.state.modDevLockedOut} sppStaff={this.props.userRolesEntities.sppStaff} />
                    </AccordionSegment>
                </Element>
                <AccordionSegment segmentHeader={'Validation Messages'} size={'tiny'} contentHidden={this.props.currentChangeset.number === 0}>
                    <ValidationMessages/>
                </AccordionSegment>
                <AccordionSegment segmentHeader={'Attached Documents'} size={'tiny'} contentHidden={this.props.currentChangeset.number === 0}>
                    <AttachmentGrid/>
                </AccordionSegment>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        roles: state.authReducer.roles,
        currentChangeset: state.changeSetReducer.currentChangeset,
        currentTab: state.defaultReducer.currentTab,
        userRolesEntities: state.authReducer.userRolesEntities,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentTab: (currentTab: string) => dispatch (
            {type: actionTypes.UPDATE_CURRENT_TAB, payload: currentTab}),
        updateCurrentChangeset: (changeset: Changeset) => dispatch (
            {type: actionTypes.UPDATE_CURRENT_CHANGESET, payload: changeset}),
        resetChangesetVersion: () => dispatch (
            {type: actionTypes.RESET_CHANGESET_VERSION}),
        resetBasecaseVersion: () => dispatch (
            {type: actionTypes.RESET_BASECASE_VERSION})
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(BaseCaseData);