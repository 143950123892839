import React from 'react';
import {Icon, Menu} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';

interface TopLevelMenuProps {
    menuName: string;
    menuDisplayName: string;
    menuLink: string;
    menuIcon?: string;
    activeMenuItem: string;
    updateActiveMenuItem: (e: any, {name}: any) => void;
}

export const TopLevelMenu: React.FC<TopLevelMenuProps> = ({menuName, menuDisplayName, menuLink, menuIcon, activeMenuItem, updateActiveMenuItem}) => {
    return (
        <Menu.Item
            className='navMenu'
            name={menuName}
            active={activeMenuItem === menuName}
            as={NavLink}
            to={menuLink}
            exact={true}
            onClick={updateActiveMenuItem}
        >
            {menuIcon && <Icon className={menuIcon}/>}
            {menuDisplayName}
        </Menu.Item>
    );
};