import * as React from 'react';
import {Icon, Segment} from "semantic-ui-react";


interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => {
    const getCurrentYear = () => { return new Date().getFullYear();};
    const segStyle = {border: 'none'};

    return (
        <>
                <Segment textAlign={'center'} style={segStyle}>
                    <p style={{textAlign:'center'}}><Icon name={'copyright'}/> {getCurrentYear()} Southwest Power Pool, Inc. All rights reserved</p>
                    <p style={{textAlign:'center'}}>Confidential Information Authorized Access Only</p>
                </Segment>
        </>
    );
};

export default Footer;