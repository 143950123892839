import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent, GridPageChangeEvent} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {DataUpdate} from '../../interfaces/grid-interfaces';
import axios from 'axios';
import {
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    processDropDownListOptions, replaceSpaceWithUnderscore, resetOption,
    toastSuccessfulSave,
    updateEditedRows
} from '../../services/data-grid-service';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import ActionPanel from '../common/action-panel/ActionPanel';
import {ActionPanelButton} from '../common/action-panel/ActionPanelButton';
import SavePanel from '../common/action-panel/SavePanel';
import RefreshPanel from '../common/action-panel/RefreshPanel';
import AddPanel from '../common/action-panel/AddPanel';
import RemovePanel from '../common/action-panel/RemovePanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import TooltipContainer from '../common/grid/TooltipContainer';
import {getRemoveCell} from '../common/grid/RemoveCell';
import {ParticipantSubregionData, ParticipantSubregionEditData} from '../../interfaces/wrap/subregion-interface';
import {ExcelExport} from "@progress/kendo-react-excel-export";
import ExportPanel from "../common/action-panel/ExportPanel";
import format from "date-fns/format";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import ImportPanel from "../common/action-panel/ImportPanel";
import {DropDownOption, getDropDownCell} from "../common/grid/DropDownCell";
import {toast} from "react-toastify";
import {useCategorySeason} from '../../hooks/useCategorySeason';

export const ParticipantSubregionGrid = () => {
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const initialPageSize = globalPageSize ? +globalPageSize : 10;
    const initDataState = {skip: 0, take: initialPageSize};

    const [data, setData] = useState<ParticipantSubregionEditData[]>([]);
    const [originalData, setOriginalData] = useState<ParticipantSubregionData[]>([]);
    const [dataState, setDataState] = useState<any>({...initDataState});
    const [loading, setLoading] = useState<boolean>(false);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [participantList, setParticipantList] = useState<Array<DropDownOption>>([]);
    const [subregionList, setSubregionList] = useState<Array<DropDownOption>>([]);

    const {subyear, subyearList, season, seasonList, seasonActive, handleSubyear, handleSeason} = useCategorySeason();

    const getData = useCallback(() => {
        if (subyear > 0 && season !== '') {
            const params = new URLSearchParams();
            params.set('subYearId', subyear.toString());
            params.set('season', season);
            setLoading(true);
            axios.get('/api/auth/wrap/subregion/participant', {params})
                .then(resp => {
                    setData(deepCopyGridDataWithOriginal(resp.data));
                    setOriginalData(deepCopyGridData(resp.data));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [season, subyear]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/all-participants')
            .then(resp => {
                setParticipantList(processDropDownListOptions(resp.data));
            })
    }, []);

    useEffect(() => {
        axios.get('/api/auth/wrap/subregion/dropdown')
            .then(resp => {
                setSubregionList(processDropDownListOptions(resp.data));
            })
    }, []);

    const handleChange = (event: GridItemChangeEvent) => {
        const {dataItem, field, value} = event;
        const {id} = dataItem;
        if (!field || !id) {
            return;
        }
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: ParticipantSubregionEditData = {
            id: sequence++,
            subYearId: subyear,
            seasonNm: season,
            participantNm: '',
            defaultSubregion: '',
            month1: '',
            month2: '',
            month3: '',
            month4: '',
            month5: '',
            inEdit: true
        };
        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const save = () => {
        const missingRequired = data.filter((item) => !item.participantNm || !item.defaultSubregion);

        if (missingRequired.length > 0) {
            toast.error('Cannot save. Missing required column data.')
            return;
        }
        const newRows = data.filter((item) => item.id < 0);
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        axios.post('/api/auth/wrap/subregion/participant', newAndUpdatedData)
            .then(() => {
                getData();
                toastSuccessfulSave();
                setInEdit(false);
                setEditedRows([]);
            });
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
    };

    const openRemoveModal = () => setRemoveModalOpen(true);
    const closeRemoveModal = () => setRemoveModalOpen(false);

    const toggleRemove = () => {
        if (inRemove) {
            cancelRemove();
        } else {
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const confirmRemove = () => {
        axios.delete('/api/auth/wrap/subregion/participant', {data: editedRemoved})
            .then(() => {
                setRemoveModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));

                toast.success("Successfully deleted data");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    resetRemove();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save([...data]);
        }
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const ParticipantDropDownCell = useMemo(() => getDropDownCell(participantList, 'participant'), [participantList]);
    const SubregionDropDownCell = useMemo(() => getDropDownCell(subregionList, 'defaultSubregion'), [subregionList]);

    const handleUpload = (uploadedData: Array<ParticipantSubregionEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            const upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                return {
                    ...u,
                    id: sequence++,
                    subYearId: subyear,
                    participantNm: resetOption(u.participantNm, participantList, ''),
                    defaultSubregion: resetOption(u.defaultSubregion, subregionList, ''),
                    month1:resetOption(u.month1, subregionList, ''),
                    month2:resetOption(u.month2, subregionList, ''),
                    month3:resetOption(u.month3, subregionList, ''),
                    month4:resetOption(u.month4, subregionList, ''),
                    month5:resetOption(u.month5, subregionList, ''),
                    inEdit: true
                };
            });
        const dataUploaded: Array<ParticipantSubregionEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u);
        });
        setData(dataUploaded);
        setTempSequence(sequence);
    };

    const processUploadEdits = (upload:ParticipantSubregionEditData, item: ParticipantSubregionEditData) => {
        let participantNm = resetOption(upload.participantNm, participantList, item.participantNm);
        if (participantNm !== item.participantNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'participantNm', participantNm));
        }
        let defaultSubregion = resetOption(upload.defaultSubregion, subregionList, item.defaultSubregion);
        if (defaultSubregion !== item.defaultSubregion) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'defaultSubregion', defaultSubregion));
        }
        let month1 = !upload.month1 ? '' : resetOption(upload.month1, subregionList, item.month1);
        if (month1 !== item.month1) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month1', month1));
        }
        let month2 = !upload.month2 ? '' : resetOption(upload.month2, subregionList, item.month2);
        if (month2 !== item.month2) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month2', month2));
        }
        let month3 = !upload.month3 ? '' : resetOption(upload.month3, subregionList, item.month3);
        if (month3 !== item.month3) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month3', month3));
        }
        let month4 = !upload.month4 ? '' : resetOption(upload.month4, subregionList, item.month4);
        if (month4 !== item.month4) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month4', month4));
        }
        let month5 = !upload.month5 ? '' : resetOption(upload.month5, subregionList, item.month5);
        if (season === 'Winter' && month5 !== item.month5) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month5', month5));
        }

        const processedItem = {
            ...item,
            participantNm,
            defaultSubregion,
            month1: month1,
            month2: month2,
            month3: month3,
            month4: month4
        };
        
        if (season === 'Winter') {
            processedItem.month5 = month5;
        }
        
        return processedItem;
    }

    return (
        <div>
            <ActionPanel title={'Participant Subregion'}>
                {!inEdit && <ActionPanelButton>
                    <DropDownList
                        data={subyearList}
                        value={subyear}
                        onChange={handleSubyear}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>}
                {!inEdit && <ActionPanelButton>
                    <DropDownList
                        data={seasonList}
                        value={season}
                        onChange={handleSeason}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>}
                {!inRemove && inEdit && <AddPanel addRecord={addNewRow}/>}
                {inEdit && <RemovePanel inRemove={inRemove} openModal={openRemoveModal} toggleRemove={toggleRemove}/>}
                {!inRemove && seasonActive && <SavePanel inEdit={inEdit} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`/api/auth/wrap/subregion/participant/import/${season}/${subyear}`} setUploadRecords={handleUpload}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && <RefreshPanel disabled={inEdit} loading={loading} getData={getData}/>}
                {!inEdit && <ExportPanel exportData={excelExport} disabled={inEdit}/>}
            </ActionPanel>
            <RemoveModal open={removeModalOpen} closeModal={closeRemoveModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`Participant Subregion ${season} ${format(new Date(), 'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                <Grid
                data={process(data, dataState)}
                dataItemKey='id'
                onItemChange={handleChange}
                editField='inEdit'
                {...dataState}
                onDataStateChange={dataStateChange}
                pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                onPageChange={pageChange}
                sortable={true}
                filterable={true}
                resizable={true}
            >
                {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                <GridColumn title='Participant' field='participantNm' cell={ParticipantDropDownCell}/>
                <GridColumn title='Default Subregion' field='defaultSubregion' cell={SubregionDropDownCell}/>
                    <GridColumn title={season === 'Summer' ? `June ${subyear}` : `November ${subyear}`} field='month1' cell={SubregionDropDownCell}/>
                    <GridColumn title={season === 'Summer' ? `July ${subyear}` : `December ${subyear}`} field='month2' cell={SubregionDropDownCell}/>
                    <GridColumn title={season === 'Summer' ? `August ${subyear}` : `January ${subyear + 1}`} field='month3' cell={SubregionDropDownCell}/>
                    <GridColumn title={season === 'Summer' ? `September ${subyear}` : `February ${subyear + 1}`} field='month4' cell={SubregionDropDownCell}/>
                    {season === 'Winter' && <GridColumn title={`March ${subyear + 1}`} field='month5' cell={SubregionDropDownCell}/>}
                </Grid>
                </ExcelExport>
            </TooltipContainer>
        </div>
    );
};


