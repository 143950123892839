import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import {AccordionWrapper} from '../common/grid/AccordionWrapper';
import ForcedOutageGrid from "./ForcedOutageGrid";
import ForcedOutageTotalsGrid from "./ForcedOutageTotalsGrid";

const ForcedOutagePage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);

    useEffect(() => {
        setPageRole(getCompressedPageRole('ForcedOutage', roles));
    }, [roles]);

    return (
        <Container fluid style={{paddingLeft: 20, paddingRight: 20, paddingTop: 50}}>
            <Header size={'large'}>Storage Hydro Forced Outages</Header>
            <AccordionWrapper accordionHeader={'Storage Hydro Forced QCC MW Summer'}>
                <ForcedOutageGrid
                    {...pageRole}
                    title='Storage Hydro Forced QCC MW Summer'
                    dataApi='/api/auth/wrap/forced-outage/summer'
                    season='Summer'
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Storage Hydro Forced QCC MW Winter'}>
                <ForcedOutageGrid
                    {...pageRole}
                    title='Storage Hydro Forced QCC MW Winter'
                    dataApi='/api/auth/wrap/forced-outage/winter'
                    season='Winter'
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Storage Hydro Forced Outages Totals Summer'}>
                <ForcedOutageTotalsGrid
                    {...pageRole}
                    title='Storage Hydro Forced Outages Totals Summer'
                    dataApi='/api/auth/wrap/forced-outage'
                    season='Summer'
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Storage Hydro Forced Outages Totals Winter'}>
                <ForcedOutageTotalsGrid
                    {...pageRole}
                    title='Storage Hydro Forced Outages Totals Winter'
                    dataApi='/api/auth/wrap/forced-outage'
                    season='Winter'
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(ForcedOutagePage);
