import {GridCellProps} from '@progress/kendo-react-grid';
import {DropDownList, DropDownListFilterChangeEvent} from '@progress/kendo-react-dropdowns';
import {isDifferent} from '../../../services/data-grid-service';
import {useState} from 'react';
import {CompositeFilterDescriptor, filterBy, FilterDescriptor} from '@progress/kendo-data-query';

export interface DropDownOption {
    text: string,
    value: string | number
}

interface ResourceSpecificCellProps extends GridCellProps {
    options: Array<DropDownOption>,
    placeholder: string,
    parentField: string
}

export const ResourceSpecificCell = (props: ResourceSpecificCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const value = field && dataItem[field] ? dataItem[field] : '';
    const dataValue = field && dataItem[field] ? dataItem[field] : '';
    const parentDataValue = dataItem[props.parentField] ? dataItem[props.parentField] : '';
    const placeholder = props.placeholder ? `Select ${props.placeholder}...` : '';
    const defaultItem = {text: placeholder, value: ''};
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);

    const [data, setData] = useState<Array<DropDownOption>>(props.options.slice());

    const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        const dataOptions = props.options.slice();
        return filterBy(dataOptions, filter);
    };

    const filterChange = (event: DropDownListFilterChangeEvent) => {
        setData(filterData(event.filter));
    };

    const onChange = (event: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value.value //=== '' ? null : event.target.value.value
            });
        }
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit
                ? isChanged
                    ? <DropDownList
                        onChange={onChange}
                        data={data}
                        value={props.options.find((o) => o.value === dataValue) || defaultItem}
                        textField='text'
                        defaultItem={defaultItem}
                        disabled={parentDataValue !== 'Resource Specific'}
                        filterable={true}
                        onFilterChange={filterChange}
                        title={originalValue === null ? 'null' : originalValue}
                    />
                    : <DropDownList
                        onChange={onChange}
                        data={data}
                        value={props.options.find((o) => o.value === dataValue) || defaultItem}
                        textField='text'
                        defaultItem={defaultItem}
                        disabled={parentDataValue !== 'Resource Specific'}
                        filterable={true}
                        onFilterChange={filterChange}
                    />
                : value.toString()
            }
        </td>
    );
};

export const getResourceSpecificCell = (options: Array<DropDownOption>, placeholder: string, parentField: string) => {
    return (props: GridCellProps) => (
        <ResourceSpecificCell {...props} options={options} placeholder={placeholder} parentField={parentField}/>
    );
};
