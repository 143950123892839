import * as React from 'react';
import {
    Button,
    ButtonProps,
    Container,
    Grid,
    Icon,
    Input, InputOnChangeData,
    Popup,
    Segment
} from "semantic-ui-react";

import axios from 'axios';
import '../../CSS/react-table-custom.css';
import format from 'date-fns/format';
import {connect} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import {toast} from "react-toastify";
import ReactTableHOC from './EdstGrid';
import {filterMethod} from './ReactTableUtil';// important: this line must be placed after react-table css import*/
import AdminService from "../../services/admin-services";
import {isIE} from 'react-device-detect';

interface GridState {
    data: any,
    edit: boolean,
    updatedData: any,
    currentRow: number,
    metaData: any,
    filtered: any,
    openModal: boolean,
    selectedRowColor: string,
    status: number,
    loading: boolean,
    showAddRowsButton: boolean,
    rowsToAddToChangeset: any,
    exporting: boolean,
    edstGridInputFilterKey: any,
    serverSideData: number
}

interface BaseCaseGridProps {
    resource: string,
    currentChangesetNumber:number,
    changeset: any,
    addRowsToChangeset: (basecaseRecords: any[]) => void,
    updateChangeSetPendingSave: (pendingSave: boolean) => void,
    setChangesetRowsUpdated: (rowUpdated: boolean) => void,
    setSubYearIdForChange: (subYear: number) => void,
    rowToRemoveFromChangeset: number,
    currentUser:string,
    resAdqLockedOut?: boolean,
    modDevLockedOut?: boolean,
    category?: string,
    sppStaff?: boolean,
    userInAuthorEntityGroup: boolean,
    basecaseVersion: number
}

let fileDownload = require('js-file-download');
const adminClient = new AdminService();
class BaseCaseGrid extends React.Component<BaseCaseGridProps, GridState> {
    constructor(props: BaseCaseGridProps, state: GridState) {
        super(props,state);
        this.state = {
            data: [],
            edit: false,
            updatedData:[],
            currentRow: -1,
            metaData: [],
            filtered: [],
            openModal: false,
            selectedRowColor: '',
            status: 200,
            loading: true,
            showAddRowsButton: false,
            rowsToAddToChangeset: [],
            exporting: false,
            edstGridInputFilterKey: null,
            serverSideData: 0
        };
        if (!isIE)
        require('../../CSS/react-table-custom.css');
    }

    handleFilterChange = (filter:any) => {
        let currentFilter = this.state.filtered;
        currentFilter = filter;
        this.setState({filtered:currentFilter});
    };

    addSppVerifiedStatus = (data: any) =>{
        if(this.props.resource === 'Resources' || this.props.resource === 'ResourceOwnership'
            || this.props.resource === 'GeneratorTestResults' || this.props.resource === 'Plants'
            || this.props.resource === 'PurchasesSales'){
            data['sppVerified'] = 'NO';
            return data;
        }
        return data;
    }

    addRowsToChangeset = () => {
        let {rowsToAddToChangeset} = this.state;
        let data = JSON.parse(JSON.stringify(this.state.data));
        if (rowsToAddToChangeset === 'all') {
            let rowsToAdd = data
                .filter((value:any) => {
                    return (value.changesetStatus === null || value.changeseStatus === '');
                }).map((value:any) => {
                    value.changesetStatus = `${this.props.currentChangesetNumber} / DRAFT`;
                    return this.addSppVerifiedStatus(value);
                });
            this.props.addRowsToChangeset(rowsToAdd);
        } else {
            let changesetRows = this.state.rowsToAddToChangeset.map((value:any) => {

                data[value].changesetStatus = `${this.props.currentChangesetNumber} / DRAFT`;
                return this.addSppVerifiedStatus(data[value]);
            });
            this.props.addRowsToChangeset(changesetRows);
        }

        let dataCopy = JSON.parse(JSON.stringify(data));
        this.props.setChangesetRowsUpdated(true);
        this.props.updateChangeSetPendingSave(true);
        this.clearCheckBoxes();
        this.setState({rowsToAddToChangeset:[],data: dataCopy});
    };

    /*
    * Clears all filters
    */
    clearFilterOnClick = () => {
      this.setState({filtered:[], edstGridInputFilterKey: Date.now() });
    };

    masterCheckBoxChanged = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (!event.currentTarget.checked) {
            this.clearCheckBoxes();
            this.setState({rowsToAddToChangeset: []});
            return
        }

        let checkboxes = (document.getElementsByName("basecasecheckbox") as NodeListOf<HTMLInputElement>);
        if(this.state.filtered.length === 0) {
            for (let i = 0; i < checkboxes.length; i++) {
                if (!checkboxes[i].disabled) {
                    // @ts-ignore
                    checkboxes[i].checked = event.target.value === 'on';
                }
            }
            this.setState({rowsToAddToChangeset: 'all'})
        }
        else {
            let filteredRows = [];
            for (let i = 0; i < checkboxes.length; i++) {
                if (!checkboxes[i].disabled) {
                    // @ts-ignore
                    checkboxes[i].checked = event.target.value === 'on';
                    if(checkboxes[i].id !== '') {
                        filteredRows.push(checkboxes[i].id);
                    }
                }
            }
            this.setState({rowsToAddToChangeset: filteredRows});
        }
    };

    checkBoxChanged = (event: React.SyntheticEvent<HTMLInputElement>, checkboxData: InputOnChangeData) => {
        let {rowsToAddToChangeset,data} = this.state;

        if (event.currentTarget.checked && !rowsToAddToChangeset.includes(data[event.currentTarget.id])) {
            rowsToAddToChangeset.push(event.currentTarget.id);
            this.setState({rowsToAddToChangeset});
        } else if (!event.currentTarget.checked && rowsToAddToChangeset.includes(event.currentTarget.id)) {
            let filtered = rowsToAddToChangeset.filter((value:any) => {
                return value !== event.currentTarget.id
            });
            this.setState({rowsToAddToChangeset:filtered})
        }
    };

    clearCheckBoxes = () => {
        let checkboxes = (document.getElementsByName("basecasecheckbox") as NodeListOf<HTMLInputElement>);
        for (let i=0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        }
    };

    exportToExcel = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        if(this.state.serverSideData > 0) {
            const params = new URLSearchParams();
            params.append('resource', this.props.resource);
            axios.get('/api/auth/basecase/export', {
                params,
                responseType: 'arraybuffer'
                }
            ).then(resp => {
                this.setState({exporting: false});
                fileDownload(resp.data, `BaseCase${this.props.resource}_${format(new Date(),'ddMMyyyy_hhmmssa')}.xlsx`);
            }).catch((response) => {
                this.setState({exporting: false});
                toast.error(`Error exporting data to excel - ${response.message}`);
            });
            this.setState({exporting: true})
        }
        else {
            toast.error('Cannot export empty basecase.  Add records to basecase.  Then re-try exporting');
        }
    };

    getFiltered = () => {
        if (this.state.filtered.length > 0) {
            return 'inline'
        }
        return 'none'
    };

    componentDidUpdate(prevProps: Readonly<BaseCaseGridProps>, prevState: Readonly<GridState>, snapshot?: any): void {
        if (this.props.basecaseVersion !== prevProps.basecaseVersion) {
            this.getData();
        }

        if(this.state.rowsToAddToChangeset != null && this.state.rowsToAddToChangeset.length > 0 && this.state.rowsToAddToChangeset !== 'all') {
            let checkboxes = (document.getElementsByName("basecasecheckbox") as NodeListOf<HTMLInputElement>);
            if(checkboxes.length !== this.state.rowsToAddToChangeset.length) {
                let masterChecked = false;
                if(checkboxes[0].checked) {
                    masterChecked = true;
                }
                this.clearCheckBoxes();
                if(masterChecked) {
                    checkboxes[0].checked = true;
                }
                for(let i = 0; i < checkboxes.length; i++) {
                    for (let j = 0; j < this.state.rowsToAddToChangeset.length; j++) {
                        if (checkboxes[i].id === this.state.rowsToAddToChangeset[j]) {
                            checkboxes[i].checked = true;
                        }
                    }
                }
            }
        }

        if (this.props.rowToRemoveFromChangeset !== prevProps.rowToRemoveFromChangeset) {
            let data = [...this.state.data].map((d: any) => {
                if (d.pk === this.props.rowToRemoveFromChangeset) {
                    return {...d, changesetStatus: null};
                } else {
                    return d;
                }
            });
            this.setState({data});
        }
    }

    getCheckboxColumn = () => {
        return [{
            Header: <Input type={'checkbox'} name={'basecasecheckbox'} onChange={this.masterCheckBoxChanged}/>,
            Cell: (cellInfo: any) => {
                return (
                    <div style={{textAlign: 'center'}}>
                        <Input type={'checkbox'} disabled={cellInfo.original.changesetStatus !== null}
                               name={'basecasecheckbox'} id={cellInfo.index} onChange={this.checkBoxChanged}/>
                    </div>
                )
            },
            Filter: <Popup trigger={<Icon name={'filter'} size={"large"} style={{cursor: 'pointer'}}
                                          onClick={this.clearFilterOnClick}/>} content={'Clear Filters'}/>,
            sortable: false
        }];
    };

    componentDidMount() {
        this.getData();
    };

    getData = () => {
        axios.get(`/api/auth/basecase/baseCase`,
            {params: {
                    tabName: this.props.resource
                }
            }
            ).then(resp => {
            if (resp.status !== 200) {
                toast.error(`Error retrieving basecase data for ${this.props.resource} - ${resp.statusText}`)
            }
            if (resp.data.data.length > 0) {
                this.props.setSubYearIdForChange(resp.data.data[0].subYearId);
            }
            else {
                //Handle when no basecase data present, determine current subYear in order to not hit errors
                let cat = "";
                if (this.props.category === 'ModelingDevelopment') {
                    cat = "MDWG";
                }
                else {
                    if (this.props.category === 'ResourceAdequacy') {
                        cat = "RA";
                    }
                }
                adminClient.getSubYear(cat)
                    .then((data:any) => {
                        this.props.setSubYearIdForChange(+data);
                    });
            }
            this.setState({
                data: resp.data.data,
                metaData: resp.data.columnMetaDataList,
                status: resp.status,
                loading: false,
                serverSideData: resp.data.data.length
            });
        }).catch(resp => {
            toast.error(`Error retrieving basecase data - ${resp.message}`);
            this.setState({status: resp.status, loading: false})
        });
        this.setState({loading: true});
    };

    pageSizeChanged = (pageSize:number) => {
        localStorage.setItem(`${this.props.resource}BaseCasePageSize`,pageSize.toString());
    };

    disableAddToChangesetButton = () =>{
        //disabled={(((this.props.category === 'ResourceAdequacy' && this.props.resAdqLockedOut) || (this.props.category === 'ModelingDevelopment' && this.props.modDevLockedOut)) && !this.props.sppStaff) || this.props.changeset.status !== 'DRAFT' || (this.props.changeset.status === 'DRAFT' && !this.props.sppStaff && !this.props.userInAuthorEntityGroup)}
        //return true if should be disabled
        if((this.props.category === 'ResourceAdequacy' && this.props.resAdqLockedOut)&& !this.props.sppStaff){
            return true;
        }
        if((this.props.category === 'ModelingDevelopment' && this.props.modDevLockedOut)&& !this.props.sppStaff){
            return true;
        }
        if(this.props.changeset.status === 'DRAFT' && !this.props.sppStaff && !this.props.userInAuthorEntityGroup){
            return true;
        }
        if(this.props.changeset.status !== 'DRAFT'){
            return true;
        }
        //test
        return false;
    }

    public render() {
        let pageSize = localStorage.getItem(`${this.props.resource}BaseCasePageSize`);
        let {rowsToAddToChangeset} = this.state;

        const {data} = this.state;
        return (
            <Container fluid={true} style={{paddingLeft:'20px', paddingRight:'20px'}}>
                <Segment textAlign={'left'} style={{paddingLeft:'20px'}}>
                    <Button style={{display:rowsToAddToChangeset ? 'inline':'none'}} color={'black'} size={'mini'} onClick={this.clearCheckBoxes}>Clear Selection</Button>
                    <Button style={{display:this.props.currentChangesetNumber ? 'inline':'none'}} disabled={ this.disableAddToChangesetButton() } color={'black'} size={'mini'}
                            onClick={this.addRowsToChangeset}>Add Rows to Changeset</Button>
                </Segment>
                <ReactTableHOC
                    tableStyle={{height:'500px'}}
                    data={data}
                    columnMetaData={this.state.metaData}
                    defaultPageSize={pageSize ? +pageSize : 50}
                    filterable={true}
                    resource={this.props.resource}
                    isChangeSet={false}
                    filtered={this.state.filtered}
                    onFilterChanged={this.handleFilterChange}
                    className={'-striped BaseCaseGrid'}
                    loading={this.state.loading}
                    utilityColumn={this.getCheckboxColumn()}
                    defaultFilterMethod={filterMethod}
                    onFilteredChange={filtered => this.setState({filtered})}
                    inputFilterKey={this.state.edstGridInputFilterKey}
                    tableClassName={'BaseCaseGrid'}
                    onPageSizeChange={this.pageSizeChanged}
                    getTheadProps={() => {
                        return {
                            style: {
                                background: "black",
                                color: "white",
                                borderRadius: '5px'
                            }
                        };
                    }}
                    getTrProps={(state:any, rowInfo:any, column:any) => {
                        if (rowInfo && rowInfo.index === this.state.currentRow) {
                                return {
                                    style: {
                                        background: 'lightgrey'
                                    }
                                };

                        }
                            return {
                                style: {
                                    background: 'white'
                                }
                            };

                    }}
                />
                <Grid style={{paddingTop:'20px'}}>
                    <Grid.Row style={{paddingLeft:'15px'}}>
                        <Button size={'mini'} color={'black'} onClick={this.exportToExcel} loading={this.state.exporting}><Icon name={'download'}/>Export BaseCase to Excel</Button>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentChangesetNumber: state.changeSetReducer.currentChangeset.number,
        rowToRemoveFromChangeset: state.changeSetReducer.rowToRemoveFromChangeset,
        currentUser: state.authReducer.currentUser,
        changeset: state.changeSetReducer.currentChangeset,
        userInAuthorEntityGroup: state.changeSetReducer.userInAuthorEntityGroup,
        basecaseVersion: state.baseCaseReducer.basecaseVersion
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        addRowsToChangeset: (basecaseRecord: any[]) => dispatch (
            {type: actionTypes.ADD_ROWS_TO_CHANGESET, payload: basecaseRecord}
        ),
        updateChangeSetPendingSave: (pendingSave: boolean) => dispatch (
            {type: actionTypes.UPDATE_CHANGESET_PENDING_SAVE, payload: pendingSave}
        ),
        setChangesetRowsUpdated: (rowUpdated: boolean) => dispatch (
            {type: actionTypes.SET_CHANGESET_ROW_UPDATED, payload: rowUpdated}
        ),
        setSubYearIdForChange: (subYear: number) => dispatch (
            {type: actionTypes.SET_SUBYEAR_ID, payload: subYear}
        )
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(BaseCaseGrid);