import {useCallback, useContext, useState} from 'react';
import {GridCellProps} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import {GridEditContext} from './context/GridEditContext';
import {FormSubmitContext} from './context/FormSubmitContext';
import {DATA_ITEM_KEY, FORM_DATA_INDEX} from './RowEditGrid';
import RowEditRemoveModal from './RowEditRemoveModal';

export const COMMAND_CELL_WIDTH = 150;

export const CommandCell = (props: GridCellProps) => {
    const onSubmit = useContext(FormSubmitContext);
    const {onRowAction, setEditIndex, editIndex} =
        useContext(GridEditContext);
    const rowIndex = props.dataItem[FORM_DATA_INDEX];
    const isInEdit = rowIndex === editIndex;
    const isNewItem = props.dataItem[DATA_ITEM_KEY] < 0;
    const onSaveClick = useCallback((e) => {onSubmit(e);}, [onSubmit]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const onRemoveClick = useCallback(
        () => {
            onRowAction({
                rowIndex: props.dataItem[FORM_DATA_INDEX],
                operation: "remove",
                dataItem: props.dataItem,
            });
            setModalOpen(false);
        },
        [onRowAction, props.dataItem]
    );

    const onEditClick = useCallback(() => {setEditIndex(rowIndex);}, [rowIndex, setEditIndex]);

    const onCancel = useCallback(() => {setEditIndex(undefined);}, [setEditIndex]);

    const buttonClassName = isInEdit
        ? isNewItem
            ? "k-grid-save-command"
            : "k-grid-cancel-command"
        : "k-grid-edit-command";

    const buttonText = isInEdit ? 'Save' : "Edit";
    const xButtonText = isInEdit
        ? isNewItem
            ? 'Discard'
            : 'Cancel'
        : 'Remove';

    return (
        <td className="k-command-cell">
            <Button
                className={`k-button k-button-md k-rounded-md k-button-solid ${
                    isInEdit ? "k-button-solid-base" : "k-button-solid-primary"
                } ${buttonClassName}`}
                onClick={isInEdit ? onSaveClick : onEditClick}
            >
                {buttonText}
            </Button>
            <Button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
                onClick={isInEdit ? isNewItem ? onRemoveClick : onCancel : openModal}
            >
                {xButtonText}
            </Button>
            <RowEditRemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={onRemoveClick} />
        </td>
    );
};