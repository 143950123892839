export const downloadFile = (file: any, filename: string) => {
    let binaryData = [];
    binaryData.push(file);
    const fileURL = window.URL.createObjectURL(new Blob(binaryData));
    const linkElement = document.createElement('a');
    linkElement.href = fileURL;
    linkElement.download = filename;
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
};