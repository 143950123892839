import {GridCellProps} from '@progress/kendo-react-grid';
import {toast} from 'react-toastify';
import axios from 'axios';
import {Button} from '@progress/kendo-react-buttons';
import {downloadFile} from '../../../services/file-service';

interface FileCellProps extends GridCellProps {
    idField: string;
    subyearField: string;
    seasonField: string;
}

export const FileCell = (props: FileCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const filename = field && dataItem && dataItem[field] ? dataItem[field] : '';
    const id = dataItem[props.idField];
    const season = dataItem[props.seasonField];
    const subyear = dataItem[props.subyearField];
    
    const handleFileDownload = () => {
        const params = new URLSearchParams();
        params.set('subyear', subyear.toString());
        params.set('season', season);
        params.set('id', id.toString());
        axios.get('/api/auth/wrap/document/download', {params, responseType: 'blob'})
            .then(resp => {
                downloadFile(resp.data, filename);
            })
            .catch(error => {
                console.log(error);
                toast.error('Error downloading file.');
            });
    };

    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {!!filename &&
                <Button
                    onClick={handleFileDownload}
                    themeColor='primary'
                    fillMode='link'
                >
                    {filename}
                </Button>
            }
        </td>
    );
};

export const getFileCell = (idField: string, subyearField: string, seasonField: string) => {
    return (
        (props: GridCellProps) => <FileCell {...props} idField={idField} subyearField={subyearField} seasonField={seasonField}/>
    );
};