import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {
    Grid,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent
} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from '../../interfaces/grid-interfaces';
import {
    PurchasesSalesData,
    PurchasesSalesEditData,
    PurchasesSalesResourceOptions
} from "../../interfaces/purchases-sales-interface";
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState, numberBoundValidationOnEdit, numberBoundValidationOnUpload,
    processColumnMeta,
    processDropDownListOptions,
    removeDataErrorByValue,
    resetChildOption,
    resetOption, toastPreSaveValidationErrors,
    updateEditedRows,
    updateEditedVerified
} from '../../services/data-grid-service';
import {DropDownOption, getDropDownCell, getInternalExternalDropDownCell} from '../common/grid/DropDownCell';
import {CustomLocalizationProvider, getVerifiedCell} from '../common/grid/VerifiedCell';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import AddPanel from '../common/action-panel/AddPanel';
import ExportPanel from '../common/action-panel/ExportPanel';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import TooltipContainer from '../common/grid/TooltipContainer';
import ValidationPreSaveGrid from '../common/grid/ValidationPreSaveGrid';
import ImportPanel from '../common/action-panel/ImportPanel';
import {ChildDropDownOption, getChildDropDownCell} from '../common/grid/ChildDropDownCell';
import {getDecimalCell} from '../common/grid/DecimalCell';
import {getOwnershipCompanyDropDownCell} from '../common/grid/OwnershipCompanyDropDownCell';
import {getFuelTypeDropDownCell} from '../common/grid/FuelTypeDropDownCell';
import RemovePanel from '../common/action-panel/RemovePanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import {getRemoveCell} from '../common/grid/RemoveCell';
import {toast} from 'react-toastify';
import {InputCell} from '../common/grid/InputCell';
import {DateCell} from '../common/grid/DateCell';
import ValidatePanel from "../common/action-panel/ValidatePanel";
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import ClearFilterPanel from '../common/action-panel/ClearFilterPanel';
import RefreshPanel from '../common/action-panel/RefreshPanel';
import {replaceSpaceWithUnderscore} from "../../services/data-grid-service";
import format from 'date-fns/format';
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';

interface PurchasesSalesGridProps extends PageRole{
    title: string,
    dataApi: string,
    season: 'Summer' | 'Winter'
}

const sellingEntNmEmpty = 'Selling Entity Name must have a value to save the record.';
const purchasingEntNmEmpty = 'Purchasing Entity Name must have a value to save the record.';

const PurchasesSalesGrid: React.FC<PurchasesSalesGridProps> = ({title, isStaff, dataApi, season, readRole, addRole, modifyRole, removeRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<PurchasesSalesEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<PurchasesSalesData>>([]);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [subYear, setSubYear] = useState<number>(0);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const globalPageSize = localStorage.getItem(`globalPageSize`)
    const [dataState, setDataState] = useState<any>({...initialDataState, take: globalPageSize ? +globalPageSize : 10, pageSize: globalPageSize ? +globalPageSize : 10});
    const [entityOptions, setEntityOptions] = useState<Array<DropDownOption>>([]);
    const [transactionTypeOptions, setTransactionTypeOptions] = useState<Array<DropDownOption>>([]);
    const [contractTypeOptions, setContractTypeOptions] = useState<Array<DropDownOption>>([]);
    const [plantNameOptions, setPlantNameOptions] = useState<Array<DropDownOption>>([]);
    const [resourceOptions, setResourceOptions] = useState<Array<ChildDropDownOption>>([]);
    const [resourceOwnerOptions, setResourceOwnerOptions] = useState<Array<PurchasesSalesResourceOptions>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);

    const userEntities = localStorage.getItem("userEntities") !== null
        ? JSON.parse(localStorage.getItem("userEntities")!.toString()).map((item: { respEntNm: any; }) => item.respEntNm) : []

    let uniqueUserEntities = [...new Set(userEntities)]

    useEffect(() => {
        axios.get(`${dataApi}/columns`)          //get the columns
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    useEffect(() => {
        axios.get('/api/auth/ra/dropdown/all-entities')
            .then((resp) => {
                setEntityOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/ra/purchases-sales/dropdown/transaction-types')
            .then((resp) => {
                setTransactionTypeOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/ra/purchases-sales/dropdown/contract-types')
            .then((resp) => {
                setContractTypeOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${dataApi}/dropdown/plants-resources`)
            .then((resp) => {
                const plantData = processDropDownListOptions(resp.data.plantData);
                const resourceData = resp.data.resourceData
                    .map((item: any) => ({
                        text: item.resourceName,
                        value: item.resourceName,
                        parentValue: item.plantName,
                        childValue: item.fuelType
                    }));
                const resourceOwnerData = resp.data.resourceData
                    .map((item: any) => ({
                        plantNm: item.plantName,
                        resourceNm: item.resourceName,
                        fuelType: item.fuelType,
                        ownershipCompanyNm: item.ownershipCompanyNm
                    }));
                resourceData.sort((a: any, b: any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
                setPlantNameOptions(plantData);
                setResourceOptions(resourceData);
                setResourceOwnerOptions(resourceOwnerData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    useEffect(() => {
        axios.get('/api/auth/ra/subyear/current')
            .then((resp) => {
                setSubYear(resp.data);
            })
    }, []);

    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(dataApi), axios.get(`/api/auth/ra/season-window/${season}`)])
            .then((resp) => {
                setOriginalData(deepCopyGridData(resp[0].data));
                setData(deepCopyGridDataWithOriginal(resp[0].data));
                setTempSequence(-10000);
                setDataErrors([]);
                if (!isStaff) {
                    if (resp[1].data === false) {
                        toast.info(`Resource Adequacy window is closed for the ${season} season`);
                    }
                    setSeasonOpen(resp[1].data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, isStaff, readRole, season]);

    useEffect(() => {
        getData();
    }, [getData]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = (id: number, field: string, value: any) => {
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                if (field === 'contractType') {
                    dataItem.ownershipCompanyNm = '';
                    dataItem.fuelType = '';
                    if (value !== 'Resource Specific') {
                        dataItem.resourceNm = '';
                    }
                    if (value !== 'Plant Specific' && value !== 'Resource Specific') {
                        dataItem.plantNm = '';
                    }
                }
                if (field === 'plantNm') {
                    dataItem.resourceNm = '';
                    dataItem.ownershipCompanyNm = '';
                    dataItem.fuelType = '';
                }
                if (field === 'resourceNm') {
                    dataItem.ownershipCompanyNm = '';
                    dataItem.fuelType = '';
                }
                if (field === 'ownershipCompanyNm' && !dataItem.resourceNm) {
                    dataItem.fuelType = '';
                }
                if (field === 'purchasingEntNm' && !isStaff && uniqueUserEntities.indexOf(dataItem.purchasingEntNm) === -1 && uniqueUserEntities.indexOf(dataItem.sellingEntNm) === -1) {
                    dataItem.purchasingEntNm = '';
                    setDataErrors(prevDataErrors => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if (field === 'sellingEntNm' && !isStaff && uniqueUserEntities.indexOf(dataItem.purchasingEntNm) === -1 && uniqueUserEntities.indexOf(dataItem.sellingEntNm) === -1) {
                    dataItem.sellingEntNm = '';
                    setDataErrors(prevDataErrors => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if ((value || value === 0) && (['contractedCapacity', 's1Cap', 's2Cap', 's3Cap', 's4Cap', 's5Cap', 's6Cap', 's7Cap', 's8Cap', 's9Cap', 's10Cap', 's11Cap'].includes(field))) {
                    numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 999999.999, 0);
                }
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify && field === 'verified') {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
    };

    // Determine correct value for dependent columns.
    const resetResourceOwnerOption = (upload: PurchasesSalesEditData, row?: PurchasesSalesEditData): PurchasesSalesResourceOptions => {
        const contractTypeReset = !!row ? row.contractType : '';
        const contractType = resetOption(upload.contractType, contractTypeOptions, contractTypeReset);
        let plantNm = '';
        let resourceNm = '';
        let ownershipCompanyNm = '';
        let fuelType = '';

        if (contractType === 'Fleet') {
            if (upload.fuelType?.toLowerCase() === 'Conventional Based'.toLowerCase()) {
                fuelType = 'Conventional Based';
            } else if (upload.fuelType?.toLowerCase() === 'Hydro Based'.toLowerCase()) {
                fuelType = 'Hydro Based';
            } else {
                fuelType = '';
            }
            return {
                plantNm: '',
                resourceNm: '',
                ownershipCompanyNm: '',
                fuelType
            };
        }

        let option: PurchasesSalesResourceOptions | undefined;

        const plantNmReset = row && row.plantNm ? row.plantNm : '';
        plantNm = upload.plantNm ? resetOption(upload.plantNm, plantNameOptions, plantNmReset) : '';

        if (contractType === 'Plant Specific') {
            option = resourceOwnerOptions.find((op) =>
                op.plantNm?.toLowerCase() === plantNm.toLowerCase()
                && op.ownershipCompanyNm?.toLowerCase() === upload.ownershipCompanyNm?.toLowerCase());
            if (!option && row) {
                option = resourceOwnerOptions.find((op) =>
                    op.plantNm?.toLowerCase() === plantNm.toLowerCase()
                    && op.ownershipCompanyNm?.toLowerCase() === row.ownershipCompanyNm?.toLowerCase());
            }
            ownershipCompanyNm = option ? option.ownershipCompanyNm : '';
            if (ownershipCompanyNm) {
                option = resourceOwnerOptions.find((op) =>
                    op.plantNm?.toLowerCase() === plantNm.toLowerCase()
                    && op.ownershipCompanyNm?.toLowerCase() === upload.ownershipCompanyNm?.toLowerCase()
                    && op.fuelType?.toLowerCase() === upload.fuelType?.toLowerCase());
                if (!option && row) {
                    option = resourceOwnerOptions.find((op) =>
                        op.plantNm?.toLowerCase() === plantNm.toLowerCase()
                        && op.ownershipCompanyNm?.toLowerCase() === upload.ownershipCompanyNm?.toLowerCase()
                        && op.fuelType?.toLowerCase() === row.fuelType?.toLowerCase());
                }
            } else {
                option = resourceOwnerOptions.find((op) =>
                    op.plantNm?.toLowerCase() === plantNm.toLowerCase()
                    && op.fuelType?.toLowerCase() === upload.fuelType?.toLowerCase());
                if (!option && row) {
                    option = resourceOwnerOptions.find((op) =>
                        op.plantNm?.toLowerCase() === plantNm.toLowerCase()
                        && op.fuelType?.toLowerCase() === row.fuelType?.toLowerCase());
                }
            }
            fuelType = option ? option.fuelType : '';

            return {
                plantNm,
                resourceNm: '',
                ownershipCompanyNm,
                fuelType
            };
        }

        if (contractType === 'Resource Specific') {
            const resourceNmReset = row && row.resourceNm ? row.resourceNm : '';
            resourceNm = upload.resourceNm ? resetChildOption(plantNm, upload.resourceNm, resourceOptions, resourceNmReset) : '';
            option = resourceOwnerOptions.find((option) =>
                option.plantNm?.toLowerCase() === plantNm.toLowerCase()
                && option.resourceNm?.toLowerCase() === resourceNm.toLowerCase()
                && option.ownershipCompanyNm?.toLowerCase() === upload.ownershipCompanyNm?.toLowerCase());
            if (!option && row) {
                option = resourceOwnerOptions.find((option) =>
                    option.plantNm?.toLowerCase() === plantNm.toLowerCase()
                    && option.resourceNm?.toLowerCase() === resourceNm.toLowerCase()
                    && option.ownershipCompanyNm?.toLowerCase() === row.ownershipCompanyNm?.toLowerCase());
            }
            ownershipCompanyNm = option ? option.ownershipCompanyNm : '';
            option = resourceOwnerOptions.find((op) =>
                op.plantNm?.toLowerCase() === plantNm.toLowerCase()
                && op.resourceNm?.toLowerCase() === resourceNm.toLowerCase()
                && op.fuelType?.toLowerCase() === upload.fuelType?.toLowerCase());
            if (!option && row) {
                option = resourceOwnerOptions.find((op) =>
                    op.plantNm?.toLowerCase() === plantNm.toLowerCase()
                    && op.resourceNm?.toLowerCase() === resourceNm.toLowerCase()
                    && op.fuelType?.toLowerCase() === row.fuelType?.toLowerCase());
            }
            fuelType = option ? option.fuelType : '';

            return {
                plantNm,
                resourceNm,
                ownershipCompanyNm,
                fuelType
            };
        }

        // contractType not set, return empty values
        return {
            plantNm: '',
            resourceNm: '',
            ownershipCompanyNm: '',
            fuelType: ''
        };
    };

    const handleUpload = (uploadedData: Array<PurchasesSalesEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            let upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });
        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                let resOwnOption = resetResourceOwnerOption(u);
                // Reset non-editable fields to default or empty value.
                // Reset option fields to valid option or empty value.
                // Direct input fields can take update value without modification. API won't set values of wrong format.
                return {
                    ...u,
                    verified: false,
                    id: sequence++,
                    seasonType: season,
                    sellingEntNm: resetOption(u.sellingEntNm, entityOptions, ''),
                    purchasingEntNm: resetOption(u.purchasingEntNm, entityOptions, ''),
                    internalTransaction: resetOption(u.internalTransaction, processDropDownListOptions(['Internal', 'External']), ''),
                    transactionType: resetOption(u.transactionType, transactionTypeOptions, ''),
                    contractType: resetOption(u.contractType, contractTypeOptions, ''),
                    plantNm: resOwnOption.plantNm,
                    resourceNm: resOwnOption.resourceNm,
                    ownershipCompanyNm: resOwnOption.ownershipCompanyNm,
                    fuelType: resOwnOption.fuelType,
                    subYearId: subYear,
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true
                };
            });

        const dataUploaded: Array<PurchasesSalesEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u as PurchasesSalesEditData);
        });

        setData(dataUploaded);
        setTempSequence(sequence++);
    };

    const processUploadEdits = (upload: PurchasesSalesEditData, item: PurchasesSalesEditData) => {
        let resOwnOption = resetResourceOwnerOption(upload, item);

        // need to pass every field change to updateEditedRows
        let sellingEntNm = resetOption(upload.sellingEntNm, entityOptions, item.sellingEntNm);
        if (sellingEntNm?.toLowerCase() !== item.sellingEntNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'sellingEntNm', sellingEntNm));
        }
        let purchasingEntNm = resetOption(upload.purchasingEntNm, entityOptions, item.purchasingEntNm);
        if (purchasingEntNm?.toLowerCase() !== item.purchasingEntNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'purchasingEntNm', purchasingEntNm));
        }
        let internalTransaction = resetOption(upload.internalTransaction, processDropDownListOptions(['Internal', 'External']), item.internalTransaction);
        if (internalTransaction?.toLowerCase() !== item.internalTransaction?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'internalTransaction', upload.internalTransaction));
        }
        let transactionType = resetOption(upload.transactionType, transactionTypeOptions, item.transactionType);
        if (transactionType?.toLowerCase() !== item.transactionType?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'transactionType', transactionType));
        }
        let contractType = resetOption(upload.contractType, contractTypeOptions, item.contractType);
        if (contractType?.toLowerCase() !== item.contractType?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'contractType', contractType));
        }
        let plantNm = resOwnOption.plantNm;
        if (plantNm.toLowerCase() !== item.plantNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'plantNm', plantNm));
        }
        let resourceNm = resOwnOption.resourceNm;
        if (resourceNm.toLowerCase() !== item.resourceNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceNm', resourceNm));
        }
        let ownershipCompanyNm = resOwnOption.ownershipCompanyNm;
        if (ownershipCompanyNm.toLowerCase() !== item.ownershipCompanyNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'ownershipCompanyNm', ownershipCompanyNm));
        }
        let fuelType = resOwnOption.fuelType;
        if (fuelType.toLowerCase() !== item.fuelType?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'fuelType', fuelType));
        }
        if (upload.startDate !== item.startDate) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'startDate', upload.startDate));
        }
        if (upload.endDate !== item.endDate) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'endDate', upload.endDate));
        }
        if (upload.contractedCapacity !== item.contractedCapacity) {
            let field = 'contractedCapacity';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s1Cap !== item.s1Cap) {
            let field = 's1Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s2Cap !== item.s2Cap) {
            let field = 's2Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s3Cap !== item.s3Cap) {
            let field = 's3Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s4Cap !== item.s4Cap) {
            let field = 's4Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s5Cap !== item.s5Cap) {
            let field = 's5Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s6Cap !== item.s6Cap) {
            let field = 's6Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s7Cap !== item.s7Cap) {
            let field = 's7Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s8Cap !== item.s8Cap) {
            let field = 's8Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s9Cap !== item.s9Cap) {
            let field = 's9Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s10Cap !== item.s10Cap) {
            let field = 's10Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.s11Cap !== item.s11Cap) {
            let field = 's11Cap';
            numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
        }
        if (upload.sourceNameIdentifier?.toLowerCase() !== item.sourceNameIdentifier?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'sourceNameIdentifier', upload.sourceNameIdentifier));
        }
        if (upload.oasisReservationNumber?.toLowerCase() !== item.oasisReservationNumber?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'oasisReservationNumber', upload.oasisReservationNumber));
        }
        if (upload.information !== item.information) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'information', upload.information));
        }
        if (upload.comments?.toLowerCase() !== item.comments?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'comments', upload.comments));
        }

        return {
            ...item,
            sellingEntNm: sellingEntNm,
            purchasingEntNm: purchasingEntNm,
            internalTransaction: internalTransaction,
            transactionType: transactionType,
            contractType: contractType,
            plantNm: plantNm,
            resourceNm: resourceNm,
            ownershipCompanyNm: ownershipCompanyNm,
            fuelType: fuelType,
            startDate: upload.startDate,
            endDate: upload.endDate,
            contractedCapacity: upload.contractedCapacity,
            s1Cap: upload.s1Cap,
            s2Cap: upload.s2Cap,
            s3Cap: upload.s3Cap,
            s4Cap: upload.s4Cap,
            s5Cap: upload.s5Cap,
            s6Cap: upload.s6Cap,
            s7Cap: upload.s7Cap,
            s8Cap: upload.s8Cap,
            s9Cap: upload.s9Cap,
            s10Cap: upload.s10Cap,
            s11Cap: upload.s11Cap,
            sourceNameIdentifier: upload.sourceNameIdentifier,
            oasisReservationNumber: upload.oasisReservationNumber,
            information: upload.information,
            comments: upload.comments
        };
    };


    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: PurchasesSalesEditData = {
            verified: false,
            id: sequence++,
            sellingEntNm: '',
            purchasingEntNm: '',
            internalTransaction: '',
            transactionType: '',
            contractType: '',
            plantNm: '',
            resourceNm: '',
            ownershipCompanyNm: '',
            fuelType: '',
            seasonType: season,
            startDate: null,
            endDate: null,
            contractedCapacity: 0,
            s1Cap: 0,
            s2Cap: 0,
            s3Cap: 0,
            s4Cap: 0,
            s5Cap: 0,
            s6Cap: 0,
            s7Cap: 0,
            s8Cap: 0,
            s9Cap: 0,
            s10Cap: 0,
            s11Cap: 0,
            sourceNameIdentifier: '',
            oasisReservationNumber: '',
            information: '',
            comments: '',
            subYearId: subYear,
            lastUserModBy: '',
            lastUserModDt: null,
            verifiedBy: '',
            verifiedDt: null,
            inEdit: true
        };
        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    };

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
        resetRemove();
    };

    const save = () => {
        console.log('Save function called'); // Check if save function is being called
        if (filterEmptyRequiredColumns()) {
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }
        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }

        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item: PurchasesSalesEditData) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item: PurchasesSalesEditData) => !updatedIdList.includes(item.id)));
        axios.post(dataApi, newAndUpdatedData)   // save the data
            .then(() => {
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log('Error saving data', error); // Check if there's any error while saving data
            });
    };

    const filterEmptyRequiredColumns = (): boolean => {
        const missingRequired = data.filter((item) => !item.purchasingEntNm || !item.sellingEntNm);

        if (missingRequired.length === 0) {
            return false;
        }
        toast.error('There are columns missing required data. Please provide value to save changes.');
        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {
            if (!item.purchasingEntNm) {
                localErrors = addDataError(item.id, 'purchasingEntNm', 'Purchasing Entity', '',
                    purchasingEntNmEmpty, true, localErrors);
            }
            if (!item.sellingEntNm) {
                localErrors = addDataError(item.id, 'sellingEntNm', 'Selling Entity', '',
                    sellingEntNmEmpty, true, localErrors);
            }
        });
        setDataErrors(localErrors);
        return true;
    }

    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post(`${dataApi}/verify`, updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            // initialize 'removed' field to false on all data objects
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        // take 'removed' field off data objects
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const confirmRemove = () => {
        axios.delete(`/api/auth/ra/purchases-sales/delete`, {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                setData(localData);
                resetRemove();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const refreshData = () => {
        getData();
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`${dataApi}/validate`)
            .then(response => {
                setValidationErrors(response.data);
                getData();
                toast.info('Validation complete.');
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const RespEntDropDownCell = getDropDownCell(entityOptions, 'Entity');
    const InternalExternalDropdownCell = getInternalExternalDropDownCell('Reference');
    const TransactionTypeDropDownCell = getDropDownCell(transactionTypeOptions, 'Transaction Type');
    const ContractTypeDropDownCell = getDropDownCell(contractTypeOptions, 'Resource Type');
    const FuelTypeDropDownCell = getFuelTypeDropDownCell(resourceOwnerOptions);
    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);
    const PlantNameCell = getDropDownCell(plantNameOptions, 'Plant Name');
    const ResourceNameCell = getChildDropDownCell(resourceOptions, 'Resource ID', 'plantNm');
    const OwnershipCompanyDropDownCell = getOwnershipCompanyDropDownCell(resourceOwnerOptions);
    const DecimalCell = useMemo(() => getDecimalCell(3, true, 999999.999, 0), []);

    const formatExport = (data: Array<PurchasesSalesData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO',
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/upload`} setUploadRecords={handleUpload} />}
                {!inEdit && modifyRole && seasonOpen && isStaff && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleSelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && !inVerify && modifyRole && seasonOpen && <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        sortable={true}
                        filterable={true}
                        resizable={true}
                        style={{ height: "510px" }}
                        {...dataState}
                        editField='inEdit'
                        onDataStateChange={dataStateChange}
                        onItemChange={itemChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                    >
                        {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                        {
                            columnMeta.length
                                ? columnMeta.map((col: ColumnMeta, index) => {
                                    switch (col.nm) {
                                        case 'verified':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='103px' cell={VerifiedCell} filter={"boolean"}/>
                                        case 'id':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' editable={false}/>
                                        case 'sellingEntNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='155px' cell={RespEntDropDownCell}/>
                                        case 'purchasingEntNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='178px' cell={RespEntDropDownCell}/>
                                        case 'internalTransaction':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='160px' cell={InternalExternalDropdownCell}/>
                                        case 'transactionType':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='220px' cell={TransactionTypeDropDownCell}/>
                                        case 'contractType':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='140px' cell={ContractTypeDropDownCell}/>
                                        case 'plantNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='419px' cell={PlantNameCell}/>
                                        case 'resourceNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={ResourceNameCell}/>
                                        case 'ownershipCompanyNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='205px' cell={OwnershipCompanyDropDownCell}/>
                                        case 'fuelType':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='220px' cell={FuelTypeDropDownCell}/>
                                        case 'seasonType':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' editable={false}/>
                                        case 'startDate':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='160px' cell={DateCell}/>
                                        case 'endDate':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='160px' cell={DateCell}/>
                                        case 'contractedCapacity':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='155px' cell={DecimalCell}/>
                                        case 's1Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's2Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's3Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's4Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's5Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's6Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's7Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's8Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's9Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's10Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 's11Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' cell={DecimalCell}/>
                                        case 'sourceNameIdentifier':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='170px' cell={InputCell}/>
                                        case 'oasisReservationNumber':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='195px' cell={InputCell}/>
                                        case 'setId':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' editable={false}/>
                                        case 'information':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={InputCell}/>
                                        case 'comments':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={InputCell}/>
                                        case 'subYearId':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                        case 'lastUserModBy':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px' editable={false}/>
                                        case 'lastUserModDt':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='160px' editable={false}/>
                                        case 'verifiedBy':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' editable={false}/>
                                        case 'verifiedDt':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        default:
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                    }
                                })
                                : <GridColumn/>
                        }
                    </Grid>
                </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={title}/>}
        </div>
    );
};

export default PurchasesSalesGrid;






