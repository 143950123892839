import {createContext} from 'react';

export interface FacilityParticipant {
    facilityNm: string;
    participantNm: string;
}

export const FacilityLinkingOptionContext = createContext<{
    facilityOptions: string[],
    setFacilityOptions: (options: string[]) => void,
    participantOptions: string[],
    setParticipantOptions: (options: string[]) => void,
    facilityParticipantList: FacilityParticipant[]
}>({} as any);