import {GridCellProps} from '@progress/kendo-react-grid';
import * as React from 'react';
import {GridEditContext} from './context/GridEditContext';
import {Field} from '@progress/kendo-react-form';
import {Input} from '@progress/kendo-react-inputs';
import {FORM_DATA_INDEX} from './RowEditGrid';

export const StringFormCell = (props: GridCellProps) => {
    const {editIndex} = React.useContext(GridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    return (
        <td>
            {isInEdit ? (
                <Field
                    component={Input}
                    name={`${props.field}`}
                />
            ) : (
                props.dataItem[props.field || ""]
            )}
        </td>
    );
};