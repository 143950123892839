import ActionPanel from '../common/action-panel/ActionPanel';
import ExportPanel from "../common/action-panel/ExportPanel";
import axios from 'axios';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {PageRole} from "../../interfaces/grid-interfaces";
import {
    deepCopyGridDataWithOriginal,
    initialDataState,
    replaceSpaceWithUnderscore
} from "../../services/data-grid-service";
import {
    Grid,
    GridColumn
} from "@progress/kendo-react-grid";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridPageChangeEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import format from "date-fns/format";
import {process} from "@progress/kendo-data-query";
import TooltipContainer from "../common/grid/TooltipContainer";
import {ForcedOutageData, ForcedOutageEditData} from "../../interfaces/wrap/forced-outage-interface";

interface ForcedOutageTotalsGridProps extends PageRole {
    title: string,
    dataApi: string,
    season: string,
}

const ForcedOutageTotalsGrid: React.FC<ForcedOutageTotalsGridProps> = ({title, season, dataApi, isStaff, readRole, modifyRole}) => {
    const [data, setData] = useState<Array<ForcedOutageEditData>>([]);
    //const [originalData, setOriginalData] = useState<Array<ForcedOutageData>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const [dataState, setDataState] = useState<any>({
        ...initialDataState,
        take: globalPageSize ? +globalPageSize : 10,
        pageSize: globalPageSize ? +globalPageSize : 10
    });


    // summer and winter data from api
    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(`${dataApi}/${season}/totals`)])
            .then((resp) => {
                setData(deepCopyGridDataWithOriginal(resp[0].data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, season, readRole]);

    useEffect(() => {
        getData();
    }, [getData]);


    //refresh data
    const refreshData = () => {
        getData();
    };

    // clear all the filters
    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    //export mode
    const formatExport = (data: Array<ForcedOutageData>) => {
        return data.map(item => ({
            ...item
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    //page size
    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };

    const isSummer = season === 'Summer';

    return (
        <div>
            <ActionPanel title={title}>
                <ClearFilterPanel clearFilter={clearFilters}/>
                <RefreshPanel loading={loadingData} getData={refreshData} disabled={false}/>
                <ExportPanel exportData={excelExport} disabled={false}/>

            </ActionPanel>
            <TooltipContainer>
                <ExcelExport data={data}
                             fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(), 'MM-dd-yyyy')}.xlsx`)}
                             ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        sortable={true}
                        filterable={true}
                        resizable={true}
                        style={{maxHeight: "510px"}}
                        {...dataState}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                    >
                        <GridColumn title='Participant Name' field='participantNm' editable={false}/>
                        <GridColumn title={isSummer ? `June` : `November`} field='ownedMonth1' editable={false}/>
                        <GridColumn title={isSummer ? `July` : `December`} field='ownedMonth2' editable={false}/>
                        <GridColumn title={isSummer ? `August` : `January`} field='ownedMonth3' editable={false}/>
                        <GridColumn title={isSummer ? `September` : `February`} field='ownedMonth4' editable={false}/>
                        {season === 'Winter' && <GridColumn title={`March`} field='ownedMonth5' editable={false}/>}
                        {}
                    </Grid>
                </ExcelExport>
            </TooltipContainer>
        </div>
    );
};

export default ForcedOutageTotalsGrid;