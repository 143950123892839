import React, {useEffect, useState} from 'react';
import axios from "axios";
import {
    Chart,
    ChartLegend,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import {ForwardShowingProps} from '../../interfaces/wrap/forward-showing-interface';
import {fieldsToArray, isParticipantEmpty} from '../../services/wrap-report-service';
import {toast} from 'react-toastify';

const FSTransmissionDemonstrationChart: React.FC<ForwardShowingProps> = ({title, participant, subyear, season, showReport}) => {
    const init = season === 'Summer' ? [0, 0, 0, 0] : [0, 0, 0, 0, 0];
    const [pathPrmPercentData, setPathPrmPercentData] = useState<Array<number>>([...init]);
    const [pathExemptPercentData, setPathExemptPercentData] = useState<Array<number>>([...init]);
    const [requirementPercentData, setRequirementPercentData] = useState<Array<number>>([...init]);

    useEffect(() => {
        if (!showReport || !season || !subyear || isParticipantEmpty(participant)) {
            return;
        }
        const params = new URLSearchParams();
        params.set('subYear', subyear.toString());
        params.set('season', season);
        params.set('participant', participant);
        axios.get('/api/auth/wrap/forward-showing/transmission-demonstration-chart-data', {params})
            .then((resp) => {
                setPathPrmPercentData(fieldsToArray(resp.data[0], season, true));
                setPathExemptPercentData(fieldsToArray(resp.data[1], season, true));
                setRequirementPercentData(fieldsToArray(resp.data[2], season, true))
            })
            .catch(error => {
                console.log(error);
                toast.error('Error loading report data', {toastId: 'fs-show-report-error'});
            });
    }, [participant, season, showReport, subyear]);

    const summerCategories = [`June ${subyear}`, `July ${subyear}`, `August ${subyear}`, `September ${subyear}`];
    const winterCategories = [`November ${subyear}`, `December ${subyear}`, `January ${subyear + 1}`, `February ${subyear + 1}`, `March ${subyear + 1}`];
    const categories = season === 'Summer'
        ? summerCategories
        : winterCategories;
    const series = [
        {
            name: "% Transmission Demonstration of Demand+PRM",
            data: pathPrmPercentData
        },
        {
            name: "% Transmission Demonstration including Exceptions",
            data: pathExemptPercentData
        },
        {
            name: "Transmission Demonstration Requirement (%)",
            data: requirementPercentData
        }
    ];

    return (
        <Chart style={{height: 350, width: season === 'Winter' ? '1247px' : '1082px'}}>
            <ChartTitle text={title} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartValueAxis>
                <ChartValueAxisItem labels={{format: "{0}%"}} />
            </ChartValueAxis>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem
                    categories={categories}
                />
            </ChartCategoryAxis>
            <ChartSeries>
                {series.map((item, idx) => (
                    <ChartSeriesItem
                        key={idx}
                        type="line"
                        tooltip={{ visible: true, format: "{0}%"}}
                        data={item.data}
                        name={item.name}
                    />
                ))}
            </ChartSeries>
        </Chart>

    );
};

export default FSTransmissionDemonstrationChart;