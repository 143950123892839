import ActionPanel from '../common/action-panel/ActionPanel';
import ExportPanel from "../common/action-panel/ExportPanel";
import axios from 'axios';
import {
    FacilityData,
    FacilityEditData,
} from "../../interfaces/wrap/facility-interface";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {PageRole} from "../../interfaces/grid-interfaces";
import {
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState,
    replaceSpaceWithUnderscore,
    toastSuccessfulSave,
    updateEditedApproved
} from "../../services/data-grid-service";
import {
    Grid,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent,
    GridNoRecords
} from "@progress/kendo-react-grid";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridPageChangeEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import format from "date-fns/format";
import {process} from "@progress/kendo-data-query";
import TooltipContainer from "../common/grid/TooltipContainer";
import ApprovePanel from "../common/action-panel/ApprovePanel";
import {YesNoDropDownCell} from "../common/grid/TextDropDownCell";

interface FacilityGridProps extends PageRole {
    title: string,
    dataApi: string,
}

const FacilityApprovalGrid: React.FC<FacilityGridProps> = ({title, dataApi, isStaff, readRole, modifyRole}) => {
    const [data, setData] = useState<Array<FacilityEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<FacilityData>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [editedApproved, setEditedApproved] = useState<Array<number>>([]);
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const [dataState, setDataState] = useState<any>({
        ...initialDataState,
        take: globalPageSize ? +globalPageSize : 10,
        pageSize: globalPageSize ? +globalPageSize : 10
    });


    // summer and winter data from api
    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(`${dataApi}/new`)])
            .then((resp) => {
                setData(deepCopyGridDataWithOriginal(resp[0].data));
                setOriginalData(deepCopyGridData(resp[0].data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, readRole]);

    useEffect(() => {
        getData();
    }, [getData]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = (id: number, field: string, value: any) => {
        const localData = data.map(item => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedApproved(updateEditedApproved(editedApproved, originalData, id, value));
        }
    };

    //refresh data
    const refreshData = () => {
        getData();
    };

    // clear all the filters
    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    //Edit mode
    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedApproved([]);
    };

    const save = () => {
        if (editedApproved.length === 0) {
            cancelEdits();
            return;
        }
        const updatedData = data.filter((item) => editedApproved.includes(item.id));
        axios.post(`${dataApi}/approve`, updatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedApproved([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

   const handleApprovedSelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['approved'] = 'YES';
            return dataItem;
        });
        const localEdit = data.map((item) => item.id);
        setData(localData);
        setEditedApproved(localEdit);
    };

    //export mode
    const formatExport = (data: Array<FacilityData>) => {
        return data.map(item => ({
            ...item,
            approved: item.approved ? 'YES' : 'NO'
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    //page size
    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };

    return (
        <div>
            <ActionPanel title={title}>
                {isStaff && modifyRole && <ApprovePanel inEdit={inEdit} disabled={inEdit} selectAll={handleApprovedSelectAll} approve={save} toggleEdit={toggleEdit}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && <RefreshPanel disabled={inEdit} loading={loadingData} getData={refreshData}/>}
                {!inEdit && <ExportPanel exportData={excelExport} disabled={inEdit}/>}

            </ActionPanel>
            <TooltipContainer>
                <ExcelExport data={data}
                             fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(), 'MM-dd-yyyy')}.xlsx`)}
                             ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        sortable={true}
                        filterable={true}
                        resizable={true}
                        style={{maxHeight: "510px"}}
                        {...dataState}
                        editField='inEdit'
                        onDataStateChange={dataStateChange}
                        onItemChange={itemChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                    >
                        <GridNoRecords>
                            No Facilities pending approval
                        </GridNoRecords>
                        <GridColumn title='Approved' field='approved' width='179px' cell={YesNoDropDownCell}/>
                        <GridColumn title='Participant Name' field='participantNm' width='179px' editable={false}/>
                        <GridColumn title='Facility Name' field='facilityNm' width='179px' editable={false}/>
                        {}
                    </Grid>
                </ExcelExport>
            </TooltipContainer>
        </div>
    );
};

export default FacilityApprovalGrid;