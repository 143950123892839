import React from 'react';
import {GridCellProps} from '@progress/kendo-react-grid';
import {DropDownList, DropDownListFilterChangeEvent} from '@progress/kendo-react-dropdowns';
import {useState} from 'react';
import {DropDownOption} from './DropDownCell';
import {isDifferent, processDropDownListOptions} from '../../../services/data-grid-service';
import {CompositeFilterDescriptor, filterBy, FilterDescriptor} from "@progress/kendo-data-query";

interface PathDropDownCellProps extends GridCellProps {
    pathOptions: Array<any>;
    optionField: string;
}

const PathDropDownCell: React.FC<PathDropDownCellProps> = (props) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData, participantNm} = dataItem;
    const dataValue: string = field && participantNm && dataItem[field] ? dataItem[field] : '';
    const dataOption: DropDownOption = {text: dataValue, value: dataValue};
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);

    const [dataOptions, setDataOptions] = useState<Array<DropDownOption>>([dataOption]);
    const [options, setOptions] = useState<Array<DropDownOption>>([dataOption]);

    // dataOptions is the full set of available options.
    // Must maintain a separate set of options to always start filtering from the full list.
    // Otherwise filtering removes valid options until user reopens the dropdown.
    const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        return filterBy(dataOptions, filter);
    };

    const filterChange = (event: DropDownListFilterChangeEvent) => {
        setOptions(filterData(event.filter).slice());
    };

    const onChange = (event: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value.value,
            });
        }
    };

    const onOpen = () => {
        let pathValues: Array<string>;
        if (participantNm) {
            pathValues = Array.from(
                new Set(
                    props.pathOptions
                        .filter((item) => item.participantNm === participantNm)
                        .map((item) => item[props.optionField])
                )
            );
        } else {
            pathValues = [''];
        }
        setDataOptions(processDropDownListOptions(pathValues));
        setOptions(processDropDownListOptions(pathValues));
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit ? (
                isChanged ? (
                    <DropDownList
                        onChange={onChange}
                        data={options}
                        value={options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem=''
                        onOpen={onOpen}
                        title={originalValue}
                        filterable={true}
                        onFilterChange={filterChange}
                    />
                ) : (
                    <DropDownList
                        onChange={onChange}
                        data={options}
                        value={options.find((item) => item.value === dataValue)}
                        textField='text'
                        defaultItem=''
                        onOpen={onOpen}
                        filterable={true}
                        onFilterChange={filterChange}
                    />
                )
            ) : (
                dataValue
            )}
        </td>
    );
};

export const getPathDropDownCell = (pathOptions: Array<any>, optionField: string) => {
    return (props: GridCellProps) => (
        <PathDropDownCell {...props} pathOptions={pathOptions} optionField={optionField}/>
    );
};
