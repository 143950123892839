import {useEffect, useRef, useState} from "react";
import {ParticipantData, ParticipantEditData} from "../../interfaces/wrap/participant-interface";
import axios from "axios";
import {
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState,
    replaceSpaceWithUnderscore,
    toastSuccessfulSave,
    updateEditedRows
} from "../../services/data-grid-service";
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent} from "@progress/kendo-react-grid";
import {DataUpdate} from "../../interfaces/grid-interfaces";
import {toast} from "react-toastify";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridPageChangeEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import ActionPanel from "../common/action-panel/ActionPanel";
import AddPanel from "../common/action-panel/AddPanel";
import RemovePanel from "../common/action-panel/RemovePanel";
import SavePanel from "../common/action-panel/SavePanel";
import ImportPanel from "../common/action-panel/ImportPanel";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import ExportPanel from "../common/action-panel/ExportPanel";
import RemoveModal from "../common/action-panel/RemoveModal";
import format from "date-fns/format";
import {process} from "@progress/kendo-data-query";
import {getRemoveCell} from "../common/grid/RemoveCell";
import {InputCell} from "../common/grid/InputCell";
import TooltipContainer from "../common/grid/TooltipContainer";

const ParticipantManagementGrid = () => {
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const [dataState, setDataState] = useState<any>({
        ...initialDataState,
        take: globalPageSize ? +globalPageSize : 10,
        pageSize: globalPageSize ? +globalPageSize : 10
    });
    const [data, setData] = useState<Array<ParticipantEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<ParticipantData>>([]);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getData = () => {
        setLoading(true);
        axios.get('/api/auth/wrap/data/participant')
            .then((resp) => {
                setData(deepCopyGridDataWithOriginal(resp.data));
                setOriginalData(deepCopyGridData(resp.data));
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const handleChange = (event: GridItemChangeEvent) => {
        const {dataItem, field, value} = event;
        const {id} = dataItem;
        if (!field || !id) {
            return;
        }
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
    };

    //add a new row
    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: ParticipantEditData = {
            id: sequence++,
            nm: '',
            longNm: '',
            inEdit: true
        };
        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    // save data
    const save = () => {
        const missingRequired = data.filter((item) => !item.nm || !item.longNm);

        if (missingRequired.length > 0) {
            toast.error('Cannot save. Missing required column data.')
            return;
        }
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        console.log("Updated Data:", updatedData);
        axios.post('/api/auth/wrap/data/participant', newAndUpdatedData)
            .then(() => {
                getData();
                toastSuccessfulSave();
                setInEdit(false);
                setEditedRows([]);
            });
    };

    //refresh data
    const refreshData = () => {
        getData();
        setLoading(false);
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
    };

    const openRemoveModal = () => setRemoveModalOpen(true);
    const closeRemoveModal = () => setRemoveModalOpen(false);

    const toggleRemove = () => {
        if (inRemove) {
            cancelRemove();
        } else {
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const confirmRemove = () => {
        axios.delete('/api/auth/wrap/data/participant', {data: editedRemoved})
            .then((resp) => {
                let removed = [...editedRemoved];
                if (resp.data.length > 0) {
                    const notDeleted = data
                        .filter(d => resp.data.includes(d.id))
                        .map(d => d.nm)
                        .sort();
                    toast.error(`The following Participants could not be deleted because they are in use: ${notDeleted.join(', ')}.`);
                    removed = editedRemoved.filter(r => !resp.data.includes(r));
                }
                setRemoveModalOpen(false);
                const localData = data.filter((item) => !removed.includes(item.id));
                setData(localData);
                const localEdit = editedRows.filter(item => !removed.includes(item.id));
                if (localEdit.length !== editedRows.length) {
                    setEditedRows(localEdit);
                }

                if (removed.length > 0) {
                    toast.info(`Successfully deleted Participants${removed.length > 1 ? 's' : ''}.`);
                } else {
                    toast.info('No Participants deleted.')
                }
                resetRemove();

                if (!localEdit.length) {
                    cancelEdits();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    }

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save([...data]);
        }
    };

    const handleUpload = (uploadedData: Array<ParticipantEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            const upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                return {
                    ...u,
                    id: sequence++,
                    inEdit: true
                };
            });
        const dataUploaded: Array<ParticipantEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u);
        });

        setData(dataUploaded);
        setTempSequence(sequence);
    };
    const processUploadEdits = (upload: ParticipantEditData, item: ParticipantEditData) => {
        if (upload.nm !== item.nm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'nm', upload.nm));
        }
        if (upload.longNm !== item.longNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'longNm', upload.longNm));
        }
        return {...item, ...upload};
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };

    const RemoveRowCell = getRemoveCell(handleRemoveChange);

    const title = 'Participant Management';

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && <AddPanel addRecord={addNewRow}/>}
                {inEdit && <RemovePanel inRemove={inRemove} openModal={openRemoveModal} toggleRemove={toggleRemove}/>}
                {!inRemove && <SavePanel inEdit={inEdit} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`/api/auth/wrap/data/participant/import`} setUploadRecords={handleUpload}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && <RefreshPanel disabled={inEdit} loading={loading} getData={refreshData}/>}
                {!inEdit && <ExportPanel exportData={excelExport} disabled={inEdit}/>}
            </ActionPanel>
            <RemoveModal open={removeModalOpen} closeModal={closeRemoveModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <ExcelExport data={data}
                             fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(), 'MM-dd-yyyy')}.xlsx`)}  ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        onItemChange={handleChange}
                        editField='inEdit'
                        {...dataState}
                        onDataStateChange={dataStateChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                        sortable={true}
                        filterable={true}
                        resizable={true}
                    >
                        {inEdit && inRemove &&
                            <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                        <GridColumn title='ID' field='id' editable={false}/>
                        <GridColumn title='Participant Name' field='nm' cell={InputCell}/>
                        <GridColumn title='Long Name' field='longNm' cell={InputCell}/>
                    </Grid>
                </ExcelExport>
            </TooltipContainer>
        </div>
    );
};

export default ParticipantManagementGrid;
