import {useCallback, useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import axios from 'axios';
import {WrapSeasonRollForwardModal} from './WrapSeasonRollForwardModal';
import {CategorySeason} from '../../../interfaces/category-interface';
import WrapSeasonStatusGrid from "./WrapSeasonStatusGrid";
import {RolloverAdminControl} from './RolloverAdminControl';
import WrapBatchReportProcess from "./WrapBatchReportProcess";

const WrapSeasonRolloverPage = () => {
    const [seasons, setSeasons] = useState<Array<CategorySeason>>([]);

    const getSeasons = useCallback(() => {
            axios.get('/api/auth/wrap/seasons')
                .then((resp) => {
                    setSeasons(resp.data);
                });
        }, []);

    useEffect(() => {
        getSeasons();
    }, [getSeasons]);

    return (
        <Container fluid={true} style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '50px'}}>
            <Header size='large'>WRAP FS Seasonal Management</Header>
            <Header size='small'>Active Seasons</Header>
            <WrapSeasonStatusGrid seasons={seasons} getSeasons={getSeasons}/>
            <br/><br/>
            <Header size='medium'>Batch Report</Header>
            <WrapBatchReportProcess />
            <br/><br/>
            <br/><br/>
            <Header size='medium'>Roll Forward Data</Header>
            <WrapSeasonRollForwardModal getSeasons={getSeasons}/>
            <br/><br/>
            <br/><br/>
            <RolloverAdminControl getSeasons={getSeasons}/>
        </Container>
    );
};

export default WrapSeasonRolloverPage;