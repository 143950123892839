import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import ValidationMessageGrid from '../common/grid/ValidationMessageGrid';
import {ApiValidations, GridPageProps} from '../../interfaces/grid-interfaces';
import axios from 'axios';
import {connect} from 'react-redux';
import {isPageStaff} from '../../services/data-grid-service';
import {ValidationMessageAdminControl} from './ValidationMessageAdminControl';

const WrapValidationMessagePage: React.FC<GridPageProps> = ({roles}) => {
    const isStaff = isPageStaff('ValidationMessage', roles);

    const [messages, setMessages] = useState<Array<ApiValidations>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getValidations();
    }, []);

    const getValidations = () => {
        setLoading(true);
        axios.get('/api/auth/wrap/validations')
            .then((resp) => {
                setMessages(resp.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Container fluid={true} style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'50px'}}>
            <Header size={'large'}>WRAP Validation Messages</Header>
            {isStaff && <ValidationMessageAdminControl updateMessageList={setMessages}/>}
            <ValidationMessageGrid data={messages} showDataSet={true} refresh={getValidations} loading={loading} title={'Validation Messages'}/>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(WrapValidationMessagePage);