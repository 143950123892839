import React from 'react';
import {Container, Header, Tab} from 'semantic-ui-react';
import AdminDataDropdownTab from './dropdown/AdminDataDropdownTab';
import {SubregionPrmGrid} from './SubregionPrmGrid';
import {ParticipantSubregionGrid} from './ParticipantSubregionGrid';
import QccStaffGrid from "./QccStaffGrid";
import PeakDemandStaffGrid from "./PeakDemandStaffGrid";
import ParticipantManagementGrid from "./ParticipantManagementGrid";

const LOCAL_STORAGE_ACTIVE_TAB_KEY = 'wrapAdminDataActiveTab';

const AdminDataPage = () => {
    const initialTabStoredValue = localStorage.getItem(LOCAL_STORAGE_ACTIVE_TAB_KEY);
    const initialTab = initialTabStoredValue ? +initialTabStoredValue : 0; // Tab uses 0-based index.
    const [activeTab, setActiveTab] = React.useState<number>(initialTab);
    
    const handleTabChange = (event: React.SyntheticEvent, data: any) => {
        setActiveTab(data.activeIndex);
        localStorage.setItem(LOCAL_STORAGE_ACTIVE_TAB_KEY, data.activeIndex.toString());
    };
    
    const panes = [
        {
            menuItem: 'Dropdown Options',
            render: () => <Tab.Pane><AdminDataDropdownTab/></Tab.Pane>
        },
        {
            menuItem: 'Subregion PRM',
            render: () => <Tab.Pane><SubregionPrmGrid/></Tab.Pane>
        },
        {
            menuItem: 'Participant Subregions',
            render: () => <Tab.Pane><ParticipantSubregionGrid/></Tab.Pane>
        },
        {
            menuItem: 'P50 Peak Load Forecast',
            render: () =>
                <Tab.Pane>
                    <PeakDemandStaffGrid />
                </Tab.Pane>
        },
        {
            menuItem: 'QCC Results',
            render: () => (
                <Tab.Pane>
                    <QccStaffGrid />
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Participant Management',
            render: () => (
                <Tab.Pane>
                    <ParticipantManagementGrid/>
                </Tab.Pane>
            )
        }

    ];
    
    return (
        <Container fluid={true} style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '50px'}}>
            <Header size={'large'}>WRAP Admin Data Management</Header>
            <Tab
                menu={{attached: false}}
                panes={panes}
                activeIndex={activeTab}
                onTabChange={handleTabChange}
            />
        </Container>
    );
};

export default AdminDataPage;