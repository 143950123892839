// import * as React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import history from './history';
import Home from "./components/Home/Home";
import {Layout} from './components/Layout';
import Login from "./components/login/Login";
import Calendar from "./components/Calendar";
import Postings from "./components/Comment/Postings";
import BaseCaseData from "./components/common/BaseCaseData";
import {RegisterNewUser} from "./components/Administration/RegisterNewUser";
import ChangesetHistory from "./components/Administration/ChangesetHistory/ChangesetHistory";
import {RoleManagement} from "./components/Administration/RoleManagement";
import {EntityManagement} from "./components/Administration/EntityManagement/EntityManagement";
import RollOver from "./components/Administration/Rollover/RollOver";
import {UserManagement} from "./components/Administration/UserManagement";
import ReferenceDocuments from "./components/Administration/ReferenceDocuments";
import {AttachmentsHistory} from "./components/Administration/AttachmentsHistory/AttachmentsHistory";
import DeliverabilityStudy from "./components/ResourceAdequacy/DeliverabilityStudy";
import {ResourceAdequacyRequirement} from "./components/ResourceAdequacy/ResourceAdequacyRequirement";
import TenYearForecast from "./components/ResourceAdequacy/TenYearForecast";
import ChangePassword from "./components/ChangePassword";
import ForgotPassword from "./components/login/ForgotPassword";
import withAuthorization from "./components/common/Authorization";
import VerifyCode from "./components/login/VerifyCode";
import ResetPassword from "./components/login/ResetPassword";
import Error from "./components/Error";
import NotificationTracking from "./components/NotificationTracking/NotificationTracking";
import {Contact} from "./components/Administration/Contacts/Contact";
import {AccessDenied} from "./components/common/AccessDenied";
import RaDataManagement from './components/Administration/DataManagement/RaDataManagement';
import PlantsPage from './components/ResourceAdequacy/PlantsPage';
import ResourcesPage from './components/ResourceAdequacy/ResourcesPage';
import TestResultsPage from './components/ResourceAdequacy/TestResultsPage';
import PurchasesSalesPage from './components/ResourceAdequacy/PurchasesSalesPage';
import CapacityAdjustmentsPage from './components/ResourceAdequacy/CapacityAdjustmentsPage';
import DemandAndEnergyPage from './components/ResourceAdequacy/DemandAndEnergyPage';
import ResourceOwnershipPage from "./components/ResourceAdequacy/ResourceOwnershipPage";
import HistoricalPage from "./components/Administration/History/HistoricalPage";
import SeasonRolloverPage from './components/Administration/Rollover/SeasonRolloverPage';
import ValidationMessagePage from './components/ResourceAdequacy/ValidationMessagePage';
import TsrInformationPage from './components/Wrap/TsrInformationPage';
import LoadPage from './components/Wrap/LoadPage';
import DemandResponsePage from "./components/Wrap/DemandResponsePage";
import FacilityPage from './components/Wrap/FacilityPage';
import TestResultPage from './components/Wrap/TestResultPage';
import ResourcePage from './components/Wrap/ResourcePage';
import PlannedOutagePage from "./components/Wrap/PlannedOutagePage";
import AdvanceAssessmentResultsPage from "./components/Wrap/AdvanceAssessmentResultsPage";
import ContractPage from "./components/Wrap/ContractPage";
import WrapDocumentPage from "./components/Wrap/WrapDocumentPage";
import TsrMappingPage from "./components/Wrap/TsrMappingPage";
import AdminDataPage from './components/Wrap/AdminDataPage';
import ForwardShowingSummaryPage from "./components/Wrap/ForwardShowingPage";
import WrapSeasonRolloverPage from "./components/Wrap/Rollover/WrapSeasonRolloverPage";
import WrapHistoricalPage from './components/Wrap/History/WrapHistoricalPage';
import WrapValidationMessagePage from "./components/Wrap/WrapValidationMessagePage";
import ForcedOutagePage from './components/Wrap/ForcedOutagePage';


const Routes = () => {
    return (
        <Router history={history}>
            <Switch>
                <Layout location={window.location}>
                    <Route exact={true} path={'/login'} component={Login} />
                    <Route exact={true} path={'/'} component={withAuthorization(Home,[{tabNm:'any'}]) as any} />
                    <Route exact={true} path={'/ChangePassword'} component={withAuthorization(ChangePassword,[{tabNm:'any'}]) as any} />
                    <Route exact={true} path={'/Postings'} component={withAuthorization(Postings,[{tabNm:'any'}]) as any} />
                    <Route exact={true} path={'/accessDenied'} component={AccessDenied} />
                    <Route exact={true} path={'/Events'} component={withAuthorization(Calendar,[{tabNm:'any'}]) as any} />
                    <Route exact={true} path={'/ForgotPassword'} component={ForgotPassword} />
                    <Route exact={true} path={'/ResetPassword'} component={ResetPassword} />
                    <Route exact={true} path={'/Error'} component={Error} />
                    <Route exact={true} path={'/VerifyCode'} component={VerifyCode} />
                    <Route exact={true} path={'/NotificationTracking'} component={NotificationTracking} />

                    {/* Model Development */}

                    <Route exact={true} path={'/Branches'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Branches/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/BranchOutages/'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/BranchOutages/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/BusDtl'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/BusDtl/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/BusOutages'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/BusOutages/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/FixedShuntOutages'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/FixedShuntOutages/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/GeneratorOutages'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/GeneratorOutages/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Generators'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Generators/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/LoadDtl'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/LoadDtl/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/SwitchedShuntOutages'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/SwitchedShuntOutages/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transactions'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transactions/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transformer2WindingOutages'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transformer2WindingOutages/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transformer3WindingOutages'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transformer3WindingOutages/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transformers2Winding'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transformers2Winding/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transformers3Winding'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/Transformers3Winding/:changesetId'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/TwoTerminalDCTies'} component={withAuthorization(BaseCaseData) as any} />
                    <Route exact={true} path={'/TwoTerminalDCTies/:changesetId'} component={withAuthorization(BaseCaseData) as any} />

                    {/* Resource Adequacy */}
                    <Route exact={true} path={'/CapacityAdjustments'} component={withAuthorization(CapacityAdjustmentsPage, [{tabNm:'CapacityAdjustments'}]) as any} />
                    <Route exact={true} path={'/PurchasesSales'} component={withAuthorization(PurchasesSalesPage, [{tabNm:'PurchasesSales'}]) as any} />
                    <Route exact={true} path={'/ResourceOwnership'} component={withAuthorization(ResourceOwnershipPage, [{tabNm:'ResourceOwnership'}]) as any} />
                    <Route exact={true} path={'/Plants'} component={withAuthorization(PlantsPage,[{tabNm:'Plants'}]) as any} />
                    <Route exact={true} path={'/Resources'} component={withAuthorization(ResourcesPage,[{tabNm:'Resources'}]) as any} />
                    <Route exact={true} path={'/GeneratorTestResults'} component={withAuthorization(TestResultsPage,[{tabNm:'GeneratorTestResults'}]) as any} />
                    <Route exact={true} path={'/DemandAndEnergy'} component={withAuthorization(DemandAndEnergyPage, [{tabNm:'DemandAndEnergy'}]) as any} />
                    <Route exact={true} path={'/DeliverabilityStudyResults'} component={withAuthorization(DeliverabilityStudy) as any} />
                    <Route exact={true} path={'/ResourceAdequacyRequirement'} component={withAuthorization(ResourceAdequacyRequirement) as any} />
                    <Route exact={true} path={'/TenYearForecastOverview'} component={withAuthorization(TenYearForecast) as any}/>

                    {/* Administration */}
                    <Route exact={true} path={'/ChangesetHistory'} component={withAuthorization(ChangesetHistory,[{tabNm:'ChangeSet'}]) as any} />
                    <Route exact={true} path={'/EntityManagement'} component={withAuthorization(EntityManagement,[{tabNm:'ResponsibleEntity'}]) as any} />
                    <Route exact={true} path={'/RollOver'} component={withAuthorization(RollOver,[{tabNm:'ROLL_FORWARD'}]) as any} />
                    <Route exact={true} path={'/Historical'} component={withAuthorization(HistoricalPage,[{tabNm:'Historical'}]) as any} />
                    <Route exact={true} path={'/AttachmentsHistory'} component={withAuthorization(AttachmentsHistory,[{tabNm:'ChangeSetFile'}]) as any} />
                    <Route exact={true} path={'/RaDataManagement'} component={withAuthorization(RaDataManagement,[{tabNm:'RaDataManagement'}]) as any} />
                    <Route exact={true} path={'/RAValidations'} component={withAuthorization(ValidationMessagePage,[{tabNm:'ValidationMessage'}]) as any} />
                    <Route exact={true} path={'/RARollover'} component={withAuthorization(SeasonRolloverPage,[{tabNm:'RaRollover'}]) as any} />

                    {/* Administration and WRAP FS Administration */}
                    <Route exact={true} path={'/Register'} component={withAuthorization(RegisterNewUser,[{tabNm:'Register'}]) as any} />
                    <Route exact={true} path={'/RoleManagement'} component={withAuthorization(RoleManagement,[{tabNm:'Roles'}]) as any} />
                    <Route exact={true} path={'/UserManagement'} component={withAuthorization(UserManagement,[{tabNm:'Users'}]) as any} />
                    <Route exact={true} path={'/ReferenceDocuments'} component={withAuthorization(ReferenceDocuments,[{tabNm:'ReferenceDocument'}]) as any} />
                    <Route exact={true} path={'/Contacts'} component={withAuthorization(Contact,[{tabNm:'Contacts'}]) as any} />

                    {/* WRAP Forward Showing */}
                    <Route exact={true} path={'/AdditionalPlannedOutages'} component={withAuthorization(PlannedOutagePage, [{tabNm:'PlannedOutage'}]) as any} />
                    <Route exact={true} path={'/AdvanceAssessmentResults'} component={withAuthorization(AdvanceAssessmentResultsPage, [{tabNm:'AssessmentResults'}]) as any} />
                    <Route exact={true} path={'/ContractsAndObligations'} component={withAuthorization(ContractPage, [{tabNm:'Contract'}]) as any} />
                    <Route exact={true} path={'/DemandResponsePrograms'} component={withAuthorization(DemandResponsePage,[{tabNm:'DemandResponse'}]) as any} />
                    <Route exact={true} path={'/FacilityManagement'} component={withAuthorization(FacilityPage,[{tabNm:'Facility'}]) as any} />
                    <Route exact={true} path={'/FormsAndAttestationDocuments'} component={withAuthorization(WrapDocumentPage,[{tabNm:'WrapDocument'}]) as any} />
                    <Route exact={true} path={'/ForwardShowingSummary'} component={withAuthorization(ForwardShowingSummaryPage,[{tabNm:'ForwardShowingSummary'}]) as any} />
                    <Route exact={true} path={'/WrapGeneratorTestResult'} component={withAuthorization(TestResultPage,[{tabNm:'WrapGeneratorTestResult'}]) as any} />
                    <Route exact={true} path={'/Load'} component={withAuthorization(LoadPage,[{tabNm:'Load'}]) as any} />
                    <Route exact={true} path={'/Resource'} component={withAuthorization(ResourcePage,[{tabNm:'Resource'}]) as any} />
                    <Route exact={true} path={'/TSRInformation'} component={withAuthorization(TsrInformationPage,[{tabNm:'TsrInformation'}]) as any} />
                    <Route exact={true} path={'/TSRMapping'} component={withAuthorization(TsrMappingPage, [{tabNm:'TsrMapping'}]) as any} />
                    <Route exact={true} path={'/StorageHydroForcedOutages'} component={withAuthorization(ForcedOutagePage, [{tabNm:'ForcedOutage'}]) as any} />

                    {/* WRAP FS Administration */}
                    <Route exact={true} path={'/WRAPHistorical'} component={withAuthorization(WrapHistoricalPage,[{tabNm:'WrapHistorical'}]) as any} />
                    <Route exact={true} path={'/WRAPAdminDataManagement'} component={withAuthorization(AdminDataPage,[{tabNm:'WrapDataManagement'}]) as any} />
                    <Route exact={true} path={'/WRAPValidations'} component={withAuthorization(WrapValidationMessagePage,[{tabNm:'WrapValidation'}]) as any} />
                    <Route exact={true} path={'/WRAPSeasonManagement'} component={withAuthorization(WrapSeasonRolloverPage,[{tabNm:'WrapRollover'}]) as any} />
                </Layout>
            </Switch>
        </Router>
    )
};

export default Routes;