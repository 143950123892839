import axios from "axios";
import {toast} from "react-toastify";

export default class ResadqService {

    getSubmittingEntities = (resource:string) => {
        let url = `/api/auth/reports/submittingentities/${resource}`;
        return new Promise<any[]>((resolve, reject) => {
            axios.get(url).then( ({status,statusText,data}) => {
                if (status === 200) {
                    resolve(data)
                } else {
                    toast.error('Error retrieving Submitting Entities');
                }
            })
        })
    };

    getSubmittalYears = () => {
        return new Promise<any[]>((resolve, reject) => {
            axios.get(`/api/auth/reports/subYears`).then(({status,statusText,data}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error('Error retrieving Submittal Year');
                }
            })
        })
    };

    getReportData = (groupName:string,subGroupName:string, submitEntityName:string, subYearId:number, season:string) => {
        return new Promise<any>((resolve, reject) => {
            let reportDataParams: any = {
                groupName: groupName,
                subGroupName: subGroupName,
                submitEntityName: submitEntityName,
                subYearId: subYearId,
                season: season
            }
            axios.get(`/api/auth/reports/reportData`, {params: reportDataParams}).then(({status, statusText, data}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error(`Error retrieving submittal year for ${subGroupName}- ${statusText}`);
                }
            });
        });
    };
}