import {RowEditGrid} from '../../common/row-edit-grid/RowEditGrid';
import {GridColumn} from '@progress/kendo-react-grid';
import {COMMAND_CELL_WIDTH, CommandCell} from '../../common/row-edit-grid/CommandCell';
import {RequiredStringFormCell} from '../../common/row-edit-grid/RequiredStringFormCell';

export const BalancingAuthorityFormGrid = () => (
    <div style={{maxWidth: '712px'}}>
        <RowEditGrid
            dataApi={'/api/auth/wrap/data/balancing-authority'}
            uniqueFields={[{field: 'nm', title: 'Name'}, {field: 'longNm', title: 'Long Name'}]}
        >
            <GridColumn title='Command' cell={CommandCell} width={COMMAND_CELL_WIDTH}/>
            <GridColumn title='ID' field='id' editable={false} width={70}/>
            <GridColumn title='Name' field='nm' cell={RequiredStringFormCell} width={70}/>
            <GridColumn title='Long Name' field='longNm' cell={RequiredStringFormCell} width={400}/>
        </RowEditGrid>
    </div>
);