import React, {Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import {GridColumn} from '@progress/kendo-react-grid';
import {ApiValidations, ColumnMeta, DataUpdate, PanelGridProps, UiValidations} from '../../interfaces/grid-interfaces';
import {FacilityTestDetail, TestResultCapData} from '../../interfaces/wrap/test-result-interface';
import {
    addDataError, DATE_ONLY_FORMAT,
    deepCopyGridDataWithOriginal,
    parseLocalDateString, processColumnMeta,
    processDropDownListOptions, removeDataErrorByValue, resetOption
} from '../../services/data-grid-service';
import {
    getFieldColumnMeta,
    numberBoundValidationOnEdit,
    updateEditedRows
} from '../../services/generic-data-grid-service';
import {DropDownOption, getDropDownCell} from '../common/grid/DropDownCell';
import {GridEditData, GenericEditGrid} from '../common/grid/generic/GenericEditGrid';
import {InputCell} from '../common/grid/InputCell';
import {getDecimalCell} from '../common/grid/DecimalCell';
import {DateNoTimeCell} from '../common/grid/DateNoTimeCell';
import {getDocumentMapCell} from '../common/grid/DocumentMapCell';
import {getDemandResponseDecimalCell} from '../common/grid/DemandResponseDecimalCell';


type TestResultCapEditData = GridEditData<TestResultCapData>;

const maxValue = 99999;

const TestResultCapGrid: FC<PanelGridProps> = ({title, dataApi, windowOpen, season,
                                                subyear, isStaff, readRole, addRole,
                                                modifyRole, removeRole}) => {
    const KEY_FIELD = 'id';

    const [columnMeta, setColumnMeta] = useState<ColumnMeta[]>([]);
    const [data, setData] = useState<TestResultCapEditData[]>([]);
    const [participantNmOptions, setParticipantNmOptions] = useState<DropDownOption[]>([]);
    const [facilityNmOptions, setFacilityNmOptions] = useState<Array<DropDownOption>>([]);
    const [facilityOptions, setFacilityOptions] = useState<FacilityTestDetail[]>([]);

    useEffect(() => {
        const api = dataApi.replace(`/${season.toLowerCase()}`, '');
        axios.get(`${api}/columns`)
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi, season]);

    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/user-participants')
            .then((resp) => {
                setParticipantNmOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get(`/api/auth/wrap/test-result/dropdown/facility/${season}`)
            .then((resp) => {
                const facilityNmList = resp.data.map((item: any) => item.facilityNm);
                setFacilityNmOptions(processDropDownListOptions(facilityNmList));
                setFacilityOptions(resp.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [season]);

    const getData = useCallback(() => {
        return new Promise<boolean>((resolve) => {
            axios.get(dataApi)
                .then(resp => {
                    const localData = resp.data.map((d: any) => ({
                        ...d,
                        testDate: parseLocalDateString(d.testDate)
                    }));
                    setData(deepCopyGridDataWithOriginal(localData));
                })
                .finally(() => {
                    // let caller know when to turn off loading indicator
                    resolve(true);
                });
        });
    }, [dataApi]);

    const verifyData = (verifyList: TestResultCapEditData[]) => {
        return new Promise<boolean>((resolve) => {
            axios.post(`${dataApi}/verify`, verifyList)
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const saveData = (saveList: TestResultCapEditData[]) => {
        return new Promise<boolean>((resolve) => {
            axios.post(dataApi, saveList)
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const deleteData = (removeList: number[]) => {
        return new Promise<boolean>((resolve) => {
            axios.delete(`${dataApi}/delete`, {data: removeList})
                .finally(() => {
                    resolve(true);
                })
        });
    };

    const validateData = () => {
        return new Promise<ApiValidations[]>((resolve) => {
            axios.get(`${dataApi}/validate`)
                .then(response => {
                    resolve(response.data);
                });
        });
    };

    const generateDefaultRow = (sequence: number) => ({
        id: sequence,
        subYearId: subyear,
        seasonNm: season,
        verified: false,
        participantNm: '',
        facilityNm: '',
        resourceTypeNm: '',
        energySourceNm: '',
        testNet: 0,
        testDate: null,
        testDuration: 0,
        adjustedTestNet: 0,
        dryBulbTemp: 0,
        ambientTemp: 0,
        weatherStation: '',
        testBy: '',
        comments: '',
        lastUserModBy: '',
        lastUserModDt: null,
        verifiedBy: '',
        verifiedDt: null,
        inEdit: true
    });

    const addRow = (sequence: number) => {
        const defaultNewRow: TestResultCapEditData = generateDefaultRow(sequence);

        defaultNewRow.originalData = {...defaultNewRow};

        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);

        return sequence + 1;
    };

    const setAdjustedTestNet = (season: string, resourceType?: string, testNet?: number, dryBulbTemp?: number, ambientTemp?: number) => {
        if (season !== 'Summer'  // only adjust for Summer Capability Test Results
            || resourceType !== 'Thermal'  // only adjust for 'Thermal' Resource Type Facilities
            // Make sure every part of calculation has value
            || !testNet || (!dryBulbTemp && dryBulbTemp !== 0) || (!ambientTemp && ambientTemp !== 0)
            || (dryBulbTemp >= (ambientTemp - 10))) {
            // Not enough data to calculate or Doesn't need adjustment
            return testNet || 0;
        }
        // Return adjusted testNet value
        // Flat 5% penalty, additional 0.5% penalty per degree, up to 20 degrees, below ASHRAE Rated Ambient Temp
        return ((testNet * (1 - 0.05)) - (testNet * 0.005 * Math.min(20, (ambientTemp - dryBulbTemp))));
    };

    const handleSideEffects = (item: TestResultCapEditData, id: number, field: string, value: any,
                               setDataErrors: React.Dispatch<React.SetStateAction<UiValidations[]>>) => {
        const requiredFields = columnMeta.filter(c => c.requiredInd).map(c => c.nm);
        if (value && requiredFields.includes(field)) {
            setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
        }
        if (value && field === 'facilityNm') {
            const facility = facilityOptions.find(f => f.facilityNm === value);
            if (facility) {
                item.resourceTypeNm = facility.resourceTypeNm;
                item.energySourceNm = facility.energySourceNm;
            }
        }
        let column = getFieldColumnMeta('testNet', columnMeta);
        if (field === column.nm) {
            numberBoundValidationOnEdit(item, field, column.vnm, KEY_FIELD, setDataErrors, maxValue, 0);
            // Winter always uses testNet for adjustedTestNet. Summer may need to run calculation.
            item.adjustedTestNet = setAdjustedTestNet(season, item.resourceTypeNm, value, item.dryBulbTemp, item.ambientTemp);
        }
        if (season === 'Summer' && ['resourceTypeNm', 'testNet', 'dryBulbTemp', 'ambientTemp'].includes(field)) {
            const resourceTypeNm = field === 'resourceTypeNm' ? value : item.resourceTypeNm;
            const dryBulbTemp = field === 'dryBulbTemp' ? value : item.dryBulbTemp;
            const ambientTemp = field === 'ambientTemp' ? value : item.ambientTemp;

            item.adjustedTestNet = setAdjustedTestNet(season, resourceTypeNm, item.testNet, dryBulbTemp, ambientTemp);
        }
    };

    const filterEmptyRequiredColumns = (setDataErrors: Dispatch<SetStateAction<UiValidations[]>>): boolean => {
        const empty = ' must have a value to save the record.';
        const requiredColumns = columnMeta.filter(c => c.requiredInd);
        const isEmpty = (value: any) => value === null || value === undefined || value === ''; // Zero doesn't count as empty though it is falsy.

        const missingRequired = data.filter((item) => requiredColumns.some(field => isEmpty(item[field.nm])));

        if (missingRequired.length === 0) {
            return false;
        }
        toast.error('There are columns missing required data. Please provide value to save changes.');

        missingRequired.forEach((item) => {
            requiredColumns.forEach(field => {
                if (!item[field.nm]) {
                    setDataErrors(prevState => addDataError(item.id, field.nm, field.vnm, '', field.vnm + empty, true, prevState));
                }
            });
        });
        return true;
    };

    const importData = (uploadData: TestResultCapEditData[], sequence: number,
                        setEditedRows: Dispatch<SetStateAction<DataUpdate[]>>,
                        setDataErrors: Dispatch<SetStateAction<UiValidations[]>>): number => {
        const localData = data.map((item) => {
            let upload = uploadData.find((u) => u[KEY_FIELD] === item[KEY_FIELD]);
            if (upload) {
                return processUploadEdits(upload, item, setEditedRows, setDataErrors);
            }
            return item;
        });

        // Reset non-editable fields to default or empty value.
        // Reset option fields to valid option or empty value.
        // Direct input fields can take update value without modification.
        const newUploadData = uploadData
            .filter((u) => !u[KEY_FIELD] || u[KEY_FIELD] <= 0)
            .map((u) => {
                const facility = facilityOptions.find(f => f.facilityNm === u.facilityNm);
                const resourceTypeNm = !facility ? '' : facility.resourceTypeNm;
                const energySourceNm = !facility ? '' : facility.energySourceNm;

                const item = {
                    ...u,
                    id: sequence,
                    subYearId: subyear,
                    seasonNm: season,
                    verified: false,
                    participantNm: resetOption(u.participantNm, participantNmOptions, ''),
                    facilityNm: resetOption(u.facilityNm, facilityNmOptions, ''),
                    resourceTypeNm,
                    energySourceNm,
                    testDate: parseLocalDateString(u.testDate),
                    adjustedTestNet: setAdjustedTestNet(season, u.resourceTypeNm, u.testNet, u.dryBulbTemp, u.ambientTemp),
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true,
                    originalData: generateDefaultRow(sequence)
                };
                sequence = sequence + 1;
                return item;
            });

        const dataUploaded: Array<TestResultCapEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u as TestResultCapEditData);
        });

        setData(dataUploaded);

        return sequence;
    };

    const processUploadEdits = (upload: TestResultCapEditData, item: TestResultCapEditData,
                                setEditedRows: Dispatch<SetStateAction<DataUpdate[]>>,
                                setDataErrors: Dispatch<SetStateAction<UiValidations[]>>) => {
        // Every editable field needs to update the editedRows array and replace the existing item field value.
        // Dropdown options should be reset before comparison to item field value.
        // Lower case both sides of comparison for dropdown option fields. Use `?.toLowerCase()` in case the value is falsy.
        // Apply same error checking for a field that would get error checking in handleChange.
        const participantNm = resetOption(upload.participantNm, participantNmOptions, item.participantNm);
        if (participantNm?.toLowerCase() !== item.participantNm?.toLowerCase()) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'participantNm'));
        }
        const facilityNm = resetOption(upload.facilityNm, facilityNmOptions, item.facilityNm);
        let resourceTypeNm = item.resourceTypeNm;
        let energySourceNm = item.energySourceNm;
        if (facilityNm?.toLowerCase() !== item.facilityNm?.toLowerCase()) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'facilityNm'));
            const facility = facilityOptions.find(f => f.facilityNm === upload.facilityNm);
            resourceTypeNm = !facility ? '' : facility.resourceTypeNm;
            energySourceNm = !facility ? '' : facility.energySourceNm;
        }
        if (upload.testNet !== item.testNet) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'testNet'));
            const column = getFieldColumnMeta('testNet', columnMeta);
            numberBoundValidationOnEdit(upload, column.nm, column.vnm, KEY_FIELD, setDataErrors, maxValue, 0);
        }
        if (upload.testDate !== item.testDate) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'testDate'));
        }
        if (upload.testDuration !== item.testDuration) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'testDuration'));
            const column = getFieldColumnMeta('testDuration', columnMeta);
            numberBoundValidationOnEdit(upload, column.nm, column.vnm, KEY_FIELD, setDataErrors, maxValue, 0);
        }
        if (upload.dryBulbTemp !== item.dryBulbTemp) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'dryBulbTemp'));
            const column = getFieldColumnMeta('dryBulbTemp', columnMeta);
            numberBoundValidationOnEdit(upload, column.nm, column.vnm, KEY_FIELD, setDataErrors, maxValue, 0);
        }
        if (upload.ambientTemp !== item.ambientTemp) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'ambientTemp'));
            const column = getFieldColumnMeta('ambientTemp', columnMeta);
            numberBoundValidationOnEdit(upload, column.nm, column.vnm, KEY_FIELD, setDataErrors, maxValue, 0);
        }
        if (upload.weatherStation !== item.weatherStation) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'weatherStation'));
        }
        if (upload.testBy !== item.testBy) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'testBy'));
        }
        if (upload.comments !== item.comments) {
            setEditedRows(prevState => updateEditedRows(prevState, upload, KEY_FIELD, 'comments'));
        }

        return {
            ...item,
            participantNm: participantNm,
            facilityNm: facilityNm,
            resourceTypeNm,
            energySourceNm,
            testNet: upload.testNet,
            testDate: parseLocalDateString(upload.testDate),
            testDuration: upload.testDuration,
            adjustedTestNet: setAdjustedTestNet(season, resourceTypeNm, upload.testNet, upload.dryBulbTemp, upload.ambientTemp),
            dryBulbTemp: upload.dryBulbTemp,
            ambientTemp: upload.ambientTemp,
            weatherStation: upload.weatherStation,
            testBy: upload.testBy,
            comments: upload.comments
        };
    };

    const participantsDropDownCell = getDropDownCell(participantNmOptions, 'Participant');
    const facilityDropDownCell = getDropDownCell(facilityNmOptions, 'Facility');
    const decimalCell = useMemo(() => getDecimalCell(1, true, maxValue, 0), []);
    const documentMapCell = getDocumentMapCell(subyear, season, 'TestResultCap');
    const DemandResponseCell = useMemo(() => getDemandResponseDecimalCell(1, true, 'resourceTypeNm', maxValue, 0), []);

    return (
        <GenericEditGrid<TestResultCapData>
            title={title}
            windowOpen={windowOpen}
            data={data}
            setData={setData}
            keyField={KEY_FIELD}
            getData={getData}
            verifyData={verifyData}
            saveData={saveData}
            deleteData={deleteData}
            importData={importData}
            validateData={validateData}
            addRow={addRow}
            handleChangeSideEffects={handleSideEffects}
            importApi={`${dataApi}/upload`}
            filterEmptyRequiredColumns={filterEmptyRequiredColumns}
            isStaff={isStaff}
            readRole={readRole}
            addRole={addRole}
            modifyRole={modifyRole}
            removeRole={removeRole}
        >
            {
                columnMeta
                    .filter((col: ColumnMeta) => col.nm !== 'verified')
                    .map((col: ColumnMeta, index) => {
                        if (col.nm === 'id') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='120px' editable={false}/>
                        } else if (col.nm === 'participantNm') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={participantsDropDownCell}/>
                        } else if (col.nm === 'facilityNm') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='325px' cell={facilityDropDownCell}/>
                        } else if (col.nm === 'resourceTypeNm') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' editable={false}/>
                        } else if (col.nm === 'energySourceNm') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' editable={false}/>
                        } else if (col.nm === 'testNet') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={decimalCell}/>
                        } else if (col.nm === 'adjustedTestNet' && season === 'Summer') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                        } else if (col.nm === 'testDate') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' format={DATE_ONLY_FORMAT} filter='date' cell={DateNoTimeCell}/>
                        } else if (col.nm === 'testDuration') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={decimalCell}/>
                        } else if (col.nm === 'dryBulbTemp') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={DemandResponseCell}/>
                        } else if (col.nm === 'ambientTemp') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={decimalCell}/>
                        } else if (col.nm === 'weatherStation') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={InputCell}/>
                        } else if (col.nm === 'testBy') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={InputCell}/>
                        } else if (col.nm === 'comments') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='300px' cell={InputCell}/>
                        } else if (col.nm === 'lastUserModBy') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                        } else if (col.nm === 'lastUserModDt') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                        } else if (col.nm === 'verifiedBy') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                        } else if (col.nm === 'verifiedDt') {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                        } else {
                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                        }
                    })
            }
            <GridColumn title='Linked Documents' width='200px' filterable={false} cell={documentMapCell}/>
        </GenericEditGrid>
    );
};

export default TestResultCapGrid;