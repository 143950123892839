import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Grid,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent,
    GridPageChangeEvent
} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {DataUpdate} from '../../interfaces/grid-interfaces';
import axios from 'axios';
import {
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState, replaceSpaceWithUnderscore,
    toastSuccessfulSave,
    updateEditedRows
} from '../../services/data-grid-service';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import RefreshPanel from '../common/action-panel/RefreshPanel';
import AddPanel from '../common/action-panel/AddPanel';
import RemovePanel from '../common/action-panel/RemovePanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import {getTextDropDownCell} from '../common/grid/TextDropDownCell';
import TooltipContainer from '../common/grid/TooltipContainer';
import {getRemoveCell} from '../common/grid/RemoveCell';
import {
    PeakDemandData, PeakDemandEditData
} from '../../interfaces/wrap/subregion-interface';
import {getDecimalCell} from "../common/grid/DecimalCell";
import ClearFilterPanel from '../common/action-panel/ClearFilterPanel';
import ExportPanel from "../common/action-panel/ExportPanel";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import format from "date-fns/format";
import ImportPanel from '../common/action-panel/ImportPanel';
import {toast} from 'react-toastify';

interface PeakDemandGridProps {
    readOnly: boolean;
    subyear: number;
    season: string;
    seasonOpen:boolean;
}

export const PeakDemandGrid: React.FC<PeakDemandGridProps> = ({readOnly, subyear, season, seasonOpen}) => {
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const initialPageSize = globalPageSize ? +globalPageSize : 10;

    const dataApi = '/api/auth/wrap/data/peak-demand';

    const [data, setData] = useState<PeakDemandEditData[]>([]);
    const [originalData, setOriginalData] = useState<PeakDemandData[]>([]);
    const [dataState, setDataState] = useState<any>({...initialDataState, take: initialPageSize});
    const [loading, setLoading] = useState<boolean>(false);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [participantList, setParticipantList] = useState<Array<string>>([]);

    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/all-participants')
            .then(resp => {
                setParticipantList(resp.data);
            })
    }, []);

    const getSelectedData = useCallback((subYearId: number, season: string) => {
        const params = new URLSearchParams();
        params.set('subYearId', subYearId.toString());
        params.set('season', season);
        setLoading(true);
        axios.get(dataApi, {params})
            .then(resp => {
                setData(deepCopyGridDataWithOriginal(resp.data));
                setOriginalData(deepCopyGridData(resp.data));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const getData = useCallback(() => {
        if (!subyear || !season) {
            return;
        }
        getSelectedData(subyear, season);
    }, [getSelectedData, season, subyear]);

    useEffect(() => {
        setInEdit(false);
        setEditedRows([]);
        setEditedRemoved([]);
        setInRemove(false);
        getData();
    }, [getData]);

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const handleChange = (event: GridItemChangeEvent) => {
        const {dataItem, field, value} = event;
        const {id} = dataItem;
        if (!field || !id) {
            return;
        }
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    const generateDefaultRow = (sequence: number) => ({
        id: sequence,
        subYearId: subyear,
        seasonNm: season,
        participantNm: '',
        month1: 0.0,
        month2: 0.0,
        month3: 0.0,
        month4: 0.0,
        month5: 0.0,
        inEdit: true
    });

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: PeakDemandEditData = generateDefaultRow(sequence++);

        defaultNewRow.originalData = {...defaultNewRow};

        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const save = () => {
        // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        axios.post(dataApi, newAndUpdatedData)
            .then(() => {
                getData();
                toastSuccessfulSave();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch(() => {
                toast.error('Error occurred. Save failed.');
            });
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    };

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
    };

    const handleUpload = (uploadedData: Array<PeakDemandEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            let upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                const participantNm = participantList.includes(u.participantNm) ? u.participantNm : '';

                const item = {
                    ...u,
                    id: sequence,
                    subYearId: subyear,
                    seasonNm: season,
                    participantNm,
                    inEdit: true,
                    originalData: generateDefaultRow(sequence)
                };
                sequence = sequence + 1;
                return item;
            });

        const dataUploaded: Array<PeakDemandEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u as PeakDemandEditData);
        });

        setData(dataUploaded);
        setTempSequence(sequence);
    };

    const processUploadEdits = (upload: PeakDemandEditData, item: PeakDemandEditData) => {
        const participantNm = participantList.includes(upload.participantNm) ? upload.participantNm : item.participantNm;
        if (participantNm?.toLowerCase() !== item.participantNm.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'participantNm', participantNm));
        }
        if (upload.month1 !== item.month1) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month1', upload.month1));
        }
        if (upload.month2 !== item.month2) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month2', upload.month2));
        }
        if (upload.month3 !== item.month3) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month3', upload.month3));
        }
        if (upload.month4 !== item.month4) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month4', upload.month4));
        }
        if (season === 'Winter' && upload.month5 !== item.month5) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'month5', upload.month5));
        }

        const processedItem = {
            ...item,
            participantNm,
            month1: upload.month1,
            month2: upload.month2,
            month3: upload.month3,
            month4: upload.month4
        };

        if (season === 'Winter') {
            processedItem.month5 = upload.month5;
        }

        return processedItem;
    };

    const openRemoveModal = () => setRemoveModalOpen(true);
    const closeRemoveModal = () => setRemoveModalOpen(false);

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            // initialize 'removed' field to false on all data objects
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        // take 'removed' field off data objects
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const confirmRemove = () => {
        axios.delete(dataApi, {data: editedRemoved})
            .then(() => {
                setRemoveModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));
                if (localEdit.length !== editedRows.length) {
                    setEditedRows(localEdit);
                }

                toast.info("successfully deleted item");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    resetRemove();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const formatExport = (data: Array<PeakDemandData>) => {
        return data.map(item => ({
            ...item,
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const ParticipantDropDownCell = useMemo(() => getTextDropDownCell(participantList, true), [participantList]);
    const DecimalCell = useMemo(() => getDecimalCell(1, true, 1000000, 0), []);

    return (
        <div>
            <ActionPanel title={'P50 Peak Load Forecast'}>
                {!inRemove && inEdit && <AddPanel addRecord={addNewRow}/>}
                {inEdit && <RemovePanel inRemove={inRemove} openModal={openRemoveModal} toggleRemove={toggleRemove}/>}
                {!readOnly && !inRemove && seasonOpen && <SavePanel inEdit={inEdit} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/import/${season}`} setUploadRecords={handleUpload} />}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && <RefreshPanel disabled={inEdit} loading={loading} getData={getData}/>}
                {!inEdit && <ExportPanel exportData={excelExport} disabled={inEdit}/>}
            </ActionPanel>
            <RemoveModal open={removeModalOpen} closeModal={closeRemoveModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${'P50 Peak Load Forecast'}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        onItemChange={handleChange}
                        editField='inEdit'
                        {...dataState}
                        onDataStateChange={dataStateChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                        sortable={true}
                        filterable={true}
                        resizable={true}
                    >
                        {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                        <GridColumn title='Participant' field='participantNm' cell={ParticipantDropDownCell}/>
                        <GridColumn title={season === 'Summer' ? `June ${subyear}` : `November ${subyear}`} field='month1' cell={DecimalCell}/>
                        <GridColumn title={season === 'Summer' ? `July ${subyear}` : `December ${subyear}`} field='month2' cell={DecimalCell}/>
                        <GridColumn title={season === 'Summer' ? `August ${subyear}` : `January ${subyear + 1}`} field='month3' cell={DecimalCell}/>
                        <GridColumn title={season === 'Summer' ? `September ${subyear}` : `February ${subyear + 1}`} field='month4' cell={DecimalCell}/>
                        {season === 'Winter' && <GridColumn title={`March ${subyear + 1}`} field='month5' cell={DecimalCell}/>}
                    </Grid>
                </ExcelExport>
            </TooltipContainer>
        </div>
    );
};




