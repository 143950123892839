import { useEffect, useState } from 'react';
import { CategorySeasonData } from '../interfaces/category-interface';
import axios from 'axios';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import {toast} from "react-toastify";

export const useCategorySeason = () => {
    const [categorySeasonList, setCategorySeasonList] = useState<Array<CategorySeasonData>>([]);
    const [subyear, setSubyear] = useState<number>(0);
    const [subyearList, setSubyearList] = useState<Array<number>>([]);
    const [season, setSeason] = useState<string>('');
    const [seasonList, setSeasonList] = useState<Array<string>>([]);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);
    const [seasonActive, setSeasonActive] = useState<boolean>(true);

    const getEmptyDefaultSeason = () => ({ subyear: 0, season: '', category: '', status: '', windowOpen: false });
    const getActiveStatus = (status: string) => status.toLowerCase() === 'current' || status.toLowerCase() === 'next';

    useEffect(() => {
        axios.get('/api/auth/wrap/category-seasons')
            .then(resp => {
                const seasonList = resp.data
                    .filter((cs: CategorySeasonData) => cs.status.toLowerCase() !== 'rolling')
                    .map((cs: CategorySeasonData) => {
                        if (cs.status.toLowerCase() === 'current') {
                            setSubyear(cs.subyear);
                            setSeason(cs.season);
                            setSeasonOpen(cs.windowOpen);
                            setSeasonActive(getActiveStatus(cs.status));
                        }
                        return {
                            subyear: cs.subyear,
                            season: cs.season,
                            category: cs.category,
                            status: cs.status,
                            windowOpen: cs.windowOpen
                        };
                    });
                setCategorySeasonList(seasonList);
                setSubyearList(Array.from(new Set(seasonList.map((cs: CategorySeasonData) => cs.subyear))));
                const currentSubyear = seasonList.find((cs: CategorySeasonData) => cs.status.toLowerCase() === 'current')?.subyear;
                setSeasonList(seasonList
                    .filter((cs: CategorySeasonData) => cs.subyear === currentSubyear)
                    .map((cs: CategorySeasonData) => cs.season)
                    .sort());
            })
            .catch(() => {
                toast.error('Error loading season information.')
            });
    }, []);

    const handleSubyear = (event: DropDownListChangeEvent) => {
        const subYearId = event.target.value;
        const updatedSeasonList = categorySeasonList.filter(cs => cs.subyear === subYearId).map(cs => cs.season).sort();
        const updatedSeason = updatedSeasonList.some(s => s === season) ? season : updatedSeasonList[0];
        setSubyear(subYearId);
        setSeasonList(updatedSeasonList);
        setSeason(updatedSeason);
        const categorySeason = categorySeasonList.find(cs => cs.subyear === subYearId && cs.season === updatedSeason);
        setSeasonOpen(categorySeason?.windowOpen || false);
        setSeasonActive(getActiveStatus(categorySeason?.status || ''));
    };

    const handleSeason = (event: DropDownListChangeEvent) => {
        const seasonNm = event.target.value;
        setSeason(seasonNm);
        const categorySeason = categorySeasonList.find(cs => cs.subyear === subyear && cs.season === seasonNm);
        setSeasonOpen(categorySeason?.windowOpen || false);
        setSeasonActive(getActiveStatus(categorySeason?.status || ''));
    }

    const getActiveCategorySeason = (season: 'Summer' | 'Winter') => {
        return categorySeasonList.find(cs =>
            cs.season.toLowerCase() === season.toLowerCase() && ['current', 'next'].includes(cs.status.toLowerCase()));
    };

    return { subyear, subyearList, season, seasonList, seasonOpen, seasonActive, handleSubyear, handleSeason, getActiveCategorySeason, getEmptyDefaultSeason };
};
