import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';

interface SubMenuProps {
    menuName: string;
    menuDisplayName: string;
    menuLink: string;
    updateActiveMenuItem: (e: any, {name}: any) => void;
}

export const SubMenu: React.FC<SubMenuProps> = ({menuName, menuDisplayName, menuLink, updateActiveMenuItem}) => {
    return (
        <Dropdown.Item
            as={NavLink}
            exact={true}
            to={menuLink}
            name={menuName}
            onClick={updateActiveMenuItem}
        >
            {menuDisplayName}
        </Dropdown.Item>
    );
};