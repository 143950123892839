import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import {AccordionWrapper} from '../common/grid/AccordionWrapper';
import TsrInformationGrid from "./TsrInformationGrid";
import {useCategorySeason} from '../../hooks/useCategorySeason';
import {CategorySeasonData} from '../../interfaces/category-interface';

const TsrInformationPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);
    const {getActiveCategorySeason, getEmptyDefaultSeason} = useCategorySeason();
    const [summerSeason, setSummerSeason] = useState<CategorySeasonData>(getEmptyDefaultSeason());
    const [winterSeason, setWinterSeason] = useState<CategorySeasonData>(getEmptyDefaultSeason());

    useEffect(() => {
        setPageRole(getCompressedPageRole('TsrInformation', roles));
    }, [roles]);

    useEffect(() => {
        const summer = getActiveCategorySeason('Summer');
        if (summer) {
            setSummerSeason(summer);
        }
        const winter = getActiveCategorySeason('Winter');
        if (winter) {
            setWinterSeason(winter);
        }
    }, [getActiveCategorySeason]);

    return (
        <Container fluid style={{paddingLeft: 20, paddingRight: 20, paddingTop: 50}}>
            <Header size="large">TSR (OASIS) Information</Header>
            <AccordionWrapper accordionHeader={'TSR (OASIS) Information Summer'}>
                <TsrInformationGrid
                    {...pageRole}
                    title={'TSR (OASIS) Information Summer'}
                    dataApi={'/api/auth/wrap/tsr-information/summer'}
                    season={'Summer'}
                    subyear={summerSeason.subyear}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'TSR (OASIS) Information Winter'}>
                <TsrInformationGrid
                    {...pageRole}
                    title={'TSR (OASIS) Information Winter'}
                    dataApi={'/api/auth/wrap/tsr-information/winter'}
                    season={'Winter'}
                    subyear={winterSeason.subyear}
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});


export default connect(mapStateToProps)(TsrInformationPage);
