import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from '../../interfaces/grid-interfaces';
import {ResourceOwnershipData, ResourceOwnershipEditData} from "../../interfaces/resource-ownership-interface";
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState, numberBoundValidationOnEdit, numberBoundValidationOnUpload,
    processColumnMeta,
    processDropDownListOptions, replaceSpaceWithUnderscore,
    resetChildOption,
    resetOption, toastPreSaveValidationErrors, toastSuccessfulSave,
    updateEditedRows,
    updateEditedVerified
} from '../../services/data-grid-service';
import {DropDownOption, getDropDownCell} from '../common/grid/DropDownCell';
import {getPercentCell} from '../common/grid/PercentCell';
import {ChildDropDownOption, getChildDropDownCell} from '../common/grid/ChildDropDownCell';
import {CustomLocalizationProvider, getVerifiedCell} from '../common/grid/VerifiedCell';
import {InputCell} from '../common/grid/InputCell';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import ExportPanel from "../common/action-panel/ExportPanel";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import TooltipContainer from '../common/grid/TooltipContainer';
import AddPanel from "../common/action-panel/AddPanel";
import {toast} from "react-toastify";
import {getDecimalCell} from "../common/grid/DecimalCell";
import {getChildApiDropDownCell} from "../common/grid/ChildApiDropDownCell";
import ImportPanel from "../common/action-panel/ImportPanel";
import RemovePanel from '../common/action-panel/RemovePanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import {getRemoveCell} from '../common/grid/RemoveCell';
import RefreshPanel from "../common/action-panel/RefreshPanel";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import ValidatePanel from "../common/action-panel/ValidatePanel";
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import format from "date-fns/format";
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';

interface ResourceOwnershipGridProps extends PageRole{
    title: string,
    dataApi: string,
    season: 'Summer' | 'Winter'
}

const plantNmEmpty = 'Plant Name must have a value to save the record.';
const resourceNmEmpty = 'Resource ID must have a value to save the record.';
const submitEntNmEmpty = 'Submitting Entity must have a value to save the record.';
const respEntNmEmpty = 'Responsible Entity must have a value to save the record.';

const ResourceOwnershipGrid: React.FC<ResourceOwnershipGridProps> = ({title, isStaff, dataApi, season, readRole, addRole, modifyRole, removeRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<ResourceOwnershipEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<ResourceOwnershipData>>([]);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [subYear, setSubYear] = useState<number>(0);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const globalPageSize = localStorage.getItem(`globalPageSize`)
    const [dataState, setDataState] = useState<any>({...initialDataState, take: globalPageSize ? +globalPageSize : 10, pageSize: globalPageSize ? +globalPageSize : 10});
    const [entityOptions, setEntityDropDownOptions] = useState<Array<ChildDropDownOption>>([]);
    const [ownershipCompanyNmOptions, setOwnershipCompanyOptions] = useState<Array<DropDownOption>>([]);
    const [plantOptions, setPlantOptions] = useState<Array<DropDownOption>>([]);
    const [resourceOptions, setResourceOptions] = useState<Array<ChildDropDownOption>>([]);
    const [submitEntityOptions, setSubmitEntOptions] = useState<Array<DropDownOption>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);

    useEffect(() => {
        axios.get(`${dataApi}/columns`)
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    useEffect(() => {
        axios.get('/api/auth/ra/plants/dropdown')
            .then((resp) => {
                setPlantOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/ra/resources/dropdown/plant-resource')
            .then((resp) => {
                const plantData = processDropDownListOptions(resp.data.plantData);
                const resourceData = processResourceOptions(resp.data.resourceData);
                setPlantOptions(plantData);
                setResourceOptions(resourceData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/ra/resource-ownership/dropdown/ownership-company')
            .then((resp) => {
                setOwnershipCompanyOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        // ResourceOwnership uses the active season subyear
        axios.get('/api/auth/ra/subyear/current')
            .then((resp) => {
                setSubYear(resp.data);
            })
    }, []);

    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(dataApi), axios.get(`/api/auth/ra/season-window/${season}`)])
            .then((resp) => {
                setData(deepCopyGridDataWithOriginal(resp[0].data));
                setOriginalData(deepCopyGridData(resp[0].data));
                setTempSequence(-10000);
                setDataErrors([]);
                if (!isStaff) {
                    if (resp[1].data === false) {
                        toast.info(`Resource Adequacy window is closed for the ${season} season`);
                    }
                    setSeasonOpen(resp[1].data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, isStaff, readRole, season]);

    useEffect(() => {
        getData();
    }, [getData]);
    
    const refreshData = () => {
        getData();
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    useEffect(() => {
        axios.get('/api/auth/ra/resource-ownership/dropdown/submit-resp-ent')
            .then((resp) => {
                const submitEntData = processDropDownListOptions(resp.data.submitEntData);
                const respEntData = resp.data.respEntData
                    .map((item: any) => ({
                        text: item.respEntNm,
                        value: item.respEntNm,
                        parentValue: item.submitEntNm
                    }));

                respEntData.sort((a: any, b: any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
                setSubmitEntOptions(submitEntData);
                setEntityDropDownOptions(respEntData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = async (id: number, field: string, value: any) => {
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                if (field === 'submitEntNm') {
                    dataItem.respEntNm = '';
                }
                if (field === 'plantNm') {
                    dataItem.resourceNm = '';
                    dataItem.resourceStatus = '';
                }
                if (field === 'resourceNm') {
                    dataItem.resourceStatus = getResourceStatus(dataItem.plantNm, dataItem.resourceNm, resourceOptions) as string;
                }
                if ((value || value === 0) && field === 'ownershipPercent') {
                    numberBoundValidationOnEdit(Math.round(value * 100), field, setDataErrors, item, columnMeta, 100, 0);
                }
                if ((value || value === 0) && (['ownerCap', 's1Cap', 's2Cap', 's3Cap', 's4Cap', 's5Cap', 's6Cap', 's7Cap', 's8Cap', 's9Cap', 's10Cap', 's11Cap'].includes(field))) {
                    numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 999999.999, 0);
                }
                return dataItem;
            } else {
                return item;
            }
        });

        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify&& field === 'verified') {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
    };

    const getResourceStatus = (plantNm: string, resourceNm: string, options: Array<ChildDropDownOption>) => {
        const option = options.find((option: ChildDropDownOption) => option.parentValue === plantNm && option.value === resourceNm);
        return !!option ? option.childValue : '';
    };

    const processResourceOptions = (resourceData: any) => {
        return resourceData
            .map((item: any) => ({
                text: item.resourceName,
                value: item.resourceName,
                parentValue: item.plantName,
                childValue: item.resourceStatus
            }))
            .sort((a: any, b: any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
    };

    const handleUpload = (uploadedData: Array<ResourceOwnershipEditData>) => {
        console.log(uploadedData);
        let sequence = tempSequence;
        const localData = data.map((item) => {
            let upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                // Reset non-editable fields to default or empty value.
                // Reset option fields to valid option or empty value.
                // Direct input fields can take update value without modification. API won't set values of wrong format.
                let plantNm = resetOption(u.plantNm, plantOptions, '');
                let resourceNm = plantNm ? resetChildOption(u.plantNm, u.resourceNm, resourceOptions, '') : '';
                let resourceStatus = resourceNm ? getResourceStatus(u.plantNm, u.resourceNm, resourceOptions) as string : '';

                return {
                    ...u,
                    verified: false,
                    id: sequence++,
                    plantNm: plantNm,
                    resourceNm: plantNm ? resourceNm  : '',
                    resourceStatus: resourceNm ? resourceStatus : '',
                    submitEntNm: resetOption(u.submitEntNm, submitEntityOptions, ''),
                    respEntNm: resetOption(u.respEntNm, entityOptions, ''),
                    ownershipCompanyNm: resetOption(u.ownershipCompanyNm, ownershipCompanyNmOptions, ''),
                    ownershipPercent: Number(u.ownershipPercent.toFixed(2)),
                    subYearId: subYear,
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true
                };
            })
            .filter((item) => data.findIndex((dataItem) => item.plantNm === dataItem.plantNm) === -1);

        const dataUploaded: Array<ResourceOwnershipEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u as ResourceOwnershipEditData);
        });

        setData(dataUploaded);
        setTempSequence(sequence);
    };

        const processUploadEdits = (upload: ResourceOwnershipEditData, item: ResourceOwnershipEditData) => {
            // need to pass every field change to updateEditedRows
            let plantNm = resetOption(upload.plantNm, plantOptions, item.plantNm);
            if (plantNm?.toLowerCase() !== item.plantNm.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'plantNm', upload.plantNm));
            }
            resetChildOption(upload.plantNm, upload.resourceNm, resourceOptions, '')
            if (upload.resourceNm?.toLowerCase() !== item.resourceNm.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceNm', upload.resourceNm));
            }
            let submitEntNm = resetOption(upload.submitEntNm, entityOptions, item.submitEntNm);
            if (submitEntNm?.toLowerCase() !== item.respEntNm.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'respEntNm', upload.respEntNm));
            }
            let respEntNm = resetOption(upload.respEntNm, entityOptions, item.respEntNm);
            if (respEntNm?.toLowerCase() !== item.respEntNm.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'respEntNm', upload.respEntNm));
            }

            if (upload.ownershipCompanyNm?.toLowerCase() !== item.ownershipCompanyNm?.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'ownershipCompanyNm', upload.ownershipCompanyNm));
            }

            if (upload.ownershipPercent !== item.ownershipPercent) {
                let field = 'ownershipPercent';
                numberBoundValidationOnUpload(field, setDataErrors, Math.round(upload[field] * 100), item.id, columnMeta, 100, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.ownerCap !== item.ownerCap) {
                let field = 'ownerCap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s1Cap !== item.s1Cap) {
                let field = 's1Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s2Cap !== item.s2Cap) {
                let field = 's2Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s3Cap !== item.s3Cap) {
                let field = 's3Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s4Cap !== item.s4Cap) {
                let field = 's4Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s5Cap !== item.s5Cap) {
                let field = 's5Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s6Cap !== item.s6Cap) {
                let field = 's6Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s7Cap !== item.s7Cap) {
                let field = 's7Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s8Cap !== item.s8Cap) {
                let field = 's8Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s9Cap !== item.s9Cap) {
                let field = 's9Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s10Cap !== item.s10Cap) {
                let field = 's10Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.s11Cap !== item.s11Cap) {
                let field = 's11Cap';
                numberBoundValidationOnUpload(field, setDataErrors, upload[field], item.id, columnMeta, 999999.999, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }

            if (upload.comments?.toLowerCase() !== item.comments?.toLowerCase()) {
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'comments', upload.comments));
            }

            return {
                ...item,
                plantNm: upload.plantNm,
                submitEntNm: upload.submitEntNm,
                respEntNm: upload.respEntNm,
                ownershipCompanyNm: upload.ownershipCompanyNm,
                ownershipPercent: Number(upload.ownershipPercent.toFixed(2)),
                ownershipCap: upload.ownerCap,
                s1Cap: upload.s1Cap,
                s2Cap: upload.s2Cap,
                s3Cap: upload.s3Cap,
                s4Cap: upload.s4Cap,
                s5Cap: upload.s5Cap,
                s6Cap: upload.s6Cap,
                s7Cap: upload.s7Cap,
                s8Cap: upload.s8Cap,
                s9Cap: upload.s9Cap,
                s10Cap: upload.s10Cap,
                s11Cap: upload.s11Cap,
                comments: upload.comments
            };
        };

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: ResourceOwnershipEditData = {
            verified: false,
            id: sequence++,
            plantNm: '',
            resourceNm: '',
            resourceStatus: '',
            submitEntNm: '',
            respEntNm: '',
            ownershipCompanyNm: '',
            ownershipPercent: 0,
            ownerCap: 0,
            s1Cap: 0,
            s2Cap: 0,
            s3Cap: 0,
            s4Cap: 0,
            s5Cap: 0,
            s6Cap: 0,
            s7Cap: 0,
            s8Cap: 0,
            s9Cap: 0,
            s10Cap: 0,
            s11Cap: 0,
            comments: '',
            subYearId: subYear,
            lastUserModBy: '',
            lastUserModDt: null,
            verifiedBy: '',
            verifiedDt: null,
            inEdit: true
        };
        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
        resetRemove();
    };

    const save = () => {
        if (filterEmptyRequiredColumns()) {
            return;
        }

        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }
        // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        //save the data
        //axios.post(dataApi, updatedData)  //save the data
        axios.post(dataApi, newAndUpdatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const filterEmptyRequiredColumns = (): boolean => {
        const missingRequired = data.filter((item) => !item.plantNm || !item.resourceNm || !item.submitEntNm || !item.respEntNm);

        if (missingRequired.length === 0) {
            return false;
        }
        toast.error('There are columns missing required data. Please provide value to save changes.');
        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {

            if (!item.plantNm) {
                localErrors = addDataError(item.id, 'plantNm', 'Plant Name', '',
                    plantNmEmpty, true, localErrors);
            }
            if (!item.resourceNm) {
                localErrors = addDataError(item.id, 'resourceNm', 'Resource ID', '',
                    resourceNmEmpty, true, localErrors);
            }
            if (!item.submitEntNm) {
                localErrors = addDataError(item.id, 'submitEntNm', 'Submitting Entity', '',
                    submitEntNmEmpty, true, localErrors);
            }
            if (!item.respEntNm) {
                localErrors = addDataError(item.id, 'respEntNm', 'Responsible Entity', '',
                    respEntNmEmpty, true, localErrors);
            }
        });
        setDataErrors(localErrors);
        return true;
    }

    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post(`${dataApi}/verify`, updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            // initialize 'removed' field to false on all data objects
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        // take 'removed' field off data objects
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const confirmRemove = () => {
        axios.delete(`/api/auth/ra/resource-ownership/delete`, {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                setData(localData);
                resetRemove();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`${dataApi}/validate`)
            .then(response => {
                setValidationErrors(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const plantsDropDownCell = useMemo(() => getDropDownCell(plantOptions, 'Plant Name'), [plantOptions]);
    const SubmitEntDropDownCell = useMemo(() => getDropDownCell(submitEntityOptions, 'Submitting Entity'), [submitEntityOptions]);
    // Don't use useMemo with ChildDropDownCell. That causes the child field not to reset immediately when parentField value changes.
    const RespEntDropDownCell = getChildDropDownCell(entityOptions, 'Entity', 'submitEntNm');
    const ownershipCompanyNmDropDownCell = useMemo(() => getDropDownCell(ownershipCompanyNmOptions, 'Ownership Company'), [ownershipCompanyNmOptions]);
    const OwnershipPercentCell = useMemo(() => getPercentCell(0, true), []);
    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);
    const CapDecimalCell = useMemo(() => getDecimalCell(3, true, 999999.999, 0), []);
    const OwnerCapDecimalCell = useMemo( () => getDecimalCell(3, true, 999999.999, 0), []);
    const ResourceNmDropDownCell = getChildApiDropDownCell('/api/auth/ra/resources/dropdown', 'plantNm');

    const formatExport = (data: Array<ResourceOwnershipData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO',
            ownershipPercent: item.ownershipPercent === null ? null : item.ownershipPercent * 100,
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/upload`} setUploadRecords={handleUpload} />}
                {!inEdit && modifyRole && seasonOpen && isStaff && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleSelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && !inVerify && modifyRole && seasonOpen && <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        sortable={true}
                        filterable={true}
                        resizable={true}
                        style={{ height: "510px" }}
                        {...dataState}
                        editField='inEdit'
                        onDataStateChange={dataStateChange}
                        onItemChange={itemChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                    >
                        {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                        {
                            columnMeta.length
                                ? columnMeta.map((col: ColumnMeta, index) => {
                                    switch (col.nm) {
                                        case 'verified':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='105px'
                                                               cell={VerifiedCell} filter={"boolean"}/>
                                        case 'id':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='120px'
                                                               editable={false}/>
                                        case 'plantNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='425px'
                                                               cell={plantsDropDownCell}/>
                                        case 'resourceNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='104px'
                                                               cell={ResourceNmDropDownCell}/>
                                        case 'resourceStatus':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='113px'
                                                               editable={false}/>
                                        case 'submitEntNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='160px'
                                                               cell={SubmitEntDropDownCell}/>
                                        case 'respEntNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='160px'
                                                               cell={RespEntDropDownCell}/>
                                        case 'ownershipCompanyNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='425px'
                                                               cell={ownershipCompanyNmDropDownCell}/>
                                        case 'ownershipPercent':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px'
                                                               cell={OwnershipPercentCell}/>
                                        case 'ownerCap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='244px'
                                                               cell={OwnerCapDecimalCell}/>
                                        case 's1Cap':
                                        case 's2Cap':
                                        case 's3Cap':
                                        case 's4Cap':
                                        case 's5Cap':
                                        case 's6Cap':
                                        case 's7Cap':
                                        case 's8Cap':
                                        case 's9Cap':
                                        case 's10Cap':
                                        case 's11Cap':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px'
                                                               cell={CapDecimalCell}/>
                                        case 'comments':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='425px'
                                                               cell={InputCell}/>
                                        case 'subYearId':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px'
                                                               editable={false}/>
                                        case 'lastUserModBy':
                                        case 'verifiedBy':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px'
                                                               editable={false}/>
                                        case 'lastUserModDt':
                                        case 'verifiedDt':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='230px'
                                                               editable={false}/>
                                        default:
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px'
                                                               editable={col.editInd !== '0'}/>
                                    }
                                })
                                : <GridColumn/>
                        }
                    </Grid>
                </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={title}/>}
        </div>
    );
};

export default ResourceOwnershipGrid;