import {useEffect, useState} from 'react';
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import {Button} from '@progress/kendo-react-buttons';
import {Loader} from '@progress/kendo-react-indicators';
import {Container, Header} from 'semantic-ui-react';
import axios from 'axios';
import WrapHistoricalGrid from './WrapHistoricalGrid';
import {wrapHistoryTables} from './wrap-history-tables';
import {ColumnMeta} from '../../../interfaces/grid-interfaces';
import {useCategorySeason} from '../../../hooks/useCategorySeason';

const HistoricalPage = () => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<any>>([]);
    const [tabNm, setTabNm] = useState<string>('');
    const [tabNmOptions, setTabNmOptions] = useState<Array<string>>([]);
    const [gridVisible, setGridVisible] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    
    const {subyear, subyearList, season, seasonList, handleSubyear, handleSeason} = useCategorySeason();

    useEffect(() => {
        const tabNmList = Array.from(new Set(wrapHistoryTables
            .filter((item) => item.firstSubYear <= subyear && (!item.lastSubYear || (!!item.lastSubYear && item.lastSubYear >= subyear)))
            .map((item) => item.visNm)));
        setTabNmOptions(tabNmList);
        if (!tabNmList.includes(tabNm)) {
            setTabNm('');
        }
    }, [subyear, tabNm]);
    
    const handleSubyearChange = (event: DropDownListChangeEvent) => {
        handleSubyear(event);
    };

    const handleTabNmChange = (event: DropDownListChangeEvent) => {
        const tab = event.target.value;
        setTabNm(tab);
    };
    
    const showYearSeason = (columns: Array<ColumnMeta>) => {
        return columns.map(c => ({
            ...c,
            visInd: c.nm.toLowerCase() === 'subyearid' ? '1' : c.nm.toLowerCase() === 'verified' ? '0' : c.visInd
        }));
    };

    const getHistory = () => {
        const year = subyear;
        const historyTabs = wrapHistoryTables
            .filter((item) => item.firstSubYear <= year
                && (!item.lastSubYear || item.lastSubYear >= year)
                && item.season === season
                && item.visNm === tabNm);
        if (!historyTabs.length) {
            return;
        }
        setGridVisible(false);
        
        const table = historyTabs[0];
        setTitle(`${subyear} ${season} ${table.visNm}`);
        const params = new URLSearchParams();
        params.set('subYear', subyear.toString());
        if (table.parameters.includes('season')) {
            params.set('season', season);
        }
        setLoading(true);
        Promise.all([axios.get(`${table.api}/columns`, {params}), axios.get(table.api, {params})])
            .then((resp) => {
                setColumnMeta(showYearSeason(resp[0].data));
                setData(resp[1].data.map((item: any) => ({...item})));
                setGridVisible(true);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container fluid={true} style={{paddingLeft: 20, paddingRight: 20, paddingTop: 50}}>
            <Header size="large">WRAP Historical</Header>
            <span><strong>Submittal Year</strong>&nbsp;</span>
            <DropDownList
                data={subyearList}
                value={subyear}
                onChange={handleSubyearChange}
                style={{width: '100px'}}
            />
            <span>&nbsp;&nbsp;<strong>Season</strong>&nbsp;</span>
            <DropDownList
                data={seasonList}
                value={season}
                onChange={handleSeason}
                style={{width: '100px'}}
            />
            <span>&nbsp;&nbsp;<strong>Data Set</strong>&nbsp;</span>
            <DropDownList
                data={tabNmOptions}
                value={tabNm}
                onChange={handleTabNmChange}
                style={{width: '275px'}}
            />
            <span>&nbsp;&nbsp;</span>
            <Button onClick={getHistory} disabled={!(season && tabNm && subyear)} themeColor='primary'>
                <strong>Show History</strong>&nbsp;{loading &&
                <Loader size='small' type='pulsing' themeColor='inverse'/>}
            </Button>
            <br/>
            <br/>
            {gridVisible &&
                <WrapHistoricalGrid
                    columnMeta={columnMeta}
                    data={data}
                    title={title}
                />
            }
        </Container>
    );
};
export default HistoricalPage;