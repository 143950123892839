import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {GridColumn, Grid} from '@progress/kendo-react-grid';
import {
    FacilityLinkingOptionContext,
    FacilityParticipant
} from '../common/row-edit-grid/context/FacilityLinkingOptionContext';
import {RowEditGrid} from '../common/row-edit-grid/RowEditGrid';
import {COMMAND_CELL_WIDTH, CommandCell} from '../common/row-edit-grid/CommandCell';
import {FacilityLinkingOptionFormCell} from '../common/row-edit-grid/FacilityLinkingOptionFormCell';
import {ParticipantOptionFormCell} from '../common/row-edit-grid/ParticipantOptionFormCell';
import {RequiredNumberFormCell} from '../common/row-edit-grid/RequiredNumberFormCell';
import {PageRole} from "../../interfaces/grid-interfaces";
import {toast} from "react-toastify";

interface FacilityLinkingGridProps extends PageRole {
}

 const FacilityLinkingGrid: React.FC<FacilityLinkingGridProps>  = ({addRole, modifyRole, removeRole}) => {
    const [facilityOptions, setFacilityOptions] = useState<string[]>([]);
    const [participantOptions, setParticipantOptions] = useState<string[]>([]);
    const [facilityParticipantList, setFacilityParticipantList] = useState<FacilityParticipant[]>([]);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        axios.get('/api/auth/wrap/resource/dropdown/facility-participant-resource')
            .then(resp => {
                const facilityParticipant = resp.data.map((d: FacilityParticipant) => ({
                    facilityNm: d.facilityNm,
                    participantNm: d.participantNm
                }));
                setFacilityParticipantList(facilityParticipant);
                // Array.from(new Set(map object field)) is how JavaScript gets distinct field values from an array of objects. 
                setParticipantOptions(Array.from(new Set(facilityParticipant
                    .map((f: FacilityParticipant) => f.participantNm))));
            });
    }, []);

     const getData = useCallback(() => {
         axios.get(`/api/auth/wrap/facility/facility-linking`)
             .then((resp) => {
                 setData(
                     resp.data.map((dataItem: any, index: number) => ({
                         ...dataItem,
                     }))
                 );
             })
             .catch(() => {
                 toast.error('Error loading data');
             });
     }, []);

     useEffect(() => {
         getData();
     }, [getData]);

    if(modifyRole || addRole || removeRole) {
        return (
            <FacilityLinkingOptionContext.Provider value={{
                facilityOptions,
                setFacilityOptions,
                participantOptions,
                setParticipantOptions,
                facilityParticipantList
            }}>
                <div style={{maxWidth: '1442px'}}>
                    <RowEditGrid dataApi={'/api/auth/wrap/facility/facility-linking'} addUpdateTogether={true}>
                        <GridColumn title='Command' cell={CommandCell} width={COMMAND_CELL_WIDTH}/>
                        <GridColumn title='Participant' field={'participantNm'} cell={ParticipantOptionFormCell} width={200}/>
                        <GridColumn title='Facilities' field='facilityList' cell={FacilityLinkingOptionFormCell} width={400}/>
                        <GridColumn title='Point Of Interconnection' field='pointOfInterconnection' cell={RequiredNumberFormCell} format='n1' width={200}/>
                    </RowEditGrid>
                </div>
            </FacilityLinkingOptionContext.Provider>
        );
    }
    else {
        return (
            <FacilityLinkingOptionContext.Provider value={{
                facilityOptions,
                setFacilityOptions,
                participantOptions,
                setParticipantOptions,
                facilityParticipantList
            }}>
                <div style={{maxWidth: '1442px'}}>
                    <Grid
                        data={data}
                        dataItemKey='id'
                        resizable={false}
                    >
                        <GridColumn title='Participant' field={'participantNm'} width={200} editable={false}/>
                        <GridColumn title='Facilities' field='facilityList' width={400} editable={false}/>
                        <GridColumn title='Point Of Interconnection' field='pointOfInterconnection' width={200} editable={false}/>
                    </Grid>
                </div>
            </FacilityLinkingOptionContext.Provider>
        );
    }
};

export default FacilityLinkingGrid;