import {useContext, useState} from 'react';
import {MultiSelect, MultiSelectChangeEvent} from '@progress/kendo-react-dropdowns';
import {Field} from '@progress/kendo-react-form';
import {GridCellProps} from '@progress/kendo-react-grid';
import {DataSetOptionContext} from './context/DataSetOptionContext';
import {GridEditContext} from './context/GridEditContext';
import {FORM_DATA_INDEX} from './RowEditGrid';

export const DataSetOptionFormCell = (props: GridCellProps) => {
    const {editIndex} = useContext(GridEditContext);
    const {options} = useContext(DataSetOptionContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const [selected, setSelected] = useState<string[]>(props.dataItem[props.field || ''] || []);

    // https://www.telerik.com/kendo-react-ui/components/dropdowns/multiselect/binding/#toc-datasets-of-objects
    // When not in edit mode, create comma separated list of display names

    const handleChange = (event: MultiSelectChangeEvent) => {
        setSelected(event.target.value);
        if (props.onChange && editIndex) {
            props.onChange({
                dataIndex: editIndex,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value
            });
        }
    };

    return (
        <td>
            {isInEdit ? (
                <Field
                    name={`${props.field}`}
                    component={MultiSelect}
                    data={options}
                    value={selected}
                    onChange={handleChange}
                />
            ) : (
                selected.join(', ')
            )}
        </td>
    );
};