import * as React from 'react';
import {Button, ButtonProps, Container, Grid, Header, Icon, Modal, ModalProps, Popup} from "semantic-ui-react";
import axios from 'axios';
import '../../CSS/react-table-custom.css';
import GridUtil, {
    isMirrorRecord,
    missingRequiredFields,
    removeDuplicateBusRecords
} from '../common/GridUtil';
import format from 'date-fns/format';
import {connect} from "react-redux";
import {toast} from "react-toastify";
import * as actionTypes from "../../store/actions/actionTypes";
import {Principal} from "../../auth";
import ReactTableHOC from "./EdstGrid";
import PopupX from "../PopupX";
import ChangesetService from "../../services/changeset-services";
import SppVerifiedModal from "./SppVerifiedModal";
import BulkUploadValidationMessage from "./BulkUploadValidationMessage";

interface GridState {
    data: any,
    edit: boolean,
    currentRow: number,
    metaData: any,
    filtered: any,
    openModal: boolean,
    stateChangeset: Changeset,
    stateNewChangesetRow: any,
    loading: boolean,
    sorted: any,
    uploadFile: any,
    exporting: boolean,
    uploading: boolean,
    modalHeader: string,
    modalContent: string,
    saving: boolean,
    bulkUploadErrors: {},
    bulkUploadErrorsStage: {},
    bulkUploadValidations: any[],
    uploadPending: boolean,
    togglePopup: boolean,
    APPROVEDloading: boolean,
    REJECTEDloading: boolean,
    ABANDONEDloading: boolean,
    QUEUEDloading: boolean,
    SUBMITTEDloading: boolean,
    DRAFTloading: boolean,
    savedSuccessfully: boolean,
    exportButtonHidden: boolean,
    rowsDeleted: any[],
    abandonModalOpen: boolean,
    edstGridInputKey: any, // Updating causes UI editing components to refresh value being displayed
    entities: string[],
    originalBaseCaseData: any,
    originalBaseCaseDataStage: any,
    respAckIndChanged: boolean,
    confAckIndChanged: boolean,
    rowsAddedNoPk: boolean,
    userInAuthorEntityGroup: boolean,
    enableImportButton: boolean,
    changeSetAuthorIsMember: boolean,
    submittedSuccessfully: boolean,
    serverSideData: number,
    popupOpen: boolean,
    saveChangeSetBool: boolean,
    removeSelected: boolean,
    modalActive: boolean,
    sppVerifyFromGrid: boolean,
    BulkVerifyActive: boolean
    currentRowId: number
    bulkUploadRecordKeys: number[]
    bulkModal: boolean
    bulkUploadData: any[]
}

interface ChangesetGridProps {
    currentUser: string,
    resource: string,
    userEntities: any[],
    changeset: Changeset,
    roles: Principal[],
    currentTab: string,
    rowsToAddToChangeset: any,
    changeSetPendingSave: boolean,
    rowsUpdated: boolean,
    removeRowFromChangeset: (changesetId: number) => void,
    updateCurrentChangeset: (currentChangeset: Changeset) => void,
    updateChangeSetPendingSave: (pendingSave: boolean) => void,
    setChangesetRowsUpdated: (rowUpdated: boolean) => void,
    setUserInAuthorEntityGroup: (inEntityGroup: boolean) => void,
    incrementChangesetVersion: () => void,
    incrementBasecaseVersion: () => void,
    changeSetSubYearId: number,
    changesetNumber: string,
    resAdqLockedOut?: boolean,
    modDevLockedOut?: boolean,
    sppStaff?: boolean,
    changeSetAuthorIsMember: boolean,
    userRolesEntities: any
}

const find = require('lodash/find');
const some = require('lodash/some');
const includes = require('lodash/includes');
let fileDownload = require('js-file-download');
const changesetClient = new ChangesetService();

class ChangesetGrid extends React.Component<ChangesetGridProps, GridState> {

    constructor(props: ChangesetGridProps, state: GridState) {
        super(props, state);
        this.state = {
            data: [],
            edit: false,
            currentRow: -1,
            metaData: [],
            filtered: [],
            openModal: false,
            stateChangeset: {
                status: '',
                number: -1,
                name: '',
                comments: '',
                changeSetAuthorName: ''
            },
            stateNewChangesetRow: [],
            loading: false,
            sorted: [],
            uploadFile: {},
            exporting: false,
            uploading: false,
            modalContent: '',
            modalHeader: '',
            saving: false,
            bulkUploadErrors: {},
            bulkUploadErrorsStage: {},
            bulkUploadValidations: [],
            uploadPending: false,
            togglePopup: true,
            APPROVEDloading: false,
            REJECTEDloading: false,
            ABANDONEDloading: false,
            QUEUEDloading: false,
            SUBMITTEDloading: false,
            DRAFTloading: false,
            exportButtonHidden: true,
            rowsDeleted: [],
            savedSuccessfully: false,
            abandonModalOpen: false,
            edstGridInputKey: null,
            entities: [],
            originalBaseCaseData: [],
            originalBaseCaseDataStage: [],
            respAckIndChanged: false,
            confAckIndChanged: false,
            rowsAddedNoPk: false,
            userInAuthorEntityGroup: false,
            enableImportButton: false,
            changeSetAuthorIsMember: false,
            submittedSuccessfully: false,
            serverSideData: 0,
            popupOpen: false,
            saveChangeSetBool: false,
            removeSelected: false,
            modalActive: false,
            sppVerifyFromGrid: false,
            BulkVerifyActive: false,
            currentRowId: -1,
            bulkUploadRecordKeys: [],
            bulkModal: false,
            bulkUploadData: []
        };
    }

    handleFilterChange = (filter: any) => {
        this.setState({filtered: filter, edstGridInputKey: Date.now()});
    };

    handleChange = (e: any, gridNum?: number, cellType?: string) => {
        let columnName = e.id;
        if (e.id.indexOf('-') > -1) {
            columnName = e.id.split('-')[0];
        }
        if (e.value === 'REMOVE') {
            this.setState({removeSelected: true});
        } else {
            this.setState({removeSelected: false});
        }
        let data = [...this.state.data];
        if (data[e.accKey] !== undefined) {
            if (data[e.accKey][columnName] !== e.textContent) {
                if (data[e.accKey]['modBy'] !== undefined) {
                    data[e.accKey]['modBy'] = this.props.currentUser;
                }
            }
            data[e.accKey][columnName] = e.value;
            this.setState({data});
            this.props.setChangesetRowsUpdated(true);
            this.props.updateChangeSetPendingSave(true);
            let color = [] as any;
            switch (cellType) {
                case 'input':
                    color = document.querySelectorAll(".ui.input.inputClass" + gridNum + ">input#" + columnName + "-" + e.accKey);
                    break;
                case 'dropdown':
                    color = document.querySelectorAll("#" + columnName + "-" + e.accKey + ".ui.fluid.search.selection.dropdown.inputClass" + gridNum);
                    break;
                case 'edstDropDown':
                    color = document.querySelectorAll("#" + columnName + "-" + e.accKey + ".ui.fluid.search.selection.dropdown.inputClass" + gridNum);
                    break;
                case 'timestamp':
                    color = document.querySelectorAll(".react-datepicker__input-container>input#" + columnName + "-" + e.accKey);
                    break;
            }
            if (color.length > 0) {
                Array.from(color).forEach((cell) => {
                    (cell as HTMLElement).style.backgroundColor = '#5cd65c';
                    if (cellType === 'timestamp') {
                        (cell as HTMLElement).style.removeProperty('border-color');
                    }
                });
            }
        }
    };

    // Must have a role with these properties. SppStaff and access to the current table.
    hasApproveRejectRole = () => {
        let retVal: boolean;
        let tableName: string = this.props.currentTab;
        // lodash find the table name and sppStaff of true. returns undefined if not found so ! checks for null/undefined
        retVal = !!find(this.props.roles, function (r: Principal) {
            return r.tabNm === tableName && r.sppStaff;
        });
        return retVal;
    };

    handleSpecialStringsLookingLikeNumbers = (columnMetaData: any): string => {
        let colName = columnMetaData.nm.charAt(0).toLowerCase() + columnMetaData.nm.substring(1);
        switch (this.props.resource) {
            case 'Generators':
                if (colName.startsWith('auxLoad')) {
                    return '0';
                } else {
                    return '';
                }
            default:
                return '';
        }
    };

    handleSpecialCasesForNumbers = (columnMetaData: any): any => {
        let colName = columnMetaData.nm.charAt(0).toLowerCase() + columnMetaData.nm.substring(1);
        switch (this.props.resource) {
            case 'BusDtl':
                if (colName === 'id') {
                    return null;
                } else {
                    return 0;
                }
            default:
                return 0;
        }
    };

    setDefaultValueBasedOnType = (columnMetaData: any): any => {
        let type = columnMetaData.type as string;
        if (type !== undefined && type !== null) {
            type = type.toUpperCase();
            if (type === 'LONG' || type === 'INTEGER' || type === 'DOUBLE') {
                return this.handleSpecialCasesForNumbers(columnMetaData);
            } else {
                return this.handleSpecialStringsLookingLikeNumbers(columnMetaData);
            }
        }
        return '';
    };

    addRow = () => {
        if (this.state.stateChangeset.number !== 0) {
            let data = [...this.state.data];
            let metaData = this.state.metaData;
            let jObject = {};
            if ((metaData !== null) && (typeof metaData !== "undefined")) {
                metaData.sort(function (a: any, b: any) {
                    return a.sortId - b.sortId;
                });
                for (let i = 0; i < metaData.length; i++) {
                    if (metaData[i].visInd === "1") {
                        let colName = metaData[i].nm.charAt(0).toLowerCase() + metaData[i].nm.substring(1);
                        switch (colName) {
                            case 'sppVerified':
                                jObject[colName] = 'NO';
                                break;
                            case 'action':
                                jObject[colName] = 'ADD';
                                break;
                            case 'chgSId':
                                jObject[colName] = this.props.changeset.number;
                                break;
                            case 'pK':
                            case 'pk':
                                jObject[colName] = -1;
                                break;
                            case 'pvsChgSId':
                                jObject[colName] = null;
                                break;
                            case 'subYearId':
                                jObject[colName] = this.props.changeSetSubYearId;
                                break;
                            case 'creBy':
                                jObject[colName] = this.props.currentUser;
                                break;
                            default:
                                if (metaData[i].requiredInd) {
                                    jObject[colName] = '';
                                } else {
                                    jObject[colName] = this.setDefaultValueBasedOnType(metaData[i]);
                                }
                                break;
                        }
                    }

                    if (this.props.resource === 'Transactions') {
                        jObject['mirrorRec'] = '0';
                    }
                }
            }
            data.push(jObject);
            this.props.setChangesetRowsUpdated(true);
            this.props.updateChangeSetPendingSave(true);
            this.setState({data, rowsAddedNoPk: true, edstGridInputKey: Date.now()});
        }
    };

    deleteRow = (cellInfo: any) => {
        const removedRow = {...this.state.data[cellInfo.target.id]};
        const data = this.state.data.filter((d: any) => d.pK !== removedRow.pK);
        let deletedRows = [...this.state.rowsDeleted];
        deletedRows.push(removedRow);
        this.props.removeRowFromChangeset(removedRow.pK);
        let previousData = this.state.originalBaseCaseData.filter((d: any) => d.pK !== removedRow.pK);
        this.setState({
            data,
            rowsDeleted: deletedRows,
            originalBaseCaseData: previousData,
            edstGridInputKey: Date.now()
        });
        this.props.updateChangeSetPendingSave(true);
    };

    exportToExcel = () => {
        if (this.state.serverSideData > 0) {
            const params = new URLSearchParams();
            params.append('changesetId', this.state.stateChangeset.number.toString());
            params.append('tabName', this.props.resource);
            axios.get(`/api/auth/changeset/export`, {
                params,
                responseType: 'arraybuffer'
            }).then(resp => {
                this.setState({exporting: false});
                fileDownload(resp.data, `Changeset${this.props.resource}_${format(new Date(),'ddMMyyyy_hhmmssa')}.xlsx`)
            }).catch((response) => {
                this.setState({exporting: false});
                toast.error(`Error exporting data to excel - ${response.message}`)
            });
            this.setState({exporting: true});
        } else {
            toast.error('Cannot export empty changeset.  Add records to changeset, or save any pending changes by clicking the Save Changeset button.  Then re-try exporting');
        }
    };

    // filterChange = (e: any) => {
    //     let filtered = [...this.state.filtered];
    //     filtered.push({id: e.target.id, value: e.target.value});
    //     this.setState({filtered})
    // };

    hasAddRole = () => {
        let screenRole = this.props.roles.find(role => {
            return role.tabNm === this.props.resource;
        });

        if (screenRole) {
            return screenRole.addRole;
        } else {
            return false;
        }
    };

    hasAddModRemRole = () => {
        let screenRole = this.props.roles.find(role => {
            return role.tabNm === this.props.resource;
        });

        if (screenRole) {
            return (screenRole.addRole || screenRole.modifyRole || screenRole.removeRole);
        } else {
            return false;
        }
    };

    getAddRecordColumn = () => {
        if (!this.props.modDevLockedOut || this.props.sppStaff) {
            return [{
                Header: <Popup
                    trigger={<Icon name={'add'}
                                   onClick={this.addRow}
                                   disabled={this.props.changeset.status !== 'DRAFT'
                                   || (this.props.changeset.status === 'DRAFT' && !this.props.sppStaff && !this.state.userInAuthorEntityGroup)
                                   || !this.hasAddRole()}
                                   style={{cursor: 'pointer'}}/>}
                    content={'Add row'}
                    disabled={this.props.changeset.status !== 'DRAFT'
                    || (this.props.changeset.status === 'DRAFT' && !this.props.sppStaff && !this.state.userInAuthorEntityGroup)
                    || !this.hasAddRole()}
                />,
                Cell: (cellInfo: any) => {
                    return (
                        <div style={{textAlign: 'center'}}><Popup
                            trigger={<Icon id={cellInfo.index}
                                           name={'trash alternate'}
                                           style={{cursor: 'pointer'}}
                                           size={"small"}
                                           onClick={this.deleteRow}
                                           disabled={this.props.changeset.status !== 'DRAFT' || (this.props.changeset.status === 'DRAFT' && !this.props.sppStaff && !this.state.userInAuthorEntityGroup)}/>}
                            content={'Delete row'}
                            disabled={this.props.changeset.status !== 'DRAFT' || (this.props.changeset.status === 'DRAFT' && !this.props.sppStaff && !this.state.userInAuthorEntityGroup)}/>
                        </div>
                    )
                },
                Filter: () => {
                    return null
                },
                sortable: false
            }];
        } else {
            return undefined;
        }
    };

    getResponsibleEntities = () => {
        let entityList: any[] = []
        this.props.userEntities.forEach(e => { entityList.push(e.respEntNm); });
        this.setState({entities: entityList})
    };

    compare = (a: any, b: any) => {
        let comparison = 0;
        if (a.pK === undefined) {
            if (a.pk > b.pk) {
                comparison = 1;
            } else {
                if (a.pk < b.pk) {
                    comparison = -1;
                }
            }
        } else {
            if (a.pK > b.pK) {
                comparison = 1;
            } else {
                if (a.pK < b.pK) {
                    comparison = -1;
                }
            }
        }

        return comparison;
    };

    getData = () => {
        if (this.props.changeset.number !== 0) {
            this.setState({loading: true, rowsDeleted: []});
            axios.get(`/api/auth/changesetDetails`, {
                params: {
                    tabName: this.props.resource,
                    changesetId: this.props.changeset.number
                }
            })
                .then(resp => {
                    let recordKeys = [];
                    let changesetData = resp.data.data.sort(this.compare);
                    for (let i = 0; i < changesetData.length; i++) {
                        if (changesetData[i]['pK'] === undefined) {
                            recordKeys.push(changesetData[i]['pk']);
                        } else {
                            recordKeys.push(changesetData[i]['pK']);
                        }
                    }
                    axios.post('/api/auth/basecase/previousBaseCaseData', {
                        tabName: this.props.resource,
                        recordKeys: recordKeys
                    })
                        .then(baseResp => {
                            this.checkIfUserInAuthorEntityGroup(resp.data.data, resp.data.columnMetaDataList, baseResp.data.data);
                        })
                        .catch(resp => {
                            toast.error('Error retrieving original basecase data for changeset ' + this.props.changeset.number + `- ${resp.message}`);
                        });
                })
                .catch(resp => {
                    toast.error(`Error retrieving changeset data - ${resp.message}`);
                    this.setState({exportButtonHidden: true});
                });
        }
    };

    enableUpload = (changesetData: any[]) => {
        let confirmingEntity: string = 'x';
        let userEntitiesArray = [...this.props.userRolesEntities.listOfAllEntities];
        let confirmingEntityUploadCase = false;
        let lockout: boolean = false;
        if (!this.props.sppStaff && this.props.modDevLockedOut) {
            lockout = true;
        }

        if ((this.props.resource === 'Transactions') && (this.props.changeset.status === 'PENDING')) {
            confirmingEntity = 'confNm';
            for (let i = 0; i < changesetData.length; i++) {
                if ((changesetData[i][confirmingEntity]) && (changesetData[i]['mirrorRec'] === '0')) {
                    let ConfEntNm2 = changesetData[i][confirmingEntity];
                    if ((userEntitiesArray.filter(e => e === ConfEntNm2).length > 0)) {
                        confirmingEntityUploadCase = true;
                        break;
                    }
                }
            }
        }
        
        if (confirmingEntityUploadCase && this.props.changeset.status === 'PENDING' && this.props.changeset.number !== 0 && !lockout) {
            return true;
        }

        if ((this.props.changeset.status === 'DRAFT' || this.props.changeset.status === 'PENDING') && this.props.sppStaff && this.props.changeset.number !== 0 && !lockout) {
            return true;
        }
        return (this.props.changeset.status === 'DRAFT' && this.state.userInAuthorEntityGroup && this.props.changeset.number !== 0 && !lockout);
    };
    
    //checks to see if user has same entities/groups as the original changeset author. used for setting boolean values of 'add rows to changeset' and save/update / return to draft / import buttons
    checkIfUserInAuthorEntityGroup = (changesetData: any[], columnMeta: any[], prevBaseData: any[]) => {
        let entityIdList: any[] = [];
        let confirmInAuthorEntityGroup: boolean = false;

        const mirrorFreeColumnMeta = this.props.resource === 'Transactions' ? columnMeta.filter((e: any) => !e.nm.includes('MirrorRec')) : columnMeta;

        if (this.state.entities.length > 0) {
            this.props.userRolesEntities.modDevUserToEntityRelations.forEach((e: { id: any; }) => {
                entityIdList.push(e['entid'])
            })
        }
        axios.get('/api/auth/checkSameEntityAsAuthor', {
            params: {
                originalChangesetAuthor: this.props.changeset.changeSetAuthorName,
                currentUserEntities: entityIdList.length > 0 ? entityIdList.reduce((f, s) => `${f},${s}`) : []
            }
        }).then(resp => {
            confirmInAuthorEntityGroup = resp.data;
            this.props.setUserInAuthorEntityGroup(confirmInAuthorEntityGroup);
        }).catch(error => {
            console.log('Error with checkSameEntityAsAuthor', error.message);
        }).finally(() => {
            changesetClient.checkNoChange(changesetData, prevBaseData, mirrorFreeColumnMeta, this.props.resource).then((dataCheck: any) => {
                this.setState({
                    data: dataCheck,
                    metaData: mirrorFreeColumnMeta,
                    loading: false,
                    exportButtonHidden: false,
                    edstGridInputKey: Date.now(),
                    originalBaseCaseData: prevBaseData,
                    savedSuccessfully: this.state.saving,
                    saving: false,
                    uploadFile: {},
                    rowsAddedNoPk: false,
                    userInAuthorEntityGroup: this.props.userRolesEntities.sppStaff ? true : confirmInAuthorEntityGroup,
                    serverSideData: dataCheck.length,
                    enableImportButton: this.enableUpload(changesetData),
                    BulkVerifyActive: false,
                    bulkModal: false,
                    bulkUploadErrors: {},
                    bulkUploadErrorsStage: {},
                    bulkUploadRecordKeys: [],
                    bulkUploadValidations: [],
                    modalActive: false,
                    uploadPending: false,
                });
            }).catch(() => {
                toast.error('Error checking if changes were made in the transaction changeset.')
            });
        })
    };

    componentDidMount()
        :
        void {
        this.getResponsibleEntities();
        if (!this.props.changesetNumber && !this.props.changeset.number) {
            let {changeset} = this.props;
            changeset.status = '';
            changeset.name = '';
            changeset.number = 0;
            changeset.comments = '';
            this.props.updateCurrentChangeset(changeset);
        }
    }

    componentWillUnmount()
        :
        void {
        let {changeset} = this.props;
        changeset.status = '';
        changeset.name = '';
        changeset.number = 0;
        changeset.comments = '';
        this.props.updateCurrentChangeset(changeset);
    }

    componentDidUpdate(prevProps: Readonly<ChangesetGridProps>, prevState: Readonly<GridState>, snapshot ?: any) {
        if (prevProps === this.props) {
            return;
        }

        let {stateChangeset} = this.state;

        if (this.props.changeset.number !== 0 && this.props.rowsToAddToChangeset.length > 0) {
            if (this.props.changeset.status === 'DRAFT') {
                let stateNewChangesetRow: any = [];
                let noPkHolder = [];
                let data = [...this.state.data];
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].hasOwnProperty('pK') && !data[i].hasOwnProperty('pk')) {
                        noPkHolder.push(data[i]);
                        data.splice(i, 1);
                        i--;
                    }
                }
                this.props.rowsToAddToChangeset.forEach((c: any) => {
                    stateNewChangesetRow.push(c);
                    if (c["pK"]) {
                        c['action'] = 'MODIFY';
                        c['disp'] = 'DRAFT';
                        c['chgSId'] = this.props.changeset.number;
                    }
                    c['modBy'] = this.props.currentUser;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]['pK'] === c['pK']) {
                            data.splice(i, 1);
                            i--;
                        }
                    }
                    if (includes(['Plants', 'Resources', 'GeneratorTestResults'], this.props.resource)) {
                        if (!(this.state.entities.includes('SPP') || this.state.entities.includes(c.submitEntNm))) {
                            c.submitEntNm = '';
                            c.respEntNm = '';
                        }
                    }
                    data.push(c);
                });

                let recordKeys = [];
                data = data.sort(this.compare);
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['pK'] === undefined) {
                        recordKeys.push(data[i]['pk']);
                    } else {
                        recordKeys.push(data[i]['pK']);
                    }
                }
                data.push(...noPkHolder);
                axios.post('/api/auth/basecase/previousBaseCaseData', {
                        tabName: this.props.resource,
                        recordKeys: recordKeys
                }).then(baseResp => {
                    this.setState({
                        stateNewChangesetRow,
                        data,
                        originalBaseCaseData: baseResp.data.data,
                        edstGridInputKey: Date.now()
                    });
                    if (this.props.rowsToAddToChangeset.length > 0) {
                        toast.success('Successfully added basecase rows to changeset');
                    }

                    this.props.rowsToAddToChangeset.length = 0;
                }).catch(resp => {
                    toast.error('Error retrieving original basecase data for changeset ' + this.props.changeset.number + `- ${resp.message}`);
                    this.props.rowsToAddToChangeset.length = 0;
                });
            } else {
                toast.info('Cannot add records to changeset when not in DRAFT state');
            }
        }

        if (this.props.changeset.number === 0 && this.props.changeset.number !== stateChangeset.number) {
            this.setState({
                data: [],
                stateChangeset: {status: '', number: 0, name: '', comments: '', changeSetAuthorName: ''},
                submittedSuccessfully: false,
                savedSuccessfully: false
            })
        }
        if (this.props.changeset.number !== stateChangeset.number && this.props.changeset.number !== 0) {
            this.getData();
            this.setState({
                stateChangeset: this.props.changeset,
                submittedSuccessfully: false,
                savedSuccessfully: false
            })
        }
    }

    fileUploaded = (e: any) => {
        if (e.target.files.length > 0) {
            let fileExtension = e.target.files[0].name.toLowerCase();
            if (e.target.files.length !== 0) {
                if (fileExtension.endsWith('xlsx')) {
                    this.setState({uploadFile: e.target.files[0]})
                } else {
                    toast.warn('You can only upload XLSX files')
                }
            }
        }
    };

    uploadFile = () => {
        let formData = new FormData();
        formData.append('file', this.state.uploadFile);
        formData.set('resource', this.props.resource);
        formData.set('changesetId', this.state.stateChangeset.number.toString());
        axios.post(`/api/auth/changeset/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(resp => {
                let data = [...this.state.data];
                let newData = [...resp.data.gridData.data];
                let noPkHolder = [];
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].hasOwnProperty('pK') && !data[i].hasOwnProperty('pk')) {
                        if (data[i]['action'] !== 'ADD') {
                            data[i]['action'] = 'ADD';
                        }
                        noPkHolder.push(data[i]);
                        data.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < newData.length; i++) {
                    if (newData[i].hasOwnProperty('respAckInd')) {
                        newData[i]['respAckInd'] = newData[i]['respAckInd'].toUpperCase()
                    }
                    if (newData[i].hasOwnProperty('confAckInd')) {
                        newData[i]['confAckInd'] = newData[i]['confAckInd'].toUpperCase()
                    }
                }
                let countNoPk = 0;
                let newDataNoPkHolder = [];
                for (let i = 0; i < newData.length; i++) {
                    let alreadySeen = false;
                    let nonExistentPk = false;
                    if (includes(['Plants', 'Resources', 'GeneratorTestResults'], this.props.resource)) {
                        if (!(this.state.entities.includes('SPP') || this.state.entities.includes(newData[i].submitEntNm))) {
                            newData[i].submitEntNm = '';
                            newData[i].respEntNm = '';
                        }
                    }

                    if (newData[i]['pK'] > 0 && newData[i]['pK'].toString().trim() !== '') {
                        for (let j = 0; j < i; j++) {
                            if (newData[j]['pK'] === newData[i]['pK']) {
                                alreadySeen = true;
                                break;
                            }
                        }
                    } else {
                        if (countNoPk === 0) {
                            this.setState({rowsAddedNoPk: true});
                        }
                        countNoPk++;
                        newData[i]['pK'] = -1;
                        newData[i]['action'] = 'ADD';
                        newData[i]['subYearId'] = this.props.changeSetSubYearId;
                        newData[i]['creBy'] = this.props.currentUser;
                        if (newData[i]['disp'] == null) {
                            newData[i]['disp'] = 'DRAFT';
                        }
                        if (newData[i]['chgSId'] == null) {
                            newData[i]['chgSId'] = this.props.changeset.number;
                        }
                        if (this.props.resource === 'Transactions') {
                            newData[i]['mirrorRec'] = '0';
                        }
                        newDataNoPkHolder.push(newData[i]);
                        newData.splice(i, 1);
                        i--;
                        nonExistentPk = true;
                    }

                    if (!nonExistentPk) {
                        if (alreadySeen) {
                            newData.splice(i, 1);
                            i--;
                        } else {
                            if (newData[i]['disp'] === null) {
                                newData[i]['disp'] = 'DRAFT';
                            }
                            if (newData[i]['chgSId'] === null) {
                                newData[i]['chgSId'] = this.props.changeset.number;
                            }
                            if (newData[i]['action'] === null) {
                                newData[i]['action'] = 'MODIFY';
                            }
                            if (this.props.resource === 'Transactions' && newData[i]['mirrorRec'] === null) {
                                newData[i]['mirrorRec'] = '0';
                            }
                            if (data.length > 0) {
                                let index = data.map(currentRecord => currentRecord.pK).indexOf(newData[i]['pK']);
                                if (index > -1) {
                                    data[index] = newData[i];
                                    newData.splice(i, 1);
                                    i--;
                                }
                            }
                        }
                    }
                }
                data = data.concat(newData);
                let recordKeys = [];
                data = data.sort(this.compare);
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['pK'] === undefined) {
                        recordKeys.push(data[i]['pk']);
                    } else {
                        recordKeys.push(data[i]['pK']);
                    }
                }
                data.push(...noPkHolder);
                data.push(...newDataNoPkHolder);
                
                // Bulk Upload Validation
                let bulkUploadRecordKeys: number[] = []; // holds PK of row
                let sppVerifyModalActive = false; // opens SppVerification modal
                let bulkUploadValidations: any[] = []; // holds the other validationErrors
                let bulkModal = false; // opens bulk validation modal after SppVerified modal if validationErrors > 0
                resp.data.validationErrors.forEach((v: any) => {
                    if (v.validationName === 'cancelSppVerification') { // cancel SppVerification (per row) due to editing row data
                        bulkUploadRecordKeys.push(v.recordKey);
                        if (!sppVerifyModalActive) {
                            sppVerifyModalActive = true;
                        }
                    } else {
                        bulkUploadValidations.push(v); // separate non-SppVerification validation errors to different list
                        if (!bulkModal) {
                            bulkModal = true;
                        }
                    }
                });
                if (bulkUploadRecordKeys.length > 0) {
                    bulkUploadRecordKeys = bulkUploadRecordKeys.sort();
                }
                
                // Get basecase data for existing records to show original value of each cell
                axios.post('/api/auth/basecase/previousBaseCaseData', {
                        tabName: this.props.resource,
                        recordKeys: recordKeys
                })
                    .then(baseResp => {
                        let baseData = [...baseResp.data.data];
                        if (baseData.length > 0) {
                            for (let i = 0; i < baseData.length; i++) {
                                if (i < baseData.length - 1) {
                                    if (baseData[i]['pK'] === baseData[i + 1]['pK']) {
                                        baseData.splice(i, 1);
                                        i--;
                                    }
                                }
                            }
                        }
                        if (this.props.resource === 'Transactions') {
                            for (let i = 0; i < data.length; i++) {
                                if (data[i]['pK'] > 0 && data[i]['mirrorRec'] === '0') {
                                    data[i]['mirrorRec'] = isMirrorRecord(data, i, baseData);
                                }
                            }
                        }

                        this.props.setChangesetRowsUpdated(true);
                        this.props.updateChangeSetPendingSave(true);

                        this.setState({
                            uploading: false,
                            exportButtonHidden: true,
                            bulkUploadRecordKeys,
                            bulkUploadValidations,
                            modalActive: sppVerifyModalActive,
                            bulkModal
                        });

                        // Modals allow user to confirm upload. Put data into holding array until confirmation.
                        // Otherwise, merge data now and show toast.
                        if (sppVerifyModalActive || bulkModal) { // Don't merge upload to state until user confirms validations
                            this.setState({
                                bulkUploadData: data,
                                originalBaseCaseDataStage: baseData,
                                bulkUploadErrorsStage: resp.data.gridData.errors,
                                uploadPending: true
                            });
                        } else { // No validations to confirm. Merge data now.
                            this.setState({
                                data,
                                edstGridInputKey: Date.now(),
                                originalBaseCaseData: baseData,
                                bulkUploadErrors: resp.data.gridData.errors,
                                uploadPending: false
                            });
                            toast.success('Successfully uploaded file ' + this.state.uploadFile.name);
                        }

                        if (bulkModal && !sppVerifyModalActive) { // Skipping SppVerifiedModal. Open BulkUploadValidationModal now.
                            this.setState({BulkVerifyActive: true});
                        }
                    })
                    .catch(resp => {
                        toast.error('Error retrieving original basecase data for changeset ' + this.props.changeset.number + `- ${resp.message}`);
                        this.setState({uploading: false});
                    });
            }).catch(resp => {
            toast.error(`Error uploading file ${this.state.uploadFile} - ${resp.message}`);
            this.setState({uploading: false});
        });
        this.setState({uploading: true})
    };

    inputClick = () => {
        document.getElementById('uploadInput')!.click();
    };

    toggleModal = () => {
        const {popupOpen} = this.state;
        if (popupOpen) { // toggle from open to closed
            // reset modal inputs when closing
            this.setState({popupOpen: false})

        } else { // toggle from closed to open
            this.setState({popupOpen: true});
        }
    };

    pop = () => {
        if (this.state.removeSelected) {
            this.toggleModal()
        } else this.saveChangeset();
    };
    
    saveChangeset = () => {
        if (this.state.data.length > 0 || this.state.rowsDeleted.length > 0) {
            this.setState({saving: true});

            if (this.state.rowsDeleted.length > 0) {
                let payload = {
                    changesetData: this.state.rowsDeleted,
                    changesetId: this.props.changeset.number,
                    tabNm: this.props.resource
                };
                axios.post('/api/auth/deleteChangesetData', payload
                ).then(resp => {
                    if (resp.data === 'Success') {
                        if (!this.props.rowsUpdated) {
                            toast.success(`Changeset was successfully updated`);
                            this.props.updateChangeSetPendingSave(false);
                            if (this.state.data.length > 0) {
                                this.setState({exportButtonHidden: false});
                            } else {
                                this.setState({exportButtonHidden: true});
                            }
                            this.setState({
                                saving: false,
                                savedSuccessfully: true,
                                rowsDeleted: [],
                                uploadFile: {},
                                rowsAddedNoPk: false
                            });
                        }
                        if (this.state.data.length > 0) {
                            this.saveChangesetData();
                        }
                    } else {
                        this.setState({saving: false, savedSuccessfully: false});
                        toast.error('Error encountered deleting rows from changeset. Could not save');
                    }
                }).catch(resp => {
                    toast.error(`Error deleting changeset row(s) - ${resp.message}`);
                    this.setState({saving: false, savedSuccessfully: false});
                    toast.error('Error encountered deleting rows from changeset. Could not save');
                });
            } else { // data.length > 0 and rowsDeleted.length === 0
                this.saveChangesetData();
            }
        } else {
            toast.error("No new records to save for Changeset.  Add records and retry saving.");
        }
    };

    // If changeset records have been deleted, that API call should go first. If the delete API fails, don't save the remaining records.
    // If no changeset records have been deleted, then run this anyway.
    saveChangesetData = () => {
        let data = [...this.state.data];
        let duplicateList = '';

        if (this.props.resource === 'BusDtl') {
            const {duplicateBusIdList, dedupe} = removeDuplicateBusRecords(data);
            data = [...dedupe];
            duplicateList = duplicateBusIdList;
        }

        if (!missingRequiredFields(this.state.metaData, data, this.props.resource)) {
            data.forEach(d => d.modBy = this.props.currentUser);

            this.setState({data});

            let payload = {
                changesetData: data,
                changesetId: this.props.changeset.number,
                tabNm: this.props.resource
            };
            axios.post('/api/auth/saveChangesetData', payload).then(resp => {
                this.props.incrementChangesetVersion();
                if (resp.data === 'Success') {
                    toast.success(`Changeset was saved successfully`);
                    if (duplicateList) {
                        toast.warn('Changeset records with Bus Numbers: ' + duplicateList
                            + ' cannot be added. They match record key of existing records. '
                            + 'Remove original records, then can add records with Bus Numbers');
                    }
                    if (this.props.resource === 'Transactions') {
                        toast.info('"SPP to SPP" transactions are limited to two decimal places '
                            + 'of precision. "SPP to non-SPP" transactions are limited to whole integers. '
                            + 'Values are rounded.');
                    }
                    this.props.updateChangeSetPendingSave(false);
                    this.props.setChangesetRowsUpdated(false);
                    if (this.state.data.length > 0) {
                        this.setState({exportButtonHidden: false});
                    } else {
                        this.setState({exportButtonHidden: true});
                    }
                    this.getData();
                } else {
                    toast.error(`Error saving changeset - ${resp.data}`);
                    this.setState({saving: false, savedSuccessfully: false})
                }
            }).catch(error => {
                toast.error(`Error saving changeset - ${error.message}`);
                this.setState({saving: false, savedSuccessfully: false});
                this.props.incrementChangesetVersion();
            });

            this.setState({saving: true});
        } else {
            toast.warn('Required fields must be filled in to save changeset.');
        }
    };

    removeRowsFromChangeset = () => {
        this.state.data.forEach((c: any) => {
            this.props.removeRowFromChangeset(c.pK);
        });
        this.props.rowsToAddToChangeset.forEach((r: any) => {
            this.props.removeRowFromChangeset(r.pK)
        })
    };

    actOnChangeset = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        let {stateChangeset} = this.state;
        let status = data.name;
        changesetClient.actOnChangeset(stateChangeset.number, status, this.props.currentTab)
            .then(() => {
                this.handleActOnChangeSet(event, data, status);
                if (status === 'ABANDONED' || status === 'REJECTED' || status === 'APPROVED') {
                    this.removeRowsFromChangeset();
                }
                if (status === 'APPROVED') {
                    this.props.incrementBasecaseVersion();
                }
                this.props.incrementChangesetVersion();
            }).catch(() => {
            this.handleActOnChangeSet(event, data, status);
        });
        this.setState({[`${status}loading`]: true} as any)
    };

    handleActOnChangeSet = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps, status: any) => {
        this.getChangesetByNumber()
            .then(() => {
                this.getData();
                this.closeModal(event, data);
                if (status === 'SUBMITTED') {
                    this.setState({submittedSuccessfully: true});
                } else {
                    this.setState({submittedSuccessfully: false});
                }
                this.setState({[`${status}loading`]: false, savedSuccessfully: false} as any);
            }).catch(err => {
            toast.error(err);
            this.setState({[`${status}loading`]: false} as any);
        });
    };

    getChangesetByNumber = () => {
        return new Promise((resolve, reject) => {
            changesetClient.getChangesetByNumber(this.state.stateChangeset.number)
                .then((data: any) => {
                    let changeset: Changeset = {
                        comments: data.comments,
                        status: data.disp,
                        name: data.nm,
                        number: data.id,
                        changeSetAuthorName: this.state.stateChangeset.changeSetAuthorName
                    };

                    this.props.updateCurrentChangeset(changeset);
                    this.setState({stateChangeset: changeset});
                    resolve(data);
                }).catch(() => {
                reject(new Error('Error updating status of changeset ' + this.state.stateChangeset.number));
            });
        });
    };

    // todo: Only RA used SPP Verified and RA doesn't use changesets anymore. SPP Verified code is now redundant.
    sppVerifiedActive = () => {
        return ((this.props.resource === 'GeneratorTestResults'
                || this.props.resource === 'Plants'
                || this.props.resource === 'PurchasesSales'
                || this.props.resource === 'ResourceOwnership'
                || this.props.resource === 'Resources')
            && this.props.sppStaff
            && (this.props.changeset.status === 'QUEUED' || this.props.changeset.status === 'WARN'));
    };


    disableSaveButton = () => {
        let respEntity = false;
        let responsibleEntity = 'respNm';
        for (let i = 0; i < this.state.data.length; i++) {
            for (let j = 0; j < this.state.entities.length; j++) {
                if (this.state.data[i][responsibleEntity] === this.state.entities[j]) {
                    respEntity = true;
                    // added when revamping transactions to get rid of mirror records. This allows confirming entities to
                    // save (acknowledge) changesets
                } else if (this.props.resource === 'Transactions' && this.state.data[i]['confNm'] === this.state.entities[j]) {
                    respEntity = true;
                }
            }
        }
        return !(
            (
                this.props.changeset.status === 'DRAFT'
                || (this.props.changeset.status === 'PENDING' && (this.props.sppStaff || respEntity)) 
                || this.sppVerifiedActive()
            )
            && this.hasAddModRemRole()
        );
    };

    disableSubmitButton = () => {
        let isData = false;
        if (this.state.data !== undefined && this.state.data.length > 0) {
            isData = true;
        }
        return !(this.props.changeset.status === 'DRAFT' && this.state.userInAuthorEntityGroup
            && this.state.savedSuccessfully && !this.props.rowsUpdated && this.state.rowsDeleted.length === 0 && isData);
    };

    disableRejectButton = () => {
        let {status} = this.props.changeset;
        return !(status === 'WARN' || status === 'ERROR' || status === 'QUEUED');
    };

    disableApproveButton = () => {
        let status = this.props.changeset.status;
        // WARN and QUEUED are the only changeset statuses allowed for APPROVAL stored procedure
        return !(status === 'WARN' || status === 'QUEUED')
    };

    disableReturnButton = () => {
        let {status} = this.props.changeset;
        let {roles, resource} = this.props;

        if (status === 'DRAFT' || status === 'APPROVED') {
            return true;
        }
        return !(includes(['PENDING', 'QUEUED', 'WARN', 'ERROR', 'SUBMITTED'], status) && (this.state.userInAuthorEntityGroup || some(roles, {
            tabNm: resource,
            sppStaff: true
        })));
    };

    getQueueButton = (lockout: boolean | undefined) => {
        let {resource, changeset} = this.props;
        let everythingAcknowledged = true, submitter = false;
        if (resource === 'Transactions') {
            if (this.state.data.length > 0) {
                if (!this.props.sppStaff) {
                    for (let i = 0; i < this.state.data.length; i++) {
                        if (this.state.data[i]['confAckInd'] === 'NO' || this.state.data[i]['respAckInd'] === 'NO') {
                            everythingAcknowledged = false;
                        }
                    }
                }

                if (this.state.userInAuthorEntityGroup) {
                    if (this.props.changeset.status === 'DRAFT' && this.props.resource === 'PurchasesSales') {
                        if (this.state.submittedSuccessfully && !everythingAcknowledged) {
                            submitter = true;
                            everythingAcknowledged = true;
                        } else {
                            submitter = true;
                        }
                    } else {
                        if (this.props.changeset.status === 'PENDING' && this.props.resource === 'PurchasesSales') {
                            if (this.props.rowsUpdated && !this.state.savedSuccessfully) {
                                submitter = false;
                            } else {
                                submitter = true;
                                everythingAcknowledged = true;
                            }
                        } else {
                            submitter = true;
                        }
                    }
                    if (this.state.submittedSuccessfully && !everythingAcknowledged
                        && this.props.changeset.status === 'DRAFT' && this.props.resource === 'PurchasesSales') {
                        submitter = true;
                        everythingAcknowledged = true;
                    } else {
                        submitter = true;
                    }
                }
            }
            return (
                <Button
                    name={'QUEUED'} size={'mini'} color={'black'}
                    onClick={this.actOnChangeset} loading={this.state.QUEUEDloading}
                    disabled={lockout || changeset.number === 0 || changeset.status !== 'PENDING'
                    || !everythingAcknowledged || !submitter}
                >
                    Queue Changeset
                </Button>
            );
        }
        return
    };

    getAbandonButton = (lockout: boolean | undefined) => {
        let {status} = this.state.stateChangeset;
        if (some(this.props.roles, {tabNm: this.props.resource, sppStaff: false}) 
            && this.state.userInAuthorEntityGroup 
            && !this.props.userRolesEntities.sppStaff) {
            return (
                <Modal
                    trigger={
                        <Button
                            name={'ABANDONED'} size={'mini'} color={'black'}
                            onClick={this.openModal}
                            disabled={lockout || status !== 'DRAFT'}
                        >
                            Abandon Changeset
                        </Button>
                    }
                    name={'ABANDONED'} closeIcon={true} centered={true} size={'tiny'}
                    open={this.state.abandonModalOpen} onClose={this.closeModal}>
                    <Header content={'Confirm Abandon of Changeset'}/>
                    <Modal.Content>
                        <p>Are you sure you want to Abandon the changeset?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button name={'ABANDONED'} color={'green'} onClick={this.actOnChangeset}>
                            <Icon name={'checkmark'}/> Yes
                        </Button>
                        <Button name={'ABANDONED'} color={'red'} onClick={this.closeModal}>
                            <Icon name={'remove'}/> No
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return;
    };

    openModal = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        if (data.name === 'ABANDONED') {
            this.setState({abandonModalOpen: true})
        }
    };

    closeModal = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLElement>, data: ButtonProps | ModalProps) => {
        if (data.name === 'ABANDONED') {
            this.setState({abandonModalOpen: false})
        }
    };

    refreshGrid = () => {
        if (this.state.stateChangeset.number !== 0 && this.state.data.length > 0) {
            this.setState({loading: true});
            this.getChangesetByNumber()
                .then(() => {
                    this.getData();
                    this.setState({loading: false, submittedSuccessfully: false});
                }).catch(err => {
                toast.error(err);
                this.setState({loading: false});
            });
        }
    };

    openSppVerifiedModal = () => {
        this.setState({
            modalActive: true,
            sppVerifyFromGrid: true
        })
    };
    
    openBulkUploadValidationModal = () => {
        this.setState({BulkVerifyActive: true})
    };

    closeBulkUploadValidationModal = () => {
        this.setState({BulkVerifyActive: false});
        if (this.state.uploadPending) {
            toast.error('Changeset records were not uploaded. Click Complete Pending Upload to continue changeset upload.');
        }
    };

    continueSppVerifiedModal = () => {
        this.setState({modalActive: false});
        
        if (this.state.uploadPending && this.state.bulkUploadData.length > 0) { // canceled SppVerified from Bulk Upload
            if (this.state.bulkModal) { // Other bulk upload validations exist. Continue to Bulk Upload Modal. 
                this.setState({BulkVerifyActive: true});
            } else { // No other bulk upload validations. Merge data from upload. API already set corrected SPP Verified value.
                this.mergeUploadToState();
            }
        } else { // canceled SppVerified from UI click for one row only
            let data = this.state.data.map((row: any, index: number) => {
                if (index === this.state.currentRowId) {
                    return {...row, sppVerified: 'NO'};
                } else {
                    return row;
                }
            });
            this.setState({data, edstGridInputKey: Date.now()});
        }
    };
    
    continueBulkUploadVerify = () => {
        this.setState({BulkVerifyActive: false});
        this.mergeUploadToState();
    };
    
    mergeUploadToState = () => {
        this.setState((state) => ({
            data: state.bulkUploadData,
            originalBaseCaseData: state.originalBaseCaseDataStage,
            bulkUploadErrors: state.bulkUploadErrorsStage,
            exportButtonHidden: true,
            edstGridInputKey: Date.now(),
            uploadPending: false,
            bulkUploadRecordKeys: [],
            bulkUploadData: [],
            originalBaseCaseDataStage: [],
            bulkUploadErrorsStage: {}
        }));
        toast.success('Successfully uploaded file ' + this.state.uploadFile.name);
    };

    cancelUpload = () => {
        let closeMessage = 'Upload canceled';
        if (this.state.sppVerifyFromGrid) {
            closeMessage = 'Edit canceled';
        }
        this.setState({
            modalActive: false,
            bulkUploadRecordKeys: [],
            bulkUploadData: [],
            bulkUploadErrorsStage: {},
            originalBaseCaseDataStage: [],
            bulkModal: false,
            sppVerifyFromGrid: false
        });
        toast.error(closeMessage);
    }

    updateRowId = (rowId: number) => {
        this.setState({currentRowId: rowId})
    }

// @ts-ignore
    handleResponseCheck = (item) => {
        if (!item) {
            this.setState({popupOpen: false});
        } else {
            this.saveChangeset();
        }
        this.setState({popupOpen: false});
    };

    public render() {
        let lockout = !this.props.sppStaff && this.props.modDevLockedOut;
        let {changeset} = this.props;
        let {saving, loading, uploadFile, uploading, stateChangeset} = this.state;
        
        return (
            <div> {this.state.popupOpen ? <PopupX popupOpenProp={this.state.popupOpen}
                                                  isResponseCheck={this.handleResponseCheck}/> : null}
                <Container fluid={true} style={{paddingLeft: '20px', paddingRight: '20px'}}>
                    <Grid padded={'vertically'}>
                        <Grid.Row>
                            <Grid.Column floated={'right'}>
                                <div>
                                    <Popup trigger={<Button
                                        disabled={this.state.stateChangeset.number === 0 || this.state.data.length === 0}
                                        size={'large'} floated={'right'} icon='refresh'
                                        onClick={() => this.refreshGrid()}/>} content='Refresh'/>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <ReactTableHOC
                        data={this.state.data}
                        previousData={this.state.originalBaseCaseData}
                        sppStaff={this.props.sppStaff}
                        roles={this.props.roles}
                        resource={this.props.resource}
                        columnMetaData={this.state.metaData}
                        isChangeSetAuthor={changeset.changeSetAuthorName !== undefined ? changeset.changeSetAuthorName.toUpperCase() === this.props.currentUser.toUpperCase() : false}
                        isAssignedToChangeSetAuthorEntity={this.state.userInAuthorEntityGroup}
                        errors={this.state.bulkUploadErrors}
                        filterable={true}
                        filtered={this.state.filtered}
                        entities={this.state.entities}
                        onFilterChanged={this.handleFilterChange}
                        sorted={this.state.sorted}
                        onSortedChange={sorted => this.setState({sorted})}
                        utilityColumn={this.getAddRecordColumn()}
                        tableStyle={{height: '500px'}}
                        defaultPageSize={10}
                        loading={loading}
                        inputKey={this.state.edstGridInputKey}
                        lockedOut={this.props.modDevLockedOut && !this.props.sppStaff}
                        rowHeight={60}
                        isChangeSet={true}
                        multiFilterSelection={true}
                        className={`-highlight ChangesetGrid ${(!this.state.data || !this.state.data.length) && 'nodata'}`}
                        tableClassName={'ChangesetGrid'}
                        onCellChange={this.handleChange}
                        changeSetStatus={stateChangeset.status}
                        defaultFilterMethod={GridUtil.filterMethod}
                        onFilteredChange={filtered => this.setState({filtered})}
                        updateModalActive={this.openSppVerifiedModal}
                        updateRowId={this.updateRowId}
                        getTheadProps={() => {
                            return {
                                style: {
                                    background: "black",
                                    color: "white",
                                    borderRadius: '5px'
                                }
                            };
                        }}
                        getTrProps={(state: any, rowInfo: any) => {
                            if (rowInfo && rowInfo.index === this.state.currentRow) {
                                return {
                                    style: {
                                        background: 'lightgrey'
                                    }
                                };

                            }
                            return {
                                style: {
                                    background: 'white'
                                }
                            };

                        }}
                        getTheadTrProps={(state: any) => ({style: {minWidth: state.rowMinWidth}})}
                    />
                    <Grid style={{paddingTop: '20px'}}>
                        <Grid.Row style={{paddingLeft: '15px'}}>
                            <Button
                                size={'mini'} color={'black'}
                                onClick={this.pop} loading={saving}
                                disabled={lockout || changeset.number === 0 || this.disableSaveButton()}
                            >
                                <Icon name={'save'}/>
                                Save Changeset
                            </Button>
                            <Button
                                name={'SUBMITTED'} size={'mini'} color={'black'}
                                onClick={this.actOnChangeset} loading={this.state.SUBMITTEDloading}
                                disabled={lockout || changeset.number === 0 || this.disableSubmitButton()}
                            >
                                <Icon name={'sign in'}/>
                                Submit Changeset
                            </Button>
                            <Button
                                name={'DRAFT'} size={'mini'} color={'black'}
                                onClick={this.actOnChangeset} loading={this.state.DRAFTloading}
                                disabled={lockout || changeset.number === 0 || this.disableReturnButton()}
                            >
                                <Icon name={'undo'}/>
                                Return to DRAFT
                            </Button>
                            {this.getQueueButton(lockout)}
                            {this.hasApproveRejectRole() ? (
                                <Button
                                    name={'APPROVED'} size={'mini'} color={'black'}
                                    onClick={this.actOnChangeset} loading={this.state.APPROVEDloading}
                                    disabled={lockout || changeset.number === 0 || this.disableApproveButton()}
                                >
                                    <Icon name={'thumbs up'}/>
                                    Approve Changeset
                                </Button>
                            ) : null}
                            {this.hasApproveRejectRole() ? (
                                <Button
                                    name={'REJECTED'} size={'mini'} color={'black'}
                                    onClick={this.actOnChangeset} loading={this.state.REJECTEDloading}
                                    disabled={lockout || changeset.number === 0 || this.disableRejectButton()}
                                >
                                    <Icon name={'thumbs down'}/>
                                    Reject Changeset
                                </Button>
                            ) : null}
                            {this.getAbandonButton(lockout)}
                        </Grid.Row>
                        <Grid.Row style={{paddingLeft: '15px'}}>
                            <Popup
                                header={'Pending changes on changeset'}
                                content={'Save changeset before exporting if you want pending changes to be reflected in exported file.'}
                                disabled={!this.props.changeSetPendingSave}
                                trigger={
                                    <Button
                                        size={'mini'} color={'black'} onClick={this.exportToExcel}
                                        loading={this.state.exporting}
                                        disabled={this.state.exportButtonHidden || changeset.number === 0}
                                    >
                                        <Icon name={"download"}/>
                                        Export Changeset To Excel
                                    </Button>
                                }
                            />
                            <Button
                                size={'mini'}
                                color={'black'}
                                onClick={this.inputClick}
                                disabled={
                                    !this.enableUpload(this.state.data)
                                }
                            >
                                <Icon name={"upload"}/>
                                {uploadFile!.name ? uploadFile!.name : 'Import Changeset Updates'}
                            </Button>
                            <input id={'uploadInput'} accept={'.xlsx'} type={'file'} color={'black'}
                                   style={{display: 'none'}} onChange={this.fileUploaded}/>
                            <Button
                                size={'mini'} color={'black'}
                                onClick={this.uploadFile} loading={uploading}
                                style={{visibility: uploadFile!.name ? 'visible' : 'hidden'}}
                            >
                                Upload
                            </Button>
                            {(this.state.bulkModal)
                                ?
                                <BulkUploadValidationMessage
                                    modalActive={this.state.BulkVerifyActive}
                                    openModal={this.openBulkUploadValidationModal}
                                    closeModal={this.closeBulkUploadValidationModal}
                                    validationErrors={this.state.bulkUploadValidations}
                                    confirmAction={this.continueBulkUploadVerify}
                                    uploadPending={this.state.uploadPending}
                                />
                                : null}
                        </Grid.Row>
                    </Grid>
                </Container>
                <SppVerifiedModal modalActive={this.state.modalActive}
                                  continueModal={this.continueSppVerifiedModal}
                                  cancelModal={this.cancelUpload}
                                  bulkUploadRecordKeys={this.state.bulkUploadRecordKeys}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        changeset: state.changeSetReducer.currentChangeset,
        rowsToAddToChangeset: state.changeSetReducer.rowsToAddToChangeset,
        currentUser: state.authReducer.currentUser,
        userEntities: state.authReducer.userEntities,
        roles: state.authReducer.roles,
        currentTab: state.defaultReducer.currentTab,
        changeSetPendingSave: state.changeSetReducer.changeSetPendingSave,
        rowsUpdated: state.changeSetReducer.changeSetRowUpdated,
        changeSetSubYearId: state.baseCaseReducer.changeSetSubYearId,
        changeSetAuthorIsMember: state.changeSetReducer.changeSetAuthorIsMember,
        userRolesEntities: state.authReducer.userRolesEntities
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeRowFromChangeset: (changesetId: number) => dispatch(
            {type: actionTypes.REMOVE_ROW_FROM_CHANGESET, payload: changesetId}),
        updateCurrentChangeset: (currentChangeset: Changeset) => dispatch(
            {type: actionTypes.UPDATE_CURRENT_CHANGESET, payload: currentChangeset}),
        updateChangeSetPendingSave: (pendingSave: boolean) => dispatch(
            {type: actionTypes.UPDATE_CHANGESET_PENDING_SAVE, payload: pendingSave}),
        setChangesetRowsUpdated: (rowUpdated: boolean) => dispatch(
            {type: actionTypes.SET_CHANGESET_ROW_UPDATED, payload: rowUpdated}),
        setUserInAuthorEntityGroup: (inEntityGroup: boolean) => dispatch(
            {type: actionTypes.SET_USER_IN_AUTHOR_ENTITY_GROUP, payload: inEntityGroup}),
        incrementChangesetVersion: () => dispatch(
            {type: actionTypes.INCREMENT_CHANGESET_VERSION}),
        incrementBasecaseVersion: () => dispatch(
            {type: actionTypes.INCREMENT_BASECASE_VERSION})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangesetGrid);