import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import {CategorySeasonData} from '../../../interfaces/category-interface';
import {Button, Modal} from 'semantic-ui-react';

interface RollOneSeasonButtonProps {
    getSeasons: () => void;
}

export const ChangeSeasonControl: React.FC<RollOneSeasonButtonProps> = ({getSeasons}) => {
    const [backDisabled, setBackDisabled] = useState<boolean>(true);
    const [forwardDisabled, setForwardDisabled] = useState<boolean>(true);
    
    const getCategorySeasons = useCallback(() => {
        axios.get('/api/auth/wrap/category-seasons')
            .then(resp => {
                const categorySeasonList: CategorySeasonData[] = resp.data.map((cs: CategorySeasonData) => ({
                    subyear: cs.subyear,
                    season: cs.season,
                    category: cs.category,
                    status: cs.status,
                    windowOpen: cs.windowOpen
                }));
                setBackDisabled(!categorySeasonList.some(s => s.status.toLowerCase() === 'archived'));
                setForwardDisabled(!categorySeasonList.some(s => s.status.toLowerCase() === 'rolling'));
            });
    }, []);

    useEffect(() => {
        getCategorySeasons();
    }, [getCategorySeasons]);
    
    const handleBackClick = () => {
        axios.post(`/api/auth/wrap/roll-back`)
            .then(() => {
                getSeasons();
                getCategorySeasons();
            })
            .catch(() => {
                toast.error(`Error: could not roll back.`);
            });
    };

    const handleForwardClick = () => {
        axios.post(`/api/auth/wrap/roll-forward`)
            .then(() => {
                getSeasons();
                getCategorySeasons();
            })
            .catch(() => {
                toast.error(`Error: could not roll forward.`);
            });
    };
    
    return (
        <div>
            <ChangeSeasonModal direction='Back' disabled={backDisabled} handleClick={handleBackClick}/>
            &nbsp;
            <ChangeSeasonModal direction='Forward' disabled={forwardDisabled} handleClick={handleForwardClick}/>
        </div>
    );
};

interface ChangeSeasonModalProps {
    direction: 'Back' | 'Forward';
    disabled: boolean;
    handleClick: () => void;
}

const ChangeSeasonModal: React.FC<ChangeSeasonModalProps> = ({direction, disabled, handleClick}) => {
    const [open, setOpen] = useState<boolean>(false);

    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);
    
    const confirmClick = () => {
        handleClick();
        closeModal();
    };

    return (
        <Modal
            trigger={<Button disabled={disabled} onClick={openModal}>Roll {direction} One Season</Button>}
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
        >
            <Modal.Header>Confirm Roll {direction}</Modal.Header>
            <Modal.Content>
                <p>
                    This will roll {direction.toLowerCase()} by one season so that&nbsp;
                    {direction === 'Back'
                        ? 'the Current season will become the Next season, the latest archived season will become the Current season ' +
                        'and what was the next season will be replaced with a full Rollover.'
                        : 'the Current season will change status to Archived (cannot be edited), the Next season will become the Current season ' +
                        'and the earliest unused rolled season will become the Next season.'
                    }
                </p>
                <p>
                    This functionality exists to allow staff users to rewind a rollover and try again. 
                    Active seasons will automatically unlock (open the window) after switching. Non-active seasons will automatically lock (close the window). 
                    Rolling one season back may be undone by rolling one season forward (not a full rollover). 
                    These actions only change the season status, and do not modify data in any way.
                </p>
                <p>
                    Data could become out of sync and cause unusual and difficult to fix behavior in either of these scenarios:
                </p>
                <ul>
                    <li>Rolling forward to restore stale unused data. For instance, at the beginning of the season, someone rolled forward one too many times, 
                        so you rolled back, but now it's the end of the season. This scenario requires a full Rollover, not just a season status change.</li>
                    <li>Rolling back to edit previously archived data. Data is archived for historical purposes. If the season was just archived, 
                        there's a brief window of acceptable adjustments. However, rolling back a long time later may result in confusion as more users 
                        have had time to collect copies of archived data. This also invites the chance of mistakenly performing a full Rollover and wiping 
                        out user changes made since the last intended Rollover.</li>
                </ul>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={closeModal}>Cancel</Button>
                <Button color='green' onClick={confirmClick}>Continue Roll {direction}</Button>
            </Modal.Actions>
        </Modal>
    );
};