import {GridCellProps} from '@progress/kendo-react-grid';
import * as React from 'react';
import {GridEditContext} from './context/GridEditContext';
import {Field, FieldRenderProps} from '@progress/kendo-react-form';
import {FORM_DATA_INDEX, requiredValidator} from './RowEditGrid';
import {Input} from '@progress/kendo-react-inputs';

const TextInputWithValidation = (fieldRenderProps: FieldRenderProps) => {
    const {validationMessage, visited, ...others} = fieldRenderProps;
    return (
        <div>
            <Input {...others} />
            {visited && validationMessage && (
                <div role="alert" className="k-form-error k-text-start">
                    {validationMessage}
                </div>
            )}
        </div>
    );
};

export const RequiredStringFormCell = (props: GridCellProps) => {
    const {editIndex} = React.useContext(GridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    return (
        <td>
            {isInEdit ? (
                <Field
                    component={TextInputWithValidation}
                    name={`${props.field}`}
                    validator={requiredValidator}
                />
            ) : (
                props.dataItem[props.field || ""]
            )}
        </td>
    );
};