import * as React from 'react';
import {Button, Icon, Modal, ModalProps} from "semantic-ui-react";
import axios from "axios";
import {connect} from "react-redux";

interface PostingDeleteModalProps extends ModalProps{
    postingId: number;
    post: any;
    handleDelete: () => void;
}

type OwnProps = PostingDeleteModalProps & ModalProps;

interface PropsFromState {
    sppStaff: boolean
}

type AllProps = OwnProps & PropsFromState;

interface PostingDeleteModalState {
    open: boolean,
}

class PostingDeleteModal extends React.Component<AllProps, PostingDeleteModalState> {
    constructor(props: AllProps, state: PostingDeleteModalState) {
        super(props, state);
        this.state = {
            open: false,
        };
    }

    toggleModal = () => {
        this.setState(prevState => ({open: !prevState.open}));
    };

    handleDelete = () => {
        axios.delete(`/api/auth/postings/${this.props.postingId}`)
            .then((resp) => {
                this.props.handleDelete();
            })
            .catch((resp) => {
                console.log('PostingDeleteModal - handleDelete failed:', resp.data);
            });
        this.toggleModal();
    };

    render() {
        return (
            <Modal
                size={'mini'}
                open={this.state.open}
                closeOnDimmerClick={true}
                closeIcon={true}
                onClose={this.toggleModal}
                trigger={
                    <Button floated={'left'} size={'mini'} color={'black'}
                            onClick={this.toggleModal}>
                        <Icon name='delete'/>
                        Delete
                    </Button>
                }
            >
                <Modal.Header>Are you sure you want to delete this post?</Modal.Header>
                <Modal.Content>
                    <p>{this.props.post.posting}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleDelete} content={'Yes'}/>
                    <Button onClick={this.toggleModal} content={'No'}/>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        sppStaff: state.authReducer.userRolesEntities.sppStaff
    }
};

export default connect<PropsFromState, {}, OwnProps>(mapStateToProps, {})(PostingDeleteModal);