import {Container} from 'semantic-ui-react';
import {connect} from 'react-redux';
import ActionPanel from '../common/action-panel/ActionPanel';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {ActionPanelButton} from '../common/action-panel/ActionPanelButton';
import {useCategorySeason} from '../../hooks/useCategorySeason';
import {PeakDemandGrid} from "./PeakDemandGrid";

const PeakDemandStaffGrid = () => {
    const {subyear, subyearList, season, seasonList, seasonActive, handleSubyear, handleSeason} = useCategorySeason();

    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
            <ActionPanel title={'Select Submittal Year and Season'}>
                <ActionPanelButton>
                    <DropDownList
                        data={subyearList}
                        value={subyear}
                        onChange={handleSubyear}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>
                <ActionPanelButton>
                    <DropDownList
                        data={seasonList}
                        value={season}
                        onChange={handleSeason}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>
            </ActionPanel>
            <br/>
            <PeakDemandGrid
                readOnly={false}
                subyear={subyear}
                season={season}
                seasonOpen={seasonActive}
            />
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(PeakDemandStaffGrid);