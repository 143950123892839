import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {SubMenu} from './SubMenu';
import {DataMenuProps} from '../../interfaces/menu-interface';
import {getViewPage} from '../../services/user-service';
import axios from 'axios';
import {downloadFile} from '../../services/file-service';
import {toast} from 'react-toastify';

const ResAdqMenu: React.FC<DataMenuProps> = ({roles, updateActiveMenuItem}) => {
    const downloadContactList = () => {
        axios.post('/api/auth/referenceDocument/downloadRefDoc',
            null,
            { params : {filename: 'RA Contact List'}, responseType: 'arraybuffer'})
            .then(resp => {
                downloadFile(resp.data, 'RA Contact List.xlsx');
            })
            .catch((error) => {
                let errorMessage = `Error retrieving File from server - ${error.message}`;
                console.log(errorMessage, error);
                toast.error(errorMessage);
            });
    };

    return (
        <Dropdown item={true} text='Resource Adequacy' closeOnChange={true}>
            <Dropdown.Menu>
                {getViewPage('CapacityAdjustments', roles) &&
                    <SubMenu menuName='capacityAdjustments' menuDisplayName='Capacity Adjustments' menuLink='/CapacityAdjustments'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('DeliverabilityStudyResults', roles) &&
                    <SubMenu menuName='deliverabilityStudyResults' menuDisplayName='Deliverability Study Results' menuLink='/DeliverabilityStudyResults'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('DemandAndEnergy', roles) &&
                    <SubMenu menuName='demandAndEnergy' menuDisplayName='Demand And Energy' menuLink='/DemandAndEnergy'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('GeneratorTestResults', roles) &&
                    <SubMenu menuName='generatorTestResults' menuDisplayName='Generator Test Results' menuLink='/GeneratorTestResults'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Plants', roles) &&
                    <SubMenu menuName='plants' menuDisplayName='Plants' menuLink='/Plants'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('PurchasesSales', roles) &&
                    <SubMenu menuName='purchasesSales' menuDisplayName='Purchases & Sales' menuLink='/PurchasesSales'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ResourceAdequacyRequirement', roles) &&
                    <SubMenu menuName='resourceAdequacyRequirement' menuDisplayName='Resource Adequacy Requirement' menuLink='/ResourceAdequacyRequirement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ResourceOwnership', roles) &&
                    <SubMenu menuName='resourceOwnership' menuDisplayName='Resource Ownership' menuLink='/ResourceOwnership'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Resources', roles) &&
                    <SubMenu menuName='resources' menuDisplayName='Resources' menuLink='/Resources'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('TenYearForecastOverview', roles) &&
                    <SubMenu menuName='tenYearForecastOverview' menuDisplayName='Ten Year Forecast Overview' menuLink='/TenYearForecastOverview'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                <Dropdown.Item onClick={downloadContactList} docname={'RA Contact List'}>Contact List</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});

export default connect(mapStateToProps)(ResAdqMenu);