import React from 'react';
import {Accordion, Icon} from 'semantic-ui-react';

interface AccordionPanelProps {
    title: string;
    index: number;
    activeIndex: number;
    onClick: (index: number) => void;
    children: React.ReactNode;
}

/**
 * Use AccordionPanel as one of multiple children of Accordion.
 * @param title - Title of Accordion section
 * @param index - Order of this section in parent Accordion
 * @param activeIndex - Only one index may be active at once. Manage this value in parent component. 
 * To have no active sections, set to an index that is not in use such as -1.
 * @param onClick - Takes the index of this section and passes it to the parent component to be the new activeIndex.
 * @param children - Component that makes the content of this Accordion section.
 * @constructor
 */
 
export const AccordionPanel: React.FC<AccordionPanelProps> = ({title, index, activeIndex, onClick, children}) => {
    const handleClick = React.useCallback(() => {
        onClick(index);
    }, [index, onClick]);

    return (
        <>
            <Accordion.Title
                active={activeIndex === index}
                index={index}
                onClick={handleClick}
            >
                <Icon name='dropdown'/>
                {title}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
                {children}
            </Accordion.Content>
        </>
    );
};