import {RowEditGrid} from '../../common/row-edit-grid/RowEditGrid';
import {GridColumn} from '@progress/kendo-react-grid';
import {COMMAND_CELL_WIDTH, CommandCell} from '../../common/row-edit-grid/CommandCell';
import {RequiredStringFormCell} from '../../common/row-edit-grid/RequiredStringFormCell';

export const TransactionTypeFormGrid = () => (
    <div style={{maxWidth: '1042px'}}>
        <RowEditGrid
            dataApi={'/api/auth/wrap/data/transaction-type'}
            uniqueFields={[{field: 'nm', title: 'Name'}, {field: 'visualName', title: 'Visual Name'}]}
        >
            <GridColumn title='Command' cell={CommandCell} width={COMMAND_CELL_WIDTH}/>
            <GridColumn title='Id' field='id' editable={false} width={70}/>
            <GridColumn
                title='System Name'
                field='nm'
                cell={(props) => {
                    const isEditAllowed =  props.dataItem.id < 0;

                    if (isEditAllowed) {
                        return <RequiredStringFormCell {...props} />;
                    } else {
                        return <td>{props.dataItem.nm}</td>;
                    }
                }}
                width={400}
            />
            <GridColumn title='Visual Name' field='visualName' cell={RequiredStringFormCell} width={400}/>
        </RowEditGrid>
    </div>
);