import {ColumnMeta, DataUpdate, UiValidations} from '../interfaces/grid-interfaces';
import {State} from '@progress/kendo-data-query';
import {toast} from 'react-toastify';
import {addDataErrorReplaceOld, removeDataErrorByMessage, removeDataErrorsByField} from './data-grid-service';
import React from 'react';

export const getInitialDataState = (keyField: string): State => {
    const pageSize = Number(localStorage.getItem(`globalPageSize`) || 10);

    return ({
        sort: [{field: keyField, dir: 'asc'}],
        skip: 0,
        take: pageSize
    });
};

export const distinctNumberArray = (values: number[]) => (Array.from(new Set([...values])));

export const getMonthFieldsWithHeadings = (subyear: number) => ([
    {name: 'june', heading: `June ${subyear}`},
    {name: 'july', heading: `July ${subyear}`},
    {name: 'august', heading: `August ${subyear}`},
    {name: 'september', heading: `September ${subyear}`},
    {name: 'november', heading: `November ${subyear}`},
    {name: 'december', heading: `December ${subyear}`},
    {name: 'january', heading: `January ${subyear + 1}`},
    {name: 'february', heading: `February ${subyear + 1}`},
    {name: 'march', heading: `March ${subyear + 1}`}
]);

export const updateEditedRows = <T extends Record<string, any>>(editedRows: Array<DataUpdate>, data: T, keyField: string, field: string): Array<DataUpdate> => {
    const editedList = editedRows.map(e => ({
        id: e.id,
        fields: [...e.fields]
    }));

    const edited = editedList.find(e => e.id === data[keyField]);
    if (!edited) {
        return ([...editedList, {id: data[keyField], fields: [field]}]);
    }

    let editedFields = edited.fields;
    if (data.original && data.original[field] === data[field]) {
        editedFields = edited.fields.filter(f => f !== field);
    } else if (!editedFields.includes(field)) {
        editedFields.push(field);
    }

    edited.fields = editedFields;

    return editedList;
};

export const updateEditedVerified = <T extends Record<string, any>>(editedVerified: Array<number>, data: T, keyField: string): Array<number> => {
    let edited = [...editedVerified];
    if (data.original && data.verified === data.original.verified) {
        edited = edited.filter(rowKey => rowKey !== data[keyField]);
    } else {
        edited.push(data[keyField]);
    }
    return edited;
};

export const numberBoundValidationOnEdit = <T extends Record<string, any>>(item: T, field: string, heading: string, keyField: string,
                                                                           setDataErrors: React.Dispatch<React.SetStateAction<UiValidations[]>>,
                                                                           maxValue: number, minValue: number) => {
    const valueTooLarge = `${field} value is too large to save.`;
    const valueTooSmall = `${field} value is too small to save.`;

    if (toast.isActive('tooSmall' + field + item.id)) {
        toast.dismiss('tooSmall' + field + item.id);
    }
    if (toast.isActive('tooLarge' + field + item.id)) {
        toast.dismiss('tooLarge' + field + item.id);
    }

    const value = typeof item[field] === 'number' ? item[field] : 0;
    const id = item[keyField];

    if (value > maxValue) {
        toast.error(`Value too large: ${value}. Maximum is ${maxValue}.`, {toastId: 'tooLarge' + field + id});
        setDataErrors(prevState => addDataErrorReplaceOld(id, field, heading, String(value), valueTooLarge, true, prevState));
        setDataErrors(prevState => removeDataErrorByMessage(id, field, prevState, valueTooSmall));
    } else if (value < minValue) {
        toast.error(`Value too small: ${value}. Minimum is ${minValue}.`, {toastId: 'tooSmall' + field + id});
        setDataErrors(prevState => addDataErrorReplaceOld(id, field, heading, String(value), valueTooSmall, true, prevState));
        setDataErrors(prevState => removeDataErrorByMessage(id, field, prevState, valueTooLarge));
    } else {
        setDataErrors(prevState => removeDataErrorsByField(id, field, prevState));
    }
}

export const getFieldColumnMeta = (field: string, columnMeta: ColumnMeta[]) => {
    // Because find may not have a result, include default result where vnm is empty instead of columnMeta being undefined
    return columnMeta.find(c => c.nm === field) || {nm: field, vnm: ''};
}