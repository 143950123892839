import {round} from "lodash";

export const fieldsToArray = (d: any, seasonName: string, isPercent: boolean) => {
    const percentModifier = isPercent ? 100 : 1;
    const arr: any[] = [
        round(d.month1 * percentModifier,3),
        round(d.month2 * percentModifier,3),
        round(d.month3 * percentModifier,3),
        round(d.month4 * percentModifier,3)
    ];
    if (seasonName === 'Winter') {
        arr.push(round(d.month5 * percentModifier,3));
    }
    return arr;
};

export const isParticipantEmpty = (participant: string) => {
    return !participant || participant === 'Select Participant';
};