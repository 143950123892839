import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import AddPanel from '../common/action-panel/AddPanel';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import RemovePanel from "../common/action-panel/RemovePanel";
import RemoveModal from "../common/action-panel/RemoveModal";
import {getRemoveCell} from '../common/grid/RemoveCell';
import ExportPanel from "../common/action-panel/ExportPanel";
import {toast} from "react-toastify";
import axios from 'axios';
import {
    ContractData,
    ContractEditData,
} from "../../interfaces/wrap/contract-interface";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from "../../interfaces/grid-interfaces";
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal, formatLocalDate,
    initialDataState, numberBoundValidationOnEdit,
    parseLocalDateString,
    processColumnMeta, processDropDownListOptions, removeDataErrorByMessage, removeDataErrorByValue,
    replaceSpaceWithUnderscore, resetOption, resetYesNoOption, toastPreSaveValidationErrors,
    toastSuccessfulSave,
    updateEditedRows,
    updateEditedVerified
} from "../../services/data-grid-service";
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent} from "@progress/kendo-react-grid";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridPageChangeEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import format from "date-fns/format";
import {process} from "@progress/kendo-data-query";
import TooltipContainer from "../common/grid/TooltipContainer";
import {CustomLocalizationProvider, getVerifiedCell} from "../common/grid/VerifiedCell";
import {DropDownOption, getDropDownCell} from "../common/grid/DropDownCell";
import {getDecimalCell} from "../common/grid/DecimalCell";
import {InputCell} from "../common/grid/InputCell";
import ImportPanel from "../common/action-panel/ImportPanel";
import {MonthYearDateCell} from "../common/grid/MonthYearDateCell";
import ValidatePanel from "../common/action-panel/ValidatePanel";
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import {getDocumentMapCell} from '../common/grid/DocumentMapCell';
import {getResourceSpecificCell} from "../common/grid/ResourceSpecificCell";
import {YesNoDropDownCell} from "../common/grid/TextDropDownCell";

const transactionTypeNmEmpty = 'Transaction Type Name must have a value to save the record.';
const sellingParticipantNmEmpty = 'Selling Entity Name must have a value to save the record.';
const purchasingParticipantNmEmpty = 'Purchasing Entity Name must have a value to save the record.';
const facilityNmEmpty = 'Facility Name must have value to save record.';
const performanceRiskEmpty = 'Performance or Outage Risk Assumed by Purchaser must have value if Transaction Type is "System" or "Resource Specific".';
const contingencyReserveEmpty = 'Contingency Reserve Obligation Assumed by Purchaser must have value if Transaction Type is "System" or "Resource Specific".';
const startDtEmpty = 'Start Date must have value to save record.';
const endDtEmpty = 'End Date must have value to save record.';
const monthlyValuesExceedContractedCapacity = 'Transaction Capacity value(s) exceed Contracted Capacity.';

interface ContractGridProps extends PageRole {
    title: string,
    dataApi: string,
    season: 'Summer' | 'Winter',
    subyear: number
}

const ContractGrid: React.FC<ContractGridProps> = ({title, dataApi, season, subyear, isStaff, readRole, addRole, modifyRole, removeRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<ContractEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<ContractData>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [subYear, setSubYear] = useState<number>(0);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [transactionOptions, setTransactionOptions] = useState<Array<DropDownOption>>([]);
    const [participantOptions, setParticipantOptions] = useState<Array<DropDownOption>>([]);
    const [facilityNmOptions, setFacilityNmOptions] = useState<Array<DropDownOption>>([]);
    const [wrapFootprintRefOptions, setWrapFootprintRefOptions] = useState<Array<DropDownOption>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const [dataState, setDataState] = useState<any>({
        ...initialDataState,
        take: globalPageSize ? +globalPageSize : 10,
        pageSize: globalPageSize ? +globalPageSize : 10
    })

    // columns from api
    useEffect(() => {
        axios.get(`${dataApi}/columns`)
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    //summer subyear from api
    useEffect(() => {
        axios.get('/api/auth/wrap/subyear/summer')
            .then((resp) => {
                setSubYear(resp.data);
            })
    }, []);

    //winter subyear from api
    useEffect(() => {
        axios.get('/api/auth/wrap/subyear/winter')
            .then((resp) => {
                setSubYear(resp.data);
            })
    }, []);

    //transaction types dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/contract/dropdown/transaction')
            .then((resp) => {
                setTransactionOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //selling participant names dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/all-participants')
            .then((resp) => {
                setParticipantOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //facility name dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/facility/dropdown/facility-names')
            .then((resp) => {
                setFacilityNmOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const populateWrapFootprintRefOptions = useCallback(() => {
        const rawWrapFootprintRefValues = ['External', 'Internal'];
        const wrapFootprintRefOptions = rawWrapFootprintRefValues.map((value) => ({
            text: value,
            value: value,
        }));
        setWrapFootprintRefOptions(wrapFootprintRefOptions);
    }, []);

    useEffect(() => {
        populateWrapFootprintRefOptions();
    }, [populateWrapFootprintRefOptions]);


    // summer and winter data from api
    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(`${dataApi}`), axios.get('/api/auth/wrap/season-window/summer'), axios.get('/api/auth/wrap/season-window/winter')])
            .then((resp) => {
                const localData = resp[0].data.map((d: any) => ({
                    ...d,
                    startDate: parseLocalDateString(d.startDate),
                    endDate: parseLocalDateString(d.endDate)
                }));
                setData(deepCopyGridDataWithOriginal(localData));
                setOriginalData(deepCopyGridData(localData));
                setTempSequence(-10000);
                if (!isStaff) {
                    let seasonsOpen = (season === 'Summer' && resp[1].data)
                        || (season === 'Winter' && resp[2].data);
                    setSeasonOpen(seasonsOpen);
                    if (resp[1].data === false && season === 'Summer') {
                        toast.info('WRAP FS submittal window is closed for the Summer season');
                    }
                    if (resp[2].data === false && season === 'Winter') {
                        toast.info('WRAP FS submittal window is closed for the Winter season');
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, isStaff, readRole, season]);

    useEffect(() => {
        getData();
    }, [getData]);

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`${dataApi}/validate`)
            .then(response => {
                setValidationErrors(response.data);
                getData();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = (id: number, field: string, value: any) => {
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;

                // Clear any validation messages caused by missing required values.
                if (value && (['transactionTypeNm', 'sellingParticipantNm', 'purchasingParticipantNm', 'startDate', 'endDate'].includes(field))) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if (field === 'transactionTypeNm' && !['System', 'Resource Specific'].includes(value)) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'performanceRisk', prevDataErrors, ''));
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'contingencyReserve', prevDataErrors, ''));
                }
                if (value && ['performanceRisk', 'contingencyReserve'].includes(field)) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if (field === 'transactionTypeNm' && value !== 'Resource Specific') {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'facilityNm', prevDataErrors, ''));
                }
                if (value && field === 'facilityNm') {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'facilityNm', prevDataErrors, ''));
                }
                if (['june', 'july', 'august', 'september', 'november', 'december', 'january', 'february', 'march', 'contractedCapacity'].includes(field)) {
                    validateContractedCapacity(dataItem);
                    if (value || value === 0) {
                        numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 100000, 0);
                    }
                }
                
                return dataItem;
            } else {
                return item;
            }
        });

        setData(localData);

        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify && field === 'verified') {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
    };
    
    const validateContractedCapacity = (item: ContractEditData) => {
        const june = item.june ? item.june : 0;
        const july = item.july ? item.july : 0;
        const august = item.august ? item.august : 0;
        const september = item.september ? item.september : 0;
        const november = item.november ? item.november : 0;
        const december = item.december ? item.december : 0;
        const january = item.january ? item.january : 0;
        const february = item.february ? item.february : 0;
        const march = item.march ? item.march : 0;
        const cap = item.contractedCapacity ? item.contractedCapacity : 0;
        const isInvalid = (season === 'Summer' && (june > cap || july > cap || august > cap || september > cap))
            || (season === 'Winter' && (november > cap || december > cap || january > cap || february > cap || march > cap));
        let localErrors = [...dataErrors];
        if (isInvalid) {
            localErrors = addDataError(item.id, 'contractedCapacity', 'Contracted Capacity', cap.toString(),
                monthlyValuesExceedContractedCapacity, true, localErrors);
        } else {
            localErrors = removeDataErrorByMessage(item.id, 'contractedCapacity', localErrors, monthlyValuesExceedContractedCapacity);
        }
        setDataErrors(localErrors);
    };

    //refresh data
    const refreshData = () => {
        getData();
    };

    // clear all the filters
    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const generateDefaultRow = (sequence: number) => ({
        id: sequence,
        subYearId: subYear,
        verified: false,
        contractIdentifier: '',
        transactionTypeNm: '',
        sellingParticipantNm: '',
        purchasingParticipantNm: '',
        internal: '',
        facilityNm: '',
        performanceRisk: '',
        contingencyReserve: '',
        startDate: null,
        endDate: null,
        pointOfReceipt: '',
        contractedCapacity: 0,
        comments: '',
        lastUserModBy: '',
        lastUserModDt: null,
        verifiedBy: '',
        verifiedDt: null,
        inEdit: true,
        ...(season === 'Summer' ? {
                june: 0,
                july: 0,
                august: 0,
                september: 0
            } : season === 'Winter' ? {
                november: 0,
                december: 0,
                january: 0,
                february: 0,
                march: 0
            } : {}
        ),
    });

    //adding a new row
    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: ContractEditData = generateDefaultRow(sequence++);

        defaultNewRow.originalData = {...defaultNewRow};

        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    //Edit mode
    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
        resetRemove();
    };

    // Remove mode
    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

     const confirmRemove = () => {
        axios.delete(`${dataApi}/delete`, {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));
                const localDataErrors = dataErrors.filter(item => !editedRemoved.includes(item.id));

                toast.success("Successfully deleted data");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    setDataErrors(localDataErrors);
                    resetRemove();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const filterEmptyRequiredColumns = (): boolean => {
        const missingRequired = data.filter(item =>
            !item.transactionTypeNm
            || !item.sellingParticipantNm
            || !item.purchasingParticipantNm
            || !item.startDate
            || !item.endDate
            || (item.transactionTypeNm === 'Resource Specific' && !item.facilityNm)
            || (['System', 'Resource Specific'].includes(item.transactionTypeNm) && (!item.performanceRisk || !item.contingencyReserve))
        );

        if (missingRequired.length === 0) {
            return false;
        }

        toast.error('There are columns missing required data. Please provide value to save changes.');

        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {
            if (!item.transactionTypeNm) {
                localErrors = addDataError(item.id, 'transactionTypeNm', 'Transaction Type Name', '', transactionTypeNmEmpty, true, localErrors);
            }
            if (!item.sellingParticipantNm) {
                localErrors = addDataError(item.id, 'sellingParticipantNm', 'Selling Entity Name', '', sellingParticipantNmEmpty, true, localErrors);
            }
            if (!item.purchasingParticipantNm) {
                localErrors = addDataError(item.id, 'purchasingParticipantNm', 'Purchasing Entity Name', '', purchasingParticipantNmEmpty, true, localErrors);
            }
            if (!item.startDate) {
                localErrors = addDataError(item.id, 'startDate', 'Start Date', '', startDtEmpty, true, localErrors);
            }
            if (!item.endDate) {
                localErrors = addDataError(item.id, 'endDate', 'End Date', '', endDtEmpty, true, localErrors);
            }
            if (item.transactionTypeNm === 'Resource Specific' && !item.facilityNm) {
                localErrors = addDataError(item.id, 'facilityNm', 'Facility Name', '', facilityNmEmpty, true, localErrors);
            }
            if (['System', 'Resource Specific'].includes(item.transactionTypeNm)) {
                if (!item.performanceRisk) {
                    localErrors = addDataError(item.id, 'performanceRisk', 'Performance Risk', '', performanceRiskEmpty, true, localErrors);
                }
                if (!item.contingencyReserve) {
                    localErrors = addDataError(item.id, 'contingencyReserve', 'Contingency Reserve', '', contingencyReserveEmpty, true, localErrors);
                }
            }
        });
        setDataErrors(localErrors);
        return true;
    };

    const save = () => {
        if (filterEmptyRequiredColumns()) {
            return;
        }
        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));

        axios.post(`${dataApi}/save`, newAndUpdatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //verify mode
    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post(`${dataApi}/verify`, updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleVerifySelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    const handleUpload = (uploadedData: Array<ContractEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            const upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                const item = {
                    ...u,
                    id: sequence,
                    subYearId: subYear,
                    contractIdentifier: '',
                    verified: false,
                    transactionTypeNm: resetOption(u.transactionTypeNm, transactionOptions, ''),
                    sellingParticipantNm: resetOption(u.sellingParticipantNm, participantOptions, ''),
                    purchasingParticipantNm: resetOption(u.purchasingParticipantNm, participantOptions, ''),
                    internal: resetOption(u.internal, wrapFootprintRefOptions, ''),
                    facilityNm: resetOption(u.facilityNm, facilityNmOptions, ''),
                    performanceRisk: resetYesNoOption(u.performanceRisk, ''),
                    contingencyReserve: resetYesNoOption(u.contingencyReserve, ''),
                    startDate: parseLocalDateString(u.startDate, true),
                    endDate: parseLocalDateString(u.endDate, true),
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true,
                    originalData: generateDefaultRow(sequence)
                };
                sequence = sequence + 1;
                return item;
            });

        const dataUploaded: Array<ContractEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u);
        });

        setData(dataUploaded);
        setTempSequence(sequence);
    };

    const processUploadEdits = (upload: ContractEditData, item: ContractEditData) => {
        let sellingParticipantNm = resetOption(upload.sellingParticipantNm, participantOptions, item.sellingParticipantNm);
        if (sellingParticipantNm?.toLowerCase() !== item.sellingParticipantNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'sellingParticipantNm', sellingParticipantNm));
        }
        let purchasingParticipantNm = resetOption(upload.purchasingParticipantNm, participantOptions, item.purchasingParticipantNm);
        if (purchasingParticipantNm?.toLowerCase() !== item.purchasingParticipantNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'purchasingParticipantNm', purchasingParticipantNm));
        }
        let transactionTypeNm = resetOption(upload.transactionTypeNm, transactionOptions, item.transactionTypeNm);
        if (transactionTypeNm?.toLowerCase() !== item.transactionTypeNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'transactionTypeNm', transactionTypeNm));
        }
        let internal = resetOption(upload.internal, wrapFootprintRefOptions, item.internal);
        if (internal?.toLowerCase() !== item.internal.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'internal', internal));
        }
        let facilityNm = !upload.facilityNm && !!item.facilityNm ? '' : resetOption(upload.facilityNm, facilityNmOptions, item.facilityNm);
        if (facilityNm?.toLowerCase() !== item.facilityNm?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'facilityNm', facilityNm));
        }
        let performanceRisk = resetYesNoOption(upload.performanceRisk, item.performanceRisk);
        if (performanceRisk?.toLowerCase() !== item.performanceRisk?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'performanceRisk', performanceRisk));
        }
        let contingencyReserve = resetYesNoOption(upload.contingencyReserve, item.contingencyReserve);
        if (contingencyReserve?.toLowerCase() !== item.contingencyReserve?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'contingencyReserve', contingencyReserve));
        }
        let startDate = !upload.startDate ? item.startDate : parseLocalDateString(upload.startDate, true)
        if (formatLocalDate(startDate) !== formatLocalDate(item.startDate)) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'startDate', upload.startDate, 'date'));
        }
        let endDate = !upload.endDate ? item.endDate : parseLocalDateString(upload.endDate, true)
        if (formatLocalDate(endDate) !== formatLocalDate(item.endDate)) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'endDate', upload.endDate, 'date'));
        }
        if (upload.pointOfReceipt !== item.pointOfReceipt) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'pointOfReceipt', upload.pointOfReceipt));
        }
        if (upload.contractedCapacity !== item.contractedCapacity) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'contractedCapacity', upload.contractedCapacity));
        }
        if (upload.comments !== item.comments) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'comments', upload.comments));
        }

        const fieldsToUpdate = ['june', 'july', 'august', 'september', 'november', 'december', 'january', 'february', 'march'];

        fieldsToUpdate.forEach(field => {
            if (upload[field] !== item[field]) {
                numberBoundValidationOnEdit(upload[field], field, setDataErrors, item, columnMeta, 100000, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            validateContractedCapacity(upload);
        });

        const processedItem = {
            ...item,
            transactionTypeNm,
            sellingParticipantNm,
            purchasingParticipantNm,
            internal,
            facilityNm,
            performanceRisk: upload.performanceRisk,
            contingencyReserve: upload.contingencyReserve,
            startDate,
            endDate,
            pointOfReceipt: upload.pointOfReceipt,
            contractedCapacity: upload.contractedCapacity,
            comments: upload.comments
        };

        if (season === 'Summer') {
            processedItem.june = upload.june ? upload.june : 0;
            processedItem.july = upload.july ? upload.july : 0;
            processedItem.august = upload.august ? upload.august : 0;
            processedItem.september = upload.september ? upload.september : 0;
        }
        if (season === 'Winter') {
            processedItem.november = upload.november ? upload.november : 0;
            processedItem.december = upload.december ? upload.december : 0;
            processedItem.january = upload.january ? upload.january : 0;
            processedItem.february = upload.february ? upload.february : 0;
            processedItem.march = upload.march ? upload.march : 0;
        }

        return processedItem;
    };

    //export mode
    const formatExport = (data: Array<ContractData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO'
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    //page size
    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };

    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);
    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const TransactionCell = useMemo(()=> getDropDownCell(transactionOptions, 'Transaction Options'), [transactionOptions]);
    const ParticipantCell = useMemo(() => getDropDownCell(participantOptions, 'Participant Name'), [participantOptions]);
    const ResourceSpecificCell = getResourceSpecificCell(facilityNmOptions,'Facility Name','transactionTypeNm');
    const WrapFootprintRefOptionsCell = useMemo(() => getDropDownCell(wrapFootprintRefOptions, 'Wrap Footprint Ref'), [wrapFootprintRefOptions]);
    const DecimalCell = useMemo(() => getDecimalCell(1, true, 100000, 0), []);
    const documentMapCell = getDocumentMapCell(subyear, season, 'Contract');

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/upload`} setUploadRecords={handleUpload} />}
                {!inEdit && modifyRole && seasonOpen && isStaff && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleVerifySelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && !inVerify && modifyRole && seasonOpen && <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                    <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                        <Grid
                            data={process(data, dataState)}
                            dataItemKey='id'
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            style={{ height: "510px" }}
                            {...dataState}
                            editField='inEdit'
                            onDataStateChange={dataStateChange}
                            onItemChange={itemChange}
                            pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                            onPageChange={pageChange}
                        >
                            {inEdit && inRemove &&
                                <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                            {
                                columnMeta.length
                                    ? columnMeta.map((col: ColumnMeta, index) => {
                                        if (col.nm === 'id') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' editable={false}/>
                                        } else if (col.nm === 'verified') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px'  cell={VerifiedCell} filter={"boolean"}/>
                                        } else if (col.nm === 'contractIdentifier') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        } else if (col.nm === 'transactionTypeNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={TransactionCell}/>
                                        } else if (col.nm === 'sellingParticipantNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={ParticipantCell}/>
                                        } else if (col.nm === 'purchasingParticipantNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={ParticipantCell}/>
                                        } else if (col.nm === 'internal') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={WrapFootprintRefOptionsCell}/>
                                        } else if (col.nm === 'facilityNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='250px' cell={ResourceSpecificCell}/>
                                        } else if (col.nm === 'performanceRisk') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='360px' cell={YesNoDropDownCell}/>
                                        } else if (col.nm === 'contingencyReserve') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='380px' cell={YesNoDropDownCell}/>
                                        } else if (col.nm === 'startDate') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={MonthYearDateCell} filter={"date"}/>
                                        } else if (col.nm === 'endDate') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={MonthYearDateCell} filter={"date"}/>
                                        } else if (col.nm === 'pointOfReceipt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={InputCell}/>
                                        } else if (col.nm === 'contractedCapacity') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={DecimalCell}/>
                                        } else if (season === 'Summer' && col.nm === 'june') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (season === 'Summer' && col.nm === 'july') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (season === 'Summer' && col.nm === 'august') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (season === 'Summer' && col.nm === 'september') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (season === 'Winter' && col.nm === 'november') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (season === 'Winter' && col.nm === 'december') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (season === 'Winter' && col.nm === 'january') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (season === 'Winter' && col.nm === 'february') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (season === 'Winter' && col.nm === 'march') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={DecimalCell}/>
                                        } else if (col.nm === 'comments') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={InputCell}/>
                                        } else if (col.nm === 'lastUserModBy' || col.nm === 'verifiedBy') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' editable={false}/>
                                        } else if (col.nm === 'lastUserModDt' || col.nm === 'verifiedDt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' editable={false}/>
                                        } else {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                        }
                                    })
                                    : <GridColumn/>
                            }
                            <GridColumn title='Linked Documents' width='200px' cell={documentMapCell}/>
                        </Grid>
                    </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'Contract'}/>}
        </div>
    );
};

export default ContractGrid;