import {Grid, GridCellProps, GridColumn} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import React, {useState} from 'react';
import {Modal, ModalActions, ModalContent} from 'semantic-ui-react';
import {DocumentMap} from '../../../interfaces/wrap/wrap-document-interface';
import {Loader} from '@progress/kendo-react-indicators';
import axios from 'axios';
import {toast} from 'react-toastify';

export const RecordMapCell = (props: GridCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem} = props;
    const {id, subYearId, seasonNm} = dataItem;
    
    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {id > 0 && <RecordMapModal subyear={subYearId} season={seasonNm} id={id}/>}
        </td>
    );
};

interface RecordMapModalProps {
    subyear: number;
    season: string;
    id: number;
}

const RecordMapModal: React.FC<RecordMapModalProps> = ({ subyear,season, id}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [recordMap, setRecordMap] = useState<Array<DocumentMap>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const openModal = () => {
        setLoading(true);
        const params = new URLSearchParams();
        params.set('subyear', subyear.toString());
        params.set('season', season);
        params.set('id', id.toString());
        axios.get('/api/auth/wrap/document/mapped-records', {params})
            .then(resp => {
                setRecordMap(resp.data.map(processDocumentMapList));
            })
            .then(() => {
                setOpen(true);
            })
            .catch(() => {
                toast.error('Error: Unable to load linked documents.');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    const processDocumentMapList = (d: DocumentMap) => ({
        id: d.id,
        subYearId: d.subYearId,
        season: d.season,
        dataSet: d.dataSet,
        recordKey: d.recordKey,
        documentId: d.documentId,
        documentSubYearId: d.documentSubYearId,
        documentSeason: d.documentSeason,
        documentParticipantNm: d.documentParticipantNm,
        documentName: d.documentName,
        filename: d.filename,
        editAccess: d.editAccess
    });
    
    const closeModal = () => {
        setOpen(false);
        setRecordMap([]);
    };
    
    return (
        <Modal
            open={open}
            onClose={closeModal}
            closeIcon={true}
            size='fullscreen'
            trigger={
                <Button onClick={openModal} themeColor='primary'>
                    Linked Records
                    {loading && <Loader size="small" type='pulsing' themeColor='light'/>}
                </Button>
            }
        >
            <Modal.Header>Records Associated with Document</Modal.Header>
            <ModalContent>
                <Grid
                    data={recordMap}
                    filterable={true}
                    sortable={true}
                    resizable={true}
                    style={{ maxHeight: "510px" }}
                >
                    <GridColumn title='Submittal Year' field='subYearId' width='120px'/>
                    <GridColumn title='Season' field='season' width='120px'/>
                    <GridColumn title='Data Set' field='dataSet' width='300px'/>
                    <GridColumn title='Record Key' field='recordKey' width='100px'/>
                </Grid>
            </ModalContent>
            <ModalActions>
                <Button themeColor='secondary' onClick={closeModal}>
                    Close
                </Button>
            </ModalActions>
        </Modal>
    );
};