import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Grid,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent,
    GridPageChangeEvent
} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {DataUpdate} from '../../interfaces/grid-interfaces';
import axios from 'axios';
import {
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState, replaceSpaceWithUnderscore, resetYesNoOption,
    toastSuccessfulSave,
    updateEditedRows
} from '../../services/data-grid-service';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import RefreshPanel from '../common/action-panel/RefreshPanel';
import AddPanel from '../common/action-panel/AddPanel';
import RemovePanel from '../common/action-panel/RemovePanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import {getTextDropDownCell, YesNoDropDownCell} from '../common/grid/TextDropDownCell';
import TooltipContainer from '../common/grid/TooltipContainer';
import {getRemoveCell} from '../common/grid/RemoveCell';
import {
    QccSeasonData, QccSeasonEditData
} from "../../interfaces/wrap/subregion-interface";
import {getDecimalCell} from "../common/grid/DecimalCell";
import {getPercentCell} from "../common/grid/PercentCell";
import {ChildDropDownOption, getChildDropDownCell} from "../common/grid/ChildDropDownCell";
import ClearFilterPanel from '../common/action-panel/ClearFilterPanel';
import {toast} from "react-toastify";
import ExportPanel from "../common/action-panel/ExportPanel";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import format from "date-fns/format";
import ImportPanel from '../common/action-panel/ImportPanel';

interface QccProps {
    readOnly: boolean;
    subyear: number;
    season: string;
    seasonOpen: boolean;
}

export const QccSeasonGrid: React.FC<QccProps> = ({readOnly, subyear, season, seasonOpen}) => {
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const initialPageSize = globalPageSize ? +globalPageSize : 10;

    const dataApi = '/api/auth/wrap/data/qcc/season';

    const [data, setData] = useState<QccSeasonEditData[]>([]);
    const [originalData, setOriginalData] = useState<QccSeasonData[]>([]);
    const [dataState, setDataState] = useState<any>({...initialDataState, take: initialPageSize});
    const [loading, setLoading] = useState<boolean>(false);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [participantList, setParticipantList] = useState<Array<string>>([]);
    const [facilityParticipantList, setFacilityParticipantList] = React.useState<ChildDropDownOption[]>([]);
    const [participantFacilityResourceList, setParticipantFacilityResourceList] = useState<any[]>([]);

    const getSeasonFacilityParticipantOptions = (seasonNm: string) => {
        axios.get(`/api/auth/wrap/resource/dropdown/facility-participant-resource/${seasonNm}`)
            .then(resp => {
                const facilitySet = new Set();
                const facilityParticipant = resp.data.reduce((acc: any[], curr: any) => {
                    if (!facilitySet.has(curr.facilityNm)) {
                        facilitySet.add(curr.facilityNm);
                        acc.push({
                            text: curr.facilityNm,
                            value: curr.facilityNm,
                            parentValue: curr.participantNm,
                            resourceId: curr.resourceId
                        });
                    }
                    return acc;
                }, []);
                setFacilityParticipantList(facilityParticipant);
                setParticipantList(Array.from(new Set(resp.data.map((d: any) => d.participantNm))));
                setParticipantFacilityResourceList(resp.data);
            })
            .catch(error => {
                console.error('Error fetching facility-participant options:', error);
            });
    };

    //facility names dropdown
    useEffect(() => {
        if (season && seasonOpen) {
            getSeasonFacilityParticipantOptions(season);
        }
    }, [season, seasonOpen]);

    const getSelectedData = useCallback((subYearId: number, season: string) => {
        const params = new URLSearchParams();
        params.set('subYearId', subYearId.toString());
        params.set('season', season);
        setLoading(true);
        axios.get(dataApi, {params})
            .then(resp => {
                setData(deepCopyGridDataWithOriginal(resp.data));
                setOriginalData(deepCopyGridData(resp.data));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const getData = useCallback(() => {
        if (!subyear || !season) {
            return;
        }
        getSelectedData(subyear, season);
        getSeasonFacilityParticipantOptions(season);
    }, [getSelectedData, season, subyear]);

    useEffect(() => {
        setInEdit(false);
        setEditedRows([]);
        setEditedRemoved([]);
        setInRemove(false);
        getData();
    }, [getData]);

    const refreshData = () => {
        getData();
        getSeasonFacilityParticipantOptions(season);
    }

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const handleChange = (event: GridItemChangeEvent) => {
        const {dataItem, field, value} = event;
        const {id} = dataItem;

        if (!field || !id) {
            return;
        }

        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;

                if (field === 'participantNm') {
                    dataItem.facilityNm = '';
                    dataItem.resourceId = null;
                }

                // Check if there are multiple resourceId values found
                // Select the resourceId that is not already in data
                // Pop up a toast error that the Facility Resource record already exists
                if (field === 'facilityNm') {
                    const resourceIds = participantFacilityResourceList
                        .filter((item) => item.facilityNm === dataItem.facilityNm && item.participantNm === dataItem.participantNm)
                        .map((item) => item.resourceId);

                    if (resourceIds.length > 1) {
                        const uniqueResourceId = resourceIds.find((resourceId) =>
                            !data.some((record) => record.resourceId === resourceId)
                        );

                        if (uniqueResourceId) {
                            dataItem.resourceId = uniqueResourceId;
                        } else {
                            toast.error("The Facility Resource record already exists in the grid.");
                        }
                    } else if (resourceIds.length === 1) {
                        dataItem.resourceId = resourceIds[0];
                    }
                }

                if (field === 'efof' || field === 'maxCapacity') {
                    const qcc = (1 - dataItem.efof) * dataItem.maxCapacity;
                    dataItem.qcc = Number(qcc.toFixed(1));
                    dataItem.qccAdjusted = dataItem.insufficientOutageData === 'YES' ? Number((qcc * 0.7).toFixed(1)) : Number(qcc.toFixed(1));
                }
                if (field === 'insufficientOutageData') {
                    dataItem.qccAdjusted = dataItem.insufficientOutageData === 'YES' ? Number((dataItem.qcc * 0.7).toFixed(1)) : Number(dataItem.qcc.toFixed(1));
                }

                return dataItem;
            } else {
                return item;
            }
        });

        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };

    const generateDefaultRow = (sequence: number) => ({
        id: sequence,
        subYearId: subyear,
        seasonNm: season,
        participantNm: '',
        facilityNm: '',
        resourceId: null,
        primeMoverNm: '',
        energySourceNm: '',
        fuelTypeNm: '',
        maxCapacity: 0.0,
        efof: 0.0,
        qcc: 0.0,
        qccAdjusted: 0.0,
        insufficientOutageData: '',
        inEdit: true
    });

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: QccSeasonEditData = generateDefaultRow(sequence++);

        defaultNewRow.originalData = {...defaultNewRow};

        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const save = () => {
        // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        axios.post(dataApi, newAndUpdatedData)
            .then(() => {
                getData();
                toastSuccessfulSave();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch(() => {
                toast.error('Error occurred. Save failed.');
            });
    };
    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    };

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
    };

    const handleUpload = (uploadedData: Array<QccSeasonEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            let upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                const participantNm = participantList.includes(u.participantNm) ? u.participantNm : '';
                const facilityNm = participantFacilityResourceList.some(item => item.participantNm === participantNm && item.facilityNm === u.facilityNm)
                    ? u.facilityNm
                    : '';
                const resourceId = participantFacilityResourceList.some(item => item.participantNm === participantNm && item.facilityNm === facilityNm && item.resourceId === u.resourceId)
                    ? u.resourceId
                    : participantFacilityResourceList.find(item => item.participantNm === participantNm && item.facilityNm === facilityNm)?.resourceId;
                const maxCapacity = u.maxCapacity ? u.maxCapacity : 0;
                const efof = u.efof ? u.efof : 0;
                const qcc = (1 - efof) * maxCapacity;
                const insufficientOutageData = resetYesNoOption(u.insufficientOutageData, '');

                const item = {
                    ...u,
                    id: sequence,
                    subYearId: subyear,
                    seasonNm: season,
                    participantNm,
                    facilityNm,
                    resourceId,
                    maxCapacity,
                    efof,
                    qcc,
                    qccAdjusted: insufficientOutageData === 'YES' ? qcc * 0.7 : qcc,
                    insufficientOutageData,
                    inEdit: true,
                    originalData: generateDefaultRow(sequence)
                };
                sequence = sequence + 1;
                return item;
            });

        const dataUploaded: Array<QccSeasonEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u as QccSeasonEditData);
        });

        setData(dataUploaded);
        setTempSequence(sequence);
    };

    const processUploadEdits = (upload: QccSeasonEditData, item: QccSeasonEditData) => {
        const participantNm = participantList.includes(upload.participantNm) ? upload.participantNm : item.participantNm;
        if (participantNm?.toLowerCase() !== item.participantNm.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'participantNm', participantNm));
        }

        const facilityNm = participantFacilityResourceList.some(item => item.participantNm === participantNm && item.facilityNm === upload.facilityNm)
            ? upload.facilityNm
            : '';
        if (facilityNm.toLowerCase() !== item.facilityNm.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'facilityNm', upload.facilityNm));
        }

        const resourceId = participantFacilityResourceList.some(item => item.participantNm === participantNm && item.facilityNm === facilityNm && item.resourceId === upload.resourceId)
            ? upload.resourceId
            : null;
        if (resourceId !== item.resourceId) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceId', upload.resourceId));
        }

        const insufficientOutageData = resetYesNoOption(upload.insufficientOutageData, item.insufficientOutageData);
        if (insufficientOutageData?.toLowerCase() !== item.insufficientOutageData?.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'insufficientOutageData', insufficientOutageData));
        }

        const maxCapacity = upload.maxCapacity ? upload.maxCapacity : 0;
        if (maxCapacity !== item.maxCapacity) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'maxCapacity', maxCapacity));
        }

        const efof = upload.efof ? upload.efof : 0;
        if (efof !== item.efof) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'efof', efof));
        }

        const qcc = (1 - efof) * maxCapacity;

        return {
            ...item,
            participantNm,
            facilityNm,
            resourceId,
            maxCapacity,
            efof,
            qcc,
            qccAdjusted: insufficientOutageData === 'YES' ? qcc * 0.7 : qcc,
            insufficientOutageData
        };
    };

    const openRemoveModal = () => setRemoveModalOpen(true);
    const closeRemoveModal = () => setRemoveModalOpen(false);

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            // initialize 'removed' field to false on all data objects
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        // take 'removed' field off data objects
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const confirmRemove = () => {
        axios.delete(dataApi, {data: editedRemoved})
            .then(() => {
                setRemoveModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));
                if (localEdit.length !== editedRows.length) {
                    setEditedRows(localEdit);
                }

                toast.info("successfully deleted item");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    resetRemove();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const formatExport = (data: Array<QccSeasonData>) => {
        return data.map(item => ({
            ...item,
            efof: item.efof === null ? null : item.efof * 100,
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const ParticipantDropDownCell = useMemo(() => getTextDropDownCell(participantList, true), [participantList]);
    const DecimalCell = useMemo(() => getDecimalCell(1, true, 999999.999, 0), []);
    const FacilityNmCell = getChildDropDownCell(facilityParticipantList, 'facilityNm', 'participantNm');
    const EfofPercentCell = useMemo(() => getPercentCell(1, true), []);

    return (
        <div>
            <ActionPanel title={'Seasonal QCC Results'}>
                {!inRemove && inEdit && <AddPanel addRecord={addNewRow}/>}
                {inEdit && <RemovePanel inRemove={inRemove} openModal={openRemoveModal} toggleRemove={toggleRemove}/>}
                {!readOnly && !inRemove && seasonOpen && <SavePanel inEdit={inEdit} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/import/${season}`} setUploadRecords={handleUpload} />}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && <RefreshPanel disabled={inEdit} loading={loading} getData={refreshData}/>}
                {!inEdit && <ExportPanel exportData={excelExport} disabled={inEdit}/>}
            </ActionPanel>
            <RemoveModal open={removeModalOpen} closeModal={closeRemoveModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${'Seasonal QCC Results'}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        onItemChange={handleChange}
                        editField='inEdit'
                        {...dataState}
                        onDataStateChange={dataStateChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                        sortable={true}
                        filterable={true}
                        resizable={true}
                    >
                        {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                        <GridColumn title='Record Key' field='id' width='150px' editable={false}/>
                        <GridColumn title='Participant' field='participantNm' cell={ParticipantDropDownCell}/>
                        <GridColumn title='Resource Id' field='resourceId' editable={false}/>
                        <GridColumn title='Facility' field='facilityNm' cell={FacilityNmCell}/>
                        {readOnly && [
                            {title: 'Prime Mover', field: 'primeMoverNm', width: '190px'},
                            {title: 'Energy Source', field: 'energySourceNm', width: '190px'},
                            {title: 'Fuel Type', field: 'fuelTypeNm', width: '190px'}
                        ].map((column, index) => (
                            <GridColumn key={index} title={column.title} field={column.field} width={column.width} editable={false}/>
                        ))}
                        <GridColumn title={`${season} Max Capacity`} field='maxCapacity' cell={DecimalCell}/>
                        <GridColumn title={`${season} EFOF %`} field='efof' cell={EfofPercentCell}/>
                        {!readOnly && <GridColumn title={`${season} QCC`} field='qcc' editable={false}/>}
                        <GridColumn title={`${season} QCC${readOnly ? '' : ' Adjusted'}`} field='qccAdjusted' editable={false}/>
                        <GridColumn title='Insufficient Outage Data' field='insufficientOutageData' cell={YesNoDropDownCell}/>
                    </Grid>
                </ExcelExport>
            </TooltipContainer>
        </div>
    );
};