import {GridCellProps} from '@progress/kendo-react-grid';
import {DropDownList, DropDownListFilterChangeEvent} from '@progress/kendo-react-dropdowns';
import {isDifferent} from '../../../services/data-grid-service';
import {useState} from 'react';
import {CompositeFilterDescriptor, filterBy, FilterDescriptor} from '@progress/kendo-data-query';

interface TextDropDownCellProps extends GridCellProps {
    options: Array<string>;
    filterable?: boolean;
    emptyIsString?: boolean;
}

export const TextDropDownCell = (props: TextDropDownCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const value: string = field && dataItem[field] ? dataItem[field] : '';
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    
    const [data, setData] = useState<Array<string>>(props.options.slice());
    
    const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        const dataOptions = props.options.slice();
        return filterBy(dataOptions, filter);
    };
    
    const filterChange = (event: DropDownListFilterChangeEvent) => {
        setData(filterData(event.filter));
    };
    
    const onChange = (event: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value === '' 
                    ? props.emptyIsString ? '' : null
                    : event.target.value
            });
        }
    };

    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit
                ? <DropDownList
                    onChange={onChange}
                    data={data}
                    value={value}
                    filterable={props.filterable}
                    onFilterChange={props.filterable ? filterChange : undefined}
                    title={isChanged
                        ? originalValue === null
                            ? 'null'
                            : originalValue
                        : undefined}
                />
                : value
            }
        </td>
    );
};

export const getTextDropDownCell = (options: Array<string>, filterable?: boolean, emptyIsString?: boolean) => {
    return (props: GridCellProps) => (
        <TextDropDownCell {...props} options={options} filterable={!!filterable} emptyIsString={!!emptyIsString}/>
    );
};

export const YesNoDropDownCell = (props: GridCellProps) => (
    <TextDropDownCell {...props} options={['YES','NO']} filterable={false}/>
);