import {useContext, useState} from 'react';
import {Field, FieldRenderProps} from '@progress/kendo-react-form';
import {GridCellProps} from '@progress/kendo-react-grid';
import {MultiSelect, MultiSelectChangeEvent} from '@progress/kendo-react-dropdowns';
import {isArray} from 'lodash';
import {GridEditContext} from './context/GridEditContext';
import {FacilityLinkingOptionContext, FacilityParticipant} from './context/FacilityLinkingOptionContext';
import {FORM_DATA_INDEX} from './RowEditGrid';

const facilityLinkingQuantityValidator = (values: string[]) => isArray(values) && values.length >= 2 ? '' : 'At least two facilities are required.';

const MultiSelectWithValidation = (fieldRenderProps: FieldRenderProps) => {
    const {validationMessage, visited, ...others} = fieldRenderProps;
    return (
        <div>
            <MultiSelect {...others} />
            {visited && validationMessage && (
                <div role="alert" className="k-form-error k-text-start">
                    {validationMessage}
                </div>
            )}
        </div>
    );
};

export const FacilityLinkingOptionFormCell = (props: GridCellProps) => {
    const {editIndex} = useContext(GridEditContext);
    const {facilityOptions, setFacilityOptions, facilityParticipantList} = useContext(FacilityLinkingOptionContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const {dataItem, field} = props;
    const initialSelected = field && isArray(dataItem[field]) ? dataItem[field] : [];
    const [selected, setSelected] = useState<string[]>(initialSelected);

    // https://www.telerik.com/kendo-react-ui/components/dropdowns/multiselect/binding/#toc-datasets-of-objects
    // When not in edit mode, create comma separated list of display names

    const handleChange = (event: MultiSelectChangeEvent) => {
        setSelected(event.target.value);
        if (props.onChange && editIndex) {
            props.onChange({
                dataIndex: editIndex,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value
            });
        }
    };

    const handleOpen = () => {
        if (dataItem.participantNm) {
            setFacilityOptions(Array.from(new Set(facilityParticipantList
                .filter(f => f.participantNm === dataItem.participantNm)
                .map((f: FacilityParticipant) => f.facilityNm))));
        }
    };

    return (
        <td>
            {isInEdit ? (
                <Field
                    name={`${props.field}`}
                    component={MultiSelectWithValidation}
                    data={facilityOptions}
                    value={selected}
                    onChange={handleChange}
                    onOpen={handleOpen}
                    validator={facilityLinkingQuantityValidator}
                />
            ) : (
                selected.join(', ')
            )}
        </td>
    );
};