import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import {AccordionWrapper} from '../common/grid/AccordionWrapper';
import FacilityGrid from './FacilityGrid';
import FacilityApprovalGrid from "./FacilityApprovalGrid";
import FacilityLinkingGrid from './FacilityLinkingGrid';
import {useCategorySeason} from '../../hooks/useCategorySeason';

const FacilityPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);
    const {subyear, season} = useCategorySeason();

    useEffect(() => {
        setPageRole(getCompressedPageRole('Facility', roles));
    }, [roles]);

    return (
        <Container fluid style={{paddingLeft: 20, paddingRight: 20, paddingTop: 50}}>
            <Header size="large">Facilities</Header>
            <AccordionWrapper accordionHeader={'Facility Approval'}>
                <FacilityApprovalGrid
                    {...pageRole}
                    title={'Facility Approval'}
                    dataApi={'/api/auth/wrap/facility'}
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Facility'}>
                <FacilityGrid
                    {...pageRole}
                    title={'Facility'}
                    dataApi={'/api/auth/wrap/facility'}
                    subyear={subyear}
                    season={season}
                />
            </AccordionWrapper>
            <AccordionWrapper accordionHeader='Facility Linking'>
                <FacilityLinkingGrid
                    {...pageRole}
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});

export default connect(mapStateToProps)(FacilityPage);