import {useState} from 'react';
import {Accordion, Header} from 'semantic-ui-react';
import {AccordionPanel} from '../../common/AccordionPanel';
import {PrimeMoverFormGrid} from './PrimeMoverFormGrid';
import {EnergySourceFormGrid} from './EnergySourceFormGrid';
import {DocumentTypeFormGrid} from './DocumentTypeFormGrid';
import {FuelTypeFormGrid} from './FuelTypeFormGrid';
import {BalancingAuthorityFormGrid} from './BalancingAuthorityFormGrid';
import {TransactionTypeFormGrid} from './TransactionTypeFormGrid';
import {SubregionFormGrid} from './SubregionFormGrid';
import {NercPriorityFormGrid} from './NercPriorityFormGrid';
import {ServiceTypeFormGrid} from './ServiceTypeFormGrid';
import {ResourceTypeFormGrid} from './ResourceTypeFormGrid';

const AdminDataDropdownTab = () => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const handleClick = (index: number) => {
        setActiveIndex(activeIndex === index ? -1 : index);
    };

    return (
        <>
            <Header size={'medium'}>Dropdown Options</Header>
            <Accordion>
                <AccordionPanel title='Prime Mover' index={0} activeIndex={activeIndex} onClick={handleClick}>
                    <PrimeMoverFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'Energy Source'} index={1} activeIndex={activeIndex} onClick={handleClick}>
                    <EnergySourceFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'Fuel Type'} index={2} activeIndex={activeIndex} onClick={handleClick}>
                    <FuelTypeFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'Balancing Authority'} index={3} activeIndex={activeIndex} onClick={handleClick}>
                    <BalancingAuthorityFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'Transaction Type'} index={4} activeIndex={activeIndex} onClick={handleClick}>
                    <TransactionTypeFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'Document Type'} index={5} activeIndex={activeIndex} onClick={handleClick}>
                    <DocumentTypeFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'Subregion'} index={6} activeIndex={activeIndex} onClick={handleClick}>
                    <SubregionFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'NERC Priority'} index={7} activeIndex={activeIndex} onClick={handleClick}>
                    <NercPriorityFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'Service Type'} index={8} activeIndex={activeIndex} onClick={handleClick}>
                    <ServiceTypeFormGrid/>
                </AccordionPanel>
                <AccordionPanel title={'Resource Type'} index={9} activeIndex={activeIndex} onClick={handleClick}>
                    <ResourceTypeFormGrid/>
                </AccordionPanel>
            </Accordion>
        </>
    );
};

export default AdminDataDropdownTab;