import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ParticipantPrmData} from "../../interfaces/wrap/subregion-interface";
import axios from "axios";
import {
    deepCopyGridDataWithOriginal,
    initialDataState, replaceSpaceWithUnderscore
} from "../../services/data-grid-service";
import {
    Grid,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent,
    GridPageChangeEvent
} from "@progress/kendo-react-grid";
import {getTextDropDownCell} from "../common/grid/TextDropDownCell";
import ActionPanel from "../common/action-panel/ActionPanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import {process} from "@progress/kendo-data-query";
import TooltipContainer from "../common/grid/TooltipContainer";
import {getPercentCell} from "../common/grid/PercentCell";
import ClearFilterPanel from '../common/action-panel/ClearFilterPanel';
import {ExcelExport} from "@progress/kendo-react-excel-export";
import ExportPanel from "../common/action-panel/ExportPanel";
import format from "date-fns/format";

interface ParticipantPrmGridProps {
    subyear: number;
    season: string;
}

export const ParticipantPrmGrid: React.FC<ParticipantPrmGridProps> = ({subyear, season}) => {
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const initialPageSize = globalPageSize ? +globalPageSize : 10;

    const [data, setData] = useState<ParticipantPrmData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [participantList, setParticipantList] = useState<Array<string>>([]);
    const [dataState, setDataState] = useState<any>({...initialDataState, take: initialPageSize});

    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/all-participants')
            .then(resp => {
                setParticipantList(resp.data);
            })
    }, []);

    const getSelectedData = useCallback((subYearId: number, season: string) => {
        const params = new URLSearchParams();
        params.set('subYearId', subYearId.toString());
        params.set('season', season);
        setLoading(true);
        axios.get('/api/auth/wrap/subregion/participant/prm', {params})
            .then(resp => {
                setData(deepCopyGridDataWithOriginal(resp.data));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const getData = useCallback(() => {
        if (!subyear || !season) {
            return;
        }
        getSelectedData(subyear, season);
    }, [getSelectedData, season, subyear]);

    useEffect(() => {
        getData();
    }, [getData]);

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const handleChange = (event: GridItemChangeEvent) => {
        const {dataItem, field, value} = event;
        const {id} = dataItem;
        if (!field || !id) {
            return;
        }
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    const formatExport = (data: Array<ParticipantPrmData>) => {
        console.log(data);
        return data.map(item => ({
            ...item,
            month1: item.month1 === null ? null : item.month1 * 100,
            month2: item.month1 === null ? null : item.month2 * 100,
            month3: item.month1 === null ? null : item.month3 * 100,
            month4: item.month1 === null ? null : item.month4 * 100,
            month5: item.month1 === null ? null : item.month5 * 100,
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    const ParticipantDropDownCell = useMemo(() => getTextDropDownCell(participantList, true), [participantList]);
    const PrmPercentCell = useMemo(() => getPercentCell(1, true), []);

    return (
        <div>
            <ActionPanel title={'PRM Results'}>
                <ClearFilterPanel clearFilter={clearFilters}/>
                {<RefreshPanel disabled={false} loading={loading} getData={getData}/>}
                {<ExportPanel exportData={excelExport} disabled={false}/>}
            </ActionPanel>
            <TooltipContainer>
                <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${'PRM Results'}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        onItemChange={handleChange}
                        editField='inEdit'
                        {...dataState}
                        onDataStateChange={dataStateChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                        sortable={true}
                        filterable={true}
                        resizable={true}
                    >
                        <GridColumn title='Participant' field='participantNm' cell={ParticipantDropDownCell}/>
                        <GridColumn title={season === 'Summer' ? `June ${subyear}` : `November ${subyear}`} field='month1' cell={PrmPercentCell}/>
                        <GridColumn title={season === 'Summer' ? `July ${subyear}` : `December ${subyear}`} field='month2' cell={PrmPercentCell}/>
                        <GridColumn title={season === 'Summer' ? `August ${subyear}` : `January ${subyear + 1}`} field='month3' cell={PrmPercentCell}/>
                        <GridColumn title={season === 'Summer' ? `September ${subyear}` : `February ${subyear + 1}`} field='month4' cell={PrmPercentCell}/>
                        {season === 'Winter' && <GridColumn title={`March ${subyear + 1}`} field='month5' cell={PrmPercentCell}/>}
                    </Grid>
                </ExcelExport>
            </TooltipContainer>
        </div>
    );
};















