import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import { AccordionWrapper } from '../common/grid/AccordionWrapper';
import WrapDocumentGrid from "./WrapDocumentGrid";
import ActionPanel from '../common/action-panel/ActionPanel';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {ActionPanelButton} from '../common/action-panel/ActionPanelButton';
import {useCategorySeason} from '../../hooks/useCategorySeason';


const WrapDocumentPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);
    const {subyear, subyearList, season, seasonList, seasonOpen, seasonActive, handleSubyear, handleSeason} = useCategorySeason();

    useEffect(() => {
        setPageRole(getCompressedPageRole('WrapDocument', roles));
    }, [roles]);

    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 50 }}>
            <Header size={'large'}>Forms and Attestation Documents</Header>
            <ActionPanel title={'Select Submittal Year and Season'}>
                <ActionPanelButton>
                    <DropDownList
                        data={subyearList}
                        value={subyear}
                        onChange={handleSubyear}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>
                <ActionPanelButton>
                    <DropDownList
                        data={seasonList}
                        value={season}
                        onChange={handleSeason}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>
            </ActionPanel>
            <br/>
            <AccordionWrapper accordionHeader={'Participant Owned Documents'}>
                <WrapDocumentGrid
                    {...pageRole}
                    title='Participant Owned Documents'
                    dataApi='/api/auth/wrap/document'
                    subyear={subyear}
                    season={season}
                    modifyRole={pageRole.modifyRole && (seasonOpen || (pageRole.isStaff && seasonActive))}
                />
            </AccordionWrapper>
            <br/>
            {!pageRole.isStaff &&
                <AccordionWrapper accordionHeader={'Accessible Read-Only Documents'}>
                    <WrapDocumentGrid
                        title='Accessible Read-Only Documents'
                        dataApi='/api/auth/wrap/document/read-only'
                        subyear={subyear}
                        season={season}
                        isStaff={false}
                        readRole={pageRole.readRole}
                        addRole={false}
                        modifyRole={false}
                        removeRole={false}
                    />
                </AccordionWrapper>
            }
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(WrapDocumentPage);