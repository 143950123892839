import {GridCellProps} from '@progress/kendo-react-grid';
import React, {useState} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import {Button} from '@progress/kendo-react-buttons';
import {Button as ModalButton, Icon, Modal, ModalActions, ModalContent} from 'semantic-ui-react';

interface FileOperationCellProps extends GridCellProps {
    updateFile: (id: number, filename: string, uploadDate: Date | null, modBy: string, modDt: Date) => void;
}

export const FileOperationCell = (props: FileOperationCellProps) => { // todo: Prevent changes when season is not open.
    const {ariaColumnIndex, columnIndex, dataItem} = props;
    const id = dataItem['id'];
    const season = dataItem['seasonNm'];
    const filename = dataItem['filename'];
    const [file, setFile] = useState();

    const handleFileUpload = () => {
        if (!file || !season || !id) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('season', season);
        formData.append('id', id.toString());

        axios.post('/api/auth/wrap/document/upload', formData)
            .then((resp) => {
                props.updateFile(id, resp.data.filename, resp.data.uploadDate, resp.data.modBy, resp.data.uploadDate);
                toast.success('Upload successful');
            })
            .catch((error) => {
                console.log(error);
                const message = error!.response!.data;
                toast.error(!!message ? message : 'Error uploading file.');
            });
    };
    
    const handleRemove = () => {
        if (!filename || !season || !id) {
            return;
        }
        
        axios.delete(`/api/auth/wrap/document/file/${season}/${id}`)
            .then(resp => {
                if (resp.data && resp.data.modBy) {
                    props.updateFile(id, resp.data.filename, null, resp.data.modBy, resp.data.uploadDate);
                    toast.success('File successfully deleted.');
                }
            })
            .catch(error => {
                console.log(error);
                toast.error('Error deleting file.');
            });
    };
    
    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            <UploadModal file={file} setFile={setFile} handleFileUpload={handleFileUpload}/>
            &nbsp;
            {!!filename &&
                <Button themeColor='dark' onClick={handleRemove}>
                    Remove
                </Button>
            }
        </td>
    );
};

export const getFileOperationCell = (updateFilename: (id: number, filename: string, uploadDate: Date | null, modBy: string, modDt: Date) => void) => {
    return (
        (props: GridCellProps) => <FileOperationCell {...props} updateFile={updateFilename}/>
    );
};

interface FilePickerProps {
    setSelectedFile: React.Dispatch<React.SetStateAction<undefined>>;
}

const FilePicker: React.FC<FilePickerProps> = ({setSelectedFile}) => {
    const [selectedName, setSelectedName] = useState<string>('');

    // Ensure that file content is fresh when user selects file, alters file, and selects same file again.
    const clearFile = (event: any) => {
        event.target.value = null;
        setSelectedName('');
        setSelectedFile(undefined);
    };

    const handleUpload = (event: any) => {
        const file = event.target.files[0];
        setSelectedName(file.name);
        setSelectedFile(file);
    };

    return (
        <div
            className='file-upload'
            style={{
                textAlign: 'center',
                border: '3px dashed rgb(210, 227, 244)',
                padding: '1.5rem',
                position: 'relative',
                cursor: 'pointer'
            }}
        >
            <Icon name='upload'/>
            {selectedName || 'Upload'}
            <input
                type='file'
                onChange={handleUpload}
                onClick={clearFile}
                style={{
                    display: 'block',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    opacity: 0,
                    cursor: 'pointer'
                }}
            />
        </div>
    );
};

interface UploadModalProps {
    file: undefined;
    setFile: React.Dispatch<React.SetStateAction<undefined>>;
    handleFileUpload: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({file, setFile, handleFileUpload}) => {
    const [open, setOpen] = useState<boolean>(false);

    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const handleUpload = () => {
        handleFileUpload();
        closeModal();
    };

    return (
        <Modal
            open={open}
            onClose={closeModal}
            closeIcon={true}
            trigger={
                <Button onClick={openModal} themeColor='primary'>
                    Upload
                </Button>
            }
        >
            <ModalContent>
                <FilePicker setSelectedFile={setFile}/>
            </ModalContent>
            <ModalActions>
                <ModalButton onClick={closeModal} color='black'>Cancel</ModalButton>
                <ModalButton
                    color='blue'
                    disabled={!file}
                    onClick={handleUpload}
                >
                    Upload
                </ModalButton>
            </ModalActions>
        </Modal>
    );
};