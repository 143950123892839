import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import { AccordionWrapper } from '../common/grid/AccordionWrapper';
import LoadGrid from "./LoadGrid";

const LoadPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);

    useEffect(() => {
        setPageRole(getCompressedPageRole('Load', roles));
    }, [roles]);

    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 50 }}>
            <Header size={'large'}>Load</Header>
            <AccordionWrapper accordionHeader={'Load Summer'}>
                <LoadGrid
                    {...pageRole}
                    title='Load Summer'
                    dataApi='/api/auth/wrap/load/summer'
                    season='Summer'
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Load Winter'}>
                <LoadGrid
                    {...pageRole}
                    title='Load Winter'
                    dataApi='/api/auth/wrap/load/winter'
                    season='Winter'
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(LoadPage);