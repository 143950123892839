import {HistoryTable} from "../../../interfaces/wrap/history-interface";

const wrapFirstYear = 2024;

export const wrapHistoryTables: HistoryTable[] = [
    {
        category: 'WRAP',
        tabNm: 'PlannedOutage',
        visNm: 'Additional Planned Outages',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/planned-outage/history/summer',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'PlannedOutage',
        visNm: 'Additional Planned Outages',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/planned-outage/history/winter',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'TestResultCap',
        visNm: 'Capability Test Results',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/test-result/history/cap',
        parameters: ['subYear', 'season']
    },
    {
        category: 'WRAP',
        tabNm: 'TestResultCap',
        visNm: 'Capability Test Results',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/test-result/history/cap',
        parameters: ['subYear', 'season']
    },
    {
        category: 'WRAP',
        tabNm: 'Contract',
        visNm: 'Contracts & Obligations',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/contract/history/summer',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'Contract',
        visNm: 'Contracts & Obligations',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/contract/history/winter',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'DemandResponse',
        visNm: 'Demand Response Programs',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/demand-response/history/summer',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'DemandResponse',
        visNm: 'Demand Response Programs',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/demand-response/history/winter',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'Facility',
        visNm: 'Facilities',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/facility/history',
        parameters: ['subYear', 'season']
    },
    {
        category: 'WRAP',
        tabNm: 'Facility',
        visNm: 'Facilities',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/facility/history',
        parameters: ['subYear', 'season']
    },
    {
        category: 'WRAP',
        tabNm: 'Load',
        visNm: 'Load',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/load/history/summer',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'Load',
        visNm: 'Load',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/load/history/winter',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'TestResultOp',
        visNm: 'Operational Test Results',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/test-result/history/op',
        parameters: ['subYear', 'season']
    },
    {
        category: 'WRAP',
        tabNm: 'TestResultOp',
        visNm: 'Operational Test Results',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/test-result/history/op',
        parameters: ['subYear', 'season']
    },
    {
        category: 'WRAP',
        tabNm: 'Resource',
        visNm: 'Resources',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/resource/history/summer',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'Resource',
        visNm: 'Resources',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/resource/history/winter',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'TsrInformation',
        visNm: 'TSR (OASIS) Information',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/tsr-information/history/summer',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'TsrInformation',
        visNm: 'TSR (OASIS) Information',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/tsr-information/history/winter',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'TsrMapping',
        visNm: 'TSR Mapping',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Summer',
        api: '/api/auth/wrap/tsr-mapping/history/summer',
        parameters: ['subYear']
    },
    {
        category: 'WRAP',
        tabNm: 'TsrMapping',
        visNm: 'TSR Mapping',
        firstSubYear: wrapFirstYear,
        lastSubYear: null,
        season: 'Winter',
        api: '/api/auth/wrap/tsr-mapping/history/winter',
        parameters: ['subYear']
    }
];