import React, {useCallback, useEffect, useState} from 'react';
import {ApiValidations, DataUpdate, PageRole, UiValidations} from "../../interfaces/grid-interfaces";
import {WrapDocumentData, WrapDocumentEditData} from '../../interfaces/wrap/wrap-document-interface';
import axios from 'axios';
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal, removeDataErrorByValue,
    toastSuccessfulSave, updateEditedRows
} from '../../services/data-grid-service';
import {useDataState} from '../../hooks/useDataState';
import {Grid, GridColumn, GridItemChangeEvent} from '@progress/kendo-react-grid';
import {toast} from 'react-toastify';
import {getRemoveCell} from '../common/grid/RemoveCell';
import {getTextDropDownCell} from '../common/grid/TextDropDownCell';
import ActionPanel from '../common/action-panel/ActionPanel';
import AddPanel from '../common/action-panel/AddPanel';
import RemovePanel from '../common/action-panel/RemovePanel';
import SavePanel from '../common/action-panel/SavePanel';
import ClearFilterPanel from '../common/action-panel/ClearFilterPanel';
import RefreshPanel from '../common/action-panel/RefreshPanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import TooltipContainer from '../common/grid/TooltipContainer';
import {process} from '@progress/kendo-data-query';
import {InputCell} from '../common/grid/InputCell';
import {getFileOperationCell} from '../common/grid/FileOperationCell';
import {getFileCell} from '../common/grid/FileCell';
import {RecordMapCell} from '../common/grid/RecordMapCell';
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import ValidatePanel from "../common/action-panel/ValidatePanel";
import ValidationPreSaveGrid from '../common/grid/ValidationPreSaveGrid';

interface WrapDocumentProps extends PageRole{
    title: string;
    dataApi: string;
    subyear: number;
    season: string;
}

const participantNmEmpty = 'Participant must have a value to save the record.';
const documentNameEmpty = 'Document Name must have a value to save the record.';
const documentTypeEmpty = 'Document Type must have a value to save the record.';

const WrapDocumentGrid: React.FC<WrapDocumentProps> = ({title, dataApi, subyear, season, readRole, addRole, modifyRole, removeRole, isStaff}) => {

    const [data, setData] = useState<WrapDocumentEditData[]>([]);
    const [originalData, setOriginalData] = useState<WrapDocumentData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const {dataState, clearFilters, dataStateChange, pageChange, pagerSettings} = useDataState();
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [participantList, setParticipantList] = useState<Array<string>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [documentTypeList, setDocumentTypeList] = useState<Array<string>>([]);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

    //participant names dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/user-participants')
            .then((resp) => {
                setParticipantList(resp.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //participant names dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/data/document-type/dropdown')
            .then((resp) => {
                setDocumentTypeList(resp.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const getSelectedData = useCallback((subYearId: number, seasonNm: string) => {
        if (!readRole) {
            return;
        }
        const params = new URLSearchParams();
        params.set('subyear', subYearId.toString());
        params.set('season', seasonNm);
        setLoading(true);
        axios.get(`${dataApi}`, {params})
            .then(resp => {
                setData(deepCopyGridDataWithOriginal(resp.data));
                setOriginalData(deepCopyGridData(resp.data));
                setDataErrors([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dataApi, readRole]);

    const getData = useCallback(() => {
        if (!subyear || !season) {
            return;
        }
        getSelectedData(subyear, season);
    }, [getSelectedData, season, subyear]);

    useEffect(() => {
        setInEdit(false);
        setEditedRows([]);
        setEditedRemoved([]);
        setInRemove(false);
        getData();
    }, [getData]);

    const handleChange = (event: GridItemChangeEvent) => {
        const {dataItem, field, value} = event;
        const {id} = dataItem;
        if (!field || !id) {
            return;
        }
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                
                // Clear any validation messages caused by missing required values.
                if (value && (['participantNm', 'documentName', 'documentType'].includes(field))) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
    };

    const handleFileUpdate = (id: number, filename: string, uploadDate: Date | null, modBy: string, modDt: Date) => {
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.filename = filename;
                dataItem.uploadDate = uploadDate;
                dataItem.lastUserModBy = modBy;
                dataItem.lastUserModDt = modDt;
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
    };

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: WrapDocumentEditData = {
            id: sequence++,
            subYearId: subyear,
            seasonNm: season,
            verified: false,
            participantNm: '',
            documentName: '',
            filename: '',
            documentType: '',
            uploadDate: null,
            comments:'',
            lastUserModBy: '',
            lastUserModDt: null,
            verifiedBy: '',
            verifiedDt: null
        };
        defaultNewRow.originalData = {...defaultNewRow};
        defaultNewRow.inEdit = true;
        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const save = () => {
        if (filterEmptyRequiredColumns()) {
            return;
        }

        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        
        if (editedRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        
        axios.post(`/api/auth/wrap/document/${season}`, updatedData)
            .then(() => {
                getData();
                toastSuccessfulSave();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const filterEmptyRequiredColumns = (): boolean => {
        const missingRequired = data.filter((item) => !item.participantNm || !item.documentName || !item.documentType);

        if (missingRequired.length === 0) {
            return false;
        }
        toast.error('There are columns missing required data. Please provide value to save changes.');
        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {
            if (!item.participantNm) {
                localErrors = addDataError(item.id, 'participantNm', 'Participant Name', '',
                    participantNmEmpty, true, localErrors);
            }
            if (!item.documentName) {
                localErrors = addDataError(item.id, 'documentName', 'Document Name', '',
                    documentNameEmpty, true, localErrors);
            }
            if (!item.documentType) {
                localErrors = addDataError(item.id, 'documentType', 'Document Type', '',
                    documentTypeEmpty, true, localErrors);
            }
        });
        setDataErrors(localErrors);
        return true;
    }

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    };

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
    };

    const openRemoveModal = () => setRemoveModalOpen(true);
    const closeRemoveModal = () => setRemoveModalOpen(false);

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }
    };

    const cancelRemove = () => {
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const confirmRemove = () => {
        axios.delete(`/api/auth/wrap/document/${season}`, {data: editedRemoved})
            .then(() => {
                setRemoveModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                setData(localData);
                resetRemove();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`/api/auth/wrap/document/validate/${season}`)
            .then(response => {
                setValidationErrors(response.data);
                getData();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }
    
    const removeRowCell = getRemoveCell(handleRemoveChange);
    const participantDropDownCell = getTextDropDownCell(participantList, true);
    const documentTypeDropDownCell = getTextDropDownCell(documentTypeList, true);
    const fileCell = getFileCell('id', 'subYearId', 'seasonNm');
    const fileOperationCell = getFileOperationCell(handleFileUpdate);

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openRemoveModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && <SavePanel inEdit={inEdit} save={save} toggleEdit={toggleEdit}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && <RefreshPanel disabled={inEdit} loading={loading} getData={getData}/>}
                {!inEdit && modifyRole && isStaff && <ValidatePanel disabled={(inEdit)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={removeModalOpen} closeModal={closeRemoveModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <Grid
                    data={process(data, dataState)}
                    dataItemKey='id'
                    onItemChange={handleChange}
                    editField='inEdit'
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    pageable={pagerSettings}
                    onPageChange={pageChange}
                    sortable={true}
                    filterable={true}
                    resizable={true}
                >
                    {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={removeRowCell} width='100px' filter='boolean'/>}
                    <GridColumn title='Participant' field='participantNm' width='200px' cell={participantDropDownCell}/>
                    <GridColumn title='Document Name' field='documentName' width='300px' cell={InputCell}/>
                    <GridColumn title='File Name' field='filename' width='400px' cell={fileCell}/>
                    {modifyRole && !inEdit &&
                        <GridColumn title='File Operations' width='200px' filterable={false} cell={fileOperationCell}/>
                    }
                    <GridColumn title='Last Uploaded' field='uploadDate' width='200px' editable={false}/>
                    <GridColumn title='Document Type' field='documentType' width='200px' cell={documentTypeDropDownCell}/>
                    <GridColumn title='Comments' field='comments' width='300px' cell={InputCell}/>
                    <GridColumn title='Modified By' field='lastUserModBy' width='160px' editable={false}/>
                    <GridColumn title='Modified Date' field='lastUserModDt' width='160px' editable={false}/>
                    <GridColumn title='Linked Records' width='200px' filterable={false} cell={RecordMapCell}/>
                </Grid>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'Document'}/>}
        </div>
    );
};

export default WrapDocumentGrid;