import * as React from 'react';
import {Container, Grid} from 'semantic-ui-react';
import Postings from "../Comment/Postings";
import {connect} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import MdAndRaHomeWrapper from "./MdAndRaHomeWrapper";
import {useEffect} from 'react';

interface HomeProps {
    updateActiveMenuItem: (activeMenuItem: string) => void,
}

const Home: React.FC<HomeProps> = ({updateActiveMenuItem}) => {
    useEffect(() => {
        updateActiveMenuItem('home');
    }, [updateActiveMenuItem]);
    
    return (
        <Container fluid={true} textAlign={"center"} style={{paddingTop:'50px'}}>
            <Grid columns={"equal"}>
                <Grid.Row style={{paddingLeft:'20px',paddingRight:'20px'}}>
                    <Grid.Column>
                        <Postings hideDropDownOnHome={true} />
                    </Grid.Column>
                    <Grid.Column>
                        <MdAndRaHomeWrapper/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateActiveMenuItem: (activeMenuItem: string) => dispatch(
            {type: actionTypes.UPDATE_ACTIVE_MENU_ITEM, payload: activeMenuItem}),
    }
}

export default connect(null, mapDispatchToProps)(Home);
