import * as React from 'react';
import {Comment, CommentData} from "./Comment";

export interface CommentLineage {
    descendant: CommentData,
    lineage: Array<number>
}

interface CommentsProps {
    commentsList: CommentLineage[],
    handleCommentReply(newComment: CommentData): void,
    handleCommentDelete(newComment: CommentData, commentResponse : any): void,

}

interface CommentsState {
    descendantList: CommentLineage[],
    siblingArray: CommentLineage[][],
}

export class Comments extends React.Component<CommentsProps,CommentsState> {
    constructor(props: CommentsProps, state: CommentsState) {
        super(props, state);
        this.state = {
            descendantList: this.props.commentsList,
            siblingArray: this.createSiblingArray(this.props.commentsList),
        };
        this.createSiblingArray = this.createSiblingArray.bind(this);
        this.displaySiblings = this.displaySiblings.bind(this);
        this.handleCommentReply = this.handleCommentReply.bind(this);
        this.handleCommentDelete = this.handleCommentDelete.bind(this);
    }

    createSiblingArray = (descendantList: CommentLineage[]) => {
        let parent = descendantList[0].descendant.parentId;
        let children: CommentLineage[] = [];
        let siblingArray: CommentLineage[][] = [];
        descendantList.forEach((item) => {
            if (item.descendant.parentId === parent) {
                // map top level siblings in arrays and into siblingArray
                siblingArray.push([item]);
            } else {
                // push remaining descendants onto children list to be sorted next
                children.push(item);
            }
        });
        let found = false;
        children.forEach((child) => {
            found = false;
            // loop through arrays of each sibling
            let i = 0;
            let j = 0;
            while(i < siblingArray.length && !found) {
                // loop through each sibling descendant if it exists
                j = 0;
                if(!!siblingArray[i]) {
                    while (j < siblingArray[i].length && !found) {
                        // when child finds ancestor, add to that sibling's array
                        if (child.lineage.includes(siblingArray[i][j].descendant.id)) {
                            siblingArray[i].push(child);
                            // skip the rest of the loop for this child
                            found = true;
                        }
                        j++;
                    }
                }
                i++;
            }
        });
        return siblingArray;
    };

    componentDidUpdate(prevProps: CommentsProps) {
        if (this.props.commentsList !== prevProps.commentsList) {
            this.setState({siblingArray: this.createSiblingArray(this.props.commentsList)});
        }
    }

    handleCommentReply = (newComment: CommentData) => {
        this.props.handleCommentReply(newComment);
    };
    handleCommentDelete = (newComment: CommentData, commentResponse : any) => {
        this.props.handleCommentDelete(newComment, commentResponse);
        this.removeComment(newComment);
    };

    removeComment = (data: any) =>{
        let parent = 0, child = 0;
        for (let  i = 0; i < this.state.siblingArray.length; i++ ) {
            parent = i;
            child = this.state.siblingArray[i].map(i => i.descendant.id.toString()).indexOf(data.commentId);
            if(child > -1) {
                break;
            }
        }
        let stateData = this.state.siblingArray;
        stateData[parent].splice(child, 1);
        this.setState({siblingArray: stateData});
    }

    displaySiblings: any = (sibling: Array<CommentLineage>) => {
        if(!sibling || !sibling.length) {
            return;
        }
        const first = sibling[0].descendant;
        const remainder = sibling.slice(1);

        return (
            <React.Fragment key={"comment_" + first.id}>
                <li key={"comment_" + first.id}>
                    <Comment comment={first} handleCommentReply={this.handleCommentReply} handleCommentDelete={this.handleCommentDelete}/>
                </li>
                {
                    (remainder.length > 0) ?
                        <Comments commentsList={remainder} handleCommentReply={this.handleCommentReply} handleCommentDelete={this.handleCommentDelete}/>
                        : null
                }
            </React.Fragment>
        )
    };

    public render() {
        const ulStyle = {
          listStyleType: 'none',
        };

        return (
            <ul style={ulStyle}>
                {
                    this.state.siblingArray.map(this.displaySiblings)
                }
            </ul>
        );
    }
}
