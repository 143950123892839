import {RowEditGrid} from '../../common/row-edit-grid/RowEditGrid';
import {GridColumn} from '@progress/kendo-react-grid';
import {COMMAND_CELL_WIDTH, CommandCell} from '../../common/row-edit-grid/CommandCell';
import {RequiredStringFormCell} from '../../common/row-edit-grid/RequiredStringFormCell';

export const SubregionFormGrid = () => (
    <div style={{maxWidth: '642px'}}>
        <RowEditGrid
            dataApi={'/api/auth/wrap/subregion'}
            uniqueFields={[{field: 'nm', title: 'Name'}]}
        >
            <GridColumn title='Command' cell={CommandCell} width={COMMAND_CELL_WIDTH}/>
            <GridColumn title='Id' field='id' editable={false} width={70}/>
            <GridColumn title='Name' field='nm' cell={RequiredStringFormCell} width={400}/>
        </RowEditGrid>
    </div>
);