import {Container, Header} from 'semantic-ui-react';
import {AccordionWrapper} from '../common/grid/AccordionWrapper';
import {QccMonthGrid} from './QccMonthGrid';
import {QccSeasonGrid} from './QccSeasonGrid';
import {PeakDemandGrid} from './PeakDemandGrid';
import {ParticipantPrmGrid} from './ParticipantPrmGrid';
import {useCategorySeason} from "../../hooks/useCategorySeason";
import ActionPanel from "../common/action-panel/ActionPanel";
import {ActionPanelButton} from "../common/action-panel/ActionPanelButton";
import {DropDownList} from "@progress/kendo-react-dropdowns";

const AdvanceAssessmentResultsPage = () => {
    const {subyear, subyearList, season, seasonList, seasonOpen, handleSubyear, handleSeason} = useCategorySeason();
    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 50 }}>
            <Header size="large">Advance Assessment Results</Header>
            <ActionPanel title={'Select Submittal Year and Season'}>
                <ActionPanelButton>
                    <DropDownList
                        data={subyearList}
                        value={subyear}
                        onChange={handleSubyear}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>
                <ActionPanelButton>
                    <DropDownList
                        data={seasonList}
                        value={season}
                        onChange={handleSeason}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>
            </ActionPanel>
            <br/>
            <AccordionWrapper accordionHeader='Monthly QCC Results'>
                <QccMonthGrid
                    readOnly={true}
                    subyear={subyear}
                    season={season}
                    seasonOpen={seasonOpen}/>
            </AccordionWrapper>
            <br />
            <AccordionWrapper accordionHeader='Seasonal QCC Results'>
                <QccSeasonGrid
                    readOnly={true}
                    subyear={subyear}
                    season={season}
                    seasonOpen={seasonOpen}/>
            </AccordionWrapper>
            <br />
            <AccordionWrapper accordionHeader='P50 Peak Load Forecast'>
                <PeakDemandGrid
                    readOnly={true}
                    subyear={subyear}
                    season={season}
                    seasonOpen={seasonOpen}/>
            </AccordionWrapper>
            <br />
            <AccordionWrapper accordionHeader='PRM Results'>
                <ParticipantPrmGrid
                    subyear={subyear}
                    season={season}/>
            </AccordionWrapper>
        </Container>
    );
};

export default AdvanceAssessmentResultsPage;
