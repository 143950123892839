import {NumericTextBox} from '@progress/kendo-react-inputs';
import {GridCellProps} from '@progress/kendo-react-grid';
import {formatNumber} from '@progress/kendo-intl';
import {NumberFormatOptions} from '@progress/kendo-react-intl';
import {isDifferent} from '../../../services/data-grid-service';

interface PercentCellProps extends GridCellProps {
    decimalPlaces: number,
    editable: boolean
}

export const PercentCell = (props: PercentCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const value = field && (dataItem[field] || dataItem[field] === 0) ? dataItem[field] : null;
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    
    // Issue: Typing '.' first sends null value. Then subsequent numbers are sent as whole values instead of decimals.
    // Workaround: Type '0.' then type numbers and it will take them as decimals instead of whole numbers.
    const onChange = (e: any) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    const outOfBounds = () => {
        if (value < 0) {
            return true;
        } else return value > 1;
    }

    const format: NumberFormatOptions = {
        style: 'percent',
        minimumFractionDigits: props.decimalPlaces,
        maximumFractionDigits: props.decimalPlaces
    };

    const displayValue = (value: number | null, nullDisplay: string) => {
        return value === null
            ? nullDisplay
            : formatNumber(value, format)
    };
    
    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{textAlign: 'center', backgroundColor: outOfBounds() ? '#fa6b6b'
                    : isChanged ? '#5cd65c'
                        : 'transparent'}}
        >
            {inEdit && props.editable
                ? isChanged 
                    ? <NumericTextBox value={value} max={1} onChange={onChange} spinners={false} format={format} step={0} title={displayValue(originalValue, 'no value')}/>
                    : <NumericTextBox value={value} max={1} onChange={onChange} spinners={false} step={0} format={format}/>
                : displayValue(value, '')
            }
        </td>
    );
};

export const getPercentCell = (decimalPlaces: number, editable: boolean) => {
    return (props: GridCellProps) => (
        <PercentCell {...props} decimalPlaces={decimalPlaces} editable={editable}/>
    );
}