import {GridCellProps} from '@progress/kendo-react-grid';
import {formatNumber} from '@progress/kendo-intl';
import {NumberFormatOptions} from '@progress/kendo-react-intl';

export const ReportCell = (props: GridCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const rowName = dataItem['rowNm'];
    const value = field && (dataItem[field] || dataItem[field] === 0) ? dataItem[field] : 0;

    const decimalFormat: NumberFormatOptions = {
        style: 'decimal',
        maximumFractionDigits: 1
    };
    
    const percentFormat: NumberFormatOptions = {
        style: 'percent',
        maximumFractionDigits: 1
    };

    const displayValue = (value: number) => {
        if (!rowName) {
            return '';
        } else if (rowName.endsWith('Percent')) { // All ForwardShowing rowNm values should end in 'Percent' if they represent percent data.
            return formatNumber(value, percentFormat);
        } else if (rowName === 'requirementMet') { // Only two ForwardShowing rows have Yes/No and they both have to do with meeting a requirement.
            return value ? 'Yes' : 'No';
        } else {
            return formatNumber(value, decimalFormat);
        }
    };

    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{textAlign: 'center'}}
        >
            {displayValue(value)}
        </td>
    );
};