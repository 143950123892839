import {useContext} from 'react';
import {formatNumber} from '@progress/kendo-intl';
import {Field, FieldRenderProps} from '@progress/kendo-react-form';
import {GridCellProps} from '@progress/kendo-react-grid';
import {NumericTextBox} from '@progress/kendo-react-inputs';
import {NumberFormatOptions} from '@progress/kendo-react-intl';
import {GridEditContext} from './context/GridEditContext';
import {FORM_DATA_INDEX, requiredValidator} from './RowEditGrid';

const DecimalInputWithValidation = (fieldRenderProps: FieldRenderProps) => {
    const {validationMessage, visited, ...others} = fieldRenderProps;
    return (
        <div>
            <NumericTextBox {...others} />
            {visited && validationMessage && (
                <div role="alert" className="k-form-error k-text-start">
                    {validationMessage}
                </div>
            )}
        </div>
    );
};

export const RequiredNumberFormCell = (props: GridCellProps) => {
    const {editIndex} = useContext(GridEditContext);
    const {dataItem, field} = props;
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const value = field && (dataItem[field] || dataItem[field] === 0) ? dataItem[field] : null;
    const format = props.format ? props.format : 'n2';
    const formatStyle = format.charAt(0) === 'p'
        ? 'percent'
        : 'decimal';
    const formatDigits = format.length === 2 && /\d/.test(format.charAt(1)) ? Number(format.charAt(1)) : 0;

    const formatOptions: NumberFormatOptions = {
        style: formatStyle,
        maximumFractionDigits: formatDigits
    };

    return (
        <td>
            {isInEdit ? (
                <Field
                    component={DecimalInputWithValidation}
                    name={`${props.field}`}
                    spinners={false}
                    step={0}
                    format={formatOptions}
                    validator={requiredValidator}
                />
            ) : (
                formatNumber(value, formatOptions)
            )}
        </td>
    );
};