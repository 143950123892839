import NumberFormat from 'react-number-format';
//import * as React from "react";

const includes = require('lodash/includes');
export const getColumnMetaByHeaderName = (columnMetaData:any[] | undefined,headerName:string) => {
    if (columnMetaData !== undefined) {
        return columnMetaData.filter((value: any) => {
            return headerName === value.nm.charAt(0).toLowerCase() + value.nm.substring(1);
        })[0];
    }
};

export const determineInputType = (columnMetaData:any) => {
    let type = columnMetaData.type as string;
    if (type !== undefined && type !== null) {
        type = type.toUpperCase();
        if (type === 'LONG' || type === 'INTEGER' || type === 'DOUBLE') {
            return 'number';
        }
        return 'text';
    }
    return 'text';
};

export const keepRequiredInd = (resource: string, dataRow: any, columnName: string) => {
    if (resource === 'GeneratorTestResults') {
        if (dataRow['energySource'] === 'Wind' || dataRow['energySource'] === 'Solar' || dataRow['energySource'] === 'Water - Run the River') {
            if (includes(['summerGrossCapTest','summerNetCapTest','summerCapTestDt','summerCapTestDur','summerCapTestDryTemp','ratedCoolDryTemp'], columnName)) {
                return false;
            }
        }
    }
    return true;
};

export const isRequiredAndEmpty = (columnMeta: any, data: any) => {
    return columnMeta.requiredInd && (data == null || data === '')
};

export const getColumnWidth = (rows:any, accessor:string, headerText:string,dropDownValues:string[]) => {
    let dropDownLength = 0;
    if (dropDownValues !== undefined && dropDownValues !== null) {
        dropDownValues.forEach(dd => {
            if (dd.length > dropDownLength) {
                dropDownLength = dd.length;
            }
        })
    }
    if (headerText === 'Action') {
        dropDownLength = 6;
    }
    const maxWidth = 427;
    const magicSpacing = 12;
    const cellLength = Math.max(
        ...rows.map((row:any) => (`${row[accessor]}` || '').length),
        headerText.length,dropDownLength*2
    );
    return Math.min(maxWidth, Math.max(cellLength * magicSpacing,100))
};

// Compares list of numbers.
//      If all numbers are 0, return 0.
//      If positive number is found, return 1.
//      If negative number is found, return -1.
// Record Cap fields should match visible Transactions Cap columns (see Admin.ColumnMeta for current subyear)
const transactionSign = (record: any) => {
    let signSet: number[] = [];
    signSet.push(record['mWCapacity']);
    signSet.push(record['s1Cap']);
    signSet.push(record['s2Cap']);
    signSet.push(record['s4Cap']);
    signSet.push(record['s5Cap']);
    signSet.push(record['s6Cap']);
    signSet.push(record['s7Cap']);
    signSet.push(record['s8Cap']);
    signSet.push(record['s9Cap']);
    signSet.push(record['s10Cap']);
    signSet.push(record['s11Cap']);
    signSet.push(record['s12Cap']);
    signSet.push(record['s13Cap']);
    signSet.push(record['s14Cap']);
    signSet.push(record['s15Cap']);
    signSet.push(record['s16Cap']);
    signSet.push(record['s17Cap']);
    signSet.push(record['s18Cap']);
    signSet.push(record['s19Cap']);
    signSet.push(record['s20Cap']);
    signSet.push(record['s21Cap']);
    signSet.push(record['s22Cap']);
    signSet.push(record['s23Cap']);
    signSet.push(record['s25Cap']);
    signSet.push(record['s26Cap']);
    signSet.push(record['s27Cap']);
    signSet.push(record['s28Cap']);
    signSet.push(record['s29Cap']);
    signSet.push(record['s30Cap']);
    signSet.push(record['s31Cap']);

    for (let i = 0; i < signSet.length; i++) {
        if (Math.sign(signSet[i]) === -1) {
            return -1;
        } else if (Math.sign(signSet[i]) === 1) {
            return 1;
        }
    }
    return 0;
};

export const removeDuplicateBusRecords = (data: any[]) => {
    let localData = [...data];
    let duplicateBusIdList = '';
    let recordSpecificDupFound = false;

    for (let i = 0; i < localData.length; i++) {
        for (let j = 0; j < localData.length; j++) {
            if (i !== j && localData[i]['pK'].toString().trim() === localData[j]['id'].toString().trim()) {
                recordSpecificDupFound = true;
                if (duplicateBusIdList === '') {
                    duplicateBusIdList += localData[i]['id'];
                } else {
                    duplicateBusIdList += ', ' + localData[i]['id'];
                }

                if (localData[j]['pK'] == null || localData[j]['pK'] === -1 || localData[j]['pK'].toString().trim() === '') {
                    localData.splice(j, 1);
                    j--;
                }
            }
        }

        if (recordSpecificDupFound && (localData[i]['pK'] == null || localData[i]['pK'] === -1 || localData[i]['pK'].toString().trim() === '')) {
            localData.splice(i, 1);
            i--;
        }
        recordSpecificDupFound = false;
    }

    return {duplicateBusIdList, dedupe: localData};
};

const unCapitalizeFirstLetter = (uppercaseString: string) => {
    return uppercaseString.charAt(0).toLowerCase() + uppercaseString.slice(1);
};

export const missingRequiredFields = (columnMetaData: any[], data: any[], resource: string) => {
    let requiredColumns = columnMetaData
        .filter(column => column.requiredInd && column.editInd === "1" && column.visInd === "1")
        .map(column => unCapitalizeFirstLetter(column.nm));

    if (requiredColumns.length > 0) {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < requiredColumns.length; j++) {
                if (data[i][requiredColumns[j]] == null || data[i][requiredColumns[j]] === '') {
                    if (keepRequiredInd(resource, data[i], requiredColumns[j])) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};

export const isMirrorRecord = (data: any, dataIndex: number, baseData: any) => {
    let i = dataIndex;
    let baseIndex = -1;
    for (let j = 0; j < baseData.length; j++) {
        if (data[i]['pK'] === baseData[j]['pK']) {
            baseIndex = j;
            break;
        }
    }
    if (baseIndex >= 0) {
        let dataSign = transactionSign(data);
        let baseSign = transactionSign(baseData);
        let mirrorSign = false;
        if ((dataSign === -1 && baseSign === 1) || (dataSign === 1 && baseSign === -1)) {
            mirrorSign = true;
        }
        if (data[i]['respNm'] === baseData[baseIndex]['confNm']
            && data[i]['fAreaId'] === baseData[baseIndex]['tAreaId']
            && data[i]['attribute1'] === baseData[baseIndex]['attribute2']) {
            if (mirrorSign) {
                return '1';
            } else {
                return '0';
            }
        }
    }
    return '0';
};


export default class GridUtil {

    static filterMethod(filter: any, row: any){
        return String(row[filter.id]).toUpperCase().startsWith(filter.value.toString().toUpperCase());
    }

    /** Formats the output on the table for percentages and currency*/
    format = (type: any, returnVal: any) => {
        if (type === "percentage") {
            return (
                <div style={{float: 'right'}}>
                    <NumberFormat value={returnVal} displayType={'text'} suffix={'%'} decimalScale={2}
                                  fixedDecimalScale={true}/>
                </div>);
        } else if (type === "currency") {
            if (returnVal >= 0) {
                return (
                    <div style={{float: 'right'}}>
                        <NumberFormat value={returnVal} displayType={'text'} thousandSeparator={','} prefix={'$'}
                                      decimalScale={2} fixedDecimalScale={true}/>
                    </div>);
            } else {
                returnVal = -returnVal;
                return (
                    <div style={{float: 'right'}}>
                        <NumberFormat value={returnVal} displayType={'text'} thousandSeparator={','} prefix={'($'}
                                      suffix={')'} decimalScale={2} fixedDecimalScale={true}/>
                    </div>);
            }
        } else {
            if (type === "number") {
                return (
                    <div style={{float: 'right'}}>
                        {returnVal}
                    </div>
                );
            } else {
                return returnVal;
            }
        }
    };
}