import * as React from 'react';
import {RouteComponentProps} from "react-router";
import {
    Button, Checkbox, CheckboxProps,
    Container,
    Dropdown,
    DropdownItemProps, DropdownProps,
    Grid,
    Header,
    Input, InputOnChangeData,
    Segment,
    TextArea, TextAreaProps
} from "semantic-ui-react";
import axios from "axios";
import {toast} from "react-toastify";
import {Roles} from '../../interfaces/roles-interface';

interface RoleManagementState {
    selectedRole: string,
    existingRoles: DropdownItemProps[],
    tableList: any,
    checkedTables: any,
    originalTables: any,
    rolesLoading: boolean,
    roles: Roles[],
    tableMeta: TabMeta[],
    rolesTabRel: RolesTabRel[],
    activeRole: Roles,
    categoryList: DropdownItemProps[],
}

export class RoleManagement extends React.Component<RouteComponentProps, RoleManagementState> {
    constructor(props: RouteComponentProps, state: RoleManagementState) {
        super(props, state);
        this.state = {
            selectedRole: '',
            existingRoles: [],
            tableList: [],
            checkedTables: [],
            originalTables: [],
            rolesLoading: true,
            roles: [],
            tableMeta: [],
            rolesTabRel: [],
            activeRole: {
                id: -1, 
                nm: '',
                longNm: '',
                comments: '',
                readRole: false,
                addRole: false,
                modifyRole: false,
                removeRole: false,
                sppStaff: false,
                categoryId: ''
            },
            categoryList: [],
        };
    }

    componentDidMount(): void {
        this.getRoleManagementData();
    };

    getRoleManagementData = () =>{
        axios.get('/api/auth/roles/getRoleManagementData').then(resp=>{
            if(resp.status === 200){
                this.setState({...resp.data})
                this.setRoles(resp.data.roles);
                this.setState({tableList:resp.data.tableMeta})
                this.setCategories(resp.data.categories);
            } else{
                toast.error(resp.data);
            }
        })
    };

    setCategories = (categories: any[]) => {
        let categoryList: DropdownItemProps[] = categories.map((c: any) => ({key: c.id, text:c.longNm, value: c.id}));
        this.setState({categoryList});
    };

    setRoles = (roles:Roles[]) =>{
        let existingRoles: DropdownItemProps[] = roles.map((r:any) => ({key:r.id,text:r.nm,value:r.nm}));
        existingRoles = this.sortExistingRoles(existingRoles);
        this.setState({existingRoles,rolesLoading:false});
    }

    setRoleDetails = (role:string) => {
        let activeRole: any = this.state.roles.find((r: Roles) => {
            return r.nm === role;
        })
        this.setState({activeRole})
    }

    setTableList = (id:number) =>{
        if(id === -1){
            this.setState({tableList:[]})
        } else {
            let tableList: any = this.state.rolesTabRel.filter((o: any) => o.roleId === id).map((o) => o.tabNm);
            this.setState({checkedTables:tableList, originalTables:tableList});
        }
    }

    addRole = () => {
        let categoryId = this.state.activeRole.categoryId ? this.state.activeRole.categoryId : null;
        let role = {...this.state.activeRole, categoryId};
        axios.put('/api/auth/roles/addRole',role).then( resp => {
            if(resp.status === 200) {
                toast.success("Role added successfully");
                let existingRoles:any = this.sortExistingRoles( [...this.state.existingRoles, {key: resp.data.id, text: resp.data.nm, value: resp.data.nm}])
                this.setState({existingRoles:existingRoles,
                    selectedRole: resp.data.nm, activeRole: {...this.state.activeRole, id: resp.data.id}})
            } else{
                toast.error(resp.data);
            }
        }).catch(resp => {
            toast.error(`Error adding ${this.state.activeRole.nm} - ${resp.message}`);
        });
    };

    modifyRole = () => {
        let categoryId = this.state.activeRole.categoryId ? this.state.activeRole.categoryId : null;
        let role = {...this.state.activeRole, categoryId};
        axios.put('/api/auth/roles/modifyRole',role).then( resp => {
            if(resp.status === 200) {
                toast.success("Role modified successfully");
                let updateRoleIndex: number = this.state.roles.findIndex((o:Roles) => o.id === this.state.activeRole.id);
                let newRoleArray: Roles[] = [...this.state.roles];
                newRoleArray[updateRoleIndex] = {...newRoleArray[updateRoleIndex], ...this.state.activeRole};
                let existingRoles: any = this.state.existingRoles.filter((o: DropdownItemProps) => o.key !== this.state.activeRole.id);
                existingRoles.push({key: resp.data.id, text: resp.data.nm, value: resp.data.nm});
                existingRoles = this.sortExistingRoles(existingRoles);
                this.setState({existingRoles: existingRoles, selectedRole: resp.data.nm, roles: newRoleArray});
            } else{
                toast.error(resp.data);
            }
        }).catch(resp => {
            toast.error(`Error adding ${this.state.activeRole.nm} - ${resp.message}`);
        });
    };

    deleteRole = () => {
        axios.put('/api/auth/roles/deleteRole',this.state.activeRole).then( resp => {
            if(resp.status === 200) {
                this.removeRoleFromDropdown();
                this.removeRoleFromRoles();
                this.clearButtonClick();
                toast.success(resp.data);
            } else{
                toast.error(resp.data);
            }
        }).catch(resp => {
            toast.error(`Error deleting ${this.state.activeRole.nm} - ${resp.message}`);
        });
    };

    removeRoleFromDropdown = () =>{
        let dropDownData = this.sortExistingRoles(this.state.existingRoles.filter((o:any) => o.key !== this.state.activeRole.id));
        this.setState({existingRoles: dropDownData});
    }

    sortExistingRoles = (roles:any) =>{
        return roles.sort((a:any,b:any) =>{
            if(a.text < b.text){
                return -1;
            }
            if(a.text > b.text){
                return 1
            }
            return 0
        })
    }

    removeRoleFromRoles = () =>{
        let rolesData = this.state.roles.filter((o:any) => o.id !== this.state.activeRole.id)
        this.setState({roles: rolesData});
    }

    modifyTables = () => {
        let addedTables = this.state.checkedTables.filter((value:string) => {
            return !this.state.originalTables.includes(value)
        });

        let deletedTables = this.state.originalTables.filter((value:string) => {
            return !this.state.checkedTables.includes(value)
        });

        let modifyData:any = {
            id: this.state.activeRole.id,
            addedTables: addedTables,
            deletedTables: deletedTables
        }

        axios.put(`/api/auth/roles/modifyTables`, modifyData)
            .then(() => {
                if (addedTables) {
                    let rolesTabRel: any[] = [...this.state.rolesTabRel];
                    addedTables.forEach((o: string) => {
                        rolesTabRel.push({roleId: this.state.activeRole.id, tabNm: o})
                    });
                    this.setState({rolesTabRel})
                }
                if (deletedTables) {
                    let rolesTabRel: any[] = this.state.rolesTabRel.filter((r: any) => {
                        return !(r.roleId === this.state.activeRole.id && deletedTables.includes(r.tabNm));
                    });
                    this.setState({rolesTabRel});
                }
                toast.success(`Table list was successfully updated`);
            })
            .catch(resp => {
                toast.error(`Error updating table list - ${resp.message}`);
            });
    };

    handleChange = (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, data: InputOnChangeData | TextAreaProps) => {
        let newActiveRole: any = {...this.state.activeRole, [data.name]: data.value}
        this.setState({ activeRole: newActiveRole});
    };

    checkBoxChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let newActiveRole: any = {...this.state.activeRole, [data.name as string]: data.checked}
        this.setState({ activeRole: newActiveRole});
    };

    clearButtonClick = () => {
        this.setState({selectedRole: '',
            activeRole: {
                id: -1,
                nm: '',
                longNm: '',
                comments: '',
                readRole: false,
                addRole: false,
                modifyRole: false,
                removeRole: false,
                sppStaff: false,
                categoryId: '',
            },
            checkedTables: []
        })
    };

    dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setRoleDetails(data.value as string);
        // @ts-ignore
        let {key}:any = data.options.find(o => o.value === data.value);
        if(!key){ key = -1}
        this.setTableList(key as number);
        this.setState({selectedRole: data.value as string});
    };

    categoryDropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let categoryId = data.value ? data.value as number : undefined;
        let activeRole: any = {...this.state.activeRole, categoryId}
        this.setState({activeRole});
    };

    checkboxChanged = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let checkedTables = [...this.state.checkedTables];
        if (data.checked) {
            checkedTables.push(data.name);
        } else {
            checkedTables = checkedTables.filter(value => {
                return value !== data.name
            });
        }
        this.setState({checkedTables})
    };

    render() {
        let {nm, longNm, comments, readRole,addRole,modifyRole,removeRole,sppStaff, categoryId} = this.state.activeRole;
        return (
            <Container fluid={true} style={{paddingLeft:'50px', paddingRight:'50px', paddingTop:'50px'}}>
                <div/>
                <Header size={'huge'}>Role Management</Header>
                <Segment>
                    <Header>Role Details</Header>
                    <Grid>
                        <Grid.Row style={{display:'block',paddingLeft:'20px'}}>
                            <p><b>Select Role:</b></p>
                            <Dropdown selection={true}
                                      search={true}
                                      placeholder={'Role Names'}
                                      options={this.state.existingRoles}
                                      value={this.state.selectedRole}
                                      loading={this.state.rolesLoading}
                                      onChange={this.dropDownChange}/>
                        </Grid.Row>
                        <Grid.Row columns={8}>
                            <Grid.Column>
                                <p><b>Name:</b></p>
                                <Input value={nm}
                                       size={"mini"}
                                       type={'text'}
                                       placeholder={'Role Name'}
                                       name={'nm'}
                                       required={true}
                                       onChange={this.handleChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Long Name:</b></p>
                                <Input value={longNm}
                                       size={"mini"}
                                       type={'text'}
                                       placeholder={'Long Name'}
                                       name={'longNm'}
                                       required={true}
                                       onChange={this.handleChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Category:</b></p>
                                <Dropdown
                                    selection={true}
                                    placeholder='Category Name'
                                    options={this.state.categoryList}
                                    value={categoryId}
                                    clearable={true}
                                    onChange={this.categoryDropDownChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Comments:</b></p>
                                <TextArea value={comments} placeholder={'Enter Comments here:'} name={'comments'} onChange={this.handleChange}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={8}>
                            <Grid.Column>
                                <p><b>Read Role</b></p>
                                <Checkbox checked={readRole} name={'readRole'} onChange={this.checkBoxChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Add Role</b></p>
                                <Checkbox checked={addRole} name={'addRole'} onChange={this.checkBoxChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Modify Role</b></p>
                                <Checkbox checked={modifyRole} name={'modifyRole'} onChange={this.checkBoxChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>Remove Role</b></p>
                                <Checkbox checked={removeRole} name={'removeRole'} onChange={this.checkBoxChange}/>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>SPP Staff</b></p>
                                <Checkbox checked={sppStaff} name={'sppStaff'} onChange={this.checkBoxChange}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingLeft:'20px'}}>
                            <Button size={'mini'} color={'black'} disabled={this.state.activeRole.nm === ''} onClick={this.addRole} content={'Create'}/>
                            <Button size={'mini'} color={'black'} disabled={this.state.activeRole.nm === ''} onClick={this.modifyRole} content={'Modify'}/>
                            <Button size={'mini'} color={'black'} onClick={this.clearButtonClick} content={'Clear'}/>
                            <Button size={'mini'} color={'black'} disabled={this.state.activeRole.nm === ''} onClick={this.deleteRole} content={'Delete'}/>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header>Role/Table Relationship</Header>
                    <Grid columns={6} padded={true}>
                        {this.state.tableList.map((table:any) => {
                            return (
                            <Grid.Column key={table.vnm}>
                                <Checkbox size={'mini'}
                                           name={table.nm}
                                           transparent={true}
                                           label={table.vnm}
                                           labelPosition={'right'}
                                           checked={this.state.checkedTables.includes(table.nm)}
                                           onChange={this.checkboxChanged}/>
                            </Grid.Column>
                            )
                        })}
                        <Grid.Row>
                            <Button size={'mini'} color={'black'} content={'Assign'} disabled={this.state.activeRole.nm === ''} onClick={this.modifyTables}/>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        )
    }
}