import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import AddPanel from '../common/action-panel/AddPanel';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import RemovePanel from "../common/action-panel/RemovePanel";
import RemoveModal from "../common/action-panel/RemoveModal";
import {getRemoveCell} from '../common/grid/RemoveCell';
import ExportPanel from "../common/action-panel/ExportPanel";
import {toast} from "react-toastify";
import axios from 'axios';
import {
    FacilityData,
    FacilityEditData,
} from "../../interfaces/wrap/facility-interface";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from "../../interfaces/grid-interfaces";
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState,
    processColumnMeta,
    processDropDownListOptions,
    replaceSpaceWithUnderscore,
    resetOption,
    toastSuccessfulSave,
    updateEditedRows,
    updateEditedVerified,
    resetChildOption,
    parseLocalDateString,
    formatLocalDate,
    toastPreSaveValidationErrors,
    removeDataErrorByValue,
    numberBoundValidationOnEdit, removeDataErrorByMessage, resetYesNoOption
} from '../../services/data-grid-service';
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent} from "@progress/kendo-react-grid";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridPageChangeEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import format from "date-fns/format";
import {process} from "@progress/kendo-data-query";
import TooltipContainer from "../common/grid/TooltipContainer";
import {CustomLocalizationProvider, getVerifiedCell} from "../common/grid/VerifiedCell";
import {DropDownOption, getDropDownCell} from "../common/grid/DropDownCell";
import {getDecimalCell} from "../common/grid/DecimalCell";
import {InputCell} from "../common/grid/InputCell";
import ImportPanel from "../common/action-panel/ImportPanel";
import {MonthYearDateCell} from '../common/grid/MonthYearDateCell';
import {ChildDropDownOption} from "../common/grid/ChildDropDownCell";
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import ValidatePanel from "../common/action-panel/ValidatePanel";
import {YesNoDropDownCell} from '../common/grid/TextDropDownCell';
import {getDocumentMapCell} from '../common/grid/DocumentMapCell';
import {getOnBlurCell} from "../common/grid/OnBlurCell";
import parse from 'date-fns/parse';

const participantNmEmpty = 'Last Modifying Participant must have a value to save the record.';
const facilityNmEmpty = 'Facility Name must have a value to save the record.';
const nameplateCapacityEmpty = 'Nameplate or Max Capacity (MW) must have a value to save the record.';
const subregionNmEmpty = 'Subregion must have a value to save the record.';
const stateNmEmpty = 'State or Territory must have a value to save the record.';
const resourceTypeEmpty = 'Resource Type must hava a value to save the record.';
const storageDurationEmpty = 'Storage Duration must have a value when Resource Type is "Energy Storage" to save the record.';
const nercRegisteredEmpty = 'NERC GADS Available must have a value to save the record.';
const facilityUnique = 'Facility Name must be unique. This name is in another record.';

interface FacilityGridProps extends PageRole {
    title: string,
    dataApi: string,
    subyear: number,
    season: string
}

const FacilityGrid: React.FC<FacilityGridProps> = ({title, dataApi, isStaff, readRole, addRole, modifyRole, removeRole, subyear, season}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<FacilityEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<FacilityData>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [participantOptions, setParticipantOptions] = useState<Array<DropDownOption>>([]);
    const [statusOptions, setStatusOptions] = useState<Array<DropDownOption>>([]);
    const [subregionOptions, setSubregionOptions] = useState<Array<DropDownOption>>([]);
    const [stateOptions, setStateOptions] = useState<Array<DropDownOption>>([]);
    const [resourceTypeOptions, setResourceTypeOptions] = useState<Array<DropDownOption>>([]);
    const [primeMoverOptions, setPrimeMoverOptions] = useState<Array<DropDownOption>>([]);
    const [energySourceOptions, setEnergySourceOptions] = useState<Array<DropDownOption>>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<Array<ChildDropDownOption>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const [dataState, setDataState] = useState<any>({
        ...initialDataState,
        take: globalPageSize ? +globalPageSize : 10,
        pageSize: globalPageSize ? +globalPageSize : 10
    });
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

    // columns from api
    useEffect(() => {
        axios.get(`${dataApi}/columns`)
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);


    //participant names dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/user-participants')
            .then((resp) => {
                setParticipantOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //status dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/facility/dropdown/status')
            .then((resp) => {
                setStatusOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //status dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/facility/dropdown/subregion')
            .then((resp) => {
                setSubregionOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //status dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/facility/dropdown/state')
            .then((resp) => {
                setStateOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/wrap/facility/dropdown/resource-type')
            .then((resp) => {
                setResourceTypeOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/wrap/facility/dropdown/prime-mover')
            .then((resp) => {
                setPrimeMoverOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/wrap/facility/dropdown/energy-source')
            .then((resp) => {
                const energySourceData = processDropDownListOptions(resp.data
                    .map((option: { energySourceName: any; }) => option.energySourceName));
                const fuelTypeData = resp.data
                    .map((item: any) => ({
                        text: item.fuelTypeName,
                        value: item.fuelTypeName,
                        parentValue: item.energySourceName
                    }));
                fuelTypeData.sort((a: any, b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
                setEnergySourceOptions(energySourceData);
                setFuelTypeOptions(fuelTypeData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // summer and winter data from api
    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(`${dataApi}`)])
            .then((resp) => {
                const localData = resp[0].data.map((d: any) => ({
                    ...d,
                    initialOperationDt: parseLocalDateString(d.initialOperationDt),
                    projectedRetirementDt: parseLocalDateString(d.projectedRetirementDt),
                    testDt: d.testDt ? format((parse(d.testDt as string, 'yyyy-MM-dd', new Date())), 'MM/yyyy') : null
                }));
                setData(deepCopyGridDataWithOriginal(localData));
                setOriginalData(deepCopyGridData(localData));
                setTempSequence(-10000);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, readRole]);

    useEffect(() => {
        getData();
    }, [getData]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = (id: number, field: string, value: any) => {
        const localData = data.map(item => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                if (field === 'energySourceNm') {
                    const option = fuelTypeOptions.find((item) => item?.parentValue === dataItem.energySourceNm);
                    dataItem.fuelTypeNm = option?.value as string;
                }
                if (field === 'storageDuration') {
                    if (!value || value < 8 || value === 0) {
                        dataItem.longDurationStorage = 'NO';
                    }
                    else {
                        dataItem.longDurationStorage = 'YES';
                    }
                    if (field === 'storageDuration' && value && dataItem.resourceTypeNm === 'Energy Storage') {
                        setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                    }
                }
                if (field === 'resourceTypeNm' && dataItem.resourceTypeNm !== 'Energy Storage') {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'storageDuration', prevDataErrors, ''));
                }
                if (value && (['facilityNm','participantNm','subregionNm','stateNm','resourceTypeNm','longDurationStorage','nameplateCapacity','nercRegistered'].includes(field))) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if ((value || value === 0) && (['nameplateCapacity', 'storageDuration', 'testNet'].includes(field))) {
                    numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 100000, 0);
                }
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify) {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
    };

    //refresh data
    const refreshData = () => {
        getData();
    };

    // clear all the filters
    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`${dataApi}/validate`)
            .then(response => {
                setValidationErrors(response.data);
                getData();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    };

    const generateDefaultRow = (sequence: number) => ({
        id: sequence,
        verified: false,
        participantNm: '',
        facilityNm: '',
        plantCode: 0,
        generatorNm: '',
        nameplateCapacity: 0,
        statusNm: '',
        resourceTypeNm: '',
        primeMoverNm: '',
        energySourceNm: '',
        fuelTypeNm: '',
        drDuration: 0,
        storageDuration: 0,
        longDurationStorage: 'NO',
        sharedPoint: '',
        isHybrid: 'NO',
        facilityLinkingNm: '',
        pointOfInterconnection: 0,
        nercRegistered: '',
        mustTake: '',
        comments: '',
        approved: '',
        initialOperationDt: null,
        projectedRetirementDt: null,
        subregionNm: '',
        stateNm: '',
        lastUserModBy: '',
        lastUserModDt: null,
        verifiedBy: '',
        verifiedDt: null,
        inEdit: true,
    });

    //adding a new row
    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: FacilityEditData = generateDefaultRow(sequence++);

        defaultNewRow.originalData = {...defaultNewRow};

        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    //Edit mode
    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
        resetRemove();
    };

    // Remove mode
    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const confirmRemove = () => {
        axios.delete(`${dataApi}/delete`, {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));
                const localDataErrors = dataErrors.filter(item => !editedRemoved.includes(item.id));

                toast.success("Successfully deleted data");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    setDataErrors(localDataErrors);
                    resetRemove();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const message: string = await getFacilityDependencyWarning(id);
            if (message) {
                toast.error(message);
                return false;
            }
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const getFacilityDependencyWarning = async (id: number) => {
        const params = new URLSearchParams();
        params.set('id', id.toString());
        try {
            const response = await axios.get('/api/auth/wrap/facility/validate/facility-dependency', {params});
            return response.data;
        } catch (error) {
            console.log(error);
            return 'Cannot verify that Facility record may be removed.';
        }
    };

    const filterEmptyRequiredColumns = (): boolean => {
        const missingRequired = data.filter(item => !item.participantNm || !item.facilityNm
            || !item.nameplateCapacity || !item.subregionNm || !item.stateNm || !item.nercRegistered
            || (!item.storageDuration && item.resourceTypeNm === 'Energy Storage'));

        if (missingRequired.length === 0) {
            return false;
        }

        toast.error('There are columns missing required data. Please provide value to save changes.');
        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {

            if (!item.participantNm) {
                localErrors = addDataError(item.id, 'participantNm', 'Last Modifying Participant', '', participantNmEmpty, true, localErrors);
            }
            if (!item.facilityNm) {
                localErrors = addDataError(item.id, 'facilityNm', 'Facility Name', '', facilityNmEmpty, true, localErrors);
            }
            if (!item.nameplateCapacity) {
                localErrors = addDataError(item.id, 'nameplateCapacity', 'Nameplate or Max Capacity (MW)', '', nameplateCapacityEmpty, true, localErrors);
            }
            if (!item.subregionNm) {
                localErrors = addDataError(item.id, 'subregionNm', 'Subregion', '', subregionNmEmpty, true, localErrors);
            }
            if (!item.stateNm) {
                localErrors = addDataError(item.id, 'stateNm', 'State or Territory', '', stateNmEmpty, true, localErrors);
            }
            if (!item.resourceTypeNm) {
                localErrors = addDataError(item.id, 'resourceTypeNm', 'Resource Type', '', resourceTypeEmpty, true, localErrors);
            }
            if (!item.storageDuration && item.resourceTypeNm === 'Energy Storage') {
                localErrors = addDataError(item.id, 'storageDuration', 'Storage Duration', '', storageDurationEmpty, true, localErrors);
            }
            if (!item.nercRegistered) {
                localErrors = addDataError(item.id, 'nercRegistered', 'NERC GADS Available', '', nercRegisteredEmpty, true, localErrors);
            }
        });

        setDataErrors(localErrors);
        return true;
    };

    const save = () => {
        if (filterEmptyRequiredColumns()) {
            return;
        }
        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));

        const facilityData = newAndUpdatedData.map(item => ({
            id: item.id,
            verified: item.verified,
            participantNm: item.participantNm,
            facilityNm: item.facilityNm,
            plantCode: item.plantCode,
            generatorNm: item.generatorNm,
            nameplateCapacity: item.nameplateCapacity,
            statusNm: item.statusNm,
            initialOperationDt: item.initialOperationDt,
            projectedRetirementDt: item.projectedRetirementDt,
            subregionNm: item.subregionNm,
            stateNm: item.stateNm,
            resourceTypeNm: item.resourceTypeNm,
            primeMoverNm: item.primeMoverNm,
            energySourceNm: item.energySourceNm,
            fuelTypeNm: item.fuelTypeNm,
            drDuration: item.drDuration,
            storageDuration: item.storageDuration,
            longDurationStorage: item.longDurationStorage,
            sharedPoint: item.sharedPoint,
            isHybrid: item.isHybrid,
            facilityLinkingNm: item.facilityLinkingNm,
            pointOfInterconnection: item.pointOfInterconnection,
            nercRegistered: item.nercRegistered,
            mustTake: item.mustTake,
            comments: item.comments,
            approved: item.approved,
            lastUserModBy: item.lastUserModBy,
            lastUserModDt: item.lastUserModDt,
            verifiedBy: item.verifiedBy,
            verifiedDt: item.verifiedDt
        }));

        axios.post(`${dataApi}/save`, facilityData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //verify mode
    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post(`${dataApi}/verify`, updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleVerifySelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    //import mode
    const handleUpload = (uploadedData: Array<FacilityEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            const upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                const energySourceNm = resetOption(u.energySourceNm, energySourceOptions, '');
                const item = {
                    ...u,
                    id: sequence,
                    verified: false,
                    participantNm: resetOption(u.participantNm, participantOptions, ''),
                    statusNm: resetOption(u.statusNm, statusOptions, ''),
                    initialOperationDt: parseLocalDateString(u.initialOperationDt, true),
                    projectedRetirementDt: parseLocalDateString(u.projectedRetirementDt, true),
                    subregionNm: resetOption(u.subregionNm, subregionOptions, ''),
                    stateNm: resetOption(u.stateNm, stateOptions, ''),
                    resourceTypeNm: resetOption(u.resourceTypeNm, resourceTypeOptions, ''),
                    primeMoverNm: resetOption(u.primeMoverNm, primeMoverOptions, ''),
                    energySourceNm,
                    fuelTypeNm: resetChildOption(energySourceNm, u.fuelTypeNm, fuelTypeOptions, ''),
                    longDurationStorage: !u.storageDuration || u.storageDuration < 8 ? 'NO' : 'YES',
                    isHybrid: resetYesNoOption(u.isHybrid, ''),
                    nercRegistered: resetYesNoOption(u.nercRegistered, ''),
                    mustTake: resetYesNoOption(u.mustTake, ''),
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true,
                    originalData: generateDefaultRow(sequence)
                };
                sequence = sequence + 1;
                return item;
            });

        const dataUploaded: Array<FacilityEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u);
        });
        setData(dataUploaded);
        setTempSequence(sequence);
    };

    const processUploadEdits = (upload: FacilityEditData, item: FacilityEditData): FacilityEditData => {
        const participantNm = resetOption(upload.participantNm, participantOptions, item.participantNm);
        if (participantNm !== item.participantNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'participantNm', participantNm));
        }
        if (upload.facilityNm !== item.facilityNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'facilityNm', upload.facilityNm));
        }
        if (upload.nameplateCapacity !== item.nameplateCapacity) {
            numberBoundValidationOnEdit(upload.nameplateCapacity, 'nameplateCapacity', setDataErrors, item, columnMeta, 100000, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'nameplateCapacity', upload.nameplateCapacity));
        }
        if (upload.plantCode !== item.plantCode) {
            numberBoundValidationOnEdit(upload.plantCode, 'plantCode', setDataErrors, item, columnMeta, 100000, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'plantCode', upload.plantCode));
        }
        if (upload.generatorNm !== item.generatorNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'generatorNm', upload.generatorNm));
        }
        const statusNm = resetOption(upload.statusNm, statusOptions, item.statusNm);
        if (statusNm !== item.statusNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'statusNm', statusNm));
        }
        if (upload.initialOperationDt !== formatLocalDate(item.initialOperationDt)) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'initialOperationDt', upload.initialOperationDt, 'date'));
        }
        if (upload.projectedRetirementDt !== formatLocalDate(item.projectedRetirementDt)) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'projectedRetirementDt', upload.projectedRetirementDt, 'date'));
        }
        const subregionNm = resetOption(upload.subregionNm, subregionOptions, item.subregionNm);
        if (subregionNm !== item.subregionNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'subregionNm', subregionNm));
        }
        const stateNm = resetOption(upload.stateNm, stateOptions, item.stateNm);
        if (stateNm !== item.stateNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'stateNm', stateNm));
        }
        const resourceTypeNm = resetOption(upload.resourceTypeNm, resourceTypeOptions, item.resourceTypeNm);
        if (resourceTypeNm !== item.resourceTypeNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'resourceTypeNmn', resourceTypeNm));
        }
        const primeMoverNm = resetOption(upload.primeMoverNm, primeMoverOptions, item.primeMoverNm);
        if (primeMoverNm !== item.primeMoverNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'primeMoverNm', primeMoverNm));
        }
        const energySourceNm = resetOption(upload.energySourceNm, energySourceOptions, item.energySourceNm);
        let fuelTypeNm = item.fuelTypeNm;
        if (energySourceNm !== item.energySourceNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'energySourceNm', energySourceNm));
            fuelTypeNm = resetChildOption(energySourceNm, upload.fuelTypeNm, fuelTypeOptions, item.fuelTypeNm);
        }
        if (upload.drDuration !== item.drDuration) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'drDuration', upload.drDuration));
        }
        let longDurationStorage = item.longDurationStorage;
        if (upload.storageDuration !== item.storageDuration) {
            numberBoundValidationOnEdit(upload.storageDuration, 'storageDuration', setDataErrors, item, columnMeta, 100000, 0);
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'storageDuration', upload.storageDuration));
            longDurationStorage = !upload.storageDuration || upload.storageDuration < 8 ? 'NO' : 'YES';
        }
        const isHybrid = resetYesNoOption(upload.isHybrid, item.isHybrid);
        if (isHybrid !== item.isHybrid) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'isHybrid', isHybrid));
        }
        const nercRegistered = resetYesNoOption(upload.nercRegistered, item.nercRegistered);
        if (nercRegistered !== item.nercRegistered) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'nercRegistered', nercRegistered));
        }
        const mustTake = resetYesNoOption(upload.mustTake, item.mustTake);
        if (mustTake !== item.mustTake) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'mustTake', mustTake));
        }
        if (upload.comments !== item.comments) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'comments', upload.comments));
        }

        return ({
            ...item,
            participantNm,
            facilityNm: upload.facilityNm,
            nameplateCapacity: upload.nameplateCapacity,
            plantCode: upload.plantCode,
            generatorNm: upload.generatorNm,
            statusNm,
            initialOperationDt: parseLocalDateString(upload.initialOperationDt, true),
            projectedRetirementDt: parseLocalDateString(upload.projectedRetirementDt, true),
            subregionNm,
            stateNm,
            resourceTypeNm,
            primeMoverNm,
            energySourceNm,
            drDuration: upload.drDuration,
            fuelTypeNm,
            storageDuration: upload.storageDuration,
            longDurationStorage,
            isHybrid,
            nercRegistered,
            mustTake,
            comments: upload.comments
        });
    };

    //export mode
    const formatExport = (data: Array<FacilityData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO'
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    //page size
    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };

    const handleFacilityNameBlur = (id: number, field: string, value: string) => {
        handleChange(id, field, value);
        let params = new URLSearchParams();
        params.set('facilityNm', value);
        params.set('id', id.toString());
        axios.get('/api/auth/wrap/facility/unique/facilityNm', {params})
            .then((resp) => {
                if (resp.data === false) {
                    toast.error(facilityUnique);
                    setDataErrors((prevDataErrors) => addDataError(id, field, 'Facility Name', value, facilityUnique, true, prevDataErrors));
                } else {
                    setDataErrors((prevDataErrors) => removeDataErrorByMessage(id, field, prevDataErrors, facilityUnique));
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Error occurred while validating Facility Name.');
            });
    };

    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);
    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const ParticipantCell = useMemo(()=>getDropDownCell(participantOptions, 'Participant'), [participantOptions]);
    const DecimalCell = useMemo(() => getDecimalCell(1, true, 100000, 0), []);
    const StatusCell = useMemo(()=>getDropDownCell(statusOptions, 'Status'), [statusOptions]);
    const SubregionCell = useMemo(()=>getDropDownCell(subregionOptions, 'Subregion'), [subregionOptions]);
    const StateCell = useMemo(()=>getDropDownCell(stateOptions, 'State'), [stateOptions]);
    const ResourceTypeCell = useMemo(()=>getDropDownCell(resourceTypeOptions, 'Resource Type'), [resourceTypeOptions]);
    const PrimeMoverCell = useMemo(()=>getDropDownCell(primeMoverOptions, 'Prime Mover'), [primeMoverOptions]);
    const EnergySourceCell = useMemo(()=>getDropDownCell(energySourceOptions, 'Energy Source'), [energySourceOptions]);
    const StorageDurationCell =  useMemo(() => getDecimalCell(0, true, 100000, 0), []);
    const plantCodeCell = useMemo(() => getDecimalCell(0, true, 100000, 0), []);
    const documentMapCell = getDocumentMapCell(subyear, season, 'Facility');
    const FacilityNameCell = getOnBlurCell(handleFacilityNameBlur);
    
    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/import`} setUploadRecords={handleUpload}/>}
                {isStaff && modifyRole && !inEdit && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleVerifySelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && !inVerify && modifyRole && <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                    <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                        <Grid
                            data={process(data, dataState)}
                            dataItemKey='id'
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            style={{ height: "510px" }}
                            {...dataState}
                            editField='inEdit'
                            onDataStateChange={dataStateChange}
                            onItemChange={itemChange}
                            pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                            onPageChange={pageChange}
                        >
                            {inEdit && inRemove &&
                                <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                            {
                                columnMeta.length
                                    ? columnMeta.map((col: ColumnMeta, index) => {
                                        if (col.nm === 'id') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' editable={false}/>
                                        } else if (col.nm === 'verified') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' cell={VerifiedCell} filter={"boolean"}/>
                                        } else if (col.nm === 'participantNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={ParticipantCell}/>
                                        } else if (col.nm === 'facilityNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='130px' cell={FacilityNameCell}/>
                                        } else if (col.nm === 'plantCode') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='190px' cell={plantCodeCell}/>
                                        } else if (col.nm === 'generatorNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='190px' cell={InputCell}/>
                                        } else if (col.nm === 'nameplateCapacity') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='190px' cell={DecimalCell}/>
                                        } else if (col.nm === 'statusNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={StatusCell}/>
                                        } else if (col.nm === 'initialOperationDt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='170px' cell={MonthYearDateCell} filter={"date"}/>
                                        } else if (col.nm === 'projectedRetirementDt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={MonthYearDateCell} filter={"date"}/>
                                        } else if (col.nm === 'subregionNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={SubregionCell}/>
                                        } else if (col.nm === 'stateNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='140px' cell={StateCell}/>
                                        } else if (col.nm === 'resourceTypeNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='140px' cell={ResourceTypeCell}/>
                                        } else if (col.nm === 'primeMoverNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={PrimeMoverCell}/>
                                        } else if (col.nm === 'energySourceNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' cell={EnergySourceCell}/>
                                        } else if (col.nm === 'fuelTypeNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                        } else if (col.nm === 'drDuration') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='140px' cell={DecimalCell}/>
                                        } else if (col.nm === 'storageDuration') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='140px' cell={StorageDurationCell}/>
                                        } else if (col.nm === 'longDurationStorage') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='190px' editable={false}/>
                                        } else if (col.nm === 'sharedPoint') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                        } else if (col.nm === 'isHybrid') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={YesNoDropDownCell}/>
                                        } else if (col.nm === 'facilityLinkingNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='130px' editable={false}/>
                                        } else if (col.nm === 'pointOfInterconnection') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                        } else if (col.nm === 'testDt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                        } else if (col.nm === 'testNet') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                        } else if (col.nm === 'nercRegistered') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='170px' cell={YesNoDropDownCell}/>
                                        } else if (col.nm === 'mustTake') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='120px' cell={YesNoDropDownCell}/>
                                        } else if (col.nm === 'comments') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' cell={InputCell}/>
                                        } else if (col.nm === 'approved') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' editable={false}/>
                                        } else if (col.nm === 'lastUserModBy' || col.nm === 'verifiedBy') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' editable={false}/>
                                        } else if (col.nm === 'lastUserModDt' || col.nm === 'verifiedDt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        } else {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                        }
                                    })
                                    : <GridColumn/>
                            }
                            <GridColumn title='Linked Documents' width='200px' cell={documentMapCell}/>
                        </Grid>
                    </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'Facility'}/>}
        </div>
    );
};

export default FacilityGrid;