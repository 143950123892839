import React from 'react';
import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';

interface ActionPanelButtonProps {
    children: React.ReactNode
}

export const ActionPanelButton: React.FC<ActionPanelButtonProps> = ({children}) => {
    
    return (
        <>
            <AppBarSection>
                {children}
            </AppBarSection>
            <AppBarSpacer style={{width: 4}} />
        </>
    );
};