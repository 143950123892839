import React from 'react';
import {AdminMenuProps} from '../../interfaces/menu-interface';
import {connect} from 'react-redux';
import {getViewPage} from '../../services/user-service';
import {SubMenu} from './SubMenu';
import {Dropdown} from 'semantic-ui-react';

const WrapAdminMenu: React.FC<AdminMenuProps> = ({roles, isStaff, updateActiveMenuItem}) => {
    return (
        <Dropdown item={true} text='WRAP Administration' closeOnChange={true}>
            <Dropdown.Menu>
                {getViewPage('Register', roles) && isStaff &&
                    <SubMenu menuName='registerNewUser' menuDisplayName='Register New User' menuLink='/Register'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Roles', roles) && isStaff &&
                    <SubMenu menuName='roleManagement' menuDisplayName='Role Management' menuLink='/RoleManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Users', roles) &&
                    <SubMenu menuName='userManagement' menuDisplayName='User Management' menuLink='/UserManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('WrapDataManagement', roles) && isStaff &&
                    <SubMenu menuName='wrapAdminDataManagement' menuDisplayName='WRAP Admin Data Management'
                             menuLink='/WRAPAdminDataManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('WrapHistorical', roles) &&
                    <SubMenu menuName='wrapHistorical' menuDisplayName='WRAP Historical' menuLink='/WRAPHistorical'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('WrapRollover', roles) && isStaff &&
                    <SubMenu menuName='wrapSeasonManagement' menuDisplayName='WRAP Season Management'
                             menuLink='/WRAPSeasonManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('WrapValidation', roles) &&
                    <SubMenu menuName='wrapValidation' menuDisplayName='WRAP Validations' menuLink='/WRAPValidations'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ReferenceDocument', roles) &&
                    <SubMenu menuName='referenceDocuments' menuDisplayName='Reference Documents'
                             menuLink='/ReferenceDocuments'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
    isStaff: state.authReducer.userRolesEntities.sppStaff,
});

export default connect(mapStateToProps)(WrapAdminMenu);