import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from '../../interfaces/grid-interfaces';
import {
    PlannedOutageData,
    PlannedOutageEditData,
} from '../../interfaces/wrap/planned-outage-interface';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState,
    numberBoundValidationOnEdit,
    processColumnMeta,
    processDropDownListOptions, removeDataErrorByValue,
    replaceSpaceWithUnderscore,
    resetOption,
    toastPreSaveValidationErrors, toastSuccessfulSave,
    updateEditedRows,
    updateEditedVerified
} from '../../services/data-grid-service';
import {
    DropDownOption,
    getDropDownCell
} from '../common/grid/DropDownCell';
import {CustomLocalizationProvider, getVerifiedCell} from '../common/grid/VerifiedCell';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import ExportPanel from "../common/action-panel/ExportPanel";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import TooltipContainer from '../common/grid/TooltipContainer';
import AddPanel from "../common/action-panel/AddPanel";
import {toast} from "react-toastify";
import {getDecimalCell} from "../common/grid/DecimalCell";
import ImportPanel from "../common/action-panel/ImportPanel";
import RemovePanel from '../common/action-panel/RemovePanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import {getRemoveCell} from '../common/grid/RemoveCell';
import RefreshPanel from "../common/action-panel/RefreshPanel";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import format from "date-fns/format";
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import {getDocumentMapCell} from '../common/grid/DocumentMapCell';
import ValidatePanel from '../common/action-panel/ValidatePanel';
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";


interface PlannedOutageProps extends PageRole{
    title: string,
    dataApi: string,
    season: 'Summer' | 'Winter',
    subyear: number
}

const facilityNmEmpty = 'Facility Name must have a value to save the record.';
const participantNmEmpty = 'Last Modifying Participant must have a value to save the record.';

const PlannedOutageGrid: React.FC<PlannedOutageProps> = ({title, isStaff, dataApi, season, subyear, readRole, addRole, modifyRole, removeRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<PlannedOutageEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<PlannedOutageData>>([]);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const globalPageSize = localStorage.getItem(`globalPageSize`)
    const [dataState, setDataState] = useState<any>({...initialDataState, take: globalPageSize ? +globalPageSize : 10, pageSize: globalPageSize ? +globalPageSize : 10});
    const [facilityNmOptions, setFacilityNmOptions] = useState<Array<DropDownOption>>([]);
    const [participantOptions, setParticipantOptions] = useState<Array<DropDownOption>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`${dataApi}/columns`)
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/user-participants')
            .then((resp) => {
                setParticipantOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/api/auth/wrap/planned-outage/dropdown/submitter-facility-names')
            .then((resp) => {
                setFacilityNmOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(dataApi), axios.get(`/api/auth/wrap/season-window/${season}`)])
            .then((resp) => {
                setData(deepCopyGridDataWithOriginal(resp[0].data));
                setOriginalData(deepCopyGridData(resp[0].data));
                setTempSequence(-10000);
                setDataErrors([]);
                if (!isStaff) {
                    if (resp[1].data === false) {
                        toast.info(`WRAP FS submittal window is closed for the ${season} season`);
                    }
                    setSeasonOpen(resp[1].data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, isStaff, readRole, season]);

    useEffect(() => {
        getData();
    }, [getData]);

    const refreshData = () => {
        getData();
    };

    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = (id: number, field: string, value: any) => {
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                
                // Clear any validation messages caused by missing required values.
                if (value && (['facilityNm','participantNm'].includes(field))) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if ((value || value === 0) && (['june', 'july', 'august', 'september', 'november', 'december', 'january', 'february', 'march'].includes(field))) {
                    numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 100000, 0);
                }
                
                return dataItem;
            } else {
                return item;
            }
        });

        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify && field === 'verified') {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
    };

    const handleUpload = (uploadedData: Array<PlannedOutageEditData>) => {
        console.log(uploadedData);
        let sequence = tempSequence;
        const localData = data.map((item) => {
            let upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                // Reset non-editable fields to default or empty value.
                // Reset option fields to valid option or empty value.
                // Direct input fields can take update value without modification. API won't set values of wrong format.

                return {
                    ...u,
                    verified: false,
                    subYearId: subyear,
                    id: sequence++,
                    participantNm: resetOption(u.participantNm, participantOptions, ''),
                    facilityNm: resetOption(u.facilityNm, facilityNmOptions, ''),
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true
                };
            })

        const dataUploaded: Array<PlannedOutageEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u as PlannedOutageEditData);
        });

        setData(dataUploaded);
        setTempSequence(sequence);
    };

    const processUploadEdits = (upload: PlannedOutageEditData, item: PlannedOutageEditData) => {
        let participantNm = resetOption(upload.participantNm, participantOptions, item.participantNm);
        if (participantNm?.toLowerCase() !== item.participantNm.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'participantNm', upload.participantNm));
        }
        let facilityNm = resetOption(upload.facilityNm, facilityNmOptions, item.facilityNm);
        if (facilityNm?.toLowerCase() !== item.facilityNm.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'facilityNm', upload.facilityNm));
        }

        const fieldsToUpdate = ['june', 'july', 'august', 'september', 'november', 'december', 'january', 'february', 'march'];

        fieldsToUpdate.forEach(field => {
            if (upload[field] !== item[field]) {
                numberBoundValidationOnEdit(upload[field], field, setDataErrors, item, columnMeta, 100000, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
        });

        const processedItem = {
            ...item,
            participantNm: upload.participantNm,
            facilityNm: upload.facilityNm
        };

        if (season === 'Summer') {
            processedItem.june = upload.june ? upload.june : 0;
            processedItem.july = upload.july ? upload.july : 0;
            processedItem.august = upload.august ? upload.august : 0;
            processedItem.september = upload.september ? upload.september : 0;
        }
        if (season === 'Winter') {
            processedItem.november = upload.november ? upload.november : 0;
            processedItem.december = upload.december ? upload.december : 0;
            processedItem.january = upload.january ? upload.january : 0;
            processedItem.february = upload.february ? upload.february : 0;
            processedItem.march = upload.march ? upload.march : 0;
        }

        return processedItem;
    };

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: PlannedOutageEditData = {
            verified: false,
            subYearId: subyear,
            id: sequence++,
            participantNm: '',
            facilityNm: '',
            lastUserModBy: '',
            lastUserModDt: null,
            verifiedBy: '',
            verifiedDt: null,
            inEdit: true,
            ...(season === 'Summer' ? {
                    june: 0,
                    july: 0,
                    august: 0,
                    september: 0
                } : season === 'Winter' ? {
                    november: 0,
                    december: 0,
                    january: 0,
                    february: 0,
                    march: 0
                } : {}
            )
        };
        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
        resetRemove();
    };

    const save = () => {
        if (filterEmptyRequiredColumns()) {
            return;
        }

        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }
        // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        //save the data
        axios.post(dataApi, newAndUpdatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const filterEmptyRequiredColumns = (): boolean => {
        const missingRequired = data.filter((item) => !item.facilityNm || !item.participantNm);

        if (missingRequired.length === 0) {
            return false;
        }
        toast.error('There are columns missing required data. Please provide value to save changes.');
        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {

            if (!item.facilityNm) {
                localErrors = addDataError(item.id, 'facilityNm', 'Facility Name', '',
                    facilityNmEmpty, true, localErrors);
            }
            if (!item.participantNm) {
                localErrors = addDataError(item.id, 'participantNm', 'Last Modifying Participant', '',
                    participantNmEmpty, true, localErrors);
            }
        });
        setDataErrors(localErrors);
        return true;
    }

    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post(`${dataApi}/verify`, updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
                toast.success("Successfully verified.");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            // initialize 'removed' field to false on all data objects
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        // take 'removed' field off data objects
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const confirmRemove = () => {
        axios.delete(`${dataApi}/delete`, {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));
                const localDataErrors = dataErrors.filter(item => !editedRemoved.includes(item.id));

                toast.success("Successfully deleted data");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    setDataErrors(localDataErrors);
                    resetRemove();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`${dataApi}/validate`)
            .then(response => {
                setValidationErrors(response.data);
                getData();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    const FacilityNmDropDownCell = useMemo(() => getDropDownCell(facilityNmOptions, 'Facility'), [facilityNmOptions]);
    const MonthMwDecimalCell = useMemo(() => getDecimalCell(1, true, 100000, 0), []);
    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);
    const ParticipantsDropDownCell = useMemo(() => getDropDownCell(participantOptions, 'ParticipantNm'), [participantOptions]);
    const documentMapCell = getDocumentMapCell(subyear, season, 'PlannedOutage');


    const formatExport = (data: Array<PlannedOutageData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO',
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/upload`} setUploadRecords={handleUpload} />}
                {!inEdit && modifyRole && seasonOpen && isStaff && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleSelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && !inVerify && modifyRole && seasonOpen && <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                    <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                        <Grid
                            data={process(data, dataState)}
                            dataItemKey='id'
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            style={{ height: "510px" }}
                            {...dataState}
                            editField='inEdit'
                            onDataStateChange={dataStateChange}
                            onItemChange={itemChange}
                            pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                            onPageChange={pageChange}
                        >
                            {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                            {
                                columnMeta.length
                                    ? columnMeta.map((col: ColumnMeta, index) => {
                                        if (col.nm === 'verified') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' cell={VerifiedCell} filter={"boolean"}/>
                                        }
                                        else if (col.nm === 'id') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' editable={false}/>
                                        }
                                        else if (col.nm === 'participantNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='195px' cell={ParticipantsDropDownCell}/>
                                        }
                                        else if (col.nm === 'facilityNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='425px' cell={FacilityNmDropDownCell}/>
                                        }
                                        if (['june', 'july', 'august', 'september','november', 'december', 'january', 'february', 'march'].includes(col.nm)) {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='140px' cell={MonthMwDecimalCell}/>
                                        }
                                        else if (col.nm === 'lastUserModBy' || col.nm === 'verifiedBy') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        }
                                        else if (col.nm === 'lastUserModDt' || col.nm === 'verifiedDt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        }
                                        else {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                        }
                                    })
                                    : <GridColumn/>
                            }
                            <GridColumn title='Linked Documents' width='200px' cell={documentMapCell}/>
                        </Grid>
                    </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'PlannedOutage'}/>}
        </div>
    );
};

export default PlannedOutageGrid;