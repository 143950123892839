import * as React from 'react';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import history from '../../history';
import {Menu} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import * as actionTypes from '../../store/actions/actionTypes';
import {logout} from '../../services/user-service';
import {TopLevelMenu} from './TopLevelMenu';
import ModDevMenu from './ModDevMenu';
import ModDevAdminMenu from './ModDevAdminMenu';
import ResAdqAdminMenu from './ResAdqAdminMenu';
import ResAdqMenu from './ResAdqMenu';
import WrapMenu from './WrapMenu';
import WrapAdminMenu from './WrapAdminMenu';
import NotificationMenu from './NotificationMenu';
import CategoryMenu from './CategoryMenu';

interface LoggedInMenuProps {
    currentUser: string;
    currentCategory: string;
    activeMenuItem: string;
    md: boolean;
    ra: boolean;
    wrap: boolean;
    updateActiveMenuItem: (activeMenuItem: string) => void;
    updateCurrentCategory: (category: string) => void;
}

const LoggedInMenu: React.FC<LoggedInMenuProps> = ({
                                                       currentUser,
                                                       currentCategory,
                                                       activeMenuItem,
                                                       md,
                                                       ra,
                                                       wrap,
                                                       updateActiveMenuItem,
                                                       updateCurrentCategory
                                                   }) => {
    const [showCategoryMenu, setShowCategoryMenu] = React.useState<boolean>(false);

    useEffect(() => {
        const categoryList: string[] = [];
        if (md) {
            categoryList.push('MDWG');
        }
        if (ra) {
            categoryList.push('RA');
        }
        if (wrap) {
            categoryList.push('WRAP');
        }
        if (categoryList.length > 1) {
            setShowCategoryMenu(true);
        }
        if (!currentCategory) {
            if (categoryList.length > 0) {
                updateCurrentCategory(categoryList[0]);
            } else {
                updateCurrentCategory('');
            }
        }
    }, [currentCategory, md, ra, showCategoryMenu, updateCurrentCategory, wrap]);

    const handleItemClick = (e: any, {name}: any) => {
        updateActiveMenuItem(name);
    };

    const handleLogOut = () => {
        logout()
            .then(() => {
                history.push({pathname: '/login'});
            })
            .catch(() => {
                console.log('Error occurred during logout.');
            });
    };

    return (
        <>
            <Menu inverted={true} className={'navMenu'}>
                <TopLevelMenu menuName='home' menuDisplayName='Home' menuLink='/' activeMenuItem={activeMenuItem}
                              updateActiveMenuItem={handleItemClick}/>
                {currentCategory === 'MDWG' && <ModDevMenu updateActiveMenuItem={handleItemClick}/>}
                {currentCategory === 'MDWG' && <ModDevAdminMenu updateActiveMenuItem={handleItemClick}/>}
                {currentCategory === 'RA' && <ResAdqMenu updateActiveMenuItem={handleItemClick}/>}
                {currentCategory === 'RA' && <ResAdqAdminMenu updateActiveMenuItem={handleItemClick}/>}
                {currentCategory === 'WRAP' && <WrapMenu updateActiveMenuItem={handleItemClick}/>}
                {currentCategory === 'WRAP' && <WrapAdminMenu updateActiveMenuItem={handleItemClick}/>}
                {currentCategory && <TopLevelMenu menuName='postings' menuDisplayName='Postings' menuLink='/Postings'
                                                  menuIcon='bullhorn'
                                                  activeMenuItem={activeMenuItem}
                                                  updateActiveMenuItem={handleItemClick}/>}
                {currentCategory && <TopLevelMenu menuName='events' menuDisplayName='Events' menuLink='/Events'
                                                  menuIcon='calendar alternate outline'
                                                  activeMenuItem={activeMenuItem}
                                                  updateActiveMenuItem={handleItemClick}/>}
                {currentCategory &&
                    <NotificationMenu activeMenuItem={activeMenuItem} updateActiveMenuItem={handleItemClick}/>}
            </Menu>
            <Menu.Menu position={'right'}>
                {showCategoryMenu && <CategoryMenu/>}
                <Menu.Item as={NavLink} to={'/ChangePassword'} exact={true}>
                    {currentUser ? `Hello ${currentUser}!` : ''}
                </Menu.Item>
                <Menu.Item onClick={handleLogOut}>Log off</Menu.Item>
            </Menu.Menu>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        activeMenuItem: state.defaultReducer.activeMenuItem,
        currentUser: state.authReducer.currentUser,
        currentCategory: state.defaultReducer.currentCategory,
        md: state.authReducer.userRolesEntities.md,
        ra: state.authReducer.userRolesEntities.ra,
        wrap: state.authReducer.userRolesEntities.wrap,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateActiveMenuItem: (activeMenuItem: string) => dispatch(
            {type: actionTypes.UPDATE_ACTIVE_MENU_ITEM, payload: activeMenuItem}),
        updateCurrentCategory: (category: string) => dispatch(
            {type: actionTypes.UPDATE_EDST_CATEGORY, payload: category}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInMenu);