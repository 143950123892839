import {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid, GridColumn, GridItemChangeEvent} from '@progress/kendo-react-grid';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import {
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    toastSuccessfulSave,
    updateEditedRows
} from '../../services/data-grid-service';
import {DataUpdate} from '../../interfaces/grid-interfaces';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import RefreshPanel from '../common/action-panel/RefreshPanel';
import {getPercentCell} from '../common/grid/PercentCell';
import {ActionPanelButton} from '../common/action-panel/ActionPanelButton';
import {SubregionPrmData, SubregionPrmEditData} from '../../interfaces/wrap/subregion-interface';
import {useCategorySeason} from '../../hooks/useCategorySeason';

export const SubregionPrmGrid = () => {
    const [data, setData] = useState<SubregionPrmEditData[]>([]);
    const [originalData, setOriginalData] = useState<SubregionPrmData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    
    const {subyear, subyearList, season, seasonList, seasonActive, handleSubyear, handleSeason} = useCategorySeason();

    const getData = useCallback(() => {
        if (subyear > 0 && season !== '') {
            const params = new URLSearchParams();
            params.set('subYearId', subyear.toString());
            params.set('season', season);
            setLoading(true);
            axios.get('/api/auth/wrap/subregion/prm', {params})
                .then(resp => {
                    setData(deepCopyGridDataWithOriginal(resp.data));
                    setOriginalData(deepCopyGridData(resp.data));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [season, subyear]);

    useEffect(() => {
        getData();
    }, [getData]);

    const handleChange = (event: GridItemChangeEvent) => {
        const {dataItem, field, value} = event;
        const {id} = dataItem;
        if (!field || !id) {
            return;
        }
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
    };
    
    const save = () => {
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        axios.post('/api/auth/wrap/subregion/prm', updatedData)
            .then(() => {
                getData();
                toastSuccessfulSave();
                setInEdit(false);
                setEditedRows([]);
            });
    };

    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
    };

    const PrmPercentCell = useMemo(() => getPercentCell(1, true), []);

    return (
        <div>
            <ActionPanel title={'Subregion PRM %'}>
                {!inEdit && <ActionPanelButton>
                    <DropDownList
                        data={subyearList}
                        value={subyear}
                        onChange={handleSubyear}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>}
                {!inEdit && <ActionPanelButton>
                    <DropDownList
                        data={seasonList}
                        value={season}
                        onChange={handleSeason}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>}
                {seasonActive && <SavePanel inEdit={inEdit} save={save} toggleEdit={toggleEdit}/>}
                {!inEdit && <RefreshPanel disabled={inEdit} loading={loading} getData={getData}/>}
            </ActionPanel>
                <Grid data={data} onItemChange={handleChange} editField='inEdit'>
                    <GridColumn title='Subregion' field='subregionNm' editable={false}/>
                    <GridColumn title={season === 'Summer' ? `June ${subyear}` : `November ${subyear}`} field='month1' cell={PrmPercentCell}/>
                    <GridColumn title={season === 'Summer' ? `July ${subyear}` : `December ${subyear}`} field='month2' cell={PrmPercentCell}/>
                    <GridColumn title={season === 'Summer' ? `August ${subyear}` : `January ${subyear + 1}`} field='month3' cell={PrmPercentCell}/>
                    <GridColumn title={season === 'Summer' ? `September ${subyear}` : `February ${subyear + 1}`} field='month4' cell={PrmPercentCell}/>
                    {season === 'Winter' && <GridColumn title={`March ${subyear + 1}`} field='month5' cell={PrmPercentCell}/>}
                </Grid>
        </div>
    );
};