import {DatePicker} from "@progress/kendo-react-dateinputs";
import {GridCellProps} from "@progress/kendo-react-grid";
import {format, startOfMinute} from 'date-fns';
import {DATE_ONLY_FORMAT} from '../../../services/data-grid-service';

// Use DateCell in cell prop of GridColumn
export const DateNoTimeCell = (props: GridCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const dateValue = field && dataItem[field]
        ? dataItem[field]
        : undefined;
    const value = dateValue ? format(dateValue, DATE_ONLY_FORMAT) : '';
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = field && originalData && originalData[field] ? format(originalData[field], DATE_ONLY_FORMAT) : '';
    const isChanged = originalValue !== value;
    
    const onChange = (e: any) => {
        const datetime = e.target.value ? startOfMinute(e.target.value) : null;
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: datetime,
            });
        }
    };
    
    // KendoReact DatePicker default max year is 2099.
    // Previous date picker had default max year of 2100.
    // Setting this max year to 2100 prevents old max year values from displaying with error styling.
    // JavaScript Date uses 0 index for months so 11 = December.
    const max = new Date(2100,11,31);
    
    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{textAlign: 'center', backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit
                ? isChanged
                    ? <DatePicker
                        name={field}
                        value={dateValue}
                        max={max}
                        format={DATE_ONLY_FORMAT}
                        onChange={onChange}
                        title={originalValue}
                    />
                    : <DatePicker
                        name={field}
                        value={dateValue}
                        max={max}
                        format={DATE_ONLY_FORMAT}
                        onChange={onChange}
                    />
                : value
            }
        </td>
    );
};