import React, {useCallback, useEffect} from 'react';
import * as actionTypes from '../../store/actions/actionTypes';
import {connect} from 'react-redux';
import {Menu} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import NotificationIcon from '../NotificationIcon';
import axios from 'axios';
import {toast} from 'react-toastify';

interface NotificationMenuProps {
    activeMenuItem: string;
    updateActiveMenuItem: (e: any, {name}: any) => void;
    notificationCount: number;
    updateNotificationCount: (notificationCount: number) => void;
}

const NotificationMenu: React.FC<NotificationMenuProps> = ({
                                                               activeMenuItem,
                                                               updateActiveMenuItem,
                                                               notificationCount,
                                                               updateNotificationCount
                                                           }) => {
    const getNotificationCount = useCallback(() => {
        axios.get('/api/auth/notificationTracking/unreadNotificationsCount')
            .then(resp => {
                updateNotificationCount(resp.data);
            })
            .catch(error => {
                toast.error(`Error retrieving unread notification count - ${error.message}`);
            });
    }, [updateNotificationCount]);

    useEffect(() => {
        getNotificationCount();
    }, [getNotificationCount]);

    return (
        <Menu.Item
            className='navMenu'
            name={'notifications'}
            active={activeMenuItem === 'notifications'}
            as={NavLink}
            to={'/NotificationTracking'}
            exact={true}
            onClick={updateActiveMenuItem}
        >
            <NotificationIcon notificationCount={notificationCount}
                              menuIcon='bell outline'/>
        </Menu.Item>
    );
};

const mapStateToProps = (state: any) => {
    return {
        notificationCount: state.defaultReducer.notificationCount,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateNotificationCount: (notificationCount: number) => dispatch(
            {type: actionTypes.UPDATE_NOTIFICATION_COUNT, payload: notificationCount}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMenu);