import {DatePicker} from "@progress/kendo-react-dateinputs";
import {GridCellProps} from "@progress/kendo-react-grid";
import {isDifferent} from '../../../services/data-grid-service';

// Import one of these to use in format prop of GridColumn where cell=DateCell.
export const DATE_ONLY_FORMAT = 'MM/dd/y';
export const DATE_TIME_FORMAT = 'MM/dd/y h:mm a';

// Use DateCell in cell prop of GridColumn
export const DateCell = (props: GridCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, field} = props;
    const {inEdit, originalData} = dataItem;
    const value = field && dataItem[field]
        ? props.format === DATE_ONLY_FORMAT
            ? new Date(Date.parse(dataItem[field])).toLocaleDateString()
            : dataItem[field]
        : '';
    const dateValue = value ? new Date(Date.parse(value)) : undefined;
    const tdClassName = inEdit ? 'k-table-td k-grid-edit-cell' : 'k-table-td';
    const originalValue = originalData && originalData[field || ''];
    const isChanged = isDifferent(dataItem[field || ''], originalValue);
    
    const onChange = (e: any) => {
        const parsedValue = e.target.value ? e.target.value.toISOString() : null;
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: parsedValue,
            });
        }
    };
    
    // KendoReact DatePicker default max year is 2099.
    // Previous date picker had default max year of 2100.
    // Setting this max year to 2100 prevents old max year values from displaying with error styling.
    // JavaScript Date uses 0 index for months so 11 = December.
    const max = new Date(2100,11,31);
    
    return (
        <td
            colSpan={1}
            className={tdClassName}
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            style={{textAlign: 'center', backgroundColor: isChanged ? '#5cd65c' : 'transparent'}}
        >
            {inEdit
                ? isChanged
                    ? <DatePicker
                        name={field}
                        value={dateValue}
                        max={max}
                        format={props.format}
                        onChange={onChange}
                        title={originalValue}
                    />
                    : <DatePicker
                        name={field}
                        value={dateValue}
                        max={max}
                        format={props.format}
                        onChange={onChange}
                    />
                : value
                    ? value
                    : ''
            }
        </td>
    );
};