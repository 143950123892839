import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import format from 'date-fns/format';
import {process} from '@progress/kendo-data-query';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import {Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent} from "@progress/kendo-react-grid";
import {TsrInformationData, TsrInformationEditData} from '../../interfaces/wrap/tsr-information-interface';
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from "../../interfaces/grid-interfaces";
import {
    addDataError, DATE_ONLY_FORMAT,
    deepCopyGridData,
    deepCopyGridDataWithOriginal, formatTimestamp,
    initialDataState,
    numberBoundValidationOnEdit,
    parseTimestampString,
    processColumnMeta,
    processDropDownListOptions, removeDataErrorByMessage, removeDataErrorByValue,
    replaceSpaceWithUnderscore, resetChildOption,
    resetOption, toastPreSaveValidationErrors,
    toastSuccessfulSave,
    updateEditedRows,
    updateEditedVerified
} from '../../services/data-grid-service';
import {GridPageChangeEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import TooltipContainer from "../common/grid/TooltipContainer";
import {CustomLocalizationProvider, getVerifiedCell} from "../common/grid/VerifiedCell";
import {DropDownOption, getDropDownCell, getYesNoDropDownCell} from "../common/grid/DropDownCell";
import {getDecimalCell} from "../common/grid/DecimalCell";
import {InputCell} from "../common/grid/InputCell";
import ImportPanel from "../common/action-panel/ImportPanel";
import {getRemoveCell} from "../common/grid/RemoveCell";
import ActionPanel from "../common/action-panel/ActionPanel";
import AddPanel from "../common/action-panel/AddPanel";
import RemovePanel from "../common/action-panel/RemovePanel";
import SavePanel from "../common/action-panel/SavePanel";
import VerifyPanel from "../common/action-panel/VerifyPanel";
import ExportPanel from "../common/action-panel/ExportPanel";
import RemoveModal from "../common/action-panel/RemoveModal";
import ValidatePanel from "../common/action-panel/ValidatePanel";
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import ValidationPreSaveGrid from "../common/grid/ValidationPreSaveGrid";
import {getDocumentMapCell} from '../common/grid/DocumentMapCell';
import {getOnBlurCell} from "../common/grid/OnBlurCell";
import {ChildDropDownOption} from "../common/grid/ChildDropDownCell";
import {DateNoTimeCell} from '../common/grid/DateNoTimeCell';

const participantNmEmpty = 'Last Modifying Participant must have a value to save the record.';
const exemptionEmpty = 'Exemption Requested must have a value to save the record.';
const startDateEmpty = 'Start Date must have a value to save the record.';
const stopDateEmpty = 'Stop Date must have a value to save the record.';
const oasisEmpty = 'Oasis Reference must have a value to save nonexempt record.';
const tspEmpty = 'Transmission Service Provider must have a value to save nonexempt record.';
const porEmpty = 'Point of Receipt must have a value to save nonexempt record.';
const podEmpty = 'Point of Delivery must have a value to save nonexempt record.';
const nercPriorityEmpty = 'NERC Priority must have a value to save nonexempt record.';
const oasisUnique = 'OASIS Ref Number must be unique. This number is in another record.';


interface TsrInformationGridProps extends PageRole {
    title: string,
    dataApi: string,
    season: 'Summer' | 'Winter',
    subyear: number
}

const TsrInformationGrid: React.FC<TsrInformationGridProps> = ({title, dataApi, season, subyear, isStaff, readRole, addRole, modifyRole, removeRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<TsrInformationEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<TsrInformationData>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [subYear, setSubYear] = useState<number>(0);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [participantOptions, setParticipantOptions] = useState<Array<DropDownOption>>([]);
    const [nercPriorityOptions, setNercPriorityOptions] = useState<Array<DropDownOption>>([]);
    const [serviceTypeOptions, setServiceTypeOptions] = useState<Array<ChildDropDownOption>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    const [dataState, setDataState] = useState<any>({
        ...initialDataState,
        take: globalPageSize ? +globalPageSize : 10,
        pageSize: globalPageSize ? +globalPageSize : 10
    });
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

    // columns from api
    useEffect(() => {
        axios.get(`${dataApi}/columns`)
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dataApi]);

    // current subyear from api
    useEffect(() => {
        axios.get('/api/auth/wrap/subyear/current')
            .then((resp) => {
                setSubYear(resp.data);
            })
    }, []);

    //participant names dropdown from api
    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/user-participants')
            .then((resp) => {
                setParticipantOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // summer and winter data from api
    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get(`${dataApi}`), axios.get(`/api/auth/wrap/season-window/${season}`)])
            .then((resp) => {
                const localData = resp[0].data.map((d: any) => ({
                    ...d,
                    startDate: parseTimestampString(d.startDate),
                    stopDate: parseTimestampString(d.stopDate)
                }));
                setData(deepCopyGridDataWithOriginal(localData));
                setOriginalData(deepCopyGridData(localData));
                setTempSequence(-10000);
                setDataErrors([]);
                if (!isStaff) {
                    if (resp[1].data === false) {
                        toast.info(`WRAP FS submittal window is closed for the ${season} season`);
                    }
                    setSeasonOpen(resp[1].data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [dataApi, isStaff, readRole, season]);

    useEffect(() => {
        getData();
    }, [getData]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };

    const handleChange = (id: number, field: string, value: any) => {
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                // Clear any validation messages caused by missing required values. 
                if (value && (['participantNm', 'exemption', 'startDate', 'stopDate'].includes(field))) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if (dataItem.exemption === 'NO') { // The following fields are not required if there is an exemption. Otherwise, they are required.
                    if (value && (['oasis', 'transmissionServiceProvider', 'pointOfReceipt', 'pointOfDelivery', 'nercPriority'].includes(field))) {
                        setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                    }
                }
                if (field === 'exemption' && dataItem.exemption === 'YES') {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'oasis', prevDataErrors, ''));
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'transmissionServiceProvider', prevDataErrors, ''));
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'pointOfReceipt', prevDataErrors, ''));
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'pointOfDelivery', prevDataErrors, ''));
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, 'nercPriority', prevDataErrors, ''));
                }
                if ((value || value === 0) && (['june', 'july', 'august', 'september', 'november', 'december', 'january', 'february', 'march'].includes(field))) {
                    numberBoundValidationOnEdit(value, field, setDataErrors, item, columnMeta, 100000, 0);
                }
                if (field === 'nercPriority') {    //getting service type values based on nerc priority
                    const option = serviceTypeOptions.find((item) => item?.parentValue === dataItem.nercPriority);
                    dataItem.serviceType = option?.value as string;
                }
                return dataItem;
            } else {
                return item;
            }
        });

        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify&& field === 'verified') {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
    };

    useEffect(() => {
        axios.get('/api/auth/wrap/tsr-information/dropdown/nerc-priority')
            .then((resp) => {
                const nercPriorityData = processDropDownListOptions(resp.data
                    .map((option: { nercPriorityName: any; }) => option.nercPriorityName));
                const serviceTypeData = resp.data
                    .map((item: any) => ({
                        text: item.serviceTypeName,
                        value: item.serviceTypeName,
                        parentValue: item.nercPriorityName
                    }));
                serviceTypeData.sort((a: any, b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
                setNercPriorityOptions(nercPriorityData);
                setServiceTypeOptions(serviceTypeData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    //refresh data
    const refreshData = () => {
        getData();
    };

    // clear all the filters
    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get(`${dataApi}/validate`)
            .then(response => {
                setValidationErrors(response.data);
                getData();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    const generateDefaultRow = (sequence: number) => ({
        verified: false,
        subYearId: subYear,
        id: sequence,
        participantNm: '',
        oasis: '',
        exemption: '',
        transmissionServiceProvider: '',
        pointOfReceipt: '',
        pointOfDelivery: '',
        source: '',
        sink: '',
        nercPriority: '',
        serviceType: '',
        path: '',
        startDate: null,
        stopDate: null,
        lastUserModBy: '',
        lastUserModDt: null,
        verifiedBy: '',
        verifiedDt: null,
        inEdit: true,
        ...(season === 'Summer' ? {
                june: 0,
                july: 0,
                august: 0,
                september: 0
            } : season === 'Winter' ? {
                november: 0,
                december: 0,
                january: 0,
                february: 0,
                march: 0
            } : {}
        ),
    });

    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: TsrInformationEditData = generateDefaultRow(sequence++);

        defaultNewRow.originalData = {...defaultNewRow};

        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };

    //Edit mode
    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }

    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };

    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
        resetRemove();
    };

    // Remove mode
    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }

    };

    const cancelRemove = () => {
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };

    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const confirmRemove = () => {
        axios.delete(`${dataApi}/delete`, {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                const localEdit = editedRows.filter(item => !editedRemoved.includes(item.id));
                const localDataErrors = dataErrors.filter(item => !editedRemoved.includes(item.id));

                toast.success("Successfully deleted data");

                if (!localEdit.length && !localData.some(item => item.id < 0)) {
                    cancelEdits();
                } else {
                    // cancelEdits calls the same functions, but sets them to different values.
                    setData(localData);
                    setEditedRows(localEdit);
                    setDataErrors(localDataErrors);
                    resetRemove();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };

    const filterEmptyRequiredColumns = (): boolean => {
        const missingRequired = data.filter(item =>
            !item.participantNm
            || !item.exemption
            || !item.startDate
            || !item.stopDate
            || (item.exemption === 'NO'
                && (
                    !item.oasis
                    || !item.transmissionServiceProvider
                    || !item.pointOfReceipt
                    || !item.pointOfDelivery
                    || !item.nercPriority
                ))
        );

        if (missingRequired.length === 0) {
            return false;
        }

        toast.error('There are columns missing required data. Please provide value to save changes.');

        // Return all errors for each item that has any missing values.
        // Don't just return a single missing value when there are multiple because then the user has to attempt save multiple times to find all the errors. That's frustrating. 
        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {
            if (!item.participantNm) {
                localErrors = addDataError(item.id, 'participantNm', 'Last Modifying Participant', '', participantNmEmpty, true, localErrors);
            }
            if (!item.exemption) {
                localErrors = addDataError(item.id, 'exemption', 'Exemption Requested', '', exemptionEmpty, true, localErrors);
            }
            if (!item.startDate) {
                localErrors = addDataError(item.id, 'startDate', 'Start Date', '', startDateEmpty, true, localErrors);
            }
            if (!item.stopDate) {
                localErrors = addDataError(item.id, 'stopDate', 'Stop Date', '', stopDateEmpty, true, localErrors);
            }
            if (item.exemption === 'NO') {
                if (!item.oasis) {
                    localErrors = addDataError(item.id, 'oasis', 'Oasis', '', oasisEmpty, true, localErrors);
                }
                if (!item.transmissionServiceProvider) {
                    localErrors = addDataError(item.id, 'transmissionServiceProvider', 'Transmission Service Provider', '', tspEmpty, true, localErrors);
                }
                if (!item.pointOfReceipt) {
                    localErrors = addDataError(item.id, 'pointOfReceipt', 'Point of Receipt', '', porEmpty, true, localErrors);
                }
                if (!item.pointOfDelivery) {
                    localErrors = addDataError(item.id, 'pointOfDelivery', 'Point of Delivery', '', podEmpty, true, localErrors);
                }
                if (!item.nercPriority) {
                    localErrors = addDataError(item.id, 'nercPriority', 'NERC Priority', '', nercPriorityEmpty, true, localErrors);
                }
            }
        });

        setDataErrors(localErrors);
        return true;
    };

    const save = () => {
        if (filterEmptyRequiredColumns()) {
            return;
        }
        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        if (dataErrors.filter((error) => error.blocking).length > 0) {
            toastPreSaveValidationErrors();
            return;
        }
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }

        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));

        axios.post(dataApi, newAndUpdatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //verify mode
    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };

    const enterVerifyMode = () => {
        setInVerify(true);
    };

    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }

    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) {
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post(`${dataApi}/verify`, updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleVerifySelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    const handleUpload = (uploadedData: Array<TsrInformationEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            const upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });

        const newUploadData = uploadedData
            .filter((u) => u.id <= 0)
            .map((u) => {
                const item = {
                    ...u,
                    id: sequence,
                    subYearId: subYear,
                    verified: false,
                    participantNm: resetOption(u.participantNm, participantOptions, ''),
                    nercPriority: resetOption(u.nercPriority, nercPriorityOptions, ''),
                    serviceType: resetChildOption(u.nercPriority, u.serviceType, serviceTypeOptions, ''),
                    startDate: parseTimestampString(u.startDate),
                    stopDate: parseTimestampString(u.stopDate),
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true,
                    ...(season === 'Summer' ? {
                            june: u.june ? u.june : 0,
                            july: u.july ? u.july : 0,
                            august: u.august ? u.august : 0,
                            september: u.september ? u.september : 0
                        } : season === 'Winter' ? {
                            november: u.november ? u.november : 0,
                            december: u.december ? u.december : 0,
                            january: u.january ? u.january : 0,
                            february: u.february ? u.february : 0,
                            march: u.march ? u.march : 0
                        } : {}
                    ),
                    originalData: generateDefaultRow(sequence)
                };
                sequence = sequence + 1;
                return item;
            });

        const dataUploaded: Array<TsrInformationEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u);
        });

        setData(dataUploaded);
        setTempSequence(sequence);
    };

    const processUploadEdits = (upload: TsrInformationEditData, item: TsrInformationEditData) => {
        let participantNm = resetOption(upload.participantNm, participantOptions, item.participantNm);
        if (participantNm?.toLowerCase() !== item.participantNm.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'participantNm', participantNm));
        }
        if (upload.oasis !== item.oasis) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'oasis', upload.oasis));
        }
        if (upload.exemption !== item.exemption) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'exemption', upload.exemption));
        }
        if (upload.transmissionServiceProvider !== item.transmissionServiceProvider) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'transmissionServiceProvider', upload.transmissionServiceProvider));
        }
        if (upload.pointOfReceipt !== item.pointOfReceipt) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'pointOfReceipt', upload.pointOfReceipt));
        }
        if (upload.pointOfDelivery !== item.pointOfDelivery) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'pointOfDelivery', upload.pointOfDelivery));
        }
        if (upload.source !== item.source) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'source', upload.source));
        }
        if (upload.sink !== item.sink) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'sink', upload.sink));
        }
        let nercPriority = resetOption(upload.nercPriority, nercPriorityOptions, item.nercPriority);
        if (nercPriority?.toLowerCase() !== item.nercPriority.toLowerCase()) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'nercPriority', nercPriority));
        }
        if (upload.path !== item.path) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'path', upload.path));
        }
        if (upload.startDate !== formatTimestamp(item.startDate)) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'startDate', upload.startDate, 'datetime'));
        }
        if (upload.stopDate !== formatTimestamp(item.stopDate)) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'stopDate', upload.stopDate, 'datetime'));
        }

        const fieldsToUpdate = ['june', 'july', 'august', 'september', 'november', 'december', 'january', 'february', 'march'];

        fieldsToUpdate.forEach(field => {
            if (upload[field] !== item[field]) {
                numberBoundValidationOnEdit(upload[field], field, setDataErrors, item, columnMeta, 100000, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
        });

        const processedItem = {
            ...item,
            participantNm,
            oasis: upload.oasis,
            exemption: upload.exemption,
            transmissionServiceProvider: upload.transmissionServiceProvider,
            pointOfReceipt: upload.pointOfReceipt,
            pointOfDelivery: upload.pointOfDelivery,
            source: upload.source,
            sink: upload.sink,
            nercPriority,
            path: upload.path,
            startDate: parseTimestampString(upload.startDate),
            stopDate: parseTimestampString(upload.stopDate)
        };

        if (season === 'Summer') {
            processedItem.june = upload.june ? upload.june : 0;
            processedItem.july = upload.july ? upload.july : 0;
            processedItem.august = upload.august ? upload.august : 0;
            processedItem.september = upload.september ? upload.september : 0;
        }
        if (season === 'Winter') {
            processedItem.november = upload.november ? upload.november : 0;
            processedItem.december = upload.december ? upload.december : 0;
            processedItem.january = upload.january ? upload.january : 0;
            processedItem.february = upload.february ? upload.february : 0;
            processedItem.march = upload.march ? upload.march : 0;
        }

        return processedItem;
    };

    //export mode
    const formatExport = (data: Array<TsrInformationData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO'
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    //page size
    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };

    const handleOasisBlur = (id: number, field: string, value: string) => {
        handleChange(id, field, value);
        let params = new URLSearchParams();
        params.set('oasis', value);
        params.set('id', id.toString());
        params.set('season', season);
        axios.get('/api/auth/wrap/tsr-information/unique/oasis', {params})
            .then((resp) => {
                if (resp.data === false) {
                    toast.error(oasisUnique);
                    setDataErrors((prevDataErrors) => addDataError(id, field, 'OASIS Ref Number', value, oasisUnique, true, prevDataErrors));
                } else {
                    setDataErrors((prevDataErrors) => removeDataErrorByMessage(id, field, prevDataErrors, oasisUnique));
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Error occurred while validating Oasis number.');
            });
    };

    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);
    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const ParticipantCell = useMemo(() => getDropDownCell(participantOptions, 'Participant'), [participantOptions]);
    const DecimalCell = useMemo(() => getDecimalCell(1, true, 100000, 0), []);
    const ExemptionCell = getYesNoDropDownCell();
    const NercPriorityCell = useMemo(() => getDropDownCell(nercPriorityOptions, 'NERC Priority'), [nercPriorityOptions]);
    const documentMapCell = getDocumentMapCell(subyear, season, 'TsrInformation');
    const OasisCell = getOnBlurCell(handleOasisBlur);

    return (
        <div>
            <ActionPanel title={title}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {inEdit && removeRole && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api={`${dataApi}/import`} setUploadRecords={handleUpload}/>}
                {isStaff && modifyRole && seasonOpen && !inEdit && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleVerifySelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && !inVerify && modifyRole && seasonOpen && <ValidatePanel disabled={(inEdit || inVerify)} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                    <ExcelExport data={data} fileName={replaceSpaceWithUnderscore(`${title}_${format(new Date(),'MM-dd-yyyy')}.xlsx`)} ref={_export}>
                        <Grid
                            data={process(data, dataState)}
                            dataItemKey='id'
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            style={{ height: "510px" }}
                            {...dataState}
                            editField='inEdit'
                            onDataStateChange={dataStateChange}
                            onItemChange={itemChange}
                            pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                            onPageChange={pageChange}
                        >
                            {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                            {
                                columnMeta.length
                                    ? columnMeta.map((col: ColumnMeta, index) => {
                                        if (col.nm === 'id') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' editable={false}/>
                                        } else if (col.nm === 'subYearId'){
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' editable={false}/>
                                        } else if (col.nm === 'verified') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px'  cell={VerifiedCell} filter={"boolean"}/>
                                        } else if (col.nm === 'participantNm') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={ParticipantCell}/>
                                        } else if (col.nm === 'oasis') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={OasisCell}/>
                                        } else if (col.nm === 'exemption') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='175px' cell={ExemptionCell}/>
                                        } else if (col.nm === 'transmissionServiceProvider') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={InputCell}/>
                                        } else if (col.nm === 'pointOfReceipt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={InputCell}/>
                                        } else if (col.nm === 'pointOfDelivery') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={InputCell}/>
                                        } else if (col.nm === 'source') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={InputCell}/>
                                        } else if (col.nm === 'sink') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={InputCell}/>
                                        } else if (col.nm === 'nercPriority') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={NercPriorityCell}/>
                                        } else if (col.nm === 'serviceType') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='179px' editable={false}/>
                                        } else if (col.nm === 'path') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='116px' cell={InputCell}/>
                                        } else if (col.nm === 'startDate' || col.nm === 'stopDate') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' cell={DateNoTimeCell} format={DATE_ONLY_FORMAT} filter={"date"}/>
                                        } else if (season === 'Summer' && ['june', 'july', 'august', 'september'].includes(col.nm)) {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='244px' cell={DecimalCell}/>
                                        } else if (season === 'Winter' && ['october', 'november', 'december', 'january', 'february', 'march'].includes(col.nm)) {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='244px' cell={DecimalCell}/>
                                        } else if (col.nm === 'lastUserModBy' || col.nm === 'verifiedBy') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='180px' editable={false}/>
                                        } else if (col.nm === 'lastUserModDt' || col.nm === 'verifiedDt') {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        } else {
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                        }
                                    })
                                    : <GridColumn/>
                            }
                            <GridColumn title='Linked Documents' width='200px' cell={documentMapCell}/>
                        </Grid>
                    </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'TsrInformation'}/>}
        </div>
    );
};

export default TsrInformationGrid;
