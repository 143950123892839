import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import { AccordionWrapper } from '../common/grid/AccordionWrapper';
import ResourceGrid from "./ResourceGrid";

const ResourcePage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);

    useEffect(() => {
        setPageRole(getCompressedPageRole('Resource', roles));
    }, [roles]);

    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 50 }}>
            <Header size={'large'}>Resources</Header>
            <AccordionWrapper accordionHeader={'Resources Summer'}>
                <ResourceGrid
                    {...pageRole}
                    title='Resources Summer'
                    dataApi='/api/auth/wrap/resource/summer'
                    season='Summer'
                />
            </AccordionWrapper>
            <br/>
            <AccordionWrapper accordionHeader={'Resources Winter'}>
                <ResourceGrid
                    {...pageRole}
                    title='Resources Winter'
                    dataApi='/api/auth/wrap/resource/winter'
                    season='Winter'
                />
            </AccordionWrapper>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles
});

export default connect(mapStateToProps, {})(ResourcePage);