import React, {useState} from 'react';
import axios from 'axios';
import {Button} from '@progress/kendo-react-buttons';
import {toast} from "react-toastify";
import {Loader} from "@progress/kendo-react-indicators";

export interface WrapBatchReportProcessProps {
    //seasons: Array<CategorySeason>,
    //getSeasons: () => void
}

const WrapBatchReportProcess: React.FC<WrapBatchReportProcessProps> = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const runBatchReport = () => {
        setLoading(true);
        axios.get('/api/auth/wrap/forward-showing/run-season-batch-report')
            .then((resp) => {
                console.log(resp.data);
                toast.success('Batch Report is complete.');
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 500) {
                    toast.error('Run of Batch Report canceled due to error. Please contact EDST IT support.');
                } else if (error.response?.status === 504) {
                    toast.error('Run of Batch Report is taking a while.');
                } else {
                    toast.error(`Error during run of Batch Report: ${error.message}`);
                }
            })
            .finally(() => {
            setLoading(false);
            });
    };

    return (
        <Button onClick={runBatchReport}>Run Batch Report {loading && <Loader size="small" type="pulsing" />}</Button>
    );
};

export default WrapBatchReportProcess;