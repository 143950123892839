import {connect} from "react-redux";
import 'react-redux';
import ChangesetDetails from '../common/ChangesetDetails';
import {FC} from "react";
import HomeRaGrid from "./HomeRaGrid";
import HomeWrapGrid from "./HomeWrapGrid";

interface MdAndRaHomeWrapperProps {
    currentCategory: string,
}

const MdAndRaHomeWrapper: FC<MdAndRaHomeWrapperProps> = ({currentCategory}) => {
    return (
        <div>
            <br/>
            <br/>
            {
                currentCategory === 'MDWG'
                    ? <ChangesetDetails currentCategory={currentCategory}/>
                    : currentCategory === 'RA'
                        ? <HomeRaGrid/>
                        : currentCategory === 'WRAP'
                            ? <HomeWrapGrid/>
                            : null
            }
        </div>
    )
};

const mapStateToProps = (state: any) => {
    return {
        currentCategory: state.defaultReducer.currentCategory,
    }
};

export default connect(mapStateToProps,{})(MdAndRaHomeWrapper);