import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface RowEditRemoveModalProps {
    closeModal: () => void;
    confirmRemove: () => void;
    open: boolean;
}

const RowEditRemoveModal: React.FC<RowEditRemoveModalProps> = ({ closeModal, confirmRemove, open }) => {
    return (
        <Modal open={open} onClose={closeModal}>
            <Modal.Header>Confirm Removal</Modal.Header>
            <Modal.Content>
                <p>Are you sure you want to remove this dropdown option?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={confirmRemove}>Yes</Button>
                <Button onClick={closeModal}>No</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default RowEditRemoveModal;
