import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import React, {useState} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import {toast} from 'react-toastify';
import axios from 'axios';
import {Loader} from "@progress/kendo-react-indicators";

interface ImportPanelProps {
    api: string,
    setUploadRecords: (uploadRecords: Array<any>) => void
}

const ImportPanel: React.FC<ImportPanelProps> = ({api, setUploadRecords}) => {
    const [file, setFile] = useState();
    const [importing, setImporting] = useState<boolean>(false);

    // Ensure that file content is fresh when user selects file, alters file, and selects same file again.
    const clearFile = (event: any) => {
        event.target.value = null;
        setFile(undefined);
    };

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        if (selectedFile.name.toLowerCase().endsWith('xlsx')) {
            setFile(selectedFile);
        } else {
            toast.warn('You can only upload XLSX files');
        }
    };

    const handleFileUpload = () => {
        if (!file) {
            return;
        }
        setImporting(true);
        if ((file as File).size > 20 * 1024) { // 20KB
            toast.info('Uploading a large set of records may take a few minutes.');
        }

        const formData = new FormData();
        formData.append('file', file);

        axios.post(api, formData)
            .then((resp) => {
                setUploadRecords(resp.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setImporting(false);
                toast.success('Import complete.');
            });
    };
    return (
        <>
            <AppBarSection>
                <input type="file" name="upload" onClick={clearFile} onChange={handleFileChange} />
                <Button disabled={!file || importing} onClick={handleFileUpload}>
                    Import
                    {importing && <Loader size="small" type="pulsing" />}
                </Button>
            </AppBarSection>
            <AppBarSpacer style={{ width: 4 }} />
        </>
    );
};

export default ImportPanel;