import {Grid, GridCellProps, GridColumn} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import React, {useState} from 'react';
import {Dropdown, DropdownItemProps, DropdownProps, Grid as DisplayGrid, GridColumn as DisplayColumn, GridRow as DisplayRow, Modal, ModalActions, ModalContent} from 'semantic-ui-react';
import {DocumentMap, WrapDocumentData} from '../../../interfaces/wrap/wrap-document-interface';
import {getFileCell} from './FileCell';
import {Loader} from '@progress/kendo-react-indicators';
import axios from 'axios';
import {toast} from 'react-toastify';

interface DocumentMapCellProps extends GridCellProps {
    subyear: number;
    season: string;
    dataSet: string;
}

export const DocumentMapCell = (props: DocumentMapCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem, subyear, season, dataSet} = props;
    const recordKey = dataItem['id'];
    
    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {recordKey > 0 && <DocumentMapModal subyear={subyear} season={season} dataSet={dataSet} recordKey={recordKey}/>}
        </td>
    );
};

export const getDocumentMapCell = (subyear: number, season: string, dataSet: string) => {
    return (
        (props: GridCellProps) => <DocumentMapCell {...props} subyear={subyear} season={season} dataSet={dataSet}/>
    );
};

interface DocumentMapModalProps {
    subyear: number;
    season: string;
    dataSet: string;
    recordKey: number;
}

const DocumentMapModal: React.FC<DocumentMapModalProps> = ({subyear, season, dataSet, recordKey}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [docMap, setDocMap] = useState<Array<DocumentMap>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const openModal = () => {
        setLoading(true);
        const params = new URLSearchParams();
        params.set('subyear', subyear.toString());
        params.set('season', season);
        params.set('dataSet', dataSet);
        params.set('recordKey', recordKey.toString());
        axios.get('/api/auth/wrap/document/record-documents', {params})
            .then(resp => {
                setDocMap(resp.data.map(processDocumentMapList));
            })
            .then(() => {
                setOpen(true);
            })
            .catch(() => {
                toast.error('Error: Unable to load linked documents.');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    const processDocumentMapList = (d: DocumentMap) => ({
        id: d.id,
        subYearId: subyear,
        season: season,
        dataSet,
        recordKey,
        documentId: d.documentId,
        documentSubYearId: d.documentSubYearId,
        documentSeason: d.documentSeason,
        documentParticipantNm: d.documentParticipantNm,
        documentName: d.documentName,
        filename: d.filename,
        documentType: d.documentType,
        editAccess: d.editAccess
    });
    
    const getDocumentMapping = () => {
        return new Promise((reject) => {
            const params = new URLSearchParams();
            params.set('subyear', subyear.toString());
            params.set('season', season);
            params.set('dataSet', dataSet);
            params.set('recordKey', recordKey.toString());
            axios.get('/api/auth/wrap/document/record-documents', {params})
                .then(resp => {
                    setDocMap(resp.data.map(processDocumentMapList));
                })
                .catch(error => reject(error));
        });
    };
    
    const addMapping = (documentId: number, documentSubYearId: number, documentSeason: string) => {
        const documentMap: DocumentMap = {
            id: -1,
            subYearId: subyear,
            season: season,
            dataSet,
            recordKey,
            documentId,
            documentSubYearId,
            documentSeason,
            documentParticipantNm: '',
            documentName: '',
            filename: '',
            documentType: '',
            editAccess: true
        };
        axios.post('/api/auth/wrap/document/map', documentMap)
            .then(() => {
                getDocumentMapping();
                toast.success('Document successfully linked to record.');
            })
            .catch(() => {
                toast.error('Error: Document was not linked to record.');
            })
    };
    
    const removeMapping = (mapId: number) => {
        // call API then getDocumentMap
        axios.delete(`/api/auth/wrap/document/map/${season}/${mapId}`)
            .then(() => {
                getDocumentMapping();
            })
            .then(() => {
                toast.success('Document successfully unlinked from record.');
            });
    };
    
    const closeModal = () => {
        setOpen(false);
        setDocMap([]);
    };
    
    const fileCell = getFileCell('documentId', 'documentSubYearId', 'documentSeason');
    const removeDocumentLinkCell = getRemoveDocumentLinkCell(removeMapping);
    
    return (
        <Modal
            open={open}
            onClose={closeModal}
            closeIcon={true}
            size='fullscreen'
            trigger={
                <Button onClick={openModal} themeColor='primary'>
                    Documents
                    {loading && <Loader size="small" type='pulsing' themeColor='light'/>}
                </Button>
            }
        >
            <Modal.Header>Documents Associated with Record</Modal.Header>
            <ModalContent>
                <AddDocumentLinkModal season={season} dataSet={dataSet} recordKey={recordKey} addMapping={addMapping}/>
                <br/><br/>
                <Grid
                    data={docMap}
                    style={{ maxHeight: "510px" }}
                    resizable={true}
                >
                    <GridColumn title='Submittal Year' field='documentSubYearId' width='120px'/>
                    <GridColumn title='Season' field='documentSeason' width='120px'/>
                    <GridColumn title='Participant' field='documentParticipantNm' width='150px'/>
                    <GridColumn title='Name' field='documentName' width='300px'/>
                    <GridColumn title='File' field='filename' width='500px' cell={fileCell}/>
                    <GridColumn title='Type' field='documentType' width='150px' />
                    <GridColumn cell={removeDocumentLinkCell}/>
                </Grid>
            </ModalContent>
            <ModalActions>
                <Button themeColor='secondary' onClick={closeModal}>
                    Close
                </Button>
            </ModalActions>
        </Modal>
    );
};

interface RemoveDocumentLinkCellProps extends GridCellProps{
    removeDocument: (mapId: number) => void;
}

const RemoveDocumentLinkCell = (props: RemoveDocumentLinkCellProps) => {
    const {ariaColumnIndex, columnIndex, dataItem} = props;
    const mapId = dataItem.id;
    const editAccess = dataItem.editAccess;
    
    const handleClick = () => {
        props.removeDocument(mapId);
    };

    return (
        <td
            colSpan={1}
            className='k-table-td'
            role='gridcell'
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {editAccess
                ? <Button themeColor='dark' onClick={handleClick}>Remove Link</Button>
                : ''
            }
        </td>
    );
};

const getRemoveDocumentLinkCell = (removeDocument: (mapId: number) => void) => {
    return (
        (props: GridCellProps) => <RemoveDocumentLinkCell {...props} removeDocument={removeDocument}/>
    );
};

interface AddDocumentLinkModalProps {
    season: string;
    dataSet: string;
    recordKey: number;
    addMapping: (documentId: number, documentSubYearId: number, documentSeason: string) => void;
}

const AddDocumentLinkModal: React.FC<AddDocumentLinkModalProps> = ({season, dataSet, recordKey, addMapping}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [documentList, setDocumentList] = useState<Array<WrapDocumentData>>([]);
    const [seasonOptions, setSeasonOptions] = useState<Array<DropdownItemProps>>([]);
    const [docNameOptions, setDocNameOptions] = useState<Array<DropdownItemProps>>([]);
    const [docSeason, setDocSeason] = useState<string>(season);
    const [docName, setDocName] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const openModal = () => {
        setLoading(true);
        const params = new URLSearchParams();
        params.set('season', season);
        params.set('dataSet', dataSet);
        params.set('recordKey', recordKey.toString());
        axios.get('/api/auth/wrap/document/map/dropdown', {params})
            .then(resp => {
                if (!resp.data || !resp.data.length) {
                    toast.info('No documents available to link.');
                } else {
                    // Create local list to use with filtering option lists to eliminate set state delays.
                    const docList: WrapDocumentData[] = resp.data.map((d: WrapDocumentData) => ({
                        id: d.id,
                        subYearId: d.subYearId,
                        seasonNm: d.seasonNm,
                        verified: d.verified,
                        participantNm: d.participantNm,
                        documentName: d.documentName,
                        filename: d.filename,
                        documentType: d.documentType,
                        uploadDate: d.uploadDate,
                        comments: d.comments,
                        lastUserModBy: d.lastUserModBy,
                        lastUserModDt: d.lastUserModDt,
                        verifiedBy: d.verifiedBy,
                        verifiedDt: d.verifiedDt
                    }));
                    setDocumentList(docList);

                    // Initialize document season to same as data set grid.
                    setDocSeason(season);
                    setSeasonOptions(Array.from(new Set(docList
                        .map(d => ({
                            key: d.seasonNm,
                            text: d.seasonNm,
                            value: d.seasonNm
                        })))));
                    setDocNameOptions(docList
                        .filter(d => d.seasonNm === season)
                        .map(d => ({
                            key: d.documentName,
                            text: d.documentName,
                            value: d.documentName
                        })));

                    setOpen(true);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => {
        setOpen(false);
    };

    const handleSeasonChange = (event: any, data: DropdownProps) => {
        const value = data.value as string;
        setDocSeason(value);
        const docList = Array.from(new Set(documentList
            .filter(d => d.seasonNm === value)
            .map(d => ({
                key: d.documentName,
                text: d.documentName,
                value: d.documentName
            }))));
        setDocNameOptions(docList);
    };
    
    const handleDocNameChange = (event: any, data: DropdownProps) => {
        setDocName(data.value as string);
    };
    
    const handleSubmit = () => {
        const doc = documentList.find(d => d.seasonNm === docSeason && d.documentName === docName);
        if (!doc) {
            toast.error('Please select a document.');
            return;
        }
        addMapping(doc.id, doc.subYearId, doc.seasonNm);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={closeModal}
            closeIcon={true}
            size='large'
            trigger={
                <Button onClick={openModal} themeColor='primary'>
                    Add Document Link
                    {loading && <Loader size='small' type='pulsing' themeColor='light'/>}
                </Button>
            }
        >
            <ModalContent>
                <DisplayGrid>
                    <DisplayRow>
                        <DisplayColumn>
                            Season
                        </DisplayColumn>
                    </DisplayRow>
                    <DisplayRow>
                        <DisplayColumn width={4}>
                            <Dropdown
                                options={seasonOptions}
                                value={docSeason}
                                fluid={true}
                                placeholder='Select season'
                                onChange={handleSeasonChange}
                            />
                        </DisplayColumn>
                    </DisplayRow>
                    <DisplayRow>
                        <DisplayColumn width={4}>
                            Document Name
                        </DisplayColumn>
                    </DisplayRow>
                    <DisplayRow>
                        <DisplayColumn width={6}>
                            <Dropdown
                                options={docNameOptions}
                                value={docName}
                                fluid={true}
                                placeholder='Select document'
                                onChange={handleDocNameChange}
                            />
                        </DisplayColumn>
                    </DisplayRow>
                </DisplayGrid>
            </ModalContent>
            <ModalActions>
                <Button themeColor='secondary' onClick={closeModal}>
                    Cancel
                </Button>
                &nbsp;
                <Button themeColor='primary' onClick={handleSubmit}>
                    Link Document
                </Button>
            </ModalActions>
        </Modal>
    );
};