import React, {useEffect, useRef, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import {connect} from 'react-redux';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import {AccordionWrapper} from '../common/grid/AccordionWrapper';
import ForwardShowingGrid from './ForwardShowingGrid';
import ActionPanel from "../common/action-panel/ActionPanel";
import {ActionPanelButton} from "../common/action-panel/ActionPanelButton";
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import axios from "axios";
import {Button} from "@progress/kendo-react-buttons";
import FSCapacityObligationChart from "./FSCapacityObligationChart";
import FSTransmissionDemonstrationChart from "./FSTransmissionDemonstrationChart";
import {useCategorySeason} from '../../hooks/useCategorySeason';
import {toast} from 'react-toastify';
import {isParticipantEmpty} from '../../services/wrap-report-service';
import ExportPanel from '../common/action-panel/ExportPanel';
import {PDFExport} from '@progress/kendo-react-pdf';
import format from 'date-fns/format';

const ForwardShowingPage: React.FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);
    const [showReport, setShowReport] = useState<boolean>(false);
    const [participant, setParticipant] = useState<string>('');
    const [participantNmList, setParticipantNmList] = useState<Array<string>>([]);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    
    const {subyear, subyearList, season, seasonList, seasonOpen, handleSubyear, handleSeason} = useCategorySeason();

    useEffect(() => {
        setPageRole(getCompressedPageRole('ForwardShowingSummary', roles));
    }, [roles]);

    useEffect(() => {
        axios.get('/api/auth/wrap/dropdown/user-participants')
            .then((resp) => {
                setParticipantNmList(resp.data);
                setParticipant('Select Participant');
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleParticipant = (event: DropDownListChangeEvent) => {
        const participantNm = event.target.value;
        setParticipant(participantNm);
        setShowReport(false);
        setButtonDisabled(!subyear && !season);
    };
    
    const handleSubyearChange = (event: DropDownListChangeEvent) => {
        handleSubyear(event);
        setShowReport(false);
        setButtonDisabled(isParticipantEmpty(participant) || !season);
    };

    const handleSeasonChange = (event: DropDownListChangeEvent) => {
        handleSeason(event);
        setShowReport(false);
        setButtonDisabled(isParticipantEmpty(participant) || !subyear);
    };

    const handleCreateReport = () => {
        setShowReport(false);
        const params = new URLSearchParams();
        params.set('subYear', subyear.toString());
        params.set('season', season);
        params.set('participant', participant);
        axios.get('/api/auth/wrap/forward-showing/create', {params})
            .then(() => {
                setShowReport(true);
            })
            .catch(() => {
                toast.error('Error creating report');
            });
    };

    const handleShowReport = () => {
        setShowReport(true);
    };
    
    const pdfExportComponent = useRef<PDFExport>(null);
    
    const pdfExport = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    return (
        <Container fluid style={{paddingLeft: 20, paddingRight: 20, paddingTop: 50}}>
            <ActionPanel title={''}>
                {<ActionPanelButton>
                    <DropDownList
                        data={participantNmList}
                        value={participant}
                        onChange={handleParticipant}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>}
                {<ActionPanelButton>
                    <DropDownList
                        data={subyearList}
                        value={subyear}
                        onChange={handleSubyearChange}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>}
                {<ActionPanelButton>
                    <DropDownList
                        data={seasonList}
                        value={season}
                        onChange={handleSeasonChange}
                        style={{width: 'max-content'}}
                    />
                </ActionPanelButton>}
                {seasonOpen ?
                    <ActionPanelButton>
                        <AppBarSection>
                            <Button onClick={handleCreateReport} disabled={buttonDisabled}>
                                Create Report
                            </Button>
                        </AppBarSection>
                        <AppBarSpacer style={{width: 4}}/>
                    </ActionPanelButton>
                    :
                    <ActionPanelButton>
                        <AppBarSection>
                            <Button onClick={handleShowReport} disabled={buttonDisabled}>
                                Show Report
                            </Button>
                        </AppBarSection>
                        <AppBarSpacer style={{width: 4}}/>
                    </ActionPanelButton>
                }
                {showReport && <ExportPanel disabled={false} exportData={pdfExport}/>}
            </ActionPanel>
            <br/>
            <PDFExport
                ref={pdfExportComponent}
                paperSize='auto'
                margin={40}
                fileName={`Forward_Showing_Summary_${participant}_${season}_${subyear}_${format(new Date(), 'MM-dd-yyyy')}`}
                title={`Forward Showing Summary`}
            >
                <Header size='large'>Forward Showing Summary Report</Header>
                <br/>
                <AccordionWrapper accordionHeader={'Capacity and Obligation Summary Chart'}>
                    {showReport &&
                        <FSCapacityObligationChart
                            {...pageRole}
                            title={'Capacity and Obligation Summary'}
                            participant={participant}
                            subyear={subyear}
                            season={season}
                            showReport={showReport}
                        />
                    }
                </AccordionWrapper>
                <AccordionWrapper accordionHeader={'Transmission Demonstration Summary Chart'}>
                    {showReport &&
                        <FSTransmissionDemonstrationChart
                            {...pageRole}
                            title={'Transmission Demonstration Summary'}
                            participant={participant}
                            subyear={subyear}
                            season={season}
                            showReport={showReport}
                        />
                    }
                </AccordionWrapper>
                <AccordionWrapper accordionHeader={'Capacity Summary'}>
                    {showReport &&
                        <ForwardShowingGrid
                            {...pageRole}
                            title={'Capacity Summary'}
                            participant={participant}
                            subyear={subyear}
                            season={season}
                            showReport={showReport}
                        />
                    }
                </AccordionWrapper>
                <br/>
                <AccordionWrapper accordionHeader={'Load Summary'}>
                    {showReport &&
                        <ForwardShowingGrid
                            {...pageRole}
                            title={'Load Summary'}
                            participant={participant}
                            subyear={subyear}
                            season={season}
                            showReport={showReport}
                        />
                    }
                </AccordionWrapper>
                <br/>
                <AccordionWrapper accordionHeader={'Transmission Demonstration Summary'}>
                    {showReport &&
                        <ForwardShowingGrid
                            {...pageRole}
                            title={'Transmission Demonstration Summary'}
                            participant={participant}
                            subyear={subyear}
                            season={season}
                            showReport={showReport}
                        />
                    }
                </AccordionWrapper>
                <br/>
                <AccordionWrapper accordionHeader={'Requirements Summary'}>
                    {showReport &&
                        <ForwardShowingGrid
                            {...pageRole}
                            title={'Requirements Summary'}
                            participant={participant}
                            subyear={subyear}
                            season={season}
                            showReport={showReport}
                        />
                    }
                </AccordionWrapper>
            </PDFExport>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});

export default connect(mapStateToProps)(ForwardShowingPage);
