import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {SubMenu} from './SubMenu';
import {DataMenuProps} from '../../interfaces/menu-interface';
import {getViewPage} from '../../services/user-service';

const ModDevMenu: React.FC<DataMenuProps> = ({roles, updateActiveMenuItem}) => {
    return (
        <Dropdown item={true} text='Modeling Development' closeOnChange={true}>
            <Dropdown.Menu>
                {getViewPage('BranchOutages', roles) &&
                    <SubMenu menuName='branchOutages' menuDisplayName='Branch Outages' menuLink='/BranchOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Branches', roles) &&
                    <SubMenu menuName='branches' menuDisplayName='Branches' menuLink='/Branches'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('BusDtl', roles) &&
                    <SubMenu menuName='busDtl' menuDisplayName='Bus Details' menuLink='/BusDtl'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('BusOutages', roles) &&
                    <SubMenu menuName='busOutages' menuDisplayName='Bus Outages' menuLink='/BusOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('FixedShuntOutages', roles) &&
                    <SubMenu menuName='fixedShuntOutages' menuDisplayName='Fixed Shunt Outages' menuLink='/FixedShuntOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('GeneratorOutages', roles) &&
                    <SubMenu menuName='generatorOutages' menuDisplayName='Generator Outages' menuLink='/GeneratorOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Generators', roles) &&
                    <SubMenu menuName='generators' menuDisplayName='Generators' menuLink='/Generators'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('LoadDtl', roles) &&
                    <SubMenu menuName='loadDtl' menuDisplayName='Load Details' menuLink='/LoadDtl'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('SwitchedShuntOutages', roles) &&
                    <SubMenu menuName='switchedShuntOutages' menuDisplayName='Switched Shunt Outages' menuLink='/SwitchedShuntOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Transactions', roles) &&
                    <SubMenu menuName='transactions' menuDisplayName='Transactions' menuLink='/Transactions'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Transformer2WindingOutages', roles) &&
                    <SubMenu menuName='transformer2WindingOutages' menuDisplayName='Transformer 2 Winding Outages' menuLink='/Transformer2WindingOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Transformer3WindingOutages', roles) &&
                    <SubMenu menuName='transformer3WindingOutages' menuDisplayName='Transformer 3 Winding Outages' menuLink='/Transformer3WindingOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Transformers2Winding', roles) &&
                    <SubMenu menuName='transformers2Winding' menuDisplayName='Transformers 2 Winding' menuLink='/Transformers2Winding'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Transformers3Winding', roles) &&
                    <SubMenu menuName='transformers3Winding' menuDisplayName='Transformers 3 Winding' menuLink='/Transformers3Winding'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('TwoTerminalDCTies', roles) &&
                    <SubMenu menuName='twoTerminalDCTies' menuDisplayName='Two Terminal DC Ties' menuLink='/TwoTerminalDCTies'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});

export default connect(mapStateToProps)(ModDevMenu);