import * as actionTypes from '../actions/actionTypes';
import initialState from "./initialState";

export default function defaultReducer(state = initialState.defaultState, action:any){
    switch(action.type) {
        case actionTypes.UPDATE_CURRENT_TAB:
            return {
                ...state,
                currentTab: action.payload
            };
        case actionTypes.UPDATE_EDST_CATEGORY:
            localStorage.setItem('currentCategory',action.payload);
            return {
                ...state,
                currentCategory: action.payload
            };
        case actionTypes.SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            };
        case actionTypes.SET_DELETEMODALACTIVE:
            return {
                ...state,
                deleteModalActive: action.payload
            };
        case actionTypes.SET_DELETESCREEN:
            return {
                ...state,
                deleteScreen: action.payload
            };
        case actionTypes.SET_CURRENTROW:
            return {
                ...state,
                currentRow: action.payload
            };
        case actionTypes.SET_ACTIVETAB:
            return {
                ...state,
                activeTab: action.payload
            };
        case actionTypes.SET_USERTYPE:
            return {
                ...state,
                userType: action.payload
            };
        case actionTypes.SET_SUBYEAR:
            return {
                ...state,
                subYear: action.payload
            };
        case actionTypes.UPDATE_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.payload
            };
        case actionTypes.UPDATE_ACTIVE_MENU_ITEM:
            return {
                ...state,
                activeMenuItem: action.payload
            };
    }
    return state;
};


