import React, {useEffect, useState} from 'react';
import {ForwardShowingData, ForwardShowingProps} from "../../interfaces/wrap/forward-showing-interface";
import axios from "axios";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {ReportCell} from "../common/grid/ReportCell";
import {toast} from 'react-toastify';
import {isParticipantEmpty} from '../../services/wrap-report-service';

const ForwardShowingGrid: React.FC<ForwardShowingProps> = ({title, participant, subyear, season, showReport}) => {
    const [data, setData] = useState<Array<ForwardShowingData>>([]);

    useEffect(() => {
        if (!showReport || !season || !subyear || isParticipantEmpty(participant)) {
            return;
        }
            const params = new URLSearchParams();
            params.set('subYear', subyear.toString());
            params.set('season', season);
            params.set('participant', participant);
            params.set('gridNm', title);
            axios.get('/api/auth/wrap/forward-showing', {params})
                .then(resp => {
                    setData(resp.data);
                })
                .catch(error => {
                    console.log(error);
                    toast.error('Error loading report data', {toastId: 'fs-show-report-error'});
                });
    }, [title, participant, subyear, season, showReport]);

    return (
        <Grid
            data={data}
            dataItemKey='id'
            resizable={true}
            style={{width: season === 'Winter' ? '1247px' : '1082px'}}
        >
            <GridColumn field='rowVNm' title=' ' width= '400 px' />
            <GridColumn field='month1' title={season === 'Summer' ? `June ${subyear}` : `November ${subyear}`} width= '165 px' cell={ReportCell}/>
            <GridColumn field='month2' title={season === 'Summer' ? `July ${subyear}` : `December ${subyear}`} width= '165 px' cell={ReportCell}/>
            <GridColumn field='month3' title={season === 'Summer' ? `August ${subyear}` : `January ${subyear + 1}`} width= '165 px' cell={ReportCell}/>
            <GridColumn field='month4' title={season === 'Summer' ? `September ${subyear}` : `February ${subyear + 1}`} width= '165 px' cell={ReportCell}/>
            {season === 'Winter' && 
                <GridColumn field='month5' title={`March ${subyear + 1}`} width= '165 px' cell={ReportCell}/>
            }
        </Grid>
    );
};

export default ForwardShowingGrid;