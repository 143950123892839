import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {SubMenu} from './SubMenu';
import {DataMenuProps} from '../../interfaces/menu-interface';
import {getViewPage} from '../../services/user-service';

const WrapMenu: React.FC<DataMenuProps> = ({roles, updateActiveMenuItem}) => {
    return (
        <Dropdown item={true} text='WRAP Forward Showing' closeOnChange={true}>
            <Dropdown.Menu>
                {getViewPage('PlannedOutage', roles) &&
                    <SubMenu menuName='plannedOutage' menuDisplayName='Additional Planned Outages' menuLink='/AdditionalPlannedOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('AssessmentResults', roles) &&
                    <SubMenu menuName='assessmentResults' menuDisplayName='Advance Assessment Results' menuLink='/AdvanceAssessmentResults'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Contract', roles) &&
                    <SubMenu menuName='contract' menuDisplayName='Contracts & Obligations' menuLink='/ContractsAndObligations'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('DemandResponse', roles) &&
                    <SubMenu menuName='demandResponse' menuDisplayName='Demand Response Programs' menuLink='/DemandResponsePrograms'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Facility', roles) &&
                    <SubMenu menuName='facility' menuDisplayName='Facility Management' menuLink='/FacilityManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('WrapDocument', roles) &&
                    <SubMenu menuName='wrapDocument' menuDisplayName='Forms & Attestation Documents' menuLink='/FormsAndAttestationDocuments'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ForwardShowingSummary', roles) &&
                    <SubMenu menuName='forwardShowingSummary' menuDisplayName='Forward Showing Summary' menuLink='/ForwardShowingSummary'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('WrapGeneratorTestResult', roles) &&
                    <SubMenu menuName='wrapGeneratorTestResult' menuDisplayName='Generator Test Results' menuLink='/WrapGeneratorTestResult'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Load', roles) &&
                    <SubMenu menuName='load' menuDisplayName='Load' menuLink='/Load'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Resource', roles) &&
                    <SubMenu menuName='resource' menuDisplayName='Resources' menuLink='/Resource'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ForcedOutage', roles) &&
                    <SubMenu menuName='forcedOutage' menuDisplayName='Storage Hydro Forced Outages' menuLink='/StorageHydroForcedOutages'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('TsrInformation', roles) &&
                    <SubMenu menuName='tsrInformation' menuDisplayName='TSR (OASIS) Information' menuLink='/TSRInformation'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('TsrMapping', roles) &&
                    <SubMenu menuName='tsrMapping' menuDisplayName='TSR Mapping' menuLink='/TSRMapping'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});

export default connect(mapStateToProps)(WrapMenu);