import * as React from 'react';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes';
import {Icon, List, Segment} from "semantic-ui-react";
import axios from "axios";
import {toast} from "react-toastify";


interface FooterProps {
    currentCategory: string;
    md: boolean;
    ra: boolean;
    wrap: boolean;
    updateCurrentCategory: (category: string) => void;
}

const LoggedInFooter: React.FC<FooterProps> = ({
                                           currentCategory,
                                           md,
                                           ra,
                                           wrap,
                                           updateCurrentCategory
                                       }) => {

    useEffect(() => {
        const categoryList: string[] = [];
        if (md) {
            categoryList.push('MDWG');
        }
        if (ra) {
            categoryList.push('RA');
        }
        if (wrap) {
            categoryList.push('WRAP');
        }
        if (!currentCategory) {
            if (categoryList.length > 0) {
                updateCurrentCategory(categoryList[0]);
            } else {
                updateCurrentCategory('');
            }
        }
    }, [currentCategory, md, ra, updateCurrentCategory, wrap]);

    const fileDownload = require('js-file-download');
    const getUserManual = () => {
        let fileName = 'EDST User Guide';
        if(currentCategory === 'WRAP') {
            fileName = 'WRAP FS Instruction Manual';
        }
        axios.post('/api/auth/referenceDocument/downloadRefDoc',null, { params : {filename:fileName},
            withCredentials: true,
            responseType: 'arraybuffer'
        })
            .then(resp => {
                let extension = null;
                if(resp.headers['content-type'] === 'application/pdf' || resp.headers['content-type'] === 'application/octet-stream') {
                    extension = '.pdf';
                }
                else if (resp.headers['content-type'] === 'application/doc' || resp.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                {
                    extension = '.docx';
                }
                fileDownload(resp.data, fileName+extension)
            }).catch(resp => {
            toast.error(`Error retrieving File from server - ${resp.message}`);
        })
    };

    const segStyle = {border: 'none'};
    const linkStyle = {
        background: 'none',
        border: 'none',
        color: '#4183c4',
        cursor: 'pointer',
        margin: '0px',
        padding: '0px'
    };

    return (
        <Segment textAlign={'center'} style={segStyle}>
            <List divided={true} horizontal={true} relaxed={'very'}>
                {currentCategory === 'RA' && <List.Item><Icon name={'mail'}/><a href={'mailto:EDSTSupportRA@spp.org'}>Resource Adequacy</a></List.Item>}
                {currentCategory === 'MDWG' && <List.Item><Icon name={'mail'}/><a href={'mailto:SPPEngineeringModeling@spp.org'}>Model Development</a></List.Item>}
                {currentCategory === 'WRAP' && <List.Item><Icon name={'mail'}/><a href={'mailto:EDSTSupportFS@spp.org'}>WRAP</a></List.Item>}
                {<List.Item><Icon name={'comments'}/><a href='https://spprms.issuetrak.com' target={'blank'}>SPP RMS</a></List.Item>}
                {<List.Item><Icon name={'book'}/><button style={linkStyle} onClick={getUserManual}>User Manual</button></List.Item>}
            </List>
        </Segment>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentCategory: state.defaultReducer.currentCategory,
        md: state.authReducer.userRolesEntities.md,
        ra: state.authReducer.userRolesEntities.ra,
        wrap: state.authReducer.userRolesEntities.wrap,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentCategory: (category: string) => dispatch(
            {type: actionTypes.UPDATE_EDST_CATEGORY, payload: category}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInFooter);