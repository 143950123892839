import {FC, useEffect, useState} from 'react';
import {GridPageProps, PageRole} from '../../interfaces/grid-interfaces';
import {useCategorySeason} from '../../hooks/useCategorySeason';
import {getCompressedPageRole, initialPageRole} from '../../services/data-grid-service';
import {CategorySeasonData} from '../../interfaces/category-interface';
import {Container, Header} from 'semantic-ui-react';
import {AccordionWrapper} from '../common/grid/AccordionWrapper';
import TestResultOpGrid from './TestResultOpGrid';
import {connect} from 'react-redux';
import TestResultCapGrid from './TestResultCapGrid';

const TestResultPage: FC<GridPageProps> = ({roles}) => {
    const [pageRole, setPageRole] = useState<PageRole>(initialPageRole);
    const {getActiveCategorySeason, getEmptyDefaultSeason} = useCategorySeason();
    const [summerSeason, setSummerSeason] = useState<CategorySeasonData>(getEmptyDefaultSeason());
    const [winterSeason, setWinterSeason] = useState<CategorySeasonData>(getEmptyDefaultSeason());
    // Get Window status at page level. If the window status changes, the user needs to refresh the whole page.

    useEffect(() => {
        setPageRole(getCompressedPageRole('WrapGeneratorTestResult', roles));
    }, [roles]);

    useEffect(() => {
        const summer = getActiveCategorySeason('Summer');
        if (summer) {
            setSummerSeason(summer);
        }
        const winter = getActiveCategorySeason('Winter');
        if (winter) {
            setWinterSeason(winter);
        }
    }, [getActiveCategorySeason]);

    return (
        <Container fluid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 50 }}>
            <Header size={'large'}>Generator Test Results</Header>
            {summerSeason &&
                <AccordionWrapper accordionHeader={`Operational Test Results Summer ${summerSeason.subyear}`}>
                    <TestResultOpGrid
                        {...pageRole}
                        title={`Operational Test Results Summer ${summerSeason.subyear}`}
                        dataApi='/api/auth/wrap/test-result/op/summer'
                        windowOpen={summerSeason.windowOpen}
                        season={'Summer'}
                        subyear={summerSeason.subyear}
                    />
                </AccordionWrapper>}
            <br/>
            {winterSeason &&
                <AccordionWrapper accordionHeader={`Operational Test Results Winter ${winterSeason.subyear}`}>
                    <TestResultOpGrid
                        {...pageRole}
                        title={`Operational Test Results Winter ${winterSeason.subyear}`}
                        dataApi='/api/auth/wrap/test-result/op/winter'
                        windowOpen={winterSeason.windowOpen}
                        season={'Winter'}
                        subyear={winterSeason.subyear}
                    />
                </AccordionWrapper>}
            <br/>
            {summerSeason &&
                <AccordionWrapper accordionHeader={`Capability Test Results Summer ${summerSeason.subyear}`}>
                    <TestResultCapGrid
                        {...pageRole}
                        title={`Capability Test Results Summer ${summerSeason.subyear}`}
                        dataApi='/api/auth/wrap/test-result/cap/summer'
                        windowOpen={summerSeason.windowOpen}
                        season={'Summer'}
                        subyear={summerSeason.subyear}
                    />
                </AccordionWrapper>}
            <br/>
            {winterSeason &&
                <AccordionWrapper accordionHeader={`Capability Test Results Winter ${winterSeason.subyear}`}>
                    <TestResultCapGrid
                        {...pageRole}
                        title={`Capability Test Results Winter ${winterSeason.subyear}`}
                        dataApi='/api/auth/wrap/test-result/cap/winter'
                        windowOpen={winterSeason.windowOpen}
                        season={'Winter'}
                        subyear={winterSeason.subyear}
                    />
                </AccordionWrapper>}
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
});

export default connect(mapStateToProps)(TestResultPage);