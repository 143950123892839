import axios from "axios";
import {toast} from "react-toastify";

export default class AdminService {
    
    createNewUser = (user:User) => {
        return new Promise((resolve, reject) => {
            axios.post(`/api/auth/admin/register`,user).then(({status,statusText,data}) => {
                if (status === 200) {
                    toast.success(`New user created successfully`);
                    resolve(data);
                } else {
                    toast.error(`Error creating new user - ${statusText}`)
                }

            }).catch(({message}) => {
                reject(toast.error(`Error creating new user - ${message}`))
            })
        })
    };

    getLockOutData = () => {
        return new Promise<string>((resolve) => {
            axios.get('/api/auth/factor/rollover/getLockOutData').then(({status, data, statusText}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error(`Error getting lockout data ${statusText}`);
                }
            }).catch(resp => {
                toast.error(`Error making call to get lockout data - ${resp.message}`);
            });
        })
    };

    isAuthenticated = (userName:string, uuid:string) => {
        const url = '/api/isAuthenticated?userName=' + userName + '&uuid=' + uuid;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error checking is user authenticated'));
                    }
                });
        });
    };

    verifyCode = (verificationCode:string/*,token:string*/) => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/verify-code/${verificationCode}`)
                .then(({data,status,statusText}) => {
                    if (status === 200) {
                        resolve(data)
                    } else {
                        reject(toast.error(`Error submitting verification code to the server - ${statusText}`))
                    }
                }).catch(err => {
                if(!err.response.data.badToken) {
                    reject(toast.error(`Error submitting verification code to the server - ${err.response.data.message}`))
                }
            })
        })
    };

    getSubYear = (category:string) => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/admin/subyear/${category}`).then(({status,statusText,data}) => {
                if (status === 200) {
                    resolve(data);
                } else {
                    toast.error(`Error retrieving current subyear for ${category} - ${statusText}`)
                }
            }).catch(resp => {
                reject(toast.error(`Error making call to getSubYear - ${resp.message}`))
            });
        })
    };

    verifyEmailUnique = (email: string) => {
        return new Promise( (resolve, reject) => {
            axios.post('/api/auth/admin/emailUnique', {
                email: email
            }, {
                withCredentials: true
            }).then(({data}) => {
                resolve(data);
            }).catch(({message}) => {
                if(message) {
                    reject(toast.error(`Error retrieving user changeset data - ${message}`));
                }
            })
        });
    };

    //Start methods for Entity screen

    getEntityGridData():Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/entity/getAllEntities`,{
                }).then(resp => {
                if (resp.status === 200) {
                    resolve(resp);
                } else{
                    reject(new Error('error fetching data'));
                }
            })
        })
    }

    getSubRespRelGridData(subYear: number):Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/entity/submitEntRespEntRel`, {params: {subyearid: subYear}}).then(resp => {
                if (resp.status === 200) {
                    resolve(resp);
                } else{
                    reject(new Error('error fetching data'));
                }
            })
        })
    }

    getMpLreRelGridData(subYear: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/entity/mpToLre`, {params:{subyearid: subYear}}).then(resp => {
                if (resp.status === 200) {
                    resolve(resp);
                } else{
                    reject(new Error('error fetching data'));
                }
            })
        })
    }

    getEntityUserRelGridData(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(`/api/auth/entity/users`, {
                },
                {
                    withCredentials: true
                }).then(resp => {
                    if (resp.status === 200) {
                        resolve(resp);
                    } else{
                        reject(new Error('error fetching data'));
                    }
            })
        })
    };

    getEntityDropdowns(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(`/api/auth/entity/entityType`, {},
                {
                    withCredentials: true
                }).then(resp => {
                if (resp.status === 200) {
                    resolve(resp)
                } else {
                    reject(new Error('error fetching data'));
                }
            })
        })
    }

    getAvailableRespEntDropdowns(subYear: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/entity/availableResponsibleEntities`, {params: {subyearid: subYear}}).then(resp => {
                if (resp.status === 200) {
                    resolve(resp)
                } else {
                    reject(new Error('error fetching data'));
                }
            })
        })
    }

    getAvailableLreEntDropdowns(subYear: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/entity/availableLres`, {params: {subyearid: subYear}}).then(resp => {
                if (resp.status === 200) {
                    resolve(resp)
                } else {
                    reject(new Error('error fetching data'));
                }
            })
        })
    }

    //End methods for Entity screen

    //Begin methods for rollover screen

    getPlanYearDropdownValues(): Promise<any> {
        return new Promise((resolve) => {
            axios.get('/api/auth/rollover/getPlanYears')
                .then(resp => {
                    if (resp.status === 200) {
                        resolve(resp)
                    } else {
                        throw new Error(resp.data);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });
    };

    getSeasonTypeDropdownValues(): Promise<any> {
        return new Promise((resolve) => {
            axios.get('/api/auth/rollover/getSeasonTyp')
                .then(resp => {
                    if (resp.status === 200) {
                        resolve(resp)
                    } else {
                        throw new Error(resp.data);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });
    };

    updateSubPlanYearSeasonRel(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(`/api/auth/rollover/updateTemplateRecord`, data)
                .then(resp => {
                    if (resp.status === 200) {
                        resolve(resp);
                    } else {
                        throw new Error(resp.data);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                    reject(error);
                });
        });
    };

    insertSubPlanYearSeasonRel(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(`/api/auth/rollover/insertTemplateRecord`, data)
                .then(resp => {
                    if (resp.status === 200) {
                        resolve(resp)
                    } else {
                        throw new Error(resp.data);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                    reject(error);
                });
        });
    };

    getPrxyData(subYearNm: string, tabNm: string): Promise<any> {
        const data = {subYearNm, tabNm};
        return new Promise((resolve) => {
            axios.post(`/api/auth/rollover/getPrxyYearData`, data)
                .then(resp => {
                    if (resp.status === 200) {
                        resolve(resp.data);
                    } else {
                        throw new Error(resp.data);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });
    }

    deleteSubPlanYearSeasonRel(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(`/api/auth/rollover/deleteTemplateRecord`, data,
                {
                    withCredentials: true
                }).then(resp => {
                if (resp.status === 200) {
                    resolve(resp)
                } else {
                    reject(new Error('error fetching data'));
                }
            })
        })
    }

}
