import {Container, Header} from 'semantic-ui-react';

export const AccessDenied = () => (
    <Container fluid={true} textAlign='center' style={{paddingTop: 74}}>
        <Header as={'h1'} content={'Access Denied'} color={'red'}/>
        <div color={'red'}>
            It appears you do not have appropriate permissions to view this page. Please submit an RMS ticket if you
            believe this message to be in error.
        </div>
    </Container>
);