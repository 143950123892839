import React from 'react';
import {AdminMenuProps} from '../../interfaces/menu-interface';
import {connect} from 'react-redux';
import {getViewPage} from '../../services/user-service';
import {SubMenu} from './SubMenu';
import {Dropdown} from 'semantic-ui-react';

const ModDevAdminMenu: React.FC<AdminMenuProps> = ({roles, isStaff, updateActiveMenuItem}) => {
    return (
        <Dropdown item={true} text='Administration' closeOnChange={true}>
            <Dropdown.Menu>
                {getViewPage('ChangeSet', roles) &&
                    <SubMenu menuName='changesetHistory' menuDisplayName='Changeset History'
                             menuLink='/ChangesetHistory'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Register', roles) && isStaff &&
                    <SubMenu menuName='registerNewUser' menuDisplayName='Register New User' menuLink='/Register'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Roles', roles) && isStaff &&
                    <SubMenu menuName='roleManagement' menuDisplayName='Role Management' menuLink='/RoleManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ResponsibleEntity', roles) && isStaff &&
                    <SubMenu menuName='entityManagement' menuDisplayName='Entity Management'
                             menuLink='/EntityManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Users', roles) &&
                    <SubMenu menuName='userManagement' menuDisplayName='User Management' menuLink='/UserManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Contacts', roles) &&
                    <SubMenu menuName='contacts' menuDisplayName='Contact Management' menuLink='/Contacts'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ROLL_FORWARD', roles) && isStaff &&
                    <SubMenu menuName='rollover' menuDisplayName='Rollover' menuLink='/Rollover'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Historical', roles) &&
                    <SubMenu menuName='historical' menuDisplayName='Historical' menuLink='/Historical'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ChangeSetFile', roles) &&
                    <SubMenu menuName='attachmentsHistory' menuDisplayName='Attachments History'
                             menuLink='/AttachmentsHistory'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ReferenceDocument', roles) &&
                    <SubMenu menuName='referenceDocuments' menuDisplayName='Reference Documents'
                             menuLink='/ReferenceDocuments'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
    isStaff: state.authReducer.userRolesEntities.sppStaff,
});

export default connect(mapStateToProps)(ModDevAdminMenu);