import {useEffect, useState} from 'react';
import axios from 'axios';
import {DataSetOptionContext} from '../../common/row-edit-grid/context/DataSetOptionContext';
import {RowEditGrid} from '../../common/row-edit-grid/RowEditGrid';
import {GridColumn} from '@progress/kendo-react-grid';
import {COMMAND_CELL_WIDTH, CommandCell} from '../../common/row-edit-grid/CommandCell';
import {RequiredStringFormCell} from '../../common/row-edit-grid/RequiredStringFormCell';
import {StringFormCell} from '../../common/row-edit-grid/StringFormCell';
import {DataSetOptionFormCell} from '../../common/row-edit-grid/DataSetOptionFormCell';

export const DocumentTypeFormGrid = () => {
    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        axios.get('/api/auth/wrap/data/document-type/data-set')
            .then(resp => {
                setOptions(resp.data || []);
            });
    }, []);

    return (
        <DataSetOptionContext.Provider value={{options, setOptions}}>
            <div style={{maxWidth: '1442px'}}>
                <RowEditGrid
                    dataApi={'/api/auth/wrap/data/document-type'}
                    uniqueFields={[{field: 'nm', title: 'Name'}]}
                >
                    <GridColumn title='Command' cell={CommandCell} width={COMMAND_CELL_WIDTH}/>
                    <GridColumn title='Id' field='id' editable={false} width={70}/>
                    <GridColumn title='Name' field='nm' cell={RequiredStringFormCell} width={300}/>
                    <GridColumn title='Description' field='description' cell={StringFormCell} width={500}/>
                    <GridColumn title='Data Sets' field='dataSetList' cell={DataSetOptionFormCell} width={400}/>
                </RowEditGrid>
            </div>
        </DataSetOptionContext.Provider>
    );
};