import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {
    Grid,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent
} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {ApiValidations, ColumnMeta, DataUpdate, PageRole, UiValidations} from '../../interfaces/grid-interfaces';
import {PlantsData, PlantsEditData} from '../../interfaces/plants-interface';
import ActionPanel from '../common/action-panel/ActionPanel';
import SavePanel from '../common/action-panel/SavePanel';
import {
    addDataError,
    deepCopyGridData,
    deepCopyGridDataWithOriginal,
    initialDataState, numberBoundValidationOnEdit, numberBoundValidationOnUpload,
    processColumnMeta,
    processDropDownListOptions,
    removeDataErrorByMessage,
    removeDataErrorByValue,
    resetChildOption,
    resetOption, toastPreSaveValidationErrors, toastSuccessfulSave,
    updateEditedRows,
    updateEditedVerified
} from '../../services/data-grid-service';
import {getMaxNumberCell} from '../common/grid/MaxNumberCell';
import {getPercentCell} from '../common/grid/PercentCell';
import {DropDownOption, getDropDownCell} from '../common/grid/DropDownCell';
import {ChildDropDownOption, getChildDropDownCell} from '../common/grid/ChildDropDownCell';
import {CustomLocalizationProvider, getVerifiedCell} from '../common/grid/VerifiedCell';
import VerifyPanel from '../common/action-panel/VerifyPanel';
import {getOnBlurCell} from '../common/grid/OnBlurCell';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import TooltipContainer from '../common/grid/TooltipContainer';
import {InputCell} from '../common/grid/InputCell';
import ExportPanel from '../common/action-panel/ExportPanel';
import ImportPanel from '../common/action-panel/ImportPanel';
import AddPanel from '../common/action-panel/AddPanel';
import {toast} from 'react-toastify';
import ValidationPreSaveGrid from '../common/grid/ValidationPreSaveGrid';
import RemovePanel from '../common/action-panel/RemovePanel';
import RemoveModal from '../common/action-panel/RemoveModal';
import {getRemoveCell} from '../common/grid/RemoveCell';
import ValidationMessageGrid from "../common/grid/ValidationMessageGrid";
import ValidatePanel from "../common/action-panel/ValidatePanel";
import RefreshPanel from "../common/action-panel/RefreshPanel";
import ClearFilterPanel from "../common/action-panel/ClearFilterPanel";
import format from "date-fns/format";
import {GridPageChangeEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';



const plantUnique = 'Plant Name must be unique. This name is in another record.';
const plantNmEmpty = 'Plant Name must have a value to save the record.';
const respEntNmEmpty = 'Last Modifying Entity must have a value to save the record.';
const countyNmEmpty = 'State will not be saved if County does not have value.';

const PlantsGrid: React.FC<PageRole> = ({isStaff, readRole, addRole, modifyRole, removeRole}) => {
    const [columnMeta, setColumnMeta] = useState<Array<ColumnMeta>>([]);
    const [data, setData] = useState<Array<PlantsEditData>>([]);
    const [originalData, setOriginalData] = useState<Array<PlantsData>>([]);
    const [tempSequence, setTempSequence] = useState<number>(-10000);
    const [subYear, setSubYear] = useState<number>(0);
    const [editedRows, setEditedRows] = useState<Array<DataUpdate>>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    const [inVerify, setInVerify] = useState<boolean>(false);
    const [editedVerified, setEditedVerified] = useState<Array<number>>([]);
    const [inRemove, setInRemove] = useState<boolean>(false);
    const [editedRemoved, setEditedRemoved] = useState<Array<number>>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const globalPageSize = localStorage.getItem(`globalPageSize`)
    const [dataState, setDataState] = useState<any>({...initialDataState, take: globalPageSize ? +globalPageSize : 10, pageSize: globalPageSize ? +globalPageSize : 10});
    const [entityOptions, setEntityOptions] = useState<Array<DropDownOption>>([]);
    const [stateOptions, setStateOptions] = useState<Array<DropDownOption>>([]);
    const [countyOptions, setCountyOptions] = useState<Array<ChildDropDownOption>>([]);
    const [dataErrors, setDataErrors] = useState<Array<UiValidations>>([]);
    const [validationErrors, setValidationErrors] = useState<Array<ApiValidations>>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const [seasonOpen, setSeasonOpen] = useState<boolean>(true);

    useEffect(() => {
        axios.get('/api/auth/ra/plants/columns')
            .then((resp) => {
                setColumnMeta(processColumnMeta(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const getData = useCallback(() => {
        if (!readRole) {
            return;
        }
        setLoadingData(true);
        Promise.all([axios.get('/api/auth/ra/plants'), axios.get('/api/auth/ra/season-window/Summer'), axios.get('/api/auth/ra/season-window/Winter')])
            .then((resp) => {
                setData(deepCopyGridDataWithOriginal(resp[0].data));
                setOriginalData(deepCopyGridData(resp[0].data));
                setTempSequence(-10000);
                if (!isStaff) {
                    const seasonsOpen = resp[1].data && resp[2].data;
                    if (!seasonsOpen) {
                        toast.info('Resource Adequacy window is closed');
                    }
                    setSeasonOpen(seasonsOpen);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [isStaff, readRole]);

    useEffect(() => {
        getData();
    }, [getData]);
    
    useEffect(() => {
        // Plants uses the current season subyear
        axios.get('/api/auth/ra/subyear/current')
            .then((resp) => {
                setSubYear(resp.data);
            })
    }, []);
    
    useEffect(() => {
        axios.get('/api/auth/ra/dropdown/entities')
            .then((resp) => {
                setEntityOptions(processDropDownListOptions(resp.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    
    useEffect(() => {
        axios.get('/api/auth/ra/plants/dropdown/state-county')
            .then((resp) => {
                const stateData = processDropDownListOptions(resp.data.stateData);
                const countyData = resp.data.countyData
                    .map((item: any) => ({
                        text: item.countyName,
                        value: item.countyName,
                        parentValue: item.stateName
                    }));
                countyData.sort((a: any, b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
                setStateOptions(stateData);
                setCountyOptions(countyData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        if (!field) {
            return;
        }
        handleChange(event.dataItem.id, field, event.value);
    };
    
    const handleChange = (id: number, field: string, value: any) => {
        const localData = data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem[field] = value;
                if (field === 'stateNm') {
                    dataItem.countyNm = '';
                    setDataErrors((prevDataErrors) => addDataError(id, 'countyNm', 'County', '',
                        countyNmEmpty, false, prevDataErrors));
                }
                if (value && (['respEntNm', 'plantNm', 'countyNm'].includes(field))) {
                    setDataErrors((prevDataErrors) => removeDataErrorByValue(id, field, prevDataErrors, ''));
                }
                if ((value || value === 0) && (['percentDeliverableSummer', 'percentDeliverableWinter'].includes(field))) {
                    numberBoundValidationOnEdit((Math.floor(value * 10000)/100), field, setDataErrors, item, columnMeta, 100, 0);
                }
                return dataItem;
            } else {
                return item;
            }
        });
        setData(localData);
        if (inEdit) {
            setEditedRows(updateEditedRows(editedRows, originalData, id, field, value));
        }
        if (inVerify && field === 'verified') {
            setEditedVerified(updateEditedVerified(editedVerified, originalData, id, value));
        }
    };

    const handleUpload = (uploadedData: Array<PlantsEditData>) => {
        let sequence = tempSequence;
        const localData = data.map((item) => {
            let upload = uploadedData.find((u) => u.id === item.id);
            if (!!upload) {
                return processUploadEdits(upload, item);
            }
            return item;
        });
        const newUploadData = uploadedData
            // get uploadedData that doesn't have saved id
            .filter((u) => u.id <= 0)
            .map((u) => {
                // Reset non-editable fields to default or empty value.
                // Reset option fields to valid option or empty value.
                // Direct input fields can take update value without modification. API won't set values of wrong format.
                return {
                    ...u,
                    verified: false,
                    id: sequence++,
                    respEntNm: resetOption(u.respEntNm, entityOptions, ''),
                    stateNm: resetOption(u.stateNm, stateOptions, ''),
                    countyNm: resetChildOption(u.stateNm, u.countyNm, countyOptions, ''),
                    subYearId: subYear,
                    lastUserModBy: '',
                    lastUserModDt: null,
                    verifiedBy: '',
                    verifiedDt: null,
                    inEdit: true
                };
            })
            // don't keep new records where the unique key already exists in existing UI data
            .filter((item) => data.findIndex((dataItem) => item.plantNm === dataItem.plantNm) === -1);
        
        const dataUploaded: Array<PlantsEditData> = [];
        newUploadData.forEach((u) => {
            dataUploaded.push(u);
        });
        localData.forEach((u) => {
            dataUploaded.push(u);
        });
        const dupeFiltered = dataUploaded
            .sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
            .filter((item, index, arr) => arr.findIndex((uItem) => uItem.plantNm === item.plantNm) === index);
        setData(dupeFiltered);
        setTempSequence(sequence);
    };
    
    const processUploadEdits = (upload: PlantsEditData, item: PlantsEditData) => {
        // need to pass every field change to updateEditedRows
        let respEntNm = resetOption(upload.respEntNm, entityOptions, item.respEntNm);
        // Dropdown options should already be in the right case after resetting.
        if (respEntNm !== item.respEntNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'respEntNm', respEntNm));
        }
        // plantNm should compare case-sensitive since it will alter the value.
        if (upload.plantNm !== item.plantNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'plantNm', upload.plantNm));
        }
        if (upload.plantCode !== item.plantCode) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'plantCode', upload.plantCode));
        }
        if (isStaff) {
            if (upload.percentDeliverableSummer !== item.percentDeliverableSummer) {
                let field = 'percentDeliverableSummer';
                numberBoundValidationOnUpload(field, setDataErrors, (Math.floor(upload[field] * 10000)/100), item.id, columnMeta, 100, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
            if (upload.percentDeliverableWinter !== item.percentDeliverableWinter) {
                let field = 'percentDeliverableWinter';
                numberBoundValidationOnUpload(field, setDataErrors, (Math.floor(upload[field] * 10000)/100), item.id, columnMeta, 100, 0);
                setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, field, upload[field]));
            }
        }
        let stateNm = resetOption(upload.stateNm, stateOptions, item.stateNm);
        if (upload.stateNm !== item.stateNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'stateNm', upload.stateNm));
        }
        let countyNm = resetChildOption(upload.stateNm, upload.countyNm, countyOptions, item.countyNm);
        if (upload.countyNm !== item.countyNm) {
            setEditedRows(previousEditedRows => updateEditedRows(previousEditedRows, originalData, item.id, 'countyNm', upload.countyNm));
        }
        return {
            ...item,
            respEntNm: respEntNm,
            plantNm: upload.plantNm,
            plantCode: upload.plantCode,
            percentDeliverableSummer: isStaff ? upload.percentDeliverableSummer : item.percentDeliverableSummer,
            percentDeliverableWinter: isStaff ? upload.percentDeliverableWinter : item.percentDeliverableWinter,
            stateNm: stateNm,
            countyNm: countyNm,
            comments: upload.comments
        };
    };
    
    const addNewRow = () => {
        let sequence = tempSequence;
        const defaultNewRow: PlantsEditData = {
            verified: false,
            id: sequence++,
            respEntNm: '',
            plantNm: '',
            plantCode: null,
            percentDeliverableSummer: 0,
            percentDeliverableWinter: 0,
            stateNm: '',
            countyNm: '',
            comments: '',
            subYearId: subYear,
            lastUserModBy: '',
            lastUserModDt: null,
            verifiedBy: '',
            verifiedDt: null,
            inEdit: true
        };
        const localData = [...data];
        localData.unshift(defaultNewRow);
        setData(localData);
        setTempSequence(sequence);
    };
    
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };
    
    const toggleEdit = () => {
        if (inEdit) {
            cancelEdits();
        } else {
            enterEditMode();
        }
    }
    
    const enterEditMode = () => {
        setData(data.map(item => ({...item, inEdit: true})));
        setInEdit(true);
    };
    
    const cancelEdits = () => {
        getData();
        setInEdit(false);
        setEditedRows([]);
        setDataErrors([]);
        resetRemove();
    };

    const save = () => {
        if (checkSaveValidations()) {
            toastPreSaveValidationErrors();
            return;
        }
        if (editedRemoved.length) {
            toast.error('There are rows marked for removal. Delete them or cancel removal before saving.');
            return;
        }
        // Check for new rows along with edited rows. If no new rows AND no edited rows, then return early.
        const newRows = data.filter((item) => item.id < 0);
        if (editedRows.length === 0 && newRows.length === 0) { // No change made. Turn off edit mode.
            cancelEdits();
            return;
        }
        const updatedIdList = editedRows.map((item) => item.id);
        const updatedData = data.filter((item) => updatedIdList.includes(item.id));
        const newAndUpdatedData = updatedData.concat(newRows.filter((item) => !updatedIdList.includes(item.id)));
        axios.post('/api/auth/ra/plants/save', newAndUpdatedData)
            .then(() => {
                toastSuccessfulSave();
                getData();
                setInEdit(false);
                setEditedRows([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    
    const checkSaveValidations = () => {
        let localErrors = [...dataErrors];
        localErrors = validateRequiredToSaveColumns(localErrors);
        setDataErrors(localErrors);
        const blockingErrorList = localErrors.filter((item) => item.blocking);
        return blockingErrorList.length > 0;
    };
    
    const validateRequiredToSaveColumns = (dataErrorList: Array<UiValidations>) => {
        const missingRequired = data.filter((item) => !item.respEntNm || !item.plantNm || (item.stateNm && !item.countyNm));
        
        if (missingRequired.length === 0) {
            return dataErrorList;
        }
        toast.error('There are columns missing required data. Please provide value to save changes.');
        let localErrors = [...dataErrors];
        missingRequired.forEach((item) => {
            if (!item.respEntNm) {
                localErrors = addDataError(item.id, 'respEntNm', 'Last Modifying Entity', '',
                    respEntNmEmpty, true, localErrors);
            }
            if (!item.plantNm) {
                localErrors = addDataError(item.id, 'plantNm', 'Plant Name', '',
                    plantNmEmpty, true, localErrors);
            }
            if (item.stateNm && !item.countyNm) {
                localErrors = addDataError(item.id, 'countyNm', 'County', '',
                    countyNmEmpty, false, localErrors);
            }
        });
        return localErrors;
    }
    
    const toggleVerify = () => {
        if (inVerify) {
            cancelVerify();
        } else {
            enterVerifyMode();
        }
    };
    
    const enterVerifyMode = () => {
        setInVerify(true);
    };
    
    const cancelVerify = () => {
        setData(deepCopyGridDataWithOriginal(originalData));
        setInVerify(false);
        setEditedVerified([]);
    }
    
    const saveVerifyChanges = () => {
        if (editedVerified.length === 0) { // no edits made, just turn off verify mode
            cancelVerify();
            return;
        }
        const updatedData = data.filter((item) => editedVerified.includes(item.id));
        axios.post('/api/auth/ra/plants/verify', updatedData)
            .then(() => {
                getData();
                setInVerify(false);
                setEditedVerified([]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSelectAll = () => {
        // Adjust filter criteria to ignore pagination for processing
        const tempDataState = {...dataState}
        delete tempDataState.skip
        delete tempDataState.take

        // Iterate over filtered row data, set verified flag, push to grid
        const filteredData = process(data, tempDataState);
        const localData = filteredData.data.map((item) => {
            const dataItem = {...item};
            dataItem['verified'] = true;
            setEditedVerified(previousEditedVerified => updateEditedVerified(previousEditedVerified, originalData, item.id, true));
            return dataItem;
        });
        setData(localData);
    };

    const toggleRemove = () => {
        if (inRemove) { // Remove mode is active, so stop it.
            cancelRemove();
        } else { // Remove mode is not active, so start it.
            // initialize 'removed' field to false on all data objects
            const localData = data.map((item) => ({
                ...item,
                removed: false
            }));
            setData(localData);
            setInRemove(true);
        }
        
    };
    
    const cancelRemove = () => {
        // take 'removed' field off data objects
        const localData = data.map(({removed, ...rest}) => (rest));
        setData(localData);
        resetRemove();
    };
    
    const resetRemove = () => {
        setEditedRemoved([]);
        setInRemove(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const confirmRemove = () => {
        axios.delete('/api/auth/ra/plants/delete', {data: editedRemoved})
            .then(() => {
                setModalOpen(false);
                const localData = data.filter((item) => !editedRemoved.includes(item.id));
                setData(localData);
                resetRemove();
            })
            .catch((error) => {
                console.log(error);
            });
    };
    
    const updateRemoved = (id: number, removed: boolean) => {
        return data.map((item) => {
            if (item.id === id) {
                const dataItem = {...item};
                dataItem.removed = removed;
                return dataItem;
            } else {
                return item;
            }
        });
    };

    const handleRemoveChange = async (id: number, willRemove: boolean) => {
        const isRemoved = editedRemoved.includes(id);
        if (!isRemoved && willRemove) {
            const message: string = await getPlantDependencyWarning(id);
            if (message) {
                toast.error(message);
                return false;
            }
            const edited = [...editedRemoved];
            edited.push(id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        } else if (isRemoved && !willRemove) {
            const edited = editedRemoved.filter((item) => item !== id);
            setEditedRemoved(edited);
            setData(updateRemoved(id, willRemove));
        }
        return true;
    };
    
    const getPlantDependencyWarning = async (id: number) => {
        const params = new URLSearchParams();
        params.set('id', id.toString());
        try {
            const response = await axios.get('/api/auth/ra/plants/validate/plant-dependency', {params});
            return response.data;
        } catch (error) {
            console.log(error);
            return 'Cannot verify that Plants record may be removed.';
        }
    };
    
    const handlePlantNameBlur = (id: number, field: string, value: string) => {
        handleChange(id, field, value);
        let params = new URLSearchParams();
        params.set('plantNm', value);
        params.set('id', id.toString())
        axios.get('/api/auth/ra/plants/unique/plantNm', {params})
            .then((resp) => {
                if (resp.data === false) {
                    toast.error(plantUnique);
                    setDataErrors((prevDataErrors) => addDataError(id, field, 'Plant Name', value, plantUnique, true, prevDataErrors));
                } else {
                    setDataErrors((prevDataErrors) => removeDataErrorByMessage(id, field, prevDataErrors, plantUnique));
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Error occurred while validating Plant Name.');
            });
    };
    
    const RemoveRowCell = getRemoveCell(handleRemoveChange);
    const RespEntDropDownCell = useMemo(() => getDropDownCell(entityOptions, 'Entity'),[entityOptions]);
    const PlantCodeCell = useMemo(() => getMaxNumberCell(7),[]);
    const StateDropDownCell = useMemo(() => getDropDownCell(stateOptions, ''),[stateOptions]);
    // Don't use useMemo with ChildDropDownCell. That causes the child field not to reset immediately when parentField value changes.
    const CountyDropDownCell = getChildDropDownCell(countyOptions, 'County', 'stateNm');
    const PercentDeliverableCell = useMemo(() => getPercentCell(2, isStaff),[isStaff]);
    const VerifiedCell = useMemo(() => getVerifiedCell(inVerify && isStaff), [inVerify, isStaff]);
    const PlantNameCell = getOnBlurCell(handlePlantNameBlur);
    
    const formatExport = (data: Array<PlantsData>) => {
        return data.map(item => ({
            ...item,
            verified: item.verified ? 'YES' : 'NO',
            percentDeliverableSummer: item.percentDeliverableSummer === null ? null : item.percentDeliverableSummer * 100,
            percentDeliverableWinter: item.percentDeliverableWinter === null ? null : item.percentDeliverableWinter * 100,
        }));
    };

    const _export = useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current?.save(formatExport(data));
        }
    };

    const refreshData = () => {
        getData();
    };


    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const validationButtonHandler = () => {
        setValidationErrors([]);
        setLoadingValidation(true);
        axios.get('/api/auth/ra/plants/validate')
            .then(response => {
                setValidationErrors(response.data);
                getData();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingValidation(false);
            });
    }

    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`,tempPage.take.toString());
        }
    };

    return (
        <div>
            <ActionPanel title={'Plants'}>
                {!inRemove && inEdit && addRole && <AddPanel addRecord={addNewRow}/>}
                {isStaff && removeRole && inEdit && <RemovePanel inRemove={inRemove} openModal={openModal} toggleRemove={toggleRemove}/>}
                {!inRemove && modifyRole && seasonOpen && <SavePanel inEdit={inEdit} disabled={inVerify} save={save} toggleEdit={toggleEdit}/>}
                {!inRemove && inEdit && <ImportPanel api='/api/auth/ra/plants/upload' setUploadRecords={handleUpload} />}
                {isStaff && modifyRole && seasonOpen && !inEdit && <VerifyPanel inVerify={inVerify} disabled={inEdit} selectAll={handleSelectAll} verify={saveVerifyChanges} toggleVerify={toggleVerify}/>}
                <ClearFilterPanel clearFilter={clearFilters}/>
                {!inEdit && !inVerify && <RefreshPanel disabled={inEdit || inVerify} loading={loadingData} getData={refreshData}/>}
                {!inEdit && !inVerify && <ExportPanel exportData={excelExport} disabled={inEdit || inVerify}/>}
                {!inEdit && modifyRole && seasonOpen && !inVerify && <ValidatePanel disabled={inEdit || inVerify} loading={loadingValidation} validate={validationButtonHandler}/>}
            </ActionPanel>
            <RemoveModal open={modalOpen} closeModal={closeModal} confirmRemove={confirmRemove}/>
            <TooltipContainer>
                <CustomLocalizationProvider>
                <ExcelExport data={data} fileName={`Plants_${format(new Date(), 'MM-dd-yyyy')}.xlsx`} ref={_export}>
                    <Grid
                        data={process(data, dataState)}
                        dataItemKey='id'
                        sortable={true}
                        filterable={true}
                        resizable={true}
                        style={{ height: "510px" }}
                        {...dataState}
                        editField='inEdit'
                        onDataStateChange={dataStateChange}
                        onItemChange={itemChange}
                        pageable={{pageSizes: [5, 10, 20, 25, 50, 100]}}
                        onPageChange={pageChange}
                    >
                        {inEdit && inRemove && <GridColumn title='Remove' field='removed' cell={RemoveRowCell} width='100px' filter='boolean'/>}
                        {
                            columnMeta.length
                                ? columnMeta.map((col: ColumnMeta, index) => {
                                    switch (col.nm) {
                                        case 'verified':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='105px' cell={VerifiedCell} filter={"boolean"}/>
                                        case 'id':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='115px' editable={false}/>
                                        case 'respEntNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='160px' cell={RespEntDropDownCell}/>
                                        case 'plantNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='425px' cell={PlantNameCell}/>
                                        case 'plantCode':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='240px' cell={PlantCodeCell}/>
                                        case 'percentDeliverableSummer':
                                        case 'percentDeliverableWinter':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='312px' cell={PercentDeliverableCell}/>
                                        case 'stateNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' cell={StateDropDownCell}/>
                                        case 'countyNm':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' cell={CountyDropDownCell}/>
                                        case 'comments':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='425px' cell={InputCell}/>
                                        case 'subYearId':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='150px' editable={false}/>
                                        case 'lastUserModBy':
                                        case 'verifiedBy':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='200px' editable={false}/>
                                        case 'lastUserModDt':
                                        case 'verifiedDt':
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='230px' editable={false}/>
                                        default:
                                            return <GridColumn key={index} field={col.nm} title={col.vnm} width='100px' editable={col.editInd !== '0'}/>
                                    }
                                })
                                : <GridColumn/>
                        }
                    </Grid>
                </ExcelExport>
                </CustomLocalizationProvider>
            </TooltipContainer>
            {inEdit && dataErrors.length > 0 && <ValidationPreSaveGrid data={dataErrors}/>}
            {validationErrors.length > 0 && <ValidationMessageGrid data={validationErrors} showDataSet={false} title={'Plants'}/>}
        </div>
    );
};

export default PlantsGrid;