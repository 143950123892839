import {ColumnMeta, DataUpdate, UiValidations} from '../interfaces/grid-interfaces';
import {Principal} from '../auth';
import {State} from '@progress/kendo-data-query';
import {DropDownOption} from '../components/common/grid/DropDownCell';
import {ChildDropDownOption} from '../components/common/grid/ChildDropDownCell';
import {toast} from 'react-toastify';
import parse from 'date-fns/parse';
import {Dispatch, SetStateAction} from "react";
import {format, startOfMonth} from 'date-fns';

export const DATE_ONLY_FORMAT = 'MM/dd/yyyy';
export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';

const valueTooLarge = 'Value is too large to save.';
const valueTooSmall = 'Value is too small to save.';

type FieldType = 'date' | 'datetime';

export const initialDataState: State = {
    sort: [{field: 'id', dir: 'asc'}],
    skip: 0,
    take: 10
};

export const deepCopyGridData = (data: any) => data.map((d: any) => ({...d}));

export const deepCopyGridDataWithOriginal = (data: any) => data.map((d: any) => ({
    ...d,
    originalData: {...d}
}));

export const isPageStaff = (page: string, roles: Array<Principal>) => (
    roles.filter((role) => role.tabNm === page && role.sppStaff).length > 0
);

export const initialPageRole = {
    isStaff: false,
    readRole: false,
    addRole: false,
    modifyRole: false,
    removeRole: false
};

/***
 * Get single set of permissions based on the maximum access of the union of user roles for the page.
 * @param page Comes from Admin.TableMeta Nm where EnabledInd = 1. Page name is not the same thing as data set name.
 * @param roles Set of roles that are mapped to user. This list is set in Redux at login.
 * @return PageRole
 */
export const getCompressedPageRole = (page: string, roles: Array<Principal>) => {
    return {
        isStaff: roles.some(role => role.tabNm === page && role.sppStaff),
        readRole: roles.some(role => role.tabNm === page && role.readRole),
        addRole: roles.some(role => role.tabNm === page && role.addRole),
        modifyRole: roles.some(role => role.tabNm === page && role.modifyRole),
        removeRole: roles.some(role => role.tabNm === page && role.removeRole),
    };
};

export const processColumnMeta = (columns: any) => columns
    .filter((c: any) => c.visInd === '1')
    .sort((a: any, b: any) => a.sortId - b.sortId);

export const processDropDownListOptions = (options: Array<string>) => options
    .sort()
    .map((item: string) => ({
        text: item,
        value: item
    }));

export const parseLocalDateString = (localDate: any, monthYearOnly?: boolean) => {
    if (!localDate) {
        return null;
    }
    const day = parse(localDate as string, 'yyyy-MM-dd', new Date());
    return monthYearOnly ? startOfMonth(day) : day;
}

export const parseTimestampString = (timestamp: any) =>
    timestamp ? parse(timestamp as string, DATE_TIME_FORMAT, new Date()) : null;

export const formatLocalDate = (localDate: any) =>
    localDate ? format(localDate, 'yyyy-MM-dd') : null;

export const formatTimestamp = (timestamp: any) =>
    timestamp ? format(timestamp, DATE_TIME_FORMAT) : null;

export const updateEditedRows = <T extends { id: number }>(editedRows: Array<DataUpdate>, originalData: Array<T>, id: number, field: string, value: any, fieldType?: FieldType): Array<DataUpdate> => {
    let edited = [...editedRows];
    if (editedRows.some((e) => e.id === id)) {
        edited = editedRows.map((e) => {
            if (e.id === id) {
                let original = originalData.find((item) => item.id === id);
                let editedFields = [...e.fields];
                const included = e.fields.includes(field);
                if (field && original) {
                    let sameTypeOriginalValue = original[field];
                    let sameTypeValue = value;
                    if (fieldType) {
                        let formatDateFunction = (a: any) => a;
                        if (fieldType === 'date') {
                            formatDateFunction = formatLocalDate;
                        } else if (fieldType === 'datetime') {
                            formatDateFunction = formatTimestamp;
                        }
                        if (typeof original[field] === 'object' && typeof value === 'string') {
                            // Date value compared to imported date
                            sameTypeOriginalValue = formatDateFunction(original[field]);
                        } else if (typeof original[field] === 'object' && typeof value === 'object') {
                            // Date value compared to UI date change
                            sameTypeOriginalValue = formatDateFunction(original[field]);
                            sameTypeValue = formatDateFunction(value);
                        } else if (!original[field] || !value) {
                            // Either original or new value is null or empty string, but not both
                            if (!original[field]) {
                                sameTypeOriginalValue = '';
                            }
                            if (!value) {
                                sameTypeValue = '';
                                if (original[field]) {
                                    sameTypeOriginalValue = formatDateFunction(original[field]);
                                } else {
                                    sameTypeOriginalValue = '';
                                }
                            }
                        }
                    } else if (typeof original[field] === 'number' && typeof value === 'string') {
                        // In case some input tries to turn a number into string, make sure it's the right type before comparing.
                        sameTypeValue = +value;
                    }
                    
                    if (sameTypeOriginalValue !== sameTypeValue) {
                        if (included) { // edited field was already different from original. Return to keep edited status.
                            return e;
                        } else {
                            editedFields.push(field); // Edited row has new edited field. Add to edited fields.
                        }
                    } else if (included) { // value returned to original value so remove it from edited fields
                        editedFields = e.fields.filter((f) => f !== field);
                    }
                }
                return {id: e.id, fields: editedFields};
            } else {
                return e;
            }
        }).filter((e) => e.fields.length !== 0); // Remove rows where all edited fields have returned to the original value
    } else { // first edited field of the row during this edit session
        edited.push({id: id, fields: [field]});
    }
    return edited;
};

export const updateEditedVerified = <T extends { verified: boolean, id: number }>(editedVerified: Array<number>, originalData: Array<T>, id: number, value: any): Array<number> => {
    let edited = [...editedVerified];
    const original = originalData.find((item) => item.id === id);
    const index = edited.indexOf(id);
    if (original && original.verified === value) {
        if (index > -1) {
            edited.splice(index, 1);
        }
    } else {
        if (index === -1) {
            edited.push(id);
        }
    }
    return edited;
};

export const updateEditedApproved = <T extends { approved: string, id: number }>(editedApproved: Array<number>, originalData: Array<T>, id: number, value: any): Array<number> => {
    console.log(editedApproved);
    let edited = [...editedApproved];
    const original = originalData.find((item) => item.id === id);
    const index = edited.indexOf(id);
    if (original && original.approved === value) {
        if (index > -1) {
            edited.splice(index, 1);
        }
    } else {
        if (index === -1) {
            edited.push(id);
        }
    }
    return edited;
};

export const isDifferent = (value: any, originalValue: any) => {
    if ((originalValue || originalValue === '' || originalValue === 0)) {
        let sameTypeValue = value;
        if (typeof originalValue === 'number' && typeof value === 'string') {
            sameTypeValue = +value;
        }
        if (originalValue !== sameTypeValue) {
            return true;
        }
    }
    if (originalValue === null || typeof originalValue === 'undefined') {
        return (value || value === '' || value === 0);
    }
    return originalValue !== value;
};

export const resetOption = (optionValue: string | null, options: Array<DropDownOption>, resetValue: string) => {
    if (!optionValue) {
        return resetValue;
    }
    const option = options.find((option: DropDownOption) => option.value.toString().toLowerCase() === optionValue.toLowerCase());
    return !!option ? option.value.toString() : resetValue;
};

export const resetYesNoOption = (optionValue: string | null, resetValue: string) => {
    if (!optionValue) {
        return resetValue;
    }
    const option = optionValue.toUpperCase() === 'YES' ? 'YES' : optionValue.toUpperCase() === 'NO' ? 'NO' : '';
    return option ? option : resetValue;
};

export const resetChildOption = (parentValue: string | null, childValue: string | null, options: Array<ChildDropDownOption>, resetValue: string) => {
    if (!parentValue || !childValue) {
        return resetValue;
    }
    const option = options.find((option: ChildDropDownOption) => 
        option.parentValue.toString().toLowerCase() === parentValue.toLowerCase() 
        && option.value.toString().toLowerCase() === childValue.toLowerCase());
    return !!option ? option.value.toString() : resetValue;
};

export const resetChildDependentOption = (parentValue: string | null, childValue: string | null, options: Array<ChildDropDownOption>, resetValue: string) => {
    if (!parentValue || !childValue) {
        return resetValue;
    }
    const option = options.find((option: ChildDropDownOption) =>
        option.parentValue.toString().toLowerCase() === parentValue.toLowerCase()
        && option.value.toString().toLowerCase() === childValue.toLowerCase());
    return !!option && !!option.childValue ? option.childValue.toString() : resetValue;
};

export const addDataError = (id: number, field: string, heading: string, value: string, message: string, blocking: boolean, errors: Array<UiValidations>) => {
    let uiError: any = {id, field, heading, value, message, blocking};
    if (!errors.find((item: any) => item.id === id && item.field === field && item.message === message)) {
        return [...errors, uiError];
    }
    return errors;
};

export const addDataErrorReplaceOld = (id: number, field: string, heading: string, value: string, message: string, blocking: boolean, errors: Array<UiValidations>) => {
    let uiError: any = {id, field, heading, value, message, blocking};
    if (errors.find((item: any) => item.id === id && item.field === field && item.message === message && item.value !== value)) {
        const filteredErrors = removeDataErrorByMessage(id, field, errors, message);
        return [...filteredErrors, uiError];
    } else if (!errors.find((item: any) => item.id === id && item.field === field && item.message === message)) {
        return [...errors, uiError];
    }
    return errors;
};

export const removeDataError = (id: number, field: string, errors: Array<UiValidations>) => {
    if (errors.find((item: any) => item.id === id && item.field === field)) {
        return errors.filter((item: any) => !(item.id === id && item.field === field));
    }
    return errors;
};

export const removeDataErrorByMessage = (id: number, field: string, errors: Array<UiValidations>, message: string) => {
    if (errors.find((item: any) => item.id === id && item.field === field && item.message === message)) {
        return errors.filter((item: any) => !(item.id === id && item.field === field && item.message === message));
    }
    return errors;
};

export const removeDataErrorByValue = (id: number, field: string, errors: Array<UiValidations>, value: string) => {
    if (errors.find((item: any) => item.id === id && item.field === field && item.value === value)) {
        return errors.filter((item: any) => !(item.id === id && item.field === field && item.value === value));
    }
    return errors;
};

export const removeDataErrorsByField = (id: number, field: string, errors: Array<UiValidations>) => {
    if (errors.find((item: any) => item.id === id && item.field === field)) {
        return errors.filter((item: any) => !(item.id === id && item.field === field));
    }
    return errors;
}

// Compare only date and time down to minutes. Diff uses UTC for more precise comparison.
// DateCell only displays time precision to the minute.
export const isSameDateTime = (dateOne: string | Date | null, dateTwo: string | Date | null) => {
    if (dateOne === null && dateTwo === null) {
        return true;
    } else if (dateOne === null || dateTwo === null) {
        return false;
    }
    if (typeof dateOne === 'string') {
        dateOne = parse(dateOne, 'MM/dd/yyyy hh:mm a', new Date());
    }
    if (typeof dateTwo === 'string') {
        dateTwo = parse(dateTwo, 'MM/dd/yyyy hh:mm a', new Date());
    }
    const millisecondsPerMinute = 1000 * 60;
    const utc1 = Date.UTC(dateOne.getFullYear(), dateOne.getMonth(), dateOne.getDate(), dateOne.getHours(), dateOne.getMinutes());
    const utc2 = Date.UTC(dateTwo.getFullYear(), dateTwo.getMonth(), dateTwo.getDate(), dateTwo.getHours(), dateTwo.getMinutes());

    return Math.floor((utc2 - utc1) / millisecondsPerMinute) === 0;
};

export const getDistinctListFromField = (list: Array<any>, field: string) => {
    if (!list.length) {
        return list;
    } else {
        return Array.from(new Set(list.map((item) => item[field])));
    }
};

//helper function for upper/lower bound data validations for numeric inputs
//this one goes inside an if statement checking whether the field is one of the numeric fields and if the value exists in the change handler.
export const numberBoundValidationOnEdit = (value: number, field: string, setDataErrors: Dispatch<SetStateAction<Array<UiValidations>>>, item: any, columnMeta: Array<ColumnMeta>, maxValue: number, minValue: number) => {
    const largeToast = 'tooLarge' + field + item.id;
    const smallToast = 'tooSmall' + field + item.id;

    if (toast.isActive(smallToast)) {
        toast.dismiss(smallToast);
    }
    if (toast.isActive(largeToast)) {
        toast.dismiss(largeToast);
    }

    const heading = columnMeta.find((col) => col.nm === field)?.vnm || field;

    if (value > maxValue) {
        toast.error(`Value too large: ${value}. Maximum is ${maxValue}.`, {toastId: largeToast});
        setDataErrors((prevDataErrors) => addDataErrorReplaceOld(item.id, field, heading, String(value), valueTooLarge, true, prevDataErrors));
        setDataErrors((prevDataErrors) => removeDataErrorByMessage(item.id, field, prevDataErrors, valueTooSmall));
    } else if (value < minValue) {
        toast.error(`Value too small: ${value}. Minimum is ${minValue}.`, {toastId: smallToast});
        setDataErrors((prevDataErrors) => addDataErrorReplaceOld(item.id, field, heading, String(value), valueTooSmall, true, prevDataErrors));
        setDataErrors((prevDataErrors) => removeDataErrorByMessage(item.id, field, prevDataErrors, valueTooLarge));
    } else {
        setDataErrors((prevDataErrors) => removeDataErrorsByField(item.id, field, prevDataErrors));
    }
}

// This was used with RA (East) code. Don't use with Wrap code.
//helper function for data bounds validation meant to be used on uploaded data
//use this in the process upload on fields that should have data bounds validations
export const numberBoundValidationOnUpload = (field: string, setDataErrors: Dispatch<SetStateAction<Array<UiValidations>>>, value: number, id: number, columnMeta: Array<ColumnMeta>, maxValue: number, minValue: number) => {
    if (value > maxValue) {
        let field = 'namePlateCapacity';
        let heading = columnMeta.find((col) => col.nm === field)?.vnm;
        let message = 'Issue detected while uploading. Please refer to the error grid below.';
        if (!toast.isActive(message)) {
            toast.error(message, {toastId: message});
        }
        setDataErrors((prevDataErrors) => addDataErrorReplaceOld(id, field, heading ? heading : field, String(value),
            valueTooLarge, true, prevDataErrors));
        setDataErrors((prevDataErrors) => removeDataErrorByMessage(id, field, prevDataErrors, valueTooSmall));
    } else if (value < minValue) {
        let field = 'namePlateCapacity';
        let heading = columnMeta.find((col) => col.nm === field)?.vnm;
        let message = 'Issue detected while uploading. Please refer to the error grid below.';
        if (!toast.isActive(message)) {
            toast.error(message, {toastId: message});
        }
        setDataErrors((prevDataErrors) => addDataErrorReplaceOld(id, field, heading ? heading : field, String(value),
            valueTooSmall, true, prevDataErrors));
        setDataErrors((prevDataErrors) => removeDataErrorByMessage(id, field, prevDataErrors, valueTooLarge));
    }
}

// Common toast messages for consistency between pages.

export const toastSuccessfulSave = () => {
    toast.success('Save complete');
};

export const toastPreSaveValidationErrors = () => {
    toast.error('Please fix data validation errors below.');
};

export const replaceSpaceWithUnderscore = (text: string) => text.replace(/ /g, '_');
