import React from 'react';
import {AdminMenuProps} from '../../interfaces/menu-interface';
import {connect} from 'react-redux';
import {getViewPage} from '../../services/user-service';
import {SubMenu} from './SubMenu';
import {Dropdown} from 'semantic-ui-react';

const ResAdqAdminMenu: React.FC<AdminMenuProps> = ({roles, isStaff, updateActiveMenuItem}) => {
    return (
        <Dropdown item={true} text='Administration' closeOnChange={true}>
            <Dropdown.Menu>
                {getViewPage('Register', roles) && isStaff &&
                    <SubMenu menuName='registerNewUser' menuDisplayName='Register New User' menuLink='/Register'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Roles', roles) && isStaff &&
                    <SubMenu menuName='roleManagement' menuDisplayName='Role Management' menuLink='/RoleManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ResponsibleEntity', roles) && isStaff &&
                    <SubMenu menuName='entityManagement' menuDisplayName='Entity Management'
                             menuLink='/EntityManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Users', roles) &&
                    <SubMenu menuName='userManagement' menuDisplayName='User Management' menuLink='/UserManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('Historical', roles) &&
                    <SubMenu menuName='historical' menuDisplayName='Historical' menuLink='/Historical'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ReferenceDocument', roles) &&
                    <SubMenu menuName='referenceDocuments' menuDisplayName='Reference Documents'
                             menuLink='/ReferenceDocuments'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('RaDataManagement', roles) && isStaff &&
                    <SubMenu menuName='raDataManagement' menuDisplayName='RA Data Management'
                             menuLink='/RaDataManagement'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('ValidationMessage', roles) &&
                    <SubMenu menuName='raValidations' menuDisplayName='RA Validations' menuLink='/RAValidations'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
                {getViewPage('RaRollover', roles) && isStaff &&
                    <SubMenu menuName='raRollover' menuDisplayName='RA Rollover' menuLink='/RARollover'
                             updateActiveMenuItem={updateActiveMenuItem}/>}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state: any) => ({
    roles: state.authReducer.roles,
    isStaff: state.authReducer.userRolesEntities.sppStaff,
});

export default connect(mapStateToProps)(ResAdqAdminMenu);