import {useContext} from 'react';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {Field} from '@progress/kendo-react-form';
import {GridCellProps} from '@progress/kendo-react-grid';
import {ServiceTypeOptionContext} from './context/ServiceTypeOptionContext';
import {GridEditContext} from './context/GridEditContext';
import {FORM_DATA_INDEX} from './RowEditGrid';

export const ServiceTypeOptionFormCell = (props: GridCellProps) => {
    const {editIndex} = useContext(GridEditContext);
    const {options} = useContext(ServiceTypeOptionContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;

    return (
        <td>
            {isInEdit ? (
                <Field name={`${props.field}`} data={options} component={DropDownList}/>
            ) : (
                props.dataItem[props.field || ""]
            )}
        </td>
    );
};