import axios from "axios";
import {toast} from "react-toastify";
import JWT from 'jwt-decode';

let fileDownload = require('js-file-download');
export default class GeneralService {

    private tokenExpired: boolean;

    constructor() {
        this.tokenExpired = false;
    }

    downloadExcel = (e: any, {name}: any) => {
        let tabName: string = name;
        let params = new URLSearchParams();
        params.append('tabName', name);
        axios.get(`/api/auth/exporttoexcel/exportToExcel`
            , {
                params,
                responseType: 'arraybuffer'
            }).then(resp => {
            let extension = '.xlsx';
            fileDownload(resp.data, tabName + extension);
        }).catch(resp => {
            toast.error(`Error exporting table data - ${resp.message}`);

        });
    }

    downloadContactDateExcel = (startDate: string, endDate: string) => {
        return new Promise((resolve, reject) => {
            let params = new URLSearchParams();
            params.append('startDate', startDate);
            params.append('endDate', endDate);
            axios.get(`/api/auth/exporttoexcel/exportContactByDate`, {
                params,
                responseType: 'arraybuffer'
            }).then(resp => {
                fileDownload(resp.data, 'ContactByDateList.xlsx');
                resolve('success');
            }).catch(error => {
                toast.error(`Error exporting Contact List data - ${error.message}`);
                reject(error);
            });
        });
    }

    downloadFile = (e:any,{docname}:any) => {
        axios.post('/api/auth/referenceDocument/downloadRefDoc',null, { params : {filename:docname},
            withCredentials: true,
            responseType: 'arraybuffer'
        })
            .then(resp => {
                let extension = null;
                if(resp.headers['content-type'] === 'application/pdf' || resp.headers['content-type'] === 'application/octet-stream'){
                    extension = '.pdf';
                }
                else if (resp.headers['content-type'] === 'application/xlsx' || resp.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                {
                    extension = '.xlsx';
                }
                else if (resp.headers['content-type'] === 'application/doc' || resp.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                {
                    extension = '.docx';
                }
                fileDownload(resp.data, docname+extension)
            }).catch(resp => {
            toast.error(`Error retrieving File from server - ${resp.message}`);
        })
    };

    getNotificationCountRequest = () =>{
        return new Promise(function (resolve, reject){
            axios.get('/api/auth/notificationTracking/unreadNotificationsCount').then(
                ({status  , data}) => {
                    if (status === 200){
                        resolve(data);
                    }
                }).catch(resp => {
                    reject(resp)
                    toast.error(`Error retrieving notification count - ${resp.message}`)
            });
        });
    }

    loggedIn = (printTokenMessage?:boolean) => {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token, printTokenMessage) // handwaiving here
    };

    getToken = () => {
        return localStorage.getItem('edst_token') || '';
    };

    isTokenExpired = (token: string, printTokenMessage?:boolean) => {
        try {
            const decoded = JWT(token) as any;
            if (decoded['exp'] < Date.now() / 1000) { // Checking if token is expired. N
                if(!this.tokenExpired) {
                    this.tokenExpired = true;
                    if(printTokenMessage !== undefined && printTokenMessage) {
                        toast.warn('Token Expired. Please log back in.', {toastId: 'token_expired'});
                    }
                }
                return true;
            }
            else
                this.tokenExpired = false;
            return false;
        }
        catch (err) {
            return false;
        }
    };

    logout =() => {
        // Clear user token and profile data from localStorage
        return new Promise((resolve) => {
            localStorage.removeItem('edst_token');
            localStorage.removeItem('userName');
            localStorage.removeItem('roles');
            localStorage.removeItem('userEntities');
            localStorage.removeItem('userRolesEntities');
            sessionStorage.removeItem('uuid');
            localStorage.removeItem('currentCategory');
            resolve(true);
        }) as any;
    };

    login = (username:string,password:string) => {
        let user = {
            userName: username,
            password: password
        };
        return new Promise((resolve, reject) => {
            axios.post(`/api/login`, user)
                .then(({status, data, statusText}) => {
                    if (status === 200) {
                        resolve(data)
                    } else {
                        throw new Error(`Error logging in - ${statusText}`);
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                    reject(error);
                })
        })
    };
    
    changePassword = (oldPassword: string, newPassword: string) => {
        let requestData = {oldPassword, newPassword};
        return new Promise((resolve, reject) => {
            axios.post('/api/auth/changePassword', requestData)
                .then(({status, data, statusText}) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        throw new Error(statusText);
                    }
                })
                .catch((error) => {
                    reject(error.message);
                })
        });
    };

    sendRequestToUpdatePassword = (email: string) => {
        const url = '/api/forgotPassword';

        return new Promise((resolve, reject) => {
            axios.patch(url, {email})
                .then(({ status } ) => {
                    if (status === 200) {
                        resolve(true);
                    } else {
                        throw new Error('Error sending forgot password request for : ' + email);
                    }
                })
                .catch((error:any) => {
                    reject(new Error(error));
                });
        });
    };

    resetUserPassword = (securityUserResponse:SecurityUserResponse) => {
        const url = '/api/resetPassword';

        return new Promise<PasswordResetResponse>((resolve, reject) => {
            axios.patch(url, securityUserResponse)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting security info for user'));
                    }
                });
        });
    };

    getMenuItems = () => {
        const url = `/api/auth/getMenuItems`;

        return new Promise<MenuItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching list of menu items'));
                    }
                });
        });
    };

    /**
     * Since this API uses category as a path variable, do not call this method if category is null or empty string.
     * @param category - Valid options: 'RA', 'MDWG'
     */
    getCurrentSubYear = (category: string) =>{
        return new Promise((resolve, reject) => {
            axios.get(`/api/auth/admin/subyear/${category}`)
                .then(resp => {
                    resolve(resp);
            }).catch(() => reject());
        })
    }

    getSecurityUserResponse = (token:string) => {
        const url = '/api/securityUserResponse?token=' + token;

        return new Promise<SecurityUserResponse>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting security info for user'));
                    }
                });
        });
    };

    /***
     * Username may have letters, numbers, underscore, hyphen, period, and @.
     * User may use email as username, but the username will not be validated for proper email regex.
     * @param username
     */
    validateUsername = (username: string) => {
        let regExp = /^[\w-.@]*$/g;
        return regExp.test(username);
    };

    /***
     * Common validation of email address. Allows use of letters, numbers, periods, and hyphens in parts before domain.
     * @param email
     */
    validateEmail = (email: string) => {
        let regEx = /^[\w-.]+@[\w-]+\.[\w]+/;
        return regEx.test(email);
    };

    /***
     * EDST password rules to validate password no matter where it is set within the app.
     * @param password
     * @return error string array - Validation is successful if array is empty.
     */
    validatePassword = (password: string) => {
        const errors: string[] = [];

        if (password.length < 6) {
            errors.push('Password must be at least 6 characters.');
        }
        if (password.search(/\d/) < 0) {
            errors.push('Password must contain a number.');
        }
        if (password.search(/[a-z]/) < 0) {
            errors.push('Password must contain a lower case letter.');
        }
        if (password.search(/[A-Z]/) < 0) {
            errors.push('Password must contain an upper case letter.');
        }
        if (password.search(/[\W]/) < 0) {
            errors.push('Password must contain a special character (examples: !@#$%^&*~? ).');
        }
        
        return errors;
    };
}