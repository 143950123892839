import {Dropdown} from 'semantic-ui-react';
import React from 'react';
import {connect} from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes';
import history from '../../history';

interface CategoryMenuProps {
    currentCategory: string;
    updateCurrentCategory: (category: string) => void;
    md: boolean;
    ra: boolean;
    wrap: boolean;
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({currentCategory, updateCurrentCategory, md, ra, wrap}) => {

    const handleItemClick = (e: any, {name}: any) => {
        updateCurrentCategory(name);
        history.push({pathname: '/'});
    };

    return (
        <Dropdown item={true} text='Change Category' closeOnChange={true}>
            <Dropdown.Menu>
                {md && <Dropdown.Item name='MDWG' active={currentCategory === 'MDWG'} onClick={handleItemClick}>
                    Model Development
                </Dropdown.Item>
                }
                {ra && <Dropdown.Item name='RA' active={currentCategory === 'RA'} onClick={handleItemClick}>
                    SPP Resource Adequacy
                </Dropdown.Item>
                }
                {wrap && <Dropdown.Item name='WRAP' active={currentCategory === 'WRAP'} onClick={handleItemClick}>
                    WRAP Forward Showing
                </Dropdown.Item>
                }
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentCategory: state.defaultReducer.currentCategory,
        md: state.authReducer.userRolesEntities.md,
        ra: state.authReducer.userRolesEntities.ra,
        wrap: state.authReducer.userRolesEntities.wrap,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCurrentCategory: (category: string) => dispatch(
            {type: actionTypes.UPDATE_EDST_CATEGORY, payload: category})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMenu);