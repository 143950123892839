import {useEffect, useState} from 'react';
import axios from 'axios';
import {GridColumn} from '@progress/kendo-react-grid';
import {COMMAND_CELL_WIDTH, CommandCell} from '../../common/row-edit-grid/CommandCell';
import {RequiredStringFormCell} from '../../common/row-edit-grid/RequiredStringFormCell';
import {RowEditGrid} from '../../common/row-edit-grid/RowEditGrid';
import {ServiceTypeOptionFormCell} from '../../common/row-edit-grid/ServiceTypeOptionFormCell';
import {ServiceTypeOptionContext} from '../../common/row-edit-grid/context/ServiceTypeOptionContext';

export const NercPriorityFormGrid = () => {
    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        axios.get('/api/auth/wrap/data/service-type/dropdown')
            .then(resp => {
                setOptions(resp.data);
            })
    }, []);

    return (
        <ServiceTypeOptionContext.Provider value={{options, setOptions}}>
            <div style={{maxWidth: '1512px'}}>
                <RowEditGrid
                    dataApi={'/api/auth/wrap/data/nerc-priority'}
                    uniqueFields={[{field: 'nm', title: 'Name'}]}
                >
                    <GridColumn title='Command' cell={CommandCell} width={COMMAND_CELL_WIDTH}/>
                    <GridColumn title='ID' field='id' editable={false} width={70}/>
                    <GridColumn title='Name' field='nm' cell={RequiredStringFormCell} width={400}/>
                    <GridColumn title='Service Type' field='serviceTypeNm' cell={ServiceTypeOptionFormCell} width={400}/>
                </RowEditGrid>
            </div>
        </ServiceTypeOptionContext.Provider>
    );
};