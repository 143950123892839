import {createContext} from 'react';

export const GridEditContext = createContext<{
    onRowAction: (options: {
        rowIndex: number;
        operation: "save" | "remove" | "add";
        dataItem?: any;
    }) => void;
    setEditIndex: (rowIndex: number | undefined) => void;
    editIndex: number | undefined;
}>({} as any);