import {useState} from 'react';
import {GridDataStateChangeEvent, GridPageChangeEvent, GridPagerSettings} from '@progress/kendo-react-grid';
import {State} from '@progress/kendo-data-query';

const getInitialDataState = (): State => {
    const globalPageSize = localStorage.getItem(`globalPageSize`);
    return {
        sort: [{field: 'id', dir: 'asc'}],
        skip: 0,
        take: globalPageSize ? +globalPageSize : 10
    };
};

export const useDataState = () => {
    const [dataState, setDataState] = useState<State>(getInitialDataState());
    
    const pagerSettings: GridPagerSettings = {pageSizes: [5, 10, 20, 25, 50, 100]};

    // clear all the filters
    const clearFilters = () => {
        const noFilterDataState = {...dataState};
        delete noFilterDataState.filter;
        setDataState(noFilterDataState);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
    };
    
    const pageChange = (event: GridPageChangeEvent) => {
        const tempPage = event.page;
        if (tempPage) {
            setDataState({
                ...dataState,
                skip: tempPage.skip,
                take: tempPage.take
            });
            localStorage.setItem(`globalPageSize`, tempPage.take.toString());
        }
    };
    
    return {dataState, clearFilters, dataStateChange, pageChange, pagerSettings};
};