import {Button, ButtonGroup} from '@progress/kendo-react-buttons';
import {AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import React from 'react';

// Disabled prop should be true if grid is in edit mode.
// Edit mode and verify mode are mutually exclusive.
interface ApprovePanelProps {
    inEdit: boolean,
    disabled: boolean,
    approve: () => void,
    toggleEdit: () => void,
    selectAll: () => void
}

const ApprovePanel: React.FC<ApprovePanelProps> = ({inEdit, disabled, selectAll, approve, toggleEdit}) => {
    return (
        <>
            <AppBarSection>
                {inEdit ? (
                    <ButtonGroup>
                        <Button onClick={selectAll}>Select All</Button>
                        <Button onClick={approve}>Confirm Approval</Button>
                        <Button onClick={toggleEdit}>Cancel</Button>
                    </ButtonGroup>
                ) : (
                    <Button onClick={toggleEdit} disabled={disabled}>Approve</Button>
                )}
            </AppBarSection>
            <AppBarSpacer style={{width: 4}}/>
        </>
    );
};

export default ApprovePanel;