import React, {
    useEffect,
    useState
} from 'react';
import axios from 'axios';
import {
    Grid,
    GridColumn, GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import {
    process,
    State
} from '@progress/kendo-data-query';
import {initialDataState} from "../../services/data-grid-service";
import {ApiValidations} from "../../interfaces/grid-interfaces";
import {connect} from "react-redux";

interface HomeRaGridProps {
    currentCategory?: string
}

const HomeRaGrid: React.FC<HomeRaGridProps> = (currentCategory?) => {
        const [data, setData] = useState<Array<{ gridName: string, messages: number }>>([]);
        const [dataState, setDataState] = useState<State>({...initialDataState, take: 25});


        const getData = () => {
            axios.get('/api/auth/wrap/validations')
                .then((resp) => {
                    const gridData = [
                        {gridName: 'LoadSummer', season: 'Summer', title: 'Load Summer', messages: 0},
                        {gridName: 'LoadWinter', season: 'Winter', title: 'Load Winter', messages: 0},
                        {gridName: 'ResourceSummer', season: 'Summer', title: 'Resource Summer', messages: 0},
                        {gridName: 'ResourceWinter', season: 'Winter', title: 'Resource Winter', messages: 0},
                        {gridName: 'ContractSummer', season: 'All', title: 'Contracts & Obligations Summer', messages: 0},
                        {gridName: 'ContractWinter', season: 'All', title: 'Contracts & Obligations Winter', messages: 0},
                        {gridName: 'TsrInformationSummer', season: 'Summer', title: 'TSR (OASIS) Information Summer', messages: 0},
                        {gridName: 'TsrInformationWinter', season: 'Winter', title: 'TSR (OASIS) Information Winter', messages: 0},
                        {gridName: 'TsrMappingSummer', season: 'Summer', title: 'Purchases & Sales Summer', messages: 0},
                        {gridName: 'TsrMappingWinter', season: 'Winter', title: 'Purchases & Sales Winter', messages: 0},
                        {gridName: 'PlannedOutagesSummer', season: 'Summer', title: 'Additional Planned Outages', messages: 0},
                        {gridName: 'PlannedOutagesWinter', season: 'Winter', title: 'Additional Planned Outages', messages: 0},
                        {gridName: 'DemandResponseSummer', season: 'Summer', title: 'Demand Response Summer', messages: 0},
                        {gridName: 'DemandResponseWinter', season: 'Winter', title: 'Demand Response Winter', messages: 0},
                        {gridName: 'Facility', season: 'All', title: 'Facility Management', messages: 0},
                        {gridName: 'WrapDocument', season: 'All', title: 'Forms and Attestation Documents', messages: 0},
                        {gridName: 'ForcedOutageSummer', season: 'Summer', title: 'Storage Hydro Forced Outages Summer', messages: 0},
                        {gridName: 'ForcedOutageWinter', season: 'Winter', title: 'Storage Hydro Forced Outages Winter', messages: 0},
                        {gridName: 'TestResultOpSummer', season: 'Summer', title: 'Operational Test Results Summer', messages: 0},
                        {gridName: 'TestResultCapSummer', season: 'Summer', title: 'Capability Test Results Summer', messages: 0},
                        {gridName: 'TestResultCapWinter', season: 'Winter', title: 'Capability Test Results Winter', messages: 0}
                    ];

                    resp.data.forEach((dataItem: ApiValidations) => {
                        for (let gridDataItem of gridData) {
                            if ((dataItem.dataSetName === gridDataItem.gridName) && (dataItem.seasonName === gridDataItem.season)) {
                                gridDataItem.messages++;
                            }
                        }
                    })

                    setData(gridData);

                })
                .catch((error) => {
                    console.log(error);
                });
        };

        useEffect(() => {
            getData();
        }, []);

        useEffect(() => {
            if (currentCategory) {
                getData();
            }
        }, [currentCategory])

        const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

        const dataStateChange = (event: GridDataStateChangeEvent) => {
            setDataState(event.dataState);
        };

        return (
            <Grid
                data={process(data, dataState)}
                dataItemKey='id'
                pageable={false}
                sortable={true}
                filterable={true}
                resizable={true}
                {...dataState}
                onDataStateChange={dataStateChange}
                style={{width: 625}}
            >
                {
                    columnNames.length
                        ? columnNames.map((columnName, index) => {
                            switch (columnName) {
                                case 'gridName':
                                    return <GridColumn key={index} field={'title'} title={'Grid Name'} width='350px'/>
                                case 'messages':
                                    return <GridColumn key={index} field={'messages'} title={'Number of Validation Messages'} width='250px'/>
                                default:
                                    return null
                            }
                        })
                        : <GridColumn/>
                }
            </Grid>
        );
    }
;

const mapStateToProps = (state: any) => {
    return {
        currentCategory: state.defaultReducer.currentCategory
    }
};

export default connect(mapStateToProps)(HomeRaGrid);
